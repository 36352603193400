import '@components/common/layout/scrollbar.scss';

import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { ReactComponent as DroneIcon } from '@assets/icons/32x32/ic-drone-32.svg';
import { Center, HStack, Icon, Spinner, Text, VStack } from '@chakra-ui/react';
import DraggableControl from '@components/map/controls/DraggableControl';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { useGetFlightPlansQuery } from '@services/utm/flightPlan.api';
import { sortDate } from '@utils/date.utils';
import { getDroneNumber } from '@utils/utm/drone.utils';
import { addYears } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import { FlightPlanHistoryData } from '@/types/map.types';

import FlightPlanCard from './FlightPlanCard';

type Props = {
  top: number;
  left: number;
  onClose: () => void;
} & FlightPlanHistoryData;

export default function FlightPlanHistory({ top, left, droneCode, openingTime, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const activeSite = useAppSelector(selectActiveSite);

  const { data: flightPlans = [], isFetching } = useGetFlightPlansQuery(
    {
      fromDate: addYears(openingTime, -1).toISOString(),
      toDate: openingTime.toISOString(),
      sites: [activeSite!.code],
      droneCodes: [droneCode],
      alsoArchived: true,
    },
    { skip: !activeSite },
  );

  const activations = flightPlans.flatMap((flightPlan) =>
    flightPlan.activations.map((activation) => ({ flightPlan, activation })),
  );

  const drone = flightPlans.flatMap((fp) => fp.drones).find((drone) => drone.code === droneCode);

  return (
    <DraggableControl
      top={top}
      left={left}
      label={formatMessage({ id: 'flightPlanHistory.title' })}
      height={932}
      scrollable={false}
      width={500}
      onClose={onClose}
    >
      <VStack gap={0.25} backgroundColor="neutral.900" height="100%" overflow="hidden">
        <HStack width="100%" padding={4} backgroundColor="neutral.700" gap={2}>
          <Icon as={DroneIcon} width="32px" height="32px" color="neutral.white" />
          <Text padding={0.5} size="md" backgroundColor="neutral.black">
            {drone !== undefined ? getDroneNumber(drone) : droneCode}
          </Text>
        </HStack>
        {isFetching ? (
          <Center width="100%" height="100%">
            <Spinner height="72px" width="72px" color="neutral.white" />
          </Center>
        ) : (
          <>
            <Text padding={4} backgroundColor="neutral.700" size="lg" width="100%">
              <FormattedMessage id="flightPlanHistory.description" values={{ count: activations.length }} />
            </Text>
            {flightPlans.length === 0 ? (
              <Center width="100%" height="100%">
                <VStack width="100%">
                  <EmptyPanelDroneIcon />
                  <Text fontSize="16px" color="neutral.200">
                    <FormattedMessage id="flightPlanHistory.empty" />
                  </Text>
                </VStack>
              </Center>
            ) : (
              <VStack width="100%" gap={0.25} overflow="auto" className="popup-scrollbar">
                {activations
                  .toSorted(({ activation: activation1 }, { activation: activation2 }) =>
                    sortDate(activation1.endDate, activation2.endDate),
                  )
                  .map((info) => (
                    <FlightPlanCard
                      isSmall
                      isArchived
                      flightPlan={info.flightPlan}
                      activation={info.activation}
                      position={{ x: left, y: top }}
                      key={`${info.flightPlan.code} - ${info.activation.id}`}
                      onClose={onClose}
                    />
                  ))}
              </VStack>
            )}
          </>
        )}
      </VStack>
    </DraggableControl>
  );
}
