import { userLogout } from '@redux/authent/authent.reducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RawSituation, TacticalSituation } from '@/types/c2/c2.types';
import { ConfigConfiguration } from '@/types/config/config.types';
import { Round } from '@/types/config/round.types';
import { FlightPlan } from '@/types/utm/flightPlan.types';
import { FlyZone } from '@/types/utm/flyZone.types';

export type SituationState = {
  version: number;
  site: string;
  time: string;
  appCode: string | null;
  tacticalSituation: TacticalSituation | null;
  rawSituation: RawSituation | null;
  configuration: ConfigConfiguration | null;
  flightPlans: FlightPlan[];
  flyZones: FlyZone[];
  rounds: Round[];
};

export const SITUATION_INITIAL_STATE: SituationState = {
  version: -1,
  site: '',
  appCode: null,
  time: new Date().toISOString(),
  tacticalSituation: null,
  rawSituation: null,
  configuration: null,
  flightPlans: [],
  flyZones: [],
  rounds: [],
};

const situation = createSlice({
  name: 'situation',
  initialState: SITUATION_INITIAL_STATE,
  reducers: {
    updateSituation(state, action: PayloadAction<SituationState>) {
      if (state.version < action.payload.version) {
        return action.payload;
      }
    },
    updateVersion(state, action: PayloadAction<number>) {
      state.version = action.payload;
    },
    updateSite(state, action: PayloadAction<string>) {
      state.site = action.payload;
    },
    updateTime(state, action: PayloadAction<string>) {
      state.time = action.payload;
    },
    updateAppCode(state, action: PayloadAction<string | null>) {
      state.appCode = action.payload;
    },
    updateTacticalSituation(state, action: PayloadAction<TacticalSituation | null>) {
      state.tacticalSituation = action.payload;
    },
    updateRawSituation(state, action: PayloadAction<RawSituation | null>) {
      state.rawSituation = action.payload;
    },
    updateSituationConfiguration(state, action: PayloadAction<ConfigConfiguration | null>) {
      state.configuration = action.payload;
    },
    updateFlightplans(state, action: PayloadAction<FlightPlan[]>) {
      state.flightPlans = action.payload;
    },
    updateFlyZones(state, action: PayloadAction<FlyZone[]>) {
      state.flyZones = action.payload;
    },
    updateRounds(state, action: PayloadAction<Round[]>) {
      state.rounds = action.payload;
    },
    invalidateVersion(state) {
      state.version = SITUATION_INITIAL_STATE.version;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => {
      return { ...SITUATION_INITIAL_STATE };
    });
  },
});

export type SituationAction = ReturnType<(typeof situation.actions)[keyof typeof situation.actions]>;
export type SituationActionFunction = (typeof situation.actions)[keyof typeof situation.actions];

export const {
  updateSituation,
  invalidateVersion,
  updateVersion,
  updateSite,
  updateTime,
  updateSituationConfiguration,
  updateFlightplans,
  updateRawSituation,
  updateRounds,
  updateTacticalSituation,
  updateAppCode,
  updateFlyZones,
} = situation.actions;
export default situation.reducer;
