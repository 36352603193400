import HologardeBackground from '@assets/hologarde-background.jpg';
import { ReactComponent as ConfirmedBoldIcon } from '@assets/icons/24x24/ic-confirmed-bold-24.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/24x24/ic-error-24.svg';
import { ReactComponent as EyeIcon } from '@assets/icons/24x24/ic-eye-24.svg';
import { ReactComponent as EyeKoIcon } from '@assets/icons/24x24/ic-eye-ko-24.svg';
import { ReactComponent as LockIcon } from '@assets/icons/24x24/ic-padlock-24.svg';
import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/layout';
import {
  FormControl,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { userLogout } from '@redux/authent/authent.reducer';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import {
  useCheckPreviousPasswordsMutation,
  useGetMeQuery,
  usePatchUserPasswordMutation,
} from '@services/authent/user.api';
import { createErrorMessage, isFirstConnectionExceptionPayload } from '@utils/common.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { Field, Formik } from 'formik';
import { debounce } from 'lodash';
import { ReactNode, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { changePassword, isValidComposition, isValidRepetition, isValidSize } from 'src/validations/user.schema';

import { FirstConnectionExceptionPayload } from '@/types/commons/commons.types';

import ErrorsContainer from './common/form/ErrorsContainer';
import LoginButton from './login/LoginButton';

type Props = {
  children: ReactNode;
};

export default function UserInit({ children }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const activeUser = useAppSelector(selectActiveUser);
  const validationSchema = useValidation(changePassword);

  const [showCurrentPassword, setShowCurrentPassword] = useBoolean(false);
  const [showNewPassword, setShowNewPassword] = useBoolean(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useBoolean(false);

  const { isLoading: isLoadingGetMe, isError: isErrorGetMe, error: errorGetMe } = useGetMeQuery();
  const [
    patchPassword,
    { isLoading: isLoadingPatchPassword, isError: isErrorPatchPassword, error: errorPatchPassword },
  ] = usePatchUserPasswordMutation();
  const [checkPassword, { data: checkPasswordResult, isLoading: isLoadingCheckPassword }] =
    useCheckPreviousPasswordsMutation();

  function isValidPassword(
    currentPassword: string,
    newPassword: string,
    values: FirstConnectionExceptionPayload,
  ): boolean {
    const { first_name, name, email, login } = values.data;
    return (
      isValidSize(newPassword) &&
      isValidComposition(newPassword) &&
      isValidRepetition(newPassword, [first_name, name, email.split('@')[0], login]) &&
      currentPassword !== newPassword
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckPassword = useCallback(
    debounce((value: { id: string; newPassword: string }) => {
      checkPassword(value);
    }, 500),
    [],
  );

  const ValidIcon = <Icon height="20px" width="20px" as={ConfirmedBoldIcon} color="alertZero.500" />;
  const InvalidIcon = <Icon height="20px" width="20px" as={ErrorIcon} color="alertHi.500" />;
  const DefaultIcon = (
    <Center height="20px" width="20px" flexShrink={0}>
      {isLoadingCheckPassword ? (
        <Spinner width="20px" height="20px" color="neutral.white" />
      ) : (
        <Box flexShrink={0} borderRadius="50%" width="8px" height="8px" backgroundColor="neutral.500"></Box>
      )}
    </Center>
  );

  if (activeUser.id !== -1) {
    return children;
  }

  if (isErrorGetMe && !isFirstConnectionExceptionPayload(errorGetMe)) {
    dispatch(userLogout());
    createToast(createErrorMessage(errorGetMe), ToastStatusEnum.ERROR);
    return null;
  }

  /*console.log(`LOADING -> ${isLoadingGetMe}`);
  console.log(`ERROR -> ${errorGetMe}`);
  console.log(`INTER -> ${isLoadingGetMe || !errorGetMe}`);
  console.log(`FINAL -> ${isLoadingGetMe || !errorGetMe || !isFirstConnectionExceptionPayload(errorGetMe)}`);*/

  return (
    <Flex width="100%" height="100%" alignContent="center" justifyContent="center">
      <Image src={HologardeBackground} fit="cover" height="100%" width="100%" position="absolute" />
      {isLoadingGetMe || !errorGetMe || !isFirstConnectionExceptionPayload(errorGetMe) ? (
        <Center>
          <Spinner size="xl" color="neutral.white" />
        </Center>
      ) : (
        <VStack
          position="fixed"
          right={0}
          alignItems="start"
          gap={5}
          width="640px"
          height="100%"
          paddingLeft={8}
          paddingTop={9}
          paddingRight="104px"
          backgroundColor="neutral.900-op30"
        >
          <Text fontSize="32px" letterSpacing="4px" fontWeight="400" variant="space">
            <FormattedMessage id={`authent.firstLogin.title.${errorGetMe.data.user_state}`} />
          </Text>
          <Formik
            initialValues={{
              currentPassword: '',
              newPassword: '',
              passwordConfirmation: '',
            }}
            onSubmit={(values) =>
              patchPassword({
                id: errorGetMe.data.user_id,
                newPassword: values.newPassword,
                currentPassword: values.currentPassword,
              })
            }
            validationSchema={validationSchema}
          >
            {({ errors, touched, isValid, values, handleChange, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <VStack alignItems="start" width="100%" gap={5}>
                  <VStack alignItems="start" width="100%" gap={2}>
                    <VStack gap={1} alignItems="start">
                      <Text fontSize="16px">
                        <FormattedMessage id={`authent.firstLogin.subTitle.${errorGetMe.data.user_state}`} />
                      </Text>
                      <Text fontSize="16px">
                        <FormattedMessage id="authent.firstLogin.description" />
                      </Text>
                    </VStack>
                    <VStack alignItems="start" width="100%" gap={1}>
                      <HStack gap={2} alignItems="center">
                        {touched.newPassword || values.newPassword !== ''
                          ? isValidSize(values.newPassword)
                            ? ValidIcon
                            : InvalidIcon
                          : DefaultIcon}
                        <Text size="md">
                          <FormattedMessage id="authent.firstLogin.size" />
                        </Text>
                      </HStack>
                      <HStack gap={2} alignItems="center">
                        {checkPasswordResult === undefined
                          ? DefaultIcon
                          : checkPasswordResult && values.currentPassword !== values.newPassword
                            ? ValidIcon
                            : InvalidIcon}
                        <Text size="md">
                          <FormattedMessage id="authent.firstLogin.oldPassword" />
                        </Text>
                      </HStack>
                      <HStack gap={2} alignItems="start">
                        {touched.newPassword || values.newPassword !== ''
                          ? isValidRepetition(values.newPassword, [
                              errorGetMe.data.first_name,
                              errorGetMe.data.name,
                              errorGetMe.data.email.split('@')[0],
                              errorGetMe.data.login,
                            ])
                            ? ValidIcon
                            : InvalidIcon
                          : DefaultIcon}
                        <Text size="md">
                          <FormattedMessage id="authent.firstLogin.repetition" />
                        </Text>
                      </HStack>
                      <HStack gap={2} alignItems="start">
                        {touched.newPassword || values.newPassword !== ''
                          ? isValidComposition(values.newPassword)
                            ? ValidIcon
                            : InvalidIcon
                          : DefaultIcon}
                        <Text size="md">
                          <FormattedMessage id="authent.firstLogin.composition" />
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                  <VStack alignItems="start" width="100%" gap={3} userSelect="none">
                    <VStack gap={1} width="100%" alignItems="start">
                      <Text fontSize="16px" lineHeight="20px">
                        <FormattedMessage id="authent.firstLogin.currentPassword" />
                      </Text>
                      <FormControl isRequired isInvalid={touched.currentPassword && !!errors.currentPassword}>
                        <Field as={InputGroup} id="currentPassword" name="currentPassword">
                          <InputLeftElement pointerEvents="none" color="neutral.500">
                            <LockIcon width="20px" height="20px" />
                          </InputLeftElement>
                          <Input
                            id="currentPassword"
                            name="currentPassword"
                            placeholder={formatMessage({ id: 'authent.firstLogin.currentPassword' })}
                            paddingRight={7}
                            paddingLeft={7}
                            autoComplete="off"
                            type={showCurrentPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            variant="login"
                          />
                          <InputRightElement onClick={setShowCurrentPassword.toggle} cursor="pointer">
                            <Icon
                              as={showCurrentPassword ? EyeIcon : EyeKoIcon}
                              width="20px"
                              height="20px"
                              color="neutral.300"
                            />
                          </InputRightElement>
                        </Field>
                      </FormControl>
                    </VStack>
                    <VStack gap={1} width="100%" alignItems="start">
                      <Text fontSize="16px" lineHeight="20px">
                        <FormattedMessage id="authent.firstLogin.newPassword" />
                      </Text>
                      <FormControl isRequired isInvalid={touched.newPassword && !!errors.newPassword}>
                        <Field as={InputGroup} id="newPassword" name="newPassword">
                          <InputLeftElement pointerEvents="none" color="neutral.500">
                            <LockIcon width="20px" height="20px" />
                          </InputLeftElement>
                          <Input
                            id="newPassword"
                            name="newPassword"
                            placeholder={formatMessage({ id: 'authent.firstLogin.newPassword' })}
                            paddingRight={7}
                            paddingLeft={7}
                            autoComplete="off"
                            type={showNewPassword ? 'text' : 'password'}
                            onChange={(event) => {
                              setFieldValue('newPassword', event.target.value);
                              debouncedCheckPassword({
                                newPassword: event.target.value,
                                id: errorGetMe.data.user_id,
                              });
                            }}
                            variant="login"
                          />
                          <InputRightElement onClick={setShowNewPassword.toggle} cursor="pointer">
                            <Icon
                              as={showNewPassword ? EyeIcon : EyeKoIcon}
                              width="20px"
                              height="20px"
                              color="neutral.300"
                            />
                          </InputRightElement>
                        </Field>
                      </FormControl>
                    </VStack>
                    <VStack gap={1} width="100%" alignItems="start">
                      <Text fontSize="16px" lineHeight="20px">
                        <FormattedMessage id="authent.firstLogin.passwordConfirmation" />
                      </Text>
                      <FormControl isRequired isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}>
                        <Field as={InputGroup} id="passwordConfirmation" name="passwordConfirmation">
                          <InputLeftElement pointerEvents="none" color="neutral.500">
                            <LockIcon width="20px" height="20px" />
                          </InputLeftElement>
                          <Input
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            placeholder={formatMessage({ id: 'authent.firstLogin.passwordConfirmation' })}
                            paddingRight={7}
                            paddingLeft={7}
                            autoComplete="off"
                            type={showPasswordConfirmation ? 'text' : 'password'}
                            onChange={handleChange}
                            variant="login"
                          />
                          <InputRightElement onClick={setShowPasswordConfirmation.toggle} cursor="pointer">
                            <Icon
                              as={showPasswordConfirmation ? EyeIcon : EyeKoIcon}
                              width="20px"
                              height="20px"
                              color="neutral.300"
                            />
                          </InputRightElement>
                        </Field>
                      </FormControl>
                    </VStack>
                    <ErrorsContainer
                      errors={errors}
                      touched={touched}
                      apiError={isErrorPatchPassword ? createErrorMessage(errorPatchPassword) : undefined}
                    />
                  </VStack>
                  <LoginButton
                    isLoading={isLoadingPatchPassword}
                    isDisabled={
                      !isValid ||
                      !isValidPassword(values.currentPassword, values.newPassword, errorGetMe) ||
                      !checkPasswordResult ||
                      isLoadingCheckPassword
                    }
                    label="authent.connection"
                  />
                  <Text color="neutral.200">
                    <FormattedMessage
                      id="authent.firstLogin.help"
                      values={{ number: formatMessage({ id: 'global.adminNumber' }) }}
                    />
                  </Text>
                </VStack>
              </form>
            )}
          </Formik>
        </VStack>
      )}
    </Flex>
  );
}
