import { selectActiveUser } from '@redux/authent/authent.selectors';
import { updateCurrentWindowId, updateIsInactiveAt } from '@redux/global/global.reducer';
import { selectCurrentWindowId, selectIsInactiveAt } from '@redux/global/global.selector';
import { useCreateUserActionMutation } from '@services/authent/userAction.api';
import { timeDuration } from '@utils/date.utils';
import { millisecondsToMinutes } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { UserLight } from '@/types/authent/login.types';
import { LogTypeEnum, UserActionEnum, UserActionLog } from '@/types/c2/logs.type';

import { useAppSelector } from './redux.hooks';
import { useWithDispatch } from './useWithDispatch';

type Props = {
  inactivityLimit: number;
};
export const useInactivityTimer = ({ inactivityLimit }: Readonly<Props>) => {
  const activeUser = useAppSelector(selectActiveUser);
  const isInactiveAt = useAppSelector(selectIsInactiveAt);
  const setIsInactiveAt = useWithDispatch(updateIsInactiveAt);
  const currentWindowId = useAppSelector(selectCurrentWindowId);
  const setCurrentWindowId = useWithDispatch(updateCurrentWindowId);
  const [windowId] = useState<string>(uuidv4());
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const [createUserAction] = useCreateUserActionMutation();

  const getInactivityDuration = useCallback(
    (isInactive: boolean) => {
      if (isInactive || !isInactiveAt) {
        return inactivityLimit;
      }
      const inactivityDate = new Date(isInactiveAt);
      inactivityDate.setMinutes(inactivityDate.getMinutes() - inactivityLimit);
      const inactivityDuration = timeDuration(inactivityDate);
      return millisecondsToMinutes(inactivityDuration);
    },
    [inactivityLimit, isInactiveAt],
  );

  const getActivityLog = useCallback(
    (isInactive: boolean) => {
      const inactivityTime = getInactivityDuration(isInactive);
      const action = isInactive ? UserActionEnum.IS_INACTIVE : UserActionEnum.IS_ACTIVE;

      return {
        author: activeUser as UserLight,
        action: action,
        type: LogTypeEnum.USER_ACTION,
        time: new Date().toISOString(),
        parameters: [inactivityTime.toString()],
      } as UserActionLog;
    },
    [activeUser, getInactivityDuration],
  );

  const timer = useCallback(() => {
    timeout.current = setTimeout(
      () => {
        if (currentWindowId === '' || windowId === currentWindowId) {
          setIsInactiveAt(new Date());
          createUserAction(getActivityLog(true));
        }
      },
      inactivityLimit * 60 * 1000,
    );
  }, [createUserAction, getActivityLog, inactivityLimit, currentWindowId, setIsInactiveAt, windowId]);

  const handleActivity = useCallback(() => {
    clearTimeout(timeout.current);

    if (windowId !== currentWindowId) {
      setCurrentWindowId(windowId);
    }
    timer();
  }, [currentWindowId, setCurrentWindowId, windowId, timer]);

  const reset = () => {
    createUserAction(getActivityLog(false));
    setIsInactiveAt(null);
    handleActivity();
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click', 'touch', 'wheel'];
    if (isInactiveAt) {
      return;
    }
    events.forEach((event) => window.addEventListener(event, handleActivity));
    return () => {
      events.forEach((event) => window.removeEventListener(event, handleActivity));
      clearTimeout(timeout.current);
    };
  }, [handleActivity, isInactiveAt]);

  useEffect(() => {
    timer();
  }, []);

  return reset;
};
