import { UserActionLog } from '@/types/c2/logs.type';

import { AUTHENT_URL, authentApi } from './authent.api';

export const userActionApi = authentApi.injectEndpoints({
  endpoints: (builder) => ({
    createUserAction: builder.mutation<UserActionLog, Partial<UserActionLog>>({
      query: (userAction) => ({
        url: `${AUTHENT_URL}/user-actions`,
        method: 'POST',
        body: userAction,
      }),
    }),
  }),
});

export const { useCreateUserActionMutation } = userActionApi;
