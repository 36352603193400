import { createEntityAdapter } from '@reduxjs/toolkit';
import { sortDate } from '@utils/date.utils';

import { HoloLog } from '@/types/c2/logs.type';
import { NotificationType } from '@/types/data/data.types';

export const notificationAdapter = createEntityAdapter<NotificationType, number>({
  selectId: (notification) => notification.id,
  sortComparer: (notification1, notification2) => sortDate(notification1.startTime, notification2.startTime),
});

export const logsAdapter = createEntityAdapter<HoloLog, string>({
  selectId: (log) => log.code,
  sortComparer: (logA, logB) => new Date(logA.time).getTime() - new Date(logB.time).getTime(),
});
