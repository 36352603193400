import { ReactComponent as ArrowIcon } from '@assets/icons/24x24/ic-arrow-24.svg';
import { Center, HStack, Icon, Spinner, TabPanel, Text, VStack } from '@chakra-ui/react';
import { findStepByRank } from '@utils/data/notification.utils';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';

import { Procedure } from '@/types/config/procedure.types';

import ProcedureStepDetail from './ProcedureStepDetails';
import ProcedureSteps from './ProcedureSteps';

type Props = {
  currentStepRank: number | null;
  notificationId: number;
  procedure: Procedure;
  isFetching: boolean;
  canManage: boolean;
};

function NotificationProcedure({ notificationId, procedure, currentStepRank, isFetching, canManage }: Readonly<Props>) {
  const [selectedStepRank, setSelectedStepRank] = useState<number>(currentStepRank ?? 0);
  const currentStepInProgress = findStepByRank(procedure.steps, currentStepRank);
  const currentSelectedStep = findStepByRank(procedure.steps, selectedStepRank);
  const isAtFirstStep = selectedStepRank === 0;
  const isAtLastStep =
    selectedStepRank === procedure.steps[procedure.steps.length - 1].rank ||
    selectedStepRank === currentStepInProgress?.rank;

  return (
    <TabPanel height="100%" width="100%">
      {isFetching ? (
        <Center width="100%" height="100%" backgroundColor="neutral.700">
          <Spinner color="neutral.white" height="70px" width="70px" />
        </Center>
      ) : (
        <VStack width="100%" height="100%" gap={0.25} overflow="hidden">
          <HStack
            gap={4}
            paddingX={5}
            width="100%"
            flexShrink={0}
            justifyContent="space-between"
            height="136px"
            backgroundColor="neutral.700"
          >
            <Icon
              as={ArrowIcon}
              width="32px"
              height="32px"
              color="neutral.300"
              opacity={isAtFirstStep ? 0.5 : 1}
              cursor={isAtFirstStep ? 'default' : 'pointer'}
              transform="rotate(180deg)"
              paddingY="auto"
              onClick={() => !isAtFirstStep && setSelectedStepRank(selectedStepRank - 1)}
            />
            <VStack gap={3}>
              <ProcedureSteps
                procedure={procedure}
                selectedStepRank={selectedStepRank}
                setSelectedStepRank={setSelectedStepRank}
                size="md"
              />
              <Text fontWeight="medium">
                <FormattedMessage id={`notification.procedure.step.type.${currentSelectedStep?.type}.title`} />
              </Text>
            </VStack>
            <Icon
              as={ArrowIcon}
              width="32px"
              height="32px"
              color="neutral.300"
              opacity={isAtLastStep ? 0.5 : 1}
              cursor={isAtLastStep ? 'default' : 'pointer'}
              paddingY="auto"
              onClick={() => !isAtLastStep && setSelectedStepRank(selectedStepRank + 1)}
            />
          </HStack>
          {currentSelectedStep && (
            <ProcedureStepDetail
              step={currentSelectedStep}
              notificationId={notificationId}
              procedureId={procedure.id}
              canManage={canManage}
            />
          )}
        </VStack>
      )}
    </TabPanel>
  );
}

export default memo(NotificationProcedure, shallowEqual);
