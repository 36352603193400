import CoordinatesBar from '@components/map/controls/CoordinatesBar';
import { useAppSelector } from '@hooks/redux.hooks';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { usePatchCoordinatesUnitMutation } from '@services/config/setting.api';
import { toCoordinates } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { throttle } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { useMap } from 'react-map-gl';

import { Coordinates, CoordinatesUnitEnum } from '@/types/commons/commons.types';

type CoordinatesControlProps = {
  initialCoordinates: Coordinates;
};

function CoordinatesControl({ initialCoordinates }: Readonly<CoordinatesControlProps>) {
  const { current: mapbox } = useMap();
  const [position, setPosition] = useState<Coordinates>(initialCoordinates);
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const user = useAppSelector(selectActiveUser);
  const [updateCoordinatesUnit] = usePatchCoordinatesUnitMutation();

  const coordinatesDMS = new DmsCoordinates(position.latitude, position.longitude);

  const throttledHandleMouseMove = useMemo(
    () =>
      throttle((event: any) => { // eslint-disable-line
        if (mapbox) {
          const eventCoordinate = toCoordinates(event.lngLat);
          if (eventCoordinate) {
            setPosition({
              latitude: eventCoordinate.latitude,
              longitude: eventCoordinate.longitude,
              type: '2D',
            });
          }
        }
      }, 100),
    [mapbox],
  );

  const handleMouseMove = useCallback(throttledHandleMouseMove, [throttledHandleMouseMove]);

  const handleUnitChange = (unit: string) => {
    if (unit === CoordinatesUnitEnum.DECIMAL_DEGREES || unit === CoordinatesUnitEnum.DMS) {
      updateCoordinatesUnit({ login: user.login, coordinatesUnit: unit });
    }
  };

  useMapLayerMouseEvent('mousemove', handleMouseMove);

  return (
    <CoordinatesBar
      coordinatesDMS={coordinatesDMS}
      handleUnitChange={handleUnitChange}
      unit={userSettingsUnit}
      size="xs"
    />
  );
}

export default memo(CoordinatesControl);
