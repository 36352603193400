import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CustomSelect, { Option } from '@components/common/inputs/CustomStyledSelect';
import { useSetManualTargetIdentificationMutation } from '@services/c2/track.api';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TargetStatusEnum } from '@/types/c2/c2.types';
import { TrackLight } from '@/types/commons/commons.types';
import { Identification, IdentificationEnum } from '@/types/sensor/identification.types';

type Props = {
  identification: Identification;
  site: string;
  trackIds: TrackLight[];
  status: TargetStatusEnum;
  isDisabled?: boolean;
  width: number;
};

const AUTOMATIC = 'AUTOMATIC';

function IdentificationSelect({ identification, site, trackIds, status, width, isDisabled = false }: Readonly<Props>) {
  const formatMessage = useIntl().formatMessage;
  const [updateManualIdentification] = useSetManualTargetIdentificationMutation();

  const manualIdentificationOptions = [
    {
      value: AUTOMATIC,
      label: formatMessage({
        id: `target.identification.${identification.autoIdentification}`,
      }),
    },
  ].concat(
    Object.keys(IdentificationEnum).map((key) => {
      return {
        value: key,
        label: formatMessage({ id: `target.identification.${key}` }),
      };
    }),
  );

  return (
    <CustomSelect
      value={identification.manualIdentification ?? AUTOMATIC}
      options={manualIdentificationOptions}
      isClearable={false}
      isDisabled={isDisabled || status === TargetStatusEnum.LOST}
      width={width}
      listWidth={width}
      listOffset={false}
      variant="dark"
      formatOptionLabel={(option: Option) => (
        <HStack gap={1} justifyContent="space-between">
          <Text fontSize="16px" color="neutral.white">
            {option.label}
          </Text>
          <Text fontSize="16px" color="neutral.200">
            <FormattedMessage id={`target.${option.value === AUTOMATIC ? 'auto' : 'manual'}`} />
          </Text>
        </HStack>
      )}
      onChange={(selectedOption) =>
        selectedOption &&
        updateManualIdentification({
          tracks: trackIds,
          site: site,
          identification: selectedOption !== 'AUTOMATIC' ? (selectedOption as IdentificationEnum) : null,
        })
      }
    />
  );
}

export default memo(IdentificationSelect, isEqual);
