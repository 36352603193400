import { HStack, VStack } from '@chakra-ui/layout';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasAnySensorRoleFromActiveProfile } from '@redux/authent/authent.selectors';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { formatDate, formatDuration } from '@utils/date.utils';
import { extendedSensorShortHand } from '@utils/map/map.constants';
import { getRoundedValue } from '@utils/math.utils';
import { getTargetIdentification, getUniqueSensorFamilies, isDrone, isSpatial } from '@utils/target.utils';
import {
  AngleUnits,
  convertDistanceUnitFromMeter,
  convertSpeedUnitFromMPerS,
  formatUnit,
  HeightUnits,
  SpeedUnits,
} from '@utils/unit.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import { format } from 'date-fns';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage, useIntl } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { DroneNotificationLight } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { SensorTypeEnum } from '@/types/sensor/configuration.types';
import { SensorAction, TargetLocation } from '@/types/sensor/sensor.types';

import InfoValue from '../InfoValue';
import ClassificationSelect from './ClassificationSelect';
import DroneDetails from './DroneDetails';
import IdentificationSelect from './IdentificationSelect';

type Props = {
  target: Target<TargetLocation>;
  notification?: DroneNotificationLight;
};
export default function TargetDetails({ target, notification }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);

  const coordinatesDMS = new DmsCoordinates(
    target.lastPosition.location?.position?.latitude ?? 0,
    target.lastPosition.location?.position?.longitude ?? 0,
  );

  const formattedStartDate = formatDate(target.detectionStart, DateTimeEnum.DATE);
  const formattedEndDate = formatDate(target.detectionEnd, DateTimeEnum.DATE);
  const formattedStartTime = format(new Date(target.detectionStart), "HH':'mm");
  const formattedEndTime = format(new Date(target.detectionEnd), "HH':'mm");

  const isSameDay = formattedStartDate === formattedEndDate;

  const sensors = target.trackIds.reduce<Partial<Record<SensorTypeEnum, string[]>>>(
    (acc, { sensorType, sensorCode }) => {
      if (!acc[sensorType]) {
        acc[sensorType] = [];
      }
      acc[sensorType].push(sensorCode);
      return acc;
    },
    {} as Partial<Record<SensorTypeEnum, string[]>>,
  );

  const canManageTarget = useAppSelector((state) =>
    hasAnySensorRoleFromActiveProfile(
      state,
      getUniqueSensorFamilies(target.trackIds).map(
        (family) => ({ action: ActionEnum.MANAGE_TARGET, sensorFamily: family }) as SensorAction,
      ),
    ),
  );

  const isDroneTarget = isDrone(target);
  const showDroneInfo = isDroneTarget && notification !== undefined;

  return (
    <VStack gap={0.25} width="100%" height="100%">
      <VStack gap={4} padding={4} width="100%" backgroundColor="neutral.700">
        <HStack width="100%" gap={3}>
          <InfoValue
            label={formatMessage({ id: 'targetInfos.tab.details.identification' })}
            value={
              <IdentificationSelect
                identification={target.identification}
                site={target.site}
                trackIds={target.trackIds}
                status={target.status}
                width={isDroneTarget ? 248 : 516}
                isDisabled={!canManageTarget}
              />
            }
          />
          {isDroneTarget && (
            <InfoValue
              label={formatMessage({ id: 'targetInfos.tab.details.classification' })}
              value={
                <ClassificationSelect
                  classification={target.classification}
                  site={target.site}
                  trackIds={target.trackIds}
                  identification={getTargetIdentification(target)}
                  status={target.status}
                  isDisabled={!canManageTarget}
                />
              }
            />
          )}
        </HStack>
        <HStack width="100%" gap={3}>
          <InfoValue
            label={formatMessage({ id: 'targetInfos.tab.details.detectionDuration' })}
            value={
              <Text
                backgroundColor="neutral.800"
                textAlign="center"
                width="100%"
                lineHeight="23px"
                fontSize="18px"
                paddingY="9.5px"
                border="2px solid"
                borderColor="neutral.black"
              >
                {formatDuration(target.detectionStart, target.detectionEnd)}
              </Text>
            }
          />
          <Text alignSelf="end" width="100%" color="neutral.300" fontSize="18px" whiteSpace="pre-line">
            <FormattedMessage
              id={isSameDay ? 'targetInfos.tab.details.sameDay' : 'targetInfos.tab.details.multipleDays'}
              values={{
                startDate: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedStartDate}
                  </Text>
                ),
                startTime: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedStartTime}
                  </Text>
                ),
                endDate: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedEndDate}
                  </Text>
                ),
                endTime: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedEndTime}
                  </Text>
                ),
              }}
            />
          </Text>
        </HStack>
      </VStack>
      {showDroneInfo && <DroneDetails notification={notification} />}
      {isSpatial(target) && target.lastPosition.location && (
        <HStack gap={3} padding={4} width="100%" backgroundColor="neutral.700" alignItems="start">
          <InfoValue
            label={formatMessage({ id: 'targetInfos.tab.details.altitude' })}
            isEmpty={target.lastPosition.location.altitude === null}
            infoLabel={formatMessage({
              id: `targetInfos.tab.details.altitudeType.${target.lastPosition.location.altitudeType}`,
            })}
            value={
              <VStack width="100%" gap={0} align="start">
                <Text size="lg">
                  {formatUnit(
                    HeightUnits.METERS,
                    convertDistanceUnitFromMeter(HeightUnits.METERS, target.lastPosition.location.altitude),
                  )}
                </Text>
                <Text fontSize="18px">
                  {formatUnit(
                    HeightUnits.FEET,
                    convertDistanceUnitFromMeter(HeightUnits.FEET, target.lastPosition.location.altitude),
                  )}
                </Text>
              </VStack>
            }
          />
          <InfoValue
            label={formatMessage({ id: 'targetInfos.tab.details.height' })}
            isEmpty={target.lastPosition.location.height === null}
            infoLabel={formatMessage({
              id: `targetInfos.tab.details.altitudeType.${target.lastPosition.location.heightType}`,
            })}
            value={
              <VStack width="100%" gap={0} align="start">
                <Text size="lg">
                  {formatUnit(
                    HeightUnits.METERS,
                    convertDistanceUnitFromMeter(HeightUnits.METERS, target.lastPosition.location.height),
                  )}
                </Text>
                <Text fontSize="18px">
                  {formatUnit(
                    HeightUnits.FEET,
                    convertDistanceUnitFromMeter(HeightUnits.FEET, target.lastPosition.location.height),
                  )}
                </Text>
              </VStack>
            }
          />
          <InfoValue
            label={formatMessage({ id: 'targetInfos.tab.details.heading' })}
            value={formatUnit(AngleUnits.TURN, getRoundedValue(target.lastPosition.location.heading))}
          />
          <InfoValue
            label={formatMessage({ id: 'targetInfos.tab.details.speed' })}
            value={
              <VStack width="100%" gap={0} align="start">
                <Text size="lg">
                  {formatUnit(
                    SpeedUnits.KILOMETERS_PER_HOUR,
                    getRoundedValue(
                      convertSpeedUnitFromMPerS(SpeedUnits.KILOMETERS_PER_HOUR, target.lastPosition.location.speed, 0),
                    ),
                  )}
                </Text>
                <Text fontSize="18px">
                  {formatUnit(
                    SpeedUnits.KNOTS,
                    getRoundedValue(convertSpeedUnitFromMPerS(SpeedUnits.KNOTS, target.lastPosition.location.speed, 0)),
                  )}
                </Text>
              </VStack>
            }
          />
        </HStack>
      )}
      <Box padding={4} width="100%" backgroundColor="neutral.700">
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.sensors' })}
          value={
            <HStack width="100%" gap={0.5}>
              {Object.entries(sensors).map(([key, values]) => (
                <Tooltip
                  sx={{
                    bg: 'transparent',
                  }}
                  label={
                    <VStack gap={0.25}>
                      {values.map((code) => (
                        <Text paddingX={1.5} paddingY={1} key={code} fontSize="16px" backgroundColor="neutral.black">
                          {code}
                        </Text>
                      ))}
                    </VStack>
                  }
                  key={key}
                  placement="right"
                >
                  <Text backgroundColor="neutral.black" fontSize="18px" color="neutral.300" padding={0.5}>
                    {extendedSensorShortHand[key as SensorTypeEnum]}
                  </Text>
                </Tooltip>
              ))}
            </HStack>
          }
        />
      </Box>
      <VStack gap={3} padding={4} width="100%" backgroundColor="neutral.700" flex={1}>
        <InfoValue label={formatMessage({ id: 'targetInfos.tab.details.grids' })} value={undefined} />
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.latLong' })}
          value={formatCoordinatesToString(coordinatesDMS, userSettingsUnit)}
        />
      </VStack>
    </VStack>
  );
}
