import { ReactComponent as AirportIcon } from '@assets/icons/24x24/ic-airport-24.svg';
import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import DropSelect from '@components/common/menu/DropSelect';
import { Dispatch } from 'react';

import { Platform } from '@/types/config/config.types';

type Props = {
  platformFilters: { platform: Platform; active: boolean }[];
  setState: Dispatch<{ platform: Platform; active: boolean }[]>;
};

function Header({ platformFilters, setState }: Readonly<Props>) {
  return (
    <HStack justifyContent="start" gap={0.25} paddingX={3} minWidth={0} flex={1}>
      <DropSelect
        icon={AirportIcon}
        values={
          new Set(
            platformFilters
              .filter((platformFilter) => platformFilter.active)
              .map((platformFilter) => platformFilter.platform.code),
          )
        }
        options={[...platformFilters].map((platformFilter) => ({
          value: platformFilter.platform.code,
          label: platformFilter.platform.name,
        }))}
        onChange={(values) =>
          setState(
            platformFilters.map((platformFilter) => ({
              platform: platformFilter.platform,
              active: values.has(platformFilter.platform.code),
            })),
          )
        }
      />
      <HStack
        gap={0.25}
        overflowX="auto"
        flex={1}
        minWidth={0}
        sx={{
          '::-webkit-scrollbar': { display: 'none' },
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
        }}
      >
        {platformFilters
          .filter((platformFilter) => platformFilter.active)
          .map((platformFilter) => (
            <Text
              paddingX={2}
              key={platformFilter.platform.code}
              paddingY="11px"
              backgroundColor="neutral.black"
              flexShrink={0}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {platformFilter.platform.name}
            </Text>
          ))}
      </HStack>
    </HStack>
  );
}

export default Header;
