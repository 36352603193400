import { ReactComponent as AlertSoundIcon } from '@assets/icons/32x32/ic-alertSound-32.svg';
import { Box, Center, Icon, Spinner, Text, VStack } from '@chakra-ui/react';
import { useStartProcedureMutation } from '@services/data/notification.api';
import { isEqual } from 'lodash';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProcedureStateEnum, ProcedureStepTypeEnum } from '@/types/config/procedure.types';

import ProcedureStepsSimplified from './ProcedureStepsSimplified';

type Props = {
  notificationId: number;
  procedureState: ProcedureStateEnum | null;
  nbSteps: number;
  currentStepRank: number | null;
  currentStepType: ProcedureStepTypeEnum | null;
  canManageNotification: boolean;
};

function NotificationCardProcedure({
  notificationId,
  procedureState,
  nbSteps,
  currentStepRank,
  currentStepType,
  canManageNotification,
}: Readonly<Props>) {
  const [startProcedure] = useStartProcedureMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (procedureState === null || nbSteps === 0 || currentStepRank === null || currentStepType === null) {
    return null;
  }

  const isInAlarm = procedureState === ProcedureStateEnum.TO_PROCESS;

  if (isLoading && !isInAlarm) {
    setIsLoading(false);
  }

  return (
    <>
      <Box height={isInAlarm ? '100%' : '75%'} width="2px" bg="neutral.900" />
      {isInAlarm ? (
        <Center
          width="225px"
          height="100%"
          bgGradient="radial-gradient(115.63% 50.45% at 50% 50%, alertHi.800 74.5%, #7D272F 94%, #931E23 100%)"
          _hover={{
            bgGradient: 'radial-gradient(115.63% 50.45% at 50% 50%, alertHi.800 0%, #7D272F 94%, #931E23 100%)',
          }}
          role="group"
          cursor={canManageNotification ? 'pointer' : 'default'}
          onClick={(e) => {
            if (canManageNotification) {
              setIsLoading(true);
              startProcedure(notificationId)
                .unwrap()
                .catch(() => setIsLoading(false));
            }
            e.stopPropagation();
          }}
        >
          {isLoading ? (
            <Spinner width="32px" height="32px" color="alertHi.500" />
          ) : (
            <Icon
              as={AlertSoundIcon}
              width="32px"
              height="32px"
              color="alertHi.500"
              _groupHover={{
                color: '#E5484E',
              }}
            />
          )}
        </Center>
      ) : (
        <VStack gap={2} height="100%" width="172px" flexShrink={0} paddingX={3} paddingTop={3} pointerEvents="none">
          <ProcedureStepsSimplified currentStepRank={currentStepRank} nbSteps={nbSteps} />
          <Text textAlign="center">
            <FormattedMessage id={`notification.procedure.step.type.${currentStepType}.title`} />
          </Text>
        </VStack>
      )}
      <Box height={isInAlarm ? '100%' : '75%'} width="2px" bg="neutral.900" />
    </>
  );
}

export default memo(NotificationCardProcedure, isEqual);
