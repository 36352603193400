import darkRoad from '@assets/styles/darkRoad.json';
import lightRoad from '@assets/styles/lightRoad.json';
import plan from '@assets/styles/plan.json';
import satellite from '@assets/styles/satellite.json';
import { toLowercaseValueRecord } from '@utils/common.utils';
import { BBox } from 'geojson';
import { MapStyle } from 'react-map-gl';

import { Coordinates } from '@/types/commons/commons.types';
import { ZoneCategoryEnum } from '@/types/config/config.types';
import { DroneAlertLevelEnum } from '@/types/data/data.types';
import { FeatureTypeEnum, LayerNameEnum, SensorLikeDisplayedOnMap } from '@/types/map.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { ExtendedSensor, SensorLikeEnum, SensorTypeEnum, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { StrobeIdentificationEnum } from '@/types/sensor/identification.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';
import { FlightPlanStateEnum } from '@/types/utm/flightPlan.types';

/**
 * Notre-Dame de Paris.
 */
export const PARIS: Coordinates = {
  longitude: 2.349966090270689,
  latitude: 48.85299971321042,
  type: '2D',
};

export const DEFAULT_BOUNDS: BBox = [2.274831259727121, 48.68306550795673, 2.483168740272879, 48.76293449204327];

export const mapStyles: { [key: string]: MapStyle } = {
  plan: plan as MapStyle,
  darkRoad: darkRoad as MapStyle,
  lightRoad: lightRoad as MapStyle,
  satellite: satellite as MapStyle,
};

export const CONTEXT_MENU_ZINDEX = 100;

export const POLYGONS_OPACITY = 0.5;
export const ZONE_POLYGONS_OPACITY = 0.4;
export const NO_FLY_ZONE_OPACITY = 0.3;

export const SELECTED_FEATURE_COLOR = '#fff';

export const targetColor: Record<ClassificationEnum, string> = {
  FRIEND: '#13A813',
  UNKNOWN: '#94A3B8',
  UNCLASSIFIED: '#FFFFFF',
  WATCH: '#F1CD2D',
  SUSPECT: '#F06710',
  ENEMY: '#DE1A22',
};

export const strobeColor: Record<StrobeIdentificationEnum, string> = {
  UNKNOWN: '#222',
  REMOTE_CONTROL: '#a427bf',
  DRONE: '#bf7027',
};

export type PolygonWithOutlineColor = { fill: string; outline: string };

export const zoneColor: Partial<Record<ZoneCategoryEnum, PolygonWithOutlineColor>> = {
  [ZoneCategoryEnum.NIZ]: { fill: '#6D91E5', outline: '#6D91E5' },
  [ZoneCategoryEnum.IMZ]: { fill: '#4076E3', outline: '#4076E3' },
  [ZoneCategoryEnum.NNZ]: { fill: '#6D91E5', outline: '#6D91E5' },
};

export const nfzColor: Record<DroneAlertLevelEnum, PolygonWithOutlineColor> = {
  [DroneAlertLevelEnum.DRONE_CRITICAL]: { fill: '#FF0000', outline: '#FF0000' },
  [DroneAlertLevelEnum.DRONE_WARNING]: { fill: '#FF8C00', outline: '#FF8C00' },
  [DroneAlertLevelEnum.DRONE_INFO]: { fill: '#FAFF00', outline: '#FAFF00' },
};

export const imzMarginColor: PolygonWithOutlineColor = { fill: '#6D91E5', outline: '#6D91E5' };

export const flightPlanColor: Record<FlightPlanStateEnum, PolygonWithOutlineColor> = {
  [FlightPlanStateEnum.INACTIVE]: { fill: '#888888', outline: '#888888' },
  [FlightPlanStateEnum.ACTIVE]: { fill: '#09D700', outline: '#09D700' },
};

export const sensorShorthand: Record<SensorTypeEnum, string> = {
  ADSB: 'AD',
  AEROSCOPE: 'AER',
  ARDRONIS: 'RF',
  AUTOMATON: 'API',
  AVISO: 'AVI',
  DRONE_SHIELD: 'RF',
  GROUND_STATION: 'GCS',
  HOLODRONE: 'RID',
  HOLOPTICS: 'CAM',
  ONVIF: 'CAM',
  SENID: 'RID',
  SKY_EYE: 'RF',
  SPEXER: 'RAD',
  XENTA: 'RAD',
  ZMER: 'CAM',
};

export const subSensorShortHand: Record<SubSensorTypeEnum, string> = {
  AUTOMATON_CABINET: 'BT',
  ARDRONIS_ANTENNA: 'RF',
  SENID_ANTENNA: 'RID',
  SKY_EYE_ANTENNA: 'RF',
  SPEXER_RADAR: 'RAD',
  AUTOMATON_SEGMENT: 'TRO',
};

export const sensorLikeShortHand: Record<SensorLikeEnum, string> = {
  PERIMETER_CAMERA: 'P-CAM',
};

export const extendedSensorShortHand: Record<ExtendedSensor, string> = {
  ...sensorShorthand,
  ...subSensorShortHand,
  ...sensorLikeShortHand,
};

export const sensorLikeIconShorthand: Record<SensorLikeDisplayedOnMap, Lowercase<string>> = {
  ...toLowercaseValueRecord(sensorShorthand),
  ...toLowercaseValueRecord(subSensorShortHand),
  ...toLowercaseValueRecord(sensorLikeShortHand),
};

export const AUTOMATON_SECTION_LAYER_NAMES = [
  LayerNameEnum.AUTOMATON_SECTIONS,
  LayerNameEnum.AUTOMATON_SECTION_EXTREMITIES,
  LayerNameEnum.AUTOMATON_SECTION_ICONS,
  LayerNameEnum.AUTOMATON_SECTION_LABELS,
  LayerNameEnum.AUTOMATON_SECTION_OUTLINES,
];

export const AUTOMATON_SECTION_FEATURE_TYPES = [
  FeatureTypeEnum.AUTOMATON_SECTION_ENDING,
  FeatureTypeEnum.AUTOMATON_SECTION_ICON,
  FeatureTypeEnum.AUTOMATON_SECTION_LABEL,
  FeatureTypeEnum.AUTOMATON_SECTION_LINE,
];

export enum IconColorEnum {
  RED = 'rd',
  ORANGE = 'or',
  YELLOW = 'yl',
  GREEN = 'gn',
  WHITE = 'wh',
  GRAY = 'gy',
  PURPLE = 'pr',
}

export enum ColorEnum {
  RED = '#DE1A22',
  ORANGE = '#F06710',
  YELLOW = '#F1CD2D',
  GREEN = '#13A813',
  WHITE = '#FFFFFF',
  GRAY = '#94A3B8',
  PURPLE = '#B18FE8',
}

export const SensorIconStatusFromSensorStatus: Record<SensorStatusEnum, IconColorEnum> = {
  DEGRADED: IconColorEnum.ORANGE,
  DESYNCHRONIZED: IconColorEnum.ORANGE,
  EFFRACTION: IconColorEnum.RED,
  HEALTHY: IconColorEnum.GREEN,
  ROUND: IconColorEnum.GREEN,
  TRACKING: IconColorEnum.GREEN,
  HT_DETECTION: IconColorEnum.GREEN,
  NODATA: IconColorEnum.PURPLE,
  SLEEP: IconColorEnum.GRAY,
  WAITING: IconColorEnum.PURPLE,
  DISCONNECTED: IconColorEnum.PURPLE,
};

export const automatonSectionColor: Partial<Record<SensorStatusEnum, IconColorEnum>> = {
  EFFRACTION: IconColorEnum.RED,
  DISCONNECTED: IconColorEnum.WHITE,
  DEGRADED: IconColorEnum.ORANGE,
  SLEEP: IconColorEnum.GRAY,
  HEALTHY: IconColorEnum.GREEN,
  HT_DETECTION: IconColorEnum.GREEN,
};

export const automatonSectionColorHexa: Partial<Record<SensorStatusEnum, ColorEnum>> = {
  EFFRACTION: ColorEnum.RED,
  DISCONNECTED: ColorEnum.WHITE,
  DEGRADED: ColorEnum.ORANGE,
  SLEEP: ColorEnum.GRAY,
  HEALTHY: ColorEnum.GREEN,
  HT_DETECTION: ColorEnum.GREEN,
};

export const PERIMETER_CAMERA_FILL_COLOR = '#0097b7';
export const SELECTED_PERIMETER_CAMERA_FILL_COLOR = '#FFF';
