import { Procedure, ProcedureStepStateEnum } from '@/types/config/procedure.types';

import { DATA_URL, dataApi } from './data.api';

export const procedureApi = dataApi.injectEndpoints({
  endpoints: (builder) => ({
    updateProcedure: builder.mutation<
      Procedure,
      { notificationId: number; procedureId: number; step: number; state: ProcedureStepStateEnum }
    >({
      query: ({ procedureId, step, state }) => ({
        url: `${DATA_URL}/procedures/${procedureId}`,
        method: 'PATCH',
        params: { step, state },
      }),
      invalidatesTags: (_, __, arg) => [{ type: 'Notification', id: arg.notificationId }],
    }),
  }),
});

export const { useUpdateProcedureMutation } = procedureApi;
