import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { mapStyles } from '@utils/map/map.constants';
import { MapStyle } from 'react-map-gl';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { UserSettings } from '@/types/config/settings.types';
import { MapFilters } from '@/types/filters.types';

import { SettingsState } from './settings.reducer';

export function selectSettingsState(state: RootState): SettingsState {
  return state.settings;
}

export const selectUserSettings = createSelector(
  selectSettingsState,
  (state: SettingsState): UserSettings => state.userSettings,
);
export const selectContext = createSelector(selectUserSettings, (state: UserSettings): ContextEnum => state.context);

export const selectCoordinatesUnit = createSelector(
  selectUserSettings,
  (state: UserSettings): CoordinatesUnitEnum => state.coordinatesUnit,
);

export const selectMapBackground = createSelector(
  selectUserSettings,
  (state: UserSettings): MapStyle => mapStyles[state.mapBackground],
);

export const selectAxisInvertedY = createSelector(
  selectUserSettings,
  (state: UserSettings): boolean => state.axisInvertedY,
);

export const selectLinearJoystickCoefficient = createSelector(
  selectUserSettings,
  (state: UserSettings): number => state.linearJoystickCoefficient,
);

export const selectZoomJoystickCameraSensitivity = createSelector(
  selectUserSettings,
  (state: UserSettings): number => state.zoomJoystickCameraSensitivity,
);

export const selectAutomatedLad = createSelector(
  selectUserSettings,
  (state: UserSettings): boolean => state.automatedLad,
);

// Use selectActualMapFilters to filter features on the map,
// this selector return the last updated value from getUserSettings
export const selectMapFilters = createSelector(
  selectUserSettings,
  (state: UserSettings): MapFilters => state.mapFilters,
);

export const selectLocalMapFilters = createSelector(
  selectSettingsState,
  (state: SettingsState): Partial<MapFilters> => state.localMapFilters,
);

export const selectActualMapFilters = createSelector(
  [selectMapFilters, selectLocalMapFilters],
  (mapFilters: MapFilters, localMapFilters: Partial<MapFilters>): MapFilters => ({ ...mapFilters, ...localMapFilters }),
);

export const selectLocalMapFilterByKey = createSelector(
  [selectActualMapFilters, (state, filterKey: keyof MapFilters) => filterKey],
  (mapFilters, filterKey): boolean => {
    return mapFilters[filterKey];
  },
);
