import { ToastNotificationContainer } from '@components/cockpit/toast/notification/ToastNotificationContainer';
import ContextMenu from '@components/map/contextMenu/ContextMenu';
import { AutomatonSectionIconLayer } from '@components/map/layers/automatonSections/AutomatonSectionIconLayer';
import { AutomatonSectionLineLayer } from '@components/map/layers/automatonSections/AutomatonSectionLineLayer';
import EventLayer from '@components/map/layers/event/EventLayer';
import FlightPlanLayer from '@components/map/layers/flightPlans/FlightPlanLayer';
import LadCameraLayer from '@components/map/layers/ladCameras/LadCameraLayer';
import { PerimeterCameraLabelLayer } from '@components/map/layers/perimeterCameras/PerimeterCameraLabelLayer';
import { PerimeterCameraSectorLayer } from '@components/map/layers/perimeterCameras/PerimeterCameraSectorLayer';
import PerimeterSensorLayer from '@components/map/layers/perimeterSensors/PerimeterSensorLayer';
import PoiLayer from '@components/map/layers/poi/PoiLayer';
import RadarCoverageLayer from '@components/map/layers/radarCoverage/RadarCoverageLayer';
import RoundLayer from '@components/map/layers/round/RoundLayer';
import SelectionLayer from '@components/map/layers/SelectionLayer';
import SensorLayer from '@components/map/layers/sensors/SensorLayer';
import StrobeLayer from '@components/map/layers/strobes/StrobeLayer';
import TargetLayer from '@components/map/layers/targets/TargetLayer';
import ZoneLayer from '@components/map/layers/zones/ZoneLayer';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { selectMapDrawModeByMapId } from '@redux/maps/maps.selectors';

import { DrawPolygonLayer } from './layers/draw/polygons/DrawPolygonLayer';
import MapboxMap from './MapboxMap';

export default function LiveMap() {
  const isMapDrawModeOn = useSelectorWithMapId(selectMapDrawModeByMapId);

  // Do not add controls here

  return (
    <MapboxMap contextMenu={<ContextMenu />}>
      {isMapDrawModeOn && <DrawPolygonLayer />}
      <ZoneLayer />
      <FlightPlanLayer />
      <RoundLayer />
      <RadarCoverageLayer />
      <PerimeterCameraSectorLayer />
      <AutomatonSectionLineLayer />
      <PerimeterCameraLabelLayer />
      <LadCameraLayer />
      <StrobeLayer />
      <PerimeterSensorLayer />
      <SensorLayer />
      <AutomatonSectionIconLayer />
      <PoiLayer />
      <EventLayer />
      <TargetLayer />
      <SelectionLayer />
      <ToastNotificationContainer />
    </MapboxMap>
  );
}
