import { ReactComponent as InfoIcon } from '@assets/icons/32x32/ic-infoWarning-32.svg';
import { Button, Center, ChakraProvider, Icon, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import { messages } from '@components/locale/MessageProvider';
import { useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';

import theme from './theme/theme';

export default function ErrorPage() {
  const [isAbove1080p] = useMediaQuery('(min-width: 1920px)');
  const [isLoading, setIsLoading] = useState(false);
  const lang = navigator.language || navigator.languages[0] || 'en';
  const locale = lang.startsWith('fr') ? 'fr' : 'en';

  function handleRefresh() {
    setIsLoading(true);
    window.location.reload();
  }

  return (
    <ChakraProvider theme={theme}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Center backgroundColor="neutral.850" width="100vw" height="100vh">
          <VStack gap={7} backgroundColor="neutral.700" width="600px" padding={isAbove1080p ? 9 : 7}>
            <Icon as={InfoIcon} color="neutral.300" width="48px" height="48px" />
            <VStack width="100%" gap={4}>
              <Text fontSize={isAbove1080p ? '32px' : '24px'} variant="space">
                <FormattedMessage id="errorPage.title" />
              </Text>
              <Text textAlign="center" fontSize={isAbove1080p ? '18px' : '16px'} whiteSpace="pre-line">
                <FormattedMessage id="errorPage.description" />
              </Text>
              <Button
                size="m"
                width="max-content"
                isLoading={isLoading}
                height={isAbove1080p ? '64px' : '48px'}
                variant="formButtonPrimary"
                onClick={handleRefresh}
              >
                <Text fontSize={isAbove1080p ? '20px' : '16px'} variant="space">
                  <FormattedMessage id="errorPage.reload" />
                </Text>
              </Button>
            </VStack>
          </VStack>
        </Center>
      </IntlProvider>
    </ChakraProvider>
  );
}
