import { Button, Center, Modal, ModalContent, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useInactivityTimer } from '@hooks/useInactivityTimer';
import { selectIsInactiveAt } from '@redux/global/global.selector';
import { FormattedMessage } from 'react-intl';

type Props = {
  inactivityLimit: number;
};

export default function InactivityModal({ inactivityLimit }: Readonly<Props>) {
  const isInactiveAt = useAppSelector(selectIsInactiveAt);

  const resetInactivityTimer = useInactivityTimer({ inactivityLimit });
  return (
    <Modal
      isOpen={isInactiveAt != null}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={resetInactivityTimer}
    >
      <ModalOverlay />
      <ModalContent>
        <Center backgroundColor="neutral.700" padding={4}>
          <VStack width="100%" gap={3}>
            <Text textAlign="center">
              <FormattedMessage id="authent.inactivityMessage" values={{ minutes: inactivityLimit }} />
            </Text>
            <Button variant="formButtonPrimary" width="max-content" onClick={resetInactivityTimer}>
              <FormattedMessage id="authent.isActive" />
            </Button>
          </VStack>
        </Center>
      </ModalContent>
    </Modal>
  );
}
