import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/40x40/ic-phoneNegative-40.svg';
import { HStack, Icon, TabPanel, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Pilot } from '@/types/utm/pilot.types';

type Props = {
  pilots: Pilot[];
};

export default function FlightPlanPilots({ pilots }: Readonly<Props>) {
  return (
    <TabPanel height="100%">
      {pilots.length === 0 ? (
        <VStack width="100%" paddingY="67px">
          <EmptyPanelDroneIcon />
          <Text fontSize="16px" color="neutral.200">
            <FormattedMessage id="flightPlanInfos.tab.pilots.empty" />
          </Text>
        </VStack>
      ) : (
        <VStack width="100%" gap={0.25}>
          {pilots.map((pilot) => (
            <HStack
              key={pilot.code}
              width="100%"
              backgroundColor="neutral.800"
              padding={4}
              justifyContent="space-between"
            >
              <HStack gap={1.5}>
                <Text size="lg">
                  <FormattedMessage
                    id="flightPlanInfos.tab.pilots.name"
                    values={{ firstName: pilot.firstName, lastName: pilot.lastName }}
                  />
                </Text>
              </HStack>
              <HStack gap={1.5}>
                <Icon as={PhoneIcon} width="24px" height="24px" color="neutral.300" />
                <Text fontSize="18px">{pilot.phoneNumber}</Text>
              </HStack>
            </HStack>
          ))}
        </VStack>
      )}
    </TabPanel>
  );
}
