import {
  automatonSectionExtremityLayer,
  automatonSectionLayer,
  automatonSectionOutlineLayer,
} from '@components/map/layers/automatonSections/automatonSection.layer';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectAutomatonCabinetSectionLineFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export function AutomatonSectionLineLayer() {
  const segmentLineFeatures = useSelectorWithReplayMode(selectAutomatonCabinetSectionLineFeatures);

  if (!isFeatureCollection(segmentLineFeatures)) return null;

  return (
    <Source id={SourceNameEnum.AUTOMATON_SECTION_LINES} type="geojson" data={segmentLineFeatures} generateId>
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_OUTLINES} {...automatonSectionOutlineLayer} />
      <Layer key={LayerNameEnum.AUTOMATON_SECTIONS} {...automatonSectionLayer} />
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_EXTREMITIES} {...automatonSectionExtremityLayer} />
    </Source>
  );
}
