import { updateAuthent, updateHasBeenInitialized, updateHasToLogout } from '@redux/authent/authent.reducer';
import { addMessages } from '@redux/chat/chat.reducer';
import { updateConfiguration } from '@redux/config/config.reducer';
import { initNotifications } from '@redux/data/data.reducer';
import { updateLogs } from '@redux/global/global.reducer';
import { updateReplay } from '@redux/replay/replay.reducer';
import { updateSensors, updateSensorsByAppCode } from '@redux/sensors/sensors.reducer';
import {
  SituationAction,
  SituationState,
  updateAppCode,
  updateFlightplans,
  updateFlyZones,
  updateRawSituation,
  updateRounds,
  updateSite,
  updateSituationConfiguration,
  updateTacticalSituation,
  updateTime,
  updateVersion,
} from '@redux/situation/situation.reducer';
import { updateUtm } from '@redux/utm/utm.reducer';
import { ActionCreator, UnknownAction } from '@reduxjs/toolkit';
import patch from 'json-touch-patch';

import { GlobalConfiguration, SituationMessage } from '@/types/c2/c2.types';
import {
  ActionsMap,
  CrudSseAction,
  SseActionEnum,
  SseMessageEvent,
  SseModuleTypeEnum,
  SseTypeEnum,
} from '@/types/c2/sse.types';

export function isCrudSseAction(value: SseActionEnum): value is CrudSseAction {
  return [SseActionEnum.CREATE, SseActionEnum.UPDATE, SseActionEnum.UPDATE_ALL, SseActionEnum.DELETE].includes(value);
}

export function getStoreActionBySseMessage<T>(
  type: SseTypeEnum,
  action: CrudSseAction,
  data: T,
): UnknownAction | null | undefined {
  const actionFunction = ActionsMap[type][action];
  return actionFunction ? (actionFunction(data) as UnknownAction) : actionFunction;
}

export function modelSseHandler(message: SseMessageEvent, site: string): UnknownAction[] {
  const data = JSON.parse(message.data);
  const [action, ...rest] = message.lastEventId.split('_');
  const type = rest.join('_');

  if (isCrudSseAction(action as SseActionEnum)) {
    if (type in SseTypeEnum) {
      const actionToDo = getStoreActionBySseMessage(type as SseTypeEnum, action as CrudSseAction, data);
      if (actionToDo) {
        return [actionToDo];
      } else if (actionToDo === undefined) {
        console.error(`Behaviour for action ${type}/${action} is not implemented (received from SSE)`);
      }
      return [];
    }
    console.warn(`The type ${type} is missing in the sse model handler`);
  } else if (action === SseActionEnum.INIT) {
    const {
      users,
      profiles,
      sites,
      roles,
      authentSettings,
      groups,
      drones,
      pilots,
      flightPlans,
      flyZones,
      flyRoutes,
      flySections,
      home,
      messages,
      mergingConfiguration,
      noFlyZones,
      noFlyZones3d,
      initializationMaskingZones,
      noNeutralizationZones,
      pointOfInterests,
      zoneOfInterests,
      noInitZones,
      videoConfiguration,
      videoStreams,
      gridConfigurations,
      layouts,
      sensors,
      perimeterCameras,
      sensorStorage,
      replayEvents,
      replayManualPeriods,
      replayStorageConfiguration,
      replaySettings,
      globalScreenConfigurations,
      roundConfigurations,
      platforms,
      logs,
      equipments,
      ongoingNotifications,
      procedureModels,
    } = data[site] as GlobalConfiguration;

    switch (type) {
      case SseModuleTypeEnum.AUTHENT: {
        return [
          updateAuthent({ users, profiles, roles, sites, groups, authentSettings }),
          updateLogs(logs),
          updateHasToLogout(false),
        ];
      }
      case SseModuleTypeEnum.CHAT: {
        return [addMessages(messages), updateLogs(logs)];
      }
      case SseModuleTypeEnum.CONFIG: {
        return [
          updateConfiguration({
            home,
            mergingConfiguration,
            noFlyZones,
            noFlyZones3d,
            initializationMaskingZones,
            noNeutralizationZones,
            pointOfInterests,
            zoneOfInterests,
            noInitZones,
            videoConfiguration,
            videoStreams,
            gridConfigurations,
            layouts,
            globalScreenConfigurations,
            roundConfigurations,
            platforms,
            procedureModels,
          }),
          updateLogs(logs),
        ];
      }
      case SseModuleTypeEnum.DATA: {
        return [initNotifications(ongoingNotifications)];
      }
      case SseModuleTypeEnum.REPLAY: {
        return [
          updateReplay({ replayEvents, replayManualPeriods, replayStorageConfiguration, replaySettings }),
          updateLogs(logs),
        ];
      }
      case SseModuleTypeEnum.SENSOR: {
        return [updateSensorsByAppCode({ sensors, perimeterCameras, equipments, sensorStorage })];
      }
      case SseModuleTypeEnum.UTM: {
        return [updateUtm({ drones, pilots, flightPlans, flyZones, flySections, flyRoutes })];
      }
      case SseModuleTypeEnum.GLOBAL: {
        return [
          updateAuthent({ users, profiles, roles, sites, groups, authentSettings: authentSettings }),
          updateUtm({ drones, pilots, flightPlans, flyZones, flySections, flyRoutes }),
          updateSensors({ sensors, perimeterCameras, equipments, sensorStorage }),
          updateConfiguration({
            home,
            mergingConfiguration,
            noFlyZones,
            noFlyZones3d,
            initializationMaskingZones,
            noNeutralizationZones,
            pointOfInterests,
            zoneOfInterests,
            noInitZones,
            videoConfiguration,
            videoStreams,
            gridConfigurations,
            layouts,
            globalScreenConfigurations,
            roundConfigurations,
            platforms,
            procedureModels,
          }),
          updateReplay({ replayEvents, replayManualPeriods, replayStorageConfiguration, replaySettings }),
          updateHasBeenInitialized(true),
          addMessages(messages),
          updateLogs(logs),
          initNotifications(ongoingNotifications),
        ];
      }
      default:
        console.error('Following SseModuleTypeEnum not handled!! -> ', type);
    }
  } else if (action === SseActionEnum.LOGOUT) {
    return [updateHasToLogout(true)];
  }
  return [];
}

const situationKeyToAction: Record<keyof SituationState, ActionCreator<unknown>> = {
  version: updateVersion,
  site: updateSite,
  time: updateTime,
  appCode: updateAppCode,
  tacticalSituation: updateTacticalSituation,
  rawSituation: updateRawSituation,
  configuration: updateSituationConfiguration,
  flightPlans: updateFlightplans,
  flyZones: updateFlyZones,
  rounds: updateRounds,
};

type JsonPatch = {
  op: 'replace' | 'add' | 'remove';
  path: string;
  value: unknown;
};

export function getSituationUpdates(situation: SituationState, patches?: JsonPatch[]): SituationAction[] {
  const needUpdateKeys = Object.keys(situation)
    .map((key) => key as keyof SituationState)
    .filter((key) => patches === undefined || patches.some((patch) => patch.path.split('/')[1] === key));

  const newSituation = patches !== undefined ? patch(situation, patches) : situation;

  return needUpdateKeys.reduce((acc, curr) => {
    return [...acc, situationKeyToAction[curr](newSituation[curr])] as SituationAction[];
  }, [] as SituationAction[]);
}

export function eventToSituationActions(currentSituation: SituationState, data: string): SituationAction[] {
  const situationMessage = JSON.parse(data) as SituationMessage;
  if (situationMessage.fullSituation) {
    const newSituation = JSON.parse(situationMessage.json) as SituationState;
    return getSituationUpdates(newSituation);
  } else if (currentSituation.version < situationMessage.version) {
    const patches = JSON.parse(situationMessage.json) as JsonPatch[];
    return getSituationUpdates(currentSituation, patches);
  } else {
    return [];
  }
}
