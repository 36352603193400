import { ReactComponent as ExportIcon } from '@assets/icons/24x24/ic-share-24.svg';
import { Icon } from '@chakra-ui/icon';
import { HStack, Text } from '@chakra-ui/layout';
import CustomChipList from '@components/common/inputs/CustomChipList';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { updateNewLogBookEntry } from '@redux/global/global.reducer';
import { selectAllLogs, selectNewLogBookEntry } from '@redux/global/global.selector';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { useCreateInstructionMutation } from '@services/chat/messages.api';
import { ALL_OPTION_ID, stringToOption } from '@utils/components/customChipList.utils';
import { getLogAuthor, getLogContent } from '@utils/components/logbook.utils';
import { formatDate } from '@utils/date.utils';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FixedSizeList, ListOnScrollProps } from 'react-window';

import { HoloLog, LogTypeEnum } from '@/types/c2/logs.type';
import { DateTimeEnum } from '@/types/dateTime.types';

import CockpitPanelHeader from '../CockpitPanelHeader';
import Panel from '../Panel';
import { LogBookFooter } from './LogBookFooter';
import LogbookList from './LogbookList';

export default function LogBook() {
  const { formatMessage } = useIntl();
  const activeSite = useAppSelector(selectActiveSite);
  const newLogbookEntry = useAppSelector(selectNewLogBookEntry);
  const currentSituationTime = useAppSelector((state) => selectSituationTime(state, false));
  const csvDelimiter = ';';
  const [selectedFilters, setSelectedFilters] = useState<string[]>([ALL_OPTION_ID]);
  const listRef = useRef<FixedSizeList>(null);
  const [createInstruction] = useCreateInstructionMutation();
  const resetNewLogbookEntry = useWithDispatch(updateNewLogBookEntry);

  const logs = useAppSelector(selectAllLogs);
  const filteredLogs = logs.filter(
    (log) => selectedFilters.includes(ALL_OPTION_ID) || selectedFilters.includes(log.type),
  );

  function convertLogsToCSV(logs: HoloLog[]) {
    const headers = [
      formatMessage({ id: 'components.logbook.csvHeader.date' }),
      formatMessage({ id: 'components.logbook.csvHeader.type' }),
      formatMessage({ id: 'components.logbook.csvHeader.author' }),
      formatMessage({ id: 'components.logbook.csvHeader.content' }),
    ];

    const csvRows = [
      `sep=${csvDelimiter}`,
      headers.join(csvDelimiter),
      ...logs.map((log) => {
        const author = getLogAuthor(log);
        return [
          formatDate(log.time, DateTimeEnum.DATE_TIME),
          log.type,
          `${author?.name}${author?.firstName ? ` ${author.firstName}` : ''}`,
          getLogContent(log),
        ].join(csvDelimiter);
      }),
    ];

    return csvRows.join('\n');
  }

  function downloadCSV(logs: HoloLog[]) {
    const csvContent = convertLogsToCSV(logs);

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const url = URL.createObjectURL(blob);

    const date = formatDate(currentSituationTime ?? Date.now(), DateTimeEnum.DATE_TIME);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `filtered_logs_${date}.csv`);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  const [isAtBottom, setIsAtBottom] = useState(true);

  const handleScroll = ({ scrollOffset, scrollUpdateWasRequested }: ListOnScrollProps) => {
    if (scrollUpdateWasRequested || !listRef.current) {
      return;
    }
    const isBottom = scrollOffset >= filteredLogs.length * 102 - Number(listRef.current.props.height) - 1;
    setIsAtBottom(isBottom);
  };

  useEffect(() => {
    if (isAtBottom && listRef.current) {
      listRef.current.scrollToItem(filteredLogs.length - 1);
    }
  }, [filteredLogs.length, isAtBottom]); // Run when items change

  if (newLogbookEntry) {
    resetNewLogbookEntry(false);
  }

  return (
    <Panel
      width="60%"
      isEmpty={filteredLogs.length === 0}
      emptyLabel={formatMessage({ id: 'components.logbook.noLog' })}
      gap={0}
      header={<CockpitPanelHeader label={formatMessage({ id: 'components.logbook.title' })} noPlatform />}
      footer={
        <LogBookFooter
          onSubmit={(instruction) => {
            createInstruction({ msg: instruction, site: activeSite?.code ?? '' });
            setIsAtBottom(true);
          }}
        />
      }
      subHeader={
        <HStack width="100%" gap={3} paddingY={1} paddingX={2} backgroundColor="neutral.900" flexShrink={0}>
          <HStack gap={1}>
            <Text color={logs.length === 0 ? 'neutral.300' : 'neutral.white'}>
              <FormattedMessage id="components.panel.filter" />
            </Text>
            <CustomChipList
              availableOptions={Object.keys(LogTypeEnum).map(stringToOption)}
              selectedOptions={selectedFilters.map(stringToOption)}
              isDisabled={logs.length === 0}
              displayOptionAll
              path="components.logbook.filter"
              onChange={(value: string[]) => setSelectedFilters(value)}
            />
          </HStack>
          <Icon
            as={ExportIcon}
            color="neutral.300"
            cursor="pointer"
            width="24px"
            height="24px"
            marginLeft="auto"
            onClick={() => downloadCSV(filteredLogs)}
          />
        </HStack>
      }
    >
      <LogbookList logs={filteredLogs.map((log) => log.code)} listRef={listRef} handleScroll={handleScroll} />
    </Panel>
  );
}
