import { updateSettings } from '@redux/settings/settings.reducer';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { UserSettings } from '@/types/config/settings.types';
import { MapFiltersDto } from '@/types/filters.types';

import { CONFIG_URL, configApi } from './config.api';

export const SETTINGS_TAG: { type: 'UserSettings'; id: string } = { type: 'UserSettings', id: 'USER_SETTINGS' };

export const settingApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSettings: builder.query<UserSettings | null, void>({
      query: () => ({
        url: `${CONFIG_URL}/user-settings-v5`,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        data !== null && dispatch(updateSettings(data));
      },
      providesTags: [SETTINGS_TAG],
    }),

    updateUserSettings: builder.mutation<UserSettings, UserSettings>({
      query: (userSettings) => ({
        url: `${CONFIG_URL}/user-settings-v5`,
        method: 'PUT',
        body: userSettings,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchCoordinatesUnit: builder.mutation<UserSettings, { login: string; coordinatesUnit: CoordinatesUnitEnum }>({
      query: ({ login, coordinatesUnit }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/coordinates-unit`,
        method: 'PATCH',
        body: coordinatesUnit,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchMapBackground: builder.mutation<UserSettings, { login: string; mapBackground: string }>({
      query: ({ login, mapBackground }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/map-background`,
        method: 'PATCH',
        body: mapBackground,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchContext: builder.mutation<UserSettings, { login: string; context: string }>({
      query: ({ login, context }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/context`,
        method: 'PATCH',
        body: context,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchAxisInvertedY: builder.mutation<UserSettings, { login: string; axisInvertedY: boolean }>({
      query: ({ login, axisInvertedY }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/y-axis-inverted`,
        method: 'PATCH',
        body: axisInvertedY,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchLinearJoystickCoefficient: builder.mutation<
      UserSettings,
      {
        login: string;
        linearJoystickCoefficient: number;
      }
    >({
      query: ({ login, linearJoystickCoefficient }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/linear-joystick-coefficient`,
        method: 'PATCH',
        body: linearJoystickCoefficient,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchZoomJoystickCameraSensitivity: builder.mutation<
      UserSettings,
      {
        login: string;
        zoomJoystickCameraSensitivity: number;
      }
    >({
      query: ({ login, zoomJoystickCameraSensitivity }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/zoom-joystick-camera-sensitivity`,
        method: 'PATCH',
        body: zoomJoystickCameraSensitivity,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchMapFilters: builder.mutation<UserSettings, { login: string; mapFilters: MapFiltersDto }>({
      query: ({ login, mapFilters }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/map-filters`,
        method: 'PATCH',
        body: mapFilters,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchAutomatedLad: builder.mutation<UserSettings, { login: string; automatedLad: boolean }>({
      query: ({ login, automatedLad }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/automated-lad`,
        method: 'PATCH',
        body: automatedLad,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),

    patchGroupCode: builder.mutation<UserSettings, { login: string; groupCode: string }>({
      query: ({ login, groupCode }) => ({
        url: `${CONFIG_URL}/user-settings-v5/${login}/group-code`,
        method: 'PATCH',
        body: groupCode,
      }),
      invalidatesTags: [SETTINGS_TAG],
    }),
  }),
});

export const {
  usePatchContextMutation,
  useGetUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  usePatchAxisInvertedYMutation,
  usePatchLinearJoystickCoefficientMutation,
  usePatchZoomJoystickCameraSensitivityMutation,
  usePatchCoordinatesUnitMutation,
  usePatchMapBackgroundMutation,
  usePatchMapFiltersMutation,
  usePatchAutomatedLadMutation,
  usePatchGroupCodeMutation,
} = settingApi;
