import { ReactComponent as CloseIcon24 } from '@assets/icons/24x24/ic-panel-close-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import { memo, MouseEventHandler, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  label: string;
  count?: number;
  filter?: ReactNode;
  sort?: ReactNode;
  isFilterDisabled?: boolean;
  marginRight?: number;
  dragManagement?: {
    isDragging: boolean;
    onMouseDown: MouseEventHandler<HTMLDivElement>;
  };
  component?: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
};

function PanelHeader({
  label,
  count,
  filter,
  sort,
  isFilterDisabled,
  marginRight = 0,
  dragManagement,
  actions,
  component,
  onClose,
}: Readonly<Props>) {
  function getCursorStyle() {
    if (dragManagement) {
      return dragManagement.isDragging ? 'grabbing' : 'grab';
    }
    return 'default';
  }

  return (
    <VStack
      marginRight={marginRight}
      padding={2}
      gap={2}
      alignSelf="stretch"
      backgroundColor="cyber.900"
      flexShrink={0}
      cursor={getCursorStyle()}
      userSelect="none"
      onMouseDown={dragManagement?.onMouseDown}
    >
      <Flex
        alignItems="center"
        alignSelf="stretch"
        borderLeft="8px solid"
        borderColor="cyber.600"
        height="32px"
        width="100%"
        boxSizing="border-box"
        backgroundColor="neutral.black"
        userSelect="none"
        gap={1.5}
        textTransform="uppercase"
      >
        <Text color="neutral.200" size="md" marginLeft={1.5} noOfLines={1}>
          {label}
        </Text>
        {count !== undefined && (
          <Text color="sky.500" size="md">
            {count}
          </Text>
        )}
        {onClose && (
          <Icon marginLeft="auto" as={CloseIcon24} width="32px" height="32px" cursor="pointer" onClick={onClose} />
        )}
      </Flex>
      {(filter || sort || actions) && (
        <HStack gap={3} width="100%" alignItems="start ">
          {sort && (
            <VStack gap={1} alignItems="start" height="100%">
              <Text color={isFilterDisabled ? 'neutral.300' : 'neutral.white'}>
                <FormattedMessage id="components.panel.sort" />
              </Text>
              {sort}
            </VStack>
          )}
          {filter && (
            <VStack gap={1} alignItems="start" height="100%">
              <Text color={isFilterDisabled ? 'neutral.300' : 'neutral.white'}>
                <FormattedMessage id="components.panel.filter" />
              </Text>
              {filter}
            </VStack>
          )}
          {actions && (
            <HStack gap={0.5} flexShrink={0} marginLeft="auto">
              {actions}
            </HStack>
          )}
        </HStack>
      )}
      {component}
    </VStack>
  );
}

export default memo(PanelHeader);
