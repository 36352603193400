import { createEntityAdapter } from '@reduxjs/toolkit';
import { idComparator } from '@utils/common.utils';
import { sortDate } from '@utils/date.utils';

import { NotificationType } from '@/types/data/data.types';

export const notificationAdapter = createEntityAdapter<NotificationType, number>({
  selectId: (notification) => notification.id,
  sortComparer: (notification1, notification2) => {
    const sortValue = sortDate(notification1.startTime, notification2.startTime);
    if (sortValue === 0) {
      return idComparator(notification1, notification2);
    }
    return sortValue;
  },
});
