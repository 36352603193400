import { ReactComponent as LogBookCorner } from '@assets/components/panel/logbook/logbook-corner.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectLogByCode } from '@redux/global/global.selector';
import { getLogAuthor, getLogContent } from '@utils/components/logbook.utils';
import { formatDate } from '@utils/date.utils';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { CSSProperties, memo } from 'react';
import { useIntl } from 'react-intl';

import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  code: string;
  style: CSSProperties;
};

function LogBookMessage({ code, style }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const log = useAppSelector((state) => selectLogByCode(state, code));

  if (!log) {
    return null;
  }

  const label = getLogContent(log);
  const type = log.type;
  const author = getLogAuthor(log);
  const date = log.time;

  const authorLabel = author
    ? formatMessage(
        { id: 'components.logbook.author' },
        {
          type: formatMessage({ id: `components.logbook.filter.${type}` }),
          name: `${author.name}${author.firstName ? ` ${author.firstName}` : ''}`,
        },
      )
    : '';

  return (
    <HStack gap={0} cursor="default" width="100%" paddingTop={0.25} height={102} minHeight={102} style={style}>
      <VStack
        padding={2}
        paddingRight={0}
        gap={1}
        width="100%"
        height="100%"
        alignItems="start"
        overflow="hidden"
        backgroundColor="neutral.800-op70"
      >
        <Tooltip label={label} openDelay={300} isDisabled={label.length <= 133}>
          <Text fontSize="16px" height="100%" width="100%" wordBreak="break-all" noOfLines={2}>
            {label}
          </Text>
        </Tooltip>
        <HStack gap={1}>
          <Text backgroundColor="neutral.black" paddingY={0.25} paddingX={0.5}>
            {format(new Date(date), "HH'h'mm")}
          </Text>
          <Text>{formatDate(new Date(date), DateTimeEnum.DATE)}</Text>
          {author && (
            <Tooltip isDisabled={authorLabel.length < 36} label={`${author.name} ${author.firstName}`}>
              <Text noOfLines={1} wordBreak="break-all" marginLeft={2}>
                {authorLabel}
              </Text>
            </Tooltip>
          )}
        </HStack>
      </VStack>
      <VStack gap={0} height="100%" minWidth="24px" flexShrink={0} width="24px">
        <Box width="24px" minWidth="24px" height="calc(100% - 80px)" flexGrow={1} backgroundColor="neutral.800-op70" />
        <Icon as={LogBookCorner} height="80px" width="24px !important" />
      </VStack>
    </HStack>
  );
}

export default memo(LogBookMessage, isEqual);
