import { ADMIN_PROFILE, APP_PROFILE } from '@services/authent/authent.api';

import { DenormalizedProfile, GroupWithUsers, Profile } from '@/types/authent/groups.types';

export function isRestrictedProfile(profile: DenormalizedProfile | Profile) {
  return profile.name === ADMIN_PROFILE || profile.name === APP_PROFILE;
}

export function isRestrictedGroup(group: GroupWithUsers) {
  return group.code === ADMIN_PROFILE || group.code === APP_PROFILE;
}
