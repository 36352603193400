import { ReactComponent as PhoneIcon } from '@assets/icons/40x40/ic-phoneNegative-40.svg';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { useUpdateProcedureMutation } from '@services/data/procedure.api';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProcedureStep, ProcedureStepStateEnum } from '@/types/config/procedure.types';

import ProcedureInstructions from './ProcedureInstructions';
import StepActionButtons from './StepActionButtons';

type Props = {
  step: ProcedureStep;
  notificationId: number;
  procedureId: number;
  canManage: boolean;
};

function PhoneCallStep({ step, notificationId, procedureId, canManage }: Readonly<Props>) {
  const [updateProcedure] = useUpdateProcedureMutation();

  if (!canManage) {
    return <ProcedureInstructions instructions={step.instructions} />;
  }
  return (
    <>
      <VStack
        gap={2}
        paddingY={4}
        backgroundColor="neutral.700"
        width="100%"
        flex={step.instructions.length > 0 ? 0 : 1}
      >
        <Text>
          <FormattedMessage id="notification.procedure.step.type.PHONE_CALL.call" />
        </Text>
        <HStack gap={2}>
          <Icon as={PhoneIcon} color="neutral.300" width="40px" height="40px" />
          <Text>{step.phoneNumber ?? <FormattedMessage id="global.noInformation" />}</Text>
        </HStack>
      </VStack>
      <ProcedureInstructions instructions={step.instructions} />
      <StepActionButtons
        isMandatory={step.mandatory}
        notificationId={notificationId}
        procedureId={procedureId}
        stepRank={step.rank}
        onValidate={() =>
          updateProcedure({
            notificationId,
            procedureId,
            step: step.rank,
            state: ProcedureStepStateEnum.CONFIRMED,
          }).unwrap()
        }
      />
    </>
  );
}

export default memo(PhoneCallStep);
