import { Position } from '@turf/helpers';
import { booleanPointInPolygon } from '@turf/turf';
import { getClosestPointOnMultiPolygon, getTargetColor } from '@utils/map/map.utils';
import { getCrossOpacity } from '@utils/map/target.utils';
import { getExpiredDroneIconName, getTargetIconName } from '@utils/target.utils';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature } from 'geojson';

import { Target } from '@/types/c2/c2.types';
import { DroneNotification } from '@/types/data/data.types';
import { FeatureTypeEnum } from '@/types/map.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import { TargetLocation } from '@/types/sensor/sensor.types';
import { FlightPlan } from '@/types/utm/flightPlan.types';

export function toTargetIconFeatures(targets: Target<TargetLocation>[]): Feature[] {
  return targets.map((target) => {
    return {
      type: 'Feature',
      properties: {
        id: target.id,
        featureType: FeatureTypeEnum.TARGET_ICON,
        value: target,
        identification: target.identification.mainIdentification,
        icon: getTargetIconName(target),
      },
      geometry: {
        type: 'Point',
        coordinates: toLonLatArray(target.lastPosition!.location!.position, false),
      },
    } as Feature;
  });
}

export function toExpiredDroneIconFeature(notification: DroneNotification): Feature {
  return {
    type: 'Feature',
    properties: {
      id: notification.identifier,
      featureType: FeatureTypeEnum.EXPIRED_DRONE_ICON,
      value: notification,
      identification: IdentificationEnum.DRONE,
      icon: getExpiredDroneIconName(notification.classification, notification.reliability),
    },
    geometry: {
      type: 'Point',
      coordinates: toLonLatArray(notification.position, false),
    },
  } as Feature;
}

export function toTargetCrossFeature(targetPosition: Position, date: Date | null): Feature[] {
  if (date === null) {
    return [];
  }
  const timeSinceSelection = (Date.now() - new Date(date).getTime()) / 1000;

  return [
    {
      type: 'Feature',
      properties: {
        featureType: FeatureTypeEnum.TARGET_CROSS,
        opacity: getCrossOpacity(timeSinceSelection),
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [targetPosition, [targetPosition[0], 90]],
          [targetPosition, [targetPosition[0], -90]],
          [targetPosition, [180, targetPosition[1]]],
          [targetPosition, [-180, targetPosition[1]]],
        ],
      },
    },
  ] as Feature[];
}

export function toTargetLinkFeatures(groupedTargets: [Target<TargetLocation>, Target<TargetLocation>][]): Feature[] {
  return groupedTargets.map((targetGroup) => {
    return {
      type: 'Feature',
      properties: {
        featureType: FeatureTypeEnum.TARGET_LINK,
        color: getTargetColor(targetGroup[1]),
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          toLonLatArray(targetGroup[0].lastPosition!.location!.position, false),
          toLonLatArray(targetGroup[1].lastPosition!.location!.position, false),
        ],
      },
    } as Feature;
  });
}

export function toTargetTrajectoryFeatures(targets: Target<TargetLocation>[]): Feature[] {
  return targets
    .map((target) => {
      const trajectoryOutlineFeature = {
        type: 'Feature',
        properties: {
          id: target.id,
          featureType: FeatureTypeEnum.TARGET_TRAJECTORY,
          identification: target.identification.mainIdentification,
          color: 'black',
          width: 5,
        },
        geometry: {
          type: 'LineString',
          coordinates: target.trajectory.map((plot) => toLonLatArray(plot.location?.position)).filter((pos) => !!pos),
        },
      };
      const trajectoryFeature = {
        ...trajectoryOutlineFeature,
        properties: {
          id: target.id,
          featureType: FeatureTypeEnum.TARGET_TRAJECTORY,
          identification: target.identification.mainIdentification,
          color: getTargetColor(target),
          width: 2,
        },
      };

      return [trajectoryOutlineFeature, trajectoryFeature] as Feature[];
    })
    .flat();
}

export function toExpiredDroneTrajectoryFeatures(notification: DroneNotification): Feature[] {
  const trajectoryOutlineFeature = {
    type: 'Feature',
    properties: {
      id: notification.id,
      featureType: FeatureTypeEnum.TARGET_TRAJECTORY,
      identification: IdentificationEnum.DRONE,
      color: 'white',
      width: 5,
    },
    geometry: {
      type: 'LineString',
      coordinates: notification.trajectory.map((plot) => toLonLatArray(plot)).filter((pos) => !!pos),
    },
  };
  const trajectoryFeature = {
    ...trajectoryOutlineFeature,
    properties: {
      id: notification.id,
      featureType: FeatureTypeEnum.TARGET_TRAJECTORY,
      identification: IdentificationEnum.DRONE,
      color: 'black',
      width: 2,
    },
  };

  return [trajectoryOutlineFeature, trajectoryFeature] as Feature[];
}

export function toTargetFlightPlansLinkFeatures(dronesWithFlightPlans: {
  [key: string]: { drone: Target<TargetLocation>; flightPlans: FlightPlan[] };
}): Feature[] {
  return Object.values(dronesWithFlightPlans).flatMap(({ drone, flightPlans }) => {
    return flightPlans
      .filter(
        (flightPlan) =>
          drone.lastPosition.location?.position &&
          !booleanPointInPolygon(toLonLatArray(drone.lastPosition.location.position, false)!, flightPlan.polygons),
      )
      .map((flightPlan) => {
        const droneLocation = toLonLatArray(drone.lastPosition.location!.position, false)!;
        return {
          type: 'Feature',
          properties: {
            featureType: FeatureTypeEnum.TARGET_FLIGHT_PLAN_LINK,
            color: getTargetColor(drone),
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              toLonLatArray(drone.lastPosition.location!.position, false),
              getClosestPointOnMultiPolygon(droneLocation, flightPlan.polygons),
            ],
          },
        } as Feature;
      });
  });
}
