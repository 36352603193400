import './menuList.scss';

import { Box, Flex, Text, VStack } from '@chakra-ui/layout';
import { ButtonGroup } from '@chakra-ui/react';
import { findMenuItem } from '@utils/common.utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { MenuItemOption, MenuItemOptionIcons, MenuItems } from '@/types/menu.types';

import MenuSubItem from './MenuSubItem';

type ControlledProps =
  | {
      selectedOptions: string[];
      handleClick?: (option: string, value: MenuItemOption | MenuItemOptionIcons | undefined, depth: number) => void;
    }
  | {
      selectedOptions?: never;
      handleClick?: never;
    };

type MenuListProps = {
  fullMenuItems?: MenuItems;
  menuItems: MenuItems;
  depth: number;
  parentLabel?: string;
  buttonWidth?: string;
  resetNavigation?: boolean;
} & ControlledProps;

function MenuList({
  menuItems,
  fullMenuItems = menuItems,
  depth,
  parentLabel,
  buttonWidth,
  resetNavigation,
  selectedOptions,
  handleClick,
}: Readonly<MenuListProps>) {
  const { formatMessage } = useIntl();

  const [localSelectedOptions, setLocalSelectedOptions] = useState<string[]>(selectedOptions ?? []);
  const localHandleClick = (option: string, value: MenuItemOption | undefined, depth: number) => {
    const temp = structuredClone(localSelectedOptions).slice(0, depth + 1);
    // click on same place => revert navigation
    if (localSelectedOptions[depth] === option) {
      setLocalSelectedOptions(temp.slice(0, depth));
    } else {
      // else forward navigation
      setLocalSelectedOptions(temp.toSpliced(depth, 1, option));
      // trigger all potential action linked to menu btn
      if (value?.onAction) {
        value.onAction();
        //resetNavigation
        resetNavigation && setLocalSelectedOptions(temp.slice(0, depth));
      }
    }
  };

  const optionsToUse = selectedOptions ?? localSelectedOptions;

  return (
    <VStack gap={0} backgroundColor="neutral.black" padding={0.25}>
      {parentLabel && (
        <Flex
          height="32px"
          width="100%"
          paddingRight={1}
          paddingLeft={2}
          paddingY={0.5}
          alignItems="center"
          cursor="default"
        >
          <Text size="xs" color="sky.500">
            {formatMessage({ id: parentLabel }).toUpperCase()}
          </Text>
        </Flex>
      )}
      <ButtonGroup flexDir="column" spacing={0} gap={0} width={buttonWidth ?? '100%'}>
        {Object.entries(menuItems).map(([key, value]) => {
          if (value && !value.isHidden) {
            const isSelectedOption = optionsToUse[depth] === key;
            const { children, label, isDisabled, isActive, isLoading, shouldNotTranslate } = value;
            const labelToDisplay = () => {
              if (typeof label === 'string') {
                return shouldNotTranslate ? label : formatMessage({ id: label });
              } else {
                return label;
              }
            };

            const functionToUse = handleClick ?? localHandleClick;
            return (
              <Box key={key} gap={0} position="relative">
                <MenuSubItem
                  label={labelToDisplay()}
                  isSelected={isSelectedOption}
                  isDisabled={isDisabled}
                  isActive={isActive}
                  hasChildren={!!children}
                  onClick={() => functionToUse(key, value, depth)}
                  onHover={() => {
                    const currentLastSelectedChildren = findMenuItem(
                      fullMenuItems,
                      optionsToUse[optionsToUse.length - 1],
                    );

                    if (
                      currentLastSelectedChildren === undefined ||
                      currentLastSelectedChildren.children !== undefined
                    ) {
                      if (children !== undefined && !isSelectedOption) {
                        functionToUse(key, value, depth);
                      } else if (!children && Object.keys(menuItems).includes(optionsToUse[depth])) {
                        functionToUse(
                          selectedOptions ? selectedOptions[depth] : localSelectedOptions[depth],
                          undefined,
                          depth,
                        );
                      }
                    }
                  }}
                  isLoading={isLoading}
                />
                {key === optionsToUse[depth] && children && (
                  <VStack position="absolute" left="100%" top="-2px" gap={0}>
                    <MenuList
                      fullMenuItems={fullMenuItems}
                      menuItems={children}
                      depth={depth + 1}
                      buttonWidth={buttonWidth}
                      selectedOptions={optionsToUse}
                      handleClick={functionToUse}
                    />
                  </VStack>
                )}
              </Box>
            );
          }
        })}
      </ButtonGroup>
    </VStack>
  );
}

export default MenuList;
