import { Abstract, Modify } from '../commons/commons.types';
import { JammingModule, SubCameraConfiguration } from '../sensor/configuration.types';
import { Pilot } from './pilot.types';

export type Drone = {
  id: number;
  site: string;
  code: string;
  serialNumber: string;
  manufacturer: string;
  model: string;
  adsbNumber: string | null;
  groundStationNumber: string | null;
  holodroneNumber: string | null;
  aeroscopeNumber: string | null;
  antennaSkyEyeNumber: string | null;
  pilot: Pilot | null;
  clearance: DroneClearanceEnum;
  safetyDistance: number;
  criticalDistance: number;
  modules: DroneModule[];
} & Abstract;

export type NormalizedDrone = Modify<
  Drone,
  {
    pilot: string | null;
  }
>;

export enum DroneClearanceEnum {
  NO_CLEARANCE = 'NO_CLEARANCE',
  CLEARANCE_WITHIN_FLIGHTPLAN = 'CLEARANCE_WITHIN_FLIGHTPLAN',
  FULL_CLEARANCE_WITHIN_FLIGHTPLAN = 'FULL_CLEARANCE_WITHIN_FLIGHTPLAN',
  FULL_CLEARANCE = 'FULL_CLEARANCE',
}

export interface HasModuleType {
  moduleType: ModuleType;
}

export enum ModuleType {
  CAMERA = 'CAMERA',
  JAMMING = 'JAMMING',
}

export type DroneModule = SubCameraConfiguration | JammingModule;
