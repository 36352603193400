import {
  NotificationCriteria,
  NotificationDetails,
  NotificationDetailsParameters,
  NotificationType,
} from '@/types/data/data.types';

import { DATA_URL, dataApi } from './data.api';

export const notificationApi = dataApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<NotificationType[], NotificationCriteria>({
      query: ({ site, to, from, identifiers, displayIds, page, types, unpaginated, notificationStatus, size }) => ({
        url: `${DATA_URL}/notifications`,
        params: {
          sites: [site],
          to,
          from,
          identifiers,
          displayIds,
          notificationStatus,
          page,
          size,
          types,
          unpaginated,
        },
      }),
    }),
    exportNotifications: builder.mutation<Blob, NotificationCriteria>({
      query: ({ site, to, from, page, size, types, unpaginated }) => ({
        url: `${DATA_URL}/notifications/export`,
        method: 'GET',
        params: {
          sites: [site],
          to,
          from,
          page,
          size,
          types,
          unpaginated,
        },
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    totalOfNotifications: builder.query<number, NotificationCriteria>({
      query: ({ site, identifiers, types, notificationStatus }) => ({
        url: `${DATA_URL}/notifications/count`,
        params: {
          sites: [site],
          identifiers,
          notificationStatus,
          types,
        },
      }),
    }),
    getDetail: builder.query<NotificationDetails, NotificationDetailsParameters>({
      query: ({ id, withArchivedAlerts }) => ({
        url: `${DATA_URL}/notifications/${id}/detail`,
        params: {
          withArchivedAlerts,
        },
      }),
      providesTags: (result) => {
        return result ? [{ type: 'Notification', id: result.notification.id }] : [];
      },
    }),
    startProcedure: builder.mutation<NotificationType, number>({
      query: (notificationId) => ({
        url: `${DATA_URL}/notifications/${notificationId}/start-procedure`,
        method: 'PATCH',
      }),
      invalidatesTags: (_, __, notificationId) => [{ type: 'Notification', id: notificationId }],
    }),
    archiveAll: builder.mutation<void, void>({
      query: () => ({
        url: `${DATA_URL}/notifications/archive-all`,
        method: 'POST',
      }),
    }),
  }),
});

export const useGetNotificationsLazyQuery = notificationApi.endpoints.getNotifications.useLazyQuery;
export const {
  useGetNotificationsQuery,
  useTotalOfNotificationsQuery,
  useLazyGetDetailQuery,
  useGetDetailQuery,
  useStartProcedureMutation,
  useArchiveAllMutation,
} = notificationApi;
