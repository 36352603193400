import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { HStack, Stack, VStack } from '@chakra-ui/layout';
import { Button, NumberInputFieldProps, TabPanel, Text } from '@chakra-ui/react';
import CustomInputNumber from '@components/common/inputs/CustomInputNumber';
import { useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetStorageConfigurationQuery, useUpdateStorageConfigurationMutation } from '@services/c2/c2.api';
import { convertDuration } from '@utils/date.utils';
import { Field, Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { User } from '@/types/authent/login.types';

import { systemParamSchema } from '../../../../validations/system.param.schema';

type SystemParamFormFields = {
  purgeSituation: number;
  purgeSensorRaw: number;
  maxStorageAge: number;
};

const emptyForm: SystemParamFormFields = {
  purgeSituation: 0,
  purgeSensorRaw: 0,
  maxStorageAge: 0,
};

export function SystemParamTab() {
  const validationSchema = useValidation(systemParamSchema);
  const { activeSite } = useAppSelector(selectActiveUser) as User;
  const { data: storageConfiguration, isLoading: isLoadingGet } = useGetStorageConfigurationQuery(
    activeSite?.code ?? skipToken,
  );

  const [patchStorageConfiguration, { isLoading: isLoadingPatch }] = useUpdateStorageConfigurationMutation();

  const isLoading = isLoadingGet || isLoadingPatch;

  const [cachedValues, setCachedValues] = useState<SystemParamFormFields>(emptyForm);

  function submit(values: SystemParamFormFields) {
    if (
      storageConfiguration?.site &&
      storageConfiguration.replayConfiguration?.id &&
      storageConfiguration.sensorConfiguration?.id
    ) {
      patchStorageConfiguration({
        site: storageConfiguration.site,
        storageConfiguration: {
          ...storageConfiguration,
          replayConfiguration: {
            ...storageConfiguration.replayConfiguration,
            purgeSituation: convertDuration(values.purgeSituation, 'days', 'seconds'),
          },
          sensorConfiguration: {
            ...storageConfiguration.sensorConfiguration,
            purgeSensorRaw: convertDuration(values.purgeSensorRaw, 'days', 'seconds'),
          },
          videoConfiguration: {
            ...storageConfiguration.videoConfiguration,
            maxStorageAge: convertDuration(values.maxStorageAge, 'days', 'hours'),
          },
        },
      });
    }
  }

  const CustomField = (props: NumberInputFieldProps) => {
    const { errors, touched, setFieldValue } = useFormikContext<SystemParamFormFields>();
    const name = props.name as keyof SystemParamFormFields;
    return (
      <HStack gap={1}>
        <Field
          {...props}
          as={CustomInputNumber}
          name={name}
          showStepper={true}
          onChange={(value?: number) => setFieldValue(name, value)}
          isInvalid={touched[name] && !!errors[name]}
          width="100px"
        />
        {touched[name] && errors[name] && <Text color="neutral.black">{errors[name]}</Text>}
      </HStack>
    );
  };

  useEffect(() => {
    setCachedValues({
      purgeSituation: convertDuration(
        storageConfiguration?.replayConfiguration?.purgeSituation ?? 0,
        'seconds',
        'days',
      ),
      purgeSensorRaw: convertDuration(
        storageConfiguration?.sensorConfiguration?.purgeSensorRaw ?? 0,
        'seconds',
        'days',
      ),
      maxStorageAge: convertDuration(storageConfiguration?.videoConfiguration?.maxStorageAge ?? 0, 'seconds', 'days'),
    });
  }, [storageConfiguration]);

  return (
    <TabPanel backgroundColor="white" height="100%" padding={3}>
      <Formik
        initialValues={cachedValues}
        enableReinitialize={true}
        onSubmit={submit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, dirty, isValid, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <VStack gap={2}>
              <FormControl isRequired isInvalid={touched.purgeSituation && !!errors.purgeSituation}>
                <Stack gap={0}>
                  <FormLabel>
                    <FormattedMessage id="components.systemConfiguration.SITUATION_DATA_EXPIRATION" />
                  </FormLabel>
                </Stack>
                <CustomField name="purgeSituation" />
              </FormControl>
              <FormControl isRequired isInvalid={touched.purgeSensorRaw && !!errors.purgeSensorRaw}>
                <Stack gap={0}>
                  <FormLabel>
                    <FormattedMessage id="components.systemConfiguration.SENSOR_DATA_EXPIRATION" />
                  </FormLabel>
                  <CustomField name="purgeSensorRaw" />
                </Stack>
              </FormControl>
              <FormControl isRequired isInvalid={touched.maxStorageAge && !!errors.maxStorageAge}>
                <Stack gap={0}>
                  <FormLabel>
                    <FormattedMessage id="components.systemConfiguration.CAMERA_DATA_EXPIRATION" />
                  </FormLabel>
                  <CustomField name="maxStorageAge" />
                </Stack>
              </FormControl>
              <Button
                type="submit"
                isDisabled={
                  !dirty ||
                  !isValid ||
                  isLoading ||
                  !(
                    storageConfiguration?.site &&
                    storageConfiguration.replayConfiguration?.id &&
                    storageConfiguration.sensorConfiguration?.id
                  )
                }
                isLoading={isLoading}
                backgroundColor="gray.500"
                _hover={{ backgroundColor: 'gray.600' }}
              >
                <Text>
                  <FormattedMessage id={'components.systemConfiguration.UPDATE'} />
                </Text>
              </Button>
            </VStack>
          </form>
        )}
      </Formik>
    </TabPanel>
  );
}
