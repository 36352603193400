import { Text, VStack } from '@chakra-ui/react';
import { formatDate } from '@utils/date.utils';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { FinishedStep as FinishedStepType, ProcedureStepStateEnum } from '@/types/config/procedure.types';
import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  step: FinishedStepType;
};

export default function FinishedStep({ step }: Readonly<Props>) {
  return (
    <VStack gap={3} width="100%" paddingX={6} paddingTop={5} paddingBottom={5} backgroundColor="neutral.700">
      <Text size="md">
        <FormattedMessage id={`notification.procedure.step.type.${step.type}.done`} values={{ value: step.state }} />
      </Text>
      <VStack gap={0.5}>
        <Text size="md">
          <FormattedMessage
            id={`notification.procedure.step.${step.state === ProcedureStepStateEnum.IGNORED ? 'skipped' : 'validated'}`}
          />
        </Text>
        <Text color="neutral.300">
          <FormattedMessage
            id="notification.procedure.step.modifiedAt"
            values={{
              time: (
                <Text as="span" color="neutral.white">
                  {format(new Date(step.modificationTime), "HH':'mm")}
                </Text>
              ),
              date: (
                <Text as="span" color="neutral.white">
                  {formatDate(step.modificationTime, DateTimeEnum.DATE)}
                </Text>
              ),
            }}
          />
        </Text>
        <Text color="neutral.300">
          <FormattedMessage
            id="notification.procedure.step.modifiedBy"
            values={{
              name: (
                <Text as="span" color="neutral.white">
                  {step.modifiedBy}
                </Text>
              ),
            }}
          />
        </Text>
      </VStack>
    </VStack>
  );
}
