import { ReactComponent as CriticalBorderDecoration } from '@assets/components/toast/critical-alert-border-toast-decoration.svg';
import { ReactComponent as CameraIcon24 } from '@assets/icons/24x24/ic-camera-24.svg';
import { ReactComponent as ConfirmedIcon24 } from '@assets/icons/24x24/ic-confirmed-bold-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Spinner, Text } from '@chakra-ui/react';
import { getNotificationIcon } from '@components/cockpit/panels/notification/card/NotificationCardName';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasAnySensorRoleFromActiveProfile, selectActiveUser } from '@redux/authent/authent.selectors';
import { updateSelectedDoubtCheckSegmentName } from '@redux/global/global.reducer';
import { selectContext } from '@redux/settings/settings.selectors';
import { usePatchContextMutation } from '@services/config/setting.api';
import { useStartProcedureMutation } from '@services/data/notification.api';
import {
  getAuthoritiesForNotificationManagement,
  getNotificationColor,
  getWorstNotificationLevel,
  isCriticalNotification,
  isDroneNotification,
  isPerimeterNotification,
  isSegmentNotification,
  isSensorNotification,
} from '@utils/data/notification.utils';
import { getUniqueSensorShortHand } from '@utils/target.utils';
import { formatUnit, HeightUnits } from '@utils/unit.utils';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProcedureStateEnum } from '@/types/config/procedure.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { NotificationType, SegmentNotification } from '@/types/data/data.types';

import NotificationTimeToast from './NotificationTimeToast';

type Props = {
  notification: NotificationType;
  onClose: () => void;
};

function NotificationToast({ notification, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const context = useAppSelector(selectContext);
  const [startProcedure] = useStartProcedureMutation();
  const updateSelectedSegmentName = useWithDispatch(updateSelectedDoubtCheckSegmentName);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAcknowledge, setIsLoadingAcknowledge] = useState(false);
  const [patchContext] = usePatchContextMutation();
  const user = useAppSelector(selectActiveUser);
  const canManageNotification = useAppSelector((state) =>
    hasAnySensorRoleFromActiveProfile(state, getAuthoritiesForNotificationManagement(notification)),
  );

  useEffect(() => {
    if (isLoading && notification.procedureState !== ProcedureStateEnum.TO_PROCESS) {
      setIsLoading(false);
      onClose();
    }
  }, [isLoading, notification.procedureState, onClose]);

  function onClick() {
    if (!isLoading && canManageNotification) {
      setIsLoading(true);
      startProcedure(notification.id).finally(() => {
        setIsLoading(false);
        onClose();
      });
    }
  }

  function onAckSubmit(segmentNotification: SegmentNotification) {
    if (!isLoadingAcknowledge) {
      setIsLoadingAcknowledge(true);
      startProcedure(notification.id).finally(() => {
        updateSelectedSegmentName(segmentNotification.segmentName);
        patchContext({
          login: user.login,
          context: ContextEnum.DOUBT_PERIM,
        }).finally(() => {
          setIsLoadingAcknowledge(false);
          onClose();
        });
      });
    }
  }

  const isCritical = isCriticalNotification(notification, { isToast: true });
  const color = getNotificationColor(notification, { isToast: true });
  const level = getWorstNotificationLevel(notification, { isToast: true });

  return (
    <HStack
      gap={0}
      backgroundColor="neutral.100"
      borderLeft={isCritical ? 'none' : '8px solid'}
      borderColor={color}
      width="360px"
      position="relative"
      userSelect="none"
      flexGrow={0}
      flexShrink={0}
      overflow="hidden"
    >
      {isCritical && (
        <Icon as={CriticalBorderDecoration} position="absolute" top={0} height="371px" width="16px" flexShrink={0} />
      )}
      <VStack gap={0} height="100%" width="100%">
        <HStack gap={2} width="100%" padding={2} paddingLeft={isCritical ? 4 : 3} backgroundColor="neutral.white">
          {getNotificationIcon(notification, { isToast: true })}
          <VStack alignItems="start" gap={0.5}>
            <Text fontSize="md" color="neutral.black">
              <FormattedMessage
                id={`notification.name.${level}`}
                values={{
                  value: isSensorNotification(notification)
                    ? formatMessage({ id: `sensors.type.${notification.sensorType}` })
                    : undefined,
                }}
              />
            </Text>
            <NotificationTimeToast startTime={notification.startTime} endTime={notification.expiredTime} />
          </VStack>
        </HStack>
        <VStack
          width="100%"
          backgroundColor="neutral.100"
          alignItems="start"
          paddingY={2}
          paddingRight={3}
          paddingLeft={isCritical ? 5 : 4}
        >
          {isDroneNotification(notification) && (
            <>
              <Text fontSize="12px" fontWeight="medium" color="neutral.600">
                {notification.displayId ?? formatMessage({ id: 'target.unknown' })}
              </Text>
              <Text fontSize="12px" fontWeight="medium" color="neutral.600">
                <FormattedMessage id={`target.reliability.${notification.reliability}`} />
              </Text>
              <Text fontSize="12px" fontWeight="medium" color="neutral.600">
                {getUniqueSensorShortHand(notification.trackIds).join(', ')}
              </Text>
              <Text fontSize="12px" fontWeight="medium" color="neutral.600">
                <FormattedMessage
                  id="notification.altitudeValue"
                  values={{
                    value: formatUnit(HeightUnits.METERS, notification.currentHeight),
                  }}
                />
              </Text>
            </>
          )}
          {isSegmentNotification(notification) && (
            <>
              <Text fontSize="12px" fontWeight="medium" color="neutral.600">
                {notification.cabinetName}
              </Text>
              <Text fontSize="12px" fontWeight="medium" color="neutral.600">
                {notification.segmentName}
              </Text>
              {canManageNotification &&
                isPerimeterNotification(notification) &&
                context !== ContextEnum.DOUBT_PERIM && (
                  <Center width="100%" paddingTop={2}>
                    <HStack
                      paddingX={3}
                      gap={1.5}
                      width="100%"
                      height="52px"
                      backgroundColor="neutral.800"
                      border="2px"
                      borderColor="neutral.200"
                      boxShadow="inset 0 0 0 2px var(--chakra-colors-neutral-900)"
                      cursor="pointer"
                      onClick={() => onAckSubmit(notification)}
                    >
                      {isLoading || isLoadingAcknowledge ? (
                        <Spinner color="neutral.white" marginLeft="auto" marginRight="auto" />
                      ) : (
                        <>
                          <Icon as={CameraIcon24} width="24px" height="24px" color="neutral.300" marginLeft={2} />
                          <Text fontSize="16px" variant="space" whiteSpace="pre-line">
                            <FormattedMessage id="notification.acknowledgeAndShow" />
                          </Text>
                        </>
                      )}
                    </HStack>
                  </Center>
                )}
            </>
          )}
          {isSensorNotification(notification) && (
            <Text fontSize="12px" fontWeight="medium" color="neutral.600">
              {notification.sensorName}
            </Text>
          )}
        </VStack>

        {canManageNotification && (
          <Center width="100%" paddingBottom={2} paddingLeft={isCritical ? 5 : 4} paddingRight={3}>
            <HStack
              paddingX={3}
              paddingY="10px"
              gap={1.5}
              width="100%"
              height="52px"
              backgroundColor="neutral.800"
              border="2px"
              borderColor="neutral.200"
              boxShadow="inset 0 0 0 2px var(--chakra-colors-neutral-900)"
              cursor="pointer"
              onClick={onClick}
            >
              {isLoading ? (
                <Spinner margin="auto" color="neutral.white" />
              ) : (
                <>
                  <Icon as={ConfirmedIcon24} width="24px" height="24px" color="neutral.300" marginLeft={2} />
                  <Text fontSize="16px" variant="space">
                    <FormattedMessage id="notification.acknowledge" />
                  </Text>
                </>
              )}
            </HStack>
          </Center>
        )}
      </VStack>
    </HStack>
  );
}

export default NotificationToast;
