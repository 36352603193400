import { HStack, VStack } from '@chakra-ui/layout';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import { formatDate, formatDuration } from '@utils/date.utils';
import { extendedSensorShortHand } from '@utils/map/map.constants';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import { DroneNotificationLight } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { SensorTypeEnum } from '@/types/sensor/configuration.types';

import InfoValue from '../../InfoValue';
import DroneDetails from '../DroneDetails';

type Props = {
  notification: DroneNotificationLight;
};
export default function ExpiredDroneDetails({ notification }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const formattedStartDate = formatDate(notification.startTime, DateTimeEnum.DATE);
  const formattedEndDate = formatDate(notification.lastDetectionTime!, DateTimeEnum.DATE);
  const formattedStartTime = format(new Date(notification.startTime), "HH':'mm");
  const formattedEndTime = format(new Date(notification.lastDetectionTime!), "HH':'mm");

  const isSameDay = formattedStartDate === formattedEndDate;

  const sensors = notification.trackIds.reduce<Partial<Record<SensorTypeEnum, string[]>>>(
    (acc, { sensorType, sensorCode }) => {
      if (!acc[sensorType]) {
        acc[sensorType] = [];
      }
      acc[sensorType].push(sensorCode);
      return acc;
    },
    {} as Partial<Record<SensorTypeEnum, string[]>>,
  );

  return (
    <VStack gap={0.25} width="100%" height="100%">
      <VStack gap={4} padding={4} width="100%" backgroundColor="neutral.700">
        <HStack width="100%" gap={3}>
          <InfoValue
            label={formatMessage({ id: 'targetInfos.tab.details.detectionDuration' })}
            value={
              <Text
                backgroundColor="neutral.800"
                textAlign="center"
                width="100%"
                lineHeight="23px"
                fontSize="18px"
                paddingY="9.5px"
                border="2px solid"
                borderColor="neutral.black"
              >
                {formatDuration(notification.startTime, notification.lastDetectionTime!)}
              </Text>
            }
          />
          <Text alignSelf="end" width="100%" color="neutral.300" fontSize="18px" whiteSpace="pre-line">
            <FormattedMessage
              id={isSameDay ? 'targetInfos.tab.details.sameDay' : 'targetInfos.tab.details.multipleDays'}
              values={{
                startDate: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedStartDate}
                  </Text>
                ),
                startTime: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedStartTime}
                  </Text>
                ),
                endDate: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedEndDate}
                  </Text>
                ),
                endTime: (
                  <Text as="span" fontSize="18px" color="neutral.white">
                    {formattedEndTime}
                  </Text>
                ),
              }}
            />
          </Text>
        </HStack>
      </VStack>
      <DroneDetails notification={notification} />
      <Box padding={4} width="100%" backgroundColor="neutral.700" height="100%">
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.sensors' })}
          value={
            <HStack width="100%" gap={0.5}>
              {Object.entries(sensors).map(([key, values]) => (
                <Tooltip
                  sx={{
                    bg: 'transparent',
                  }}
                  label={
                    <VStack gap={0.25}>
                      {values.map((code) => (
                        <Text paddingX={1.5} paddingY={1} key={code} fontSize="16px" backgroundColor="neutral.black">
                          {code}
                        </Text>
                      ))}
                    </VStack>
                  }
                  key={key}
                  placement="right"
                >
                  <Text backgroundColor="neutral.black" fontSize="18px" color="neutral.300" padding={0.5}>
                    {extendedSensorShortHand[key as SensorTypeEnum]}
                  </Text>
                </Tooltip>
              ))}
            </HStack>
          }
        />
      </Box>
    </VStack>
  );
}
