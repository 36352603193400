import './index.scss';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { ChakraProvider, ColorModeScript, Spinner } from '@chakra-ui/react';
import { persistor, store } from '@redux/store';
import { ErrorInfo, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { MapProvider } from 'react-map-gl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './components/App';
import ModalsProvider from './components/common/modals/ModalsProvider';
import MessagesProvider from './components/locale/MessageProvider';
import ErrorPage from './ErrorPage';
import theme from './theme/theme';

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);

function logErrorToService(error: Error, info: ErrorInfo) {
  console.error('Caught an error:', error, info);
}

root.render(
  <StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ErrorBoundary fallback={<ErrorPage />} onError={logErrorToService}>
      <Provider store={store}>
        <PersistGate loading={<Spinner size="xl" />} persistor={persistor}>
          <MessagesProvider>
            <ChakraProvider theme={theme} toastOptions={{ toastSpacing: '10rem' }}>
              <BrowserRouter>
                <ModalsProvider>
                  <MapProvider>
                    <App />
                  </MapProvider>
                </ModalsProvider>
              </BrowserRouter>
            </ChakraProvider>
          </MessagesProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
);
