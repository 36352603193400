import { toFlightPlanFillFeatures } from '@components/map/layers/flightPlans/flightPlan.mapper';
import { toActivePoiFeatures } from '@components/map/layers/poi/poi.mapper';
import { toStrobeAzimuthLineFeatures, toStrobeSectorFeatures } from '@components/map/layers/strobes/strobe.mapper';
import {
  toExpiredDroneIconFeature,
  toExpiredDroneTrajectoryFeatures,
  toTargetCrossFeature,
  toTargetFlightPlansLinkFeatures,
  toTargetIconFeatures,
  toTargetLinkFeatures,
  toTargetTrajectoryFeatures,
} from '@components/map/layers/targets/target.mapper';
import {
  toIMZFeatures,
  toIMZMarginFeatures,
  toNFZ3dFeatures,
  toNFZFeatures,
  toNIZFeatures,
} from '@components/map/layers/zones/zone.mapper';
import {
  selectSelectedExpiredDroneNotifByMapId,
  selectSelectedFeatureDateByMapId,
  selectSelectedTargetIdByMapId,
} from '@redux/maps/maps.selectors';
import { selectActualMapFilters } from '@redux/settings/settings.selectors';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { selectParameterWithReplayMode } from '@utils/common.utils';
import { getFilteredList } from '@utils/filter.utils';
import { isSolid3dPolygonValid } from '@utils/map/zone.utils';
import {
  getTargetClassification,
  getTargetIdentification,
  getTargetReliability,
  getTargetStatus,
  isDrone,
} from '@utils/target.utils';
import {
  isValidCoordinates,
  isValidLinearLocation,
  isValidPolygon,
  toLonLatArray,
} from '@utils/validation/coordinates.utils';
import { Feature, FeatureCollection } from 'geojson';
import { groupBy, uniqBy } from 'lodash';

import { TacticalSituation, Target, Track } from '@/types/c2/c2.types';
import { TrackLight } from '@/types/commons/commons.types';
import {
  ConfigConfiguration,
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
} from '@/types/config/config.types';
import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { Round } from '@/types/config/round.types';
import { filterKeyToFeatureEnum, filterKeyToFilterTypeEnum, FilterTypeEnum, MapFilters } from '@/types/filters.types';
import { MapIdEnum } from '@/types/map.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import {
  AbstractLocation,
  LocationTypeEnum,
  PlanarLocation,
  SpatialLocation,
  StrobeLocation,
  TargetLocation,
} from '@/types/sensor/sensor.types';
import { FlightPlan, FlightPlanStateEnum } from '@/types/utm/flightPlan.types';

import { SituationState } from './situation.reducer';

function selectSituationState(state: RootState, replayMode = false): SituationState | null {
  if (replayMode) {
    return state.replay.situation;
  }
  return state.situation;
}

export const selectSituationVersion = createSelector(
  selectSituationState,
  (state: SituationState | null): number => state?.version ?? -1,
);

export function selectSituation(state: RootState): SituationState {
  return state.situation;
}

export const selectTacticalSituation = createSelector(
  selectSituationState,
  (state: SituationState | null): TacticalSituation | null => state?.tacticalSituation ?? null,
);

export const selectSituationFlightPlans = createSelector(
  selectSituationState,
  (state: SituationState | null): FlightPlan[] => state?.flightPlans ?? [],
);

export const selectSituationActiveFlightPlans = createSelector(
  selectSituationFlightPlans,
  (flightPlans: FlightPlan[]): FlightPlan[] =>
    flightPlans.filter((flightPlan) => flightPlan.state === FlightPlanStateEnum.ACTIVE),
);

export const selectSituationTime = createSelector(
  selectSituationState,
  (situation: SituationState | null): string | null => situation?.time ?? null,
);

export const selectFlightPlanFeatures = createSelector(
  [selectSituationFlightPlans, selectActualMapFilters],
  (flightPlans, mapFilters): FeatureCollection => ({
    type: 'FeatureCollection',
    features: toFlightPlanFillFeatures(
      getFilteredList(
        flightPlans,
        mapFilters,
        (fp: FlightPlan, key: keyof MapFilters): boolean =>
          filterKeyToFeatureEnum(key, FilterTypeEnum.FLIGHT_PLAN) !== fp.state,
      ),
    ),
  }),
);

export const selectSituationFlightPlanById = createSelector(
  [selectSituationFlightPlans, selectParameterWithReplayMode<number>],
  (flightPlans, id): FlightPlan | null => flightPlans.find((flightPlan) => flightPlan.id === id) ?? null,
);

export const selectSituationFlightPlansByCodes = createSelector(
  [selectSituationFlightPlans, selectParameterWithReplayMode<string[]>],
  (flightPlans, codes): FlightPlan[] => flightPlans.filter((flightPlan) => codes.includes(flightPlan.code)),
);

export const selectConfigurations = createSelector(
  selectSituationState,
  (state: SituationState | null): ConfigConfiguration | null => state?.configuration ?? null,
);

export const selectTargets = createSelector(
  selectTacticalSituation,
  (state: TacticalSituation | null): Target<TargetLocation>[] =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    state?.targets.filter((target) => isValidCoordinates(target.lastPosition?.location?.position)) ?? [],
);

export const selectFilteredTargets = createSelector(
  [selectTargets, selectActualMapFilters],
  (targets: Target<TargetLocation>[], mapFilters: MapFilters): Target<TargetLocation>[] =>
    getFilteredList(targets, mapFilters, (target: Target<TargetLocation>, key: keyof MapFilters) => {
      const filterType = filterKeyToFilterTypeEnum(key);
      const feature = filterType && filterKeyToFeatureEnum(key, filterType);
      switch (filterType) {
        case FilterTypeEnum.TARGET_TYPE:
          return feature !== getTargetIdentification(target);
        case FilterTypeEnum.TARGET_CLASSIFICATION:
          return feature !== getTargetClassification(target);
        case FilterTypeEnum.TARGET_RELIABILITY:
          return feature !== getTargetReliability(target);
        case FilterTypeEnum.TARGET_STATUS:
          return feature !== getTargetStatus(target);
        default:
          return true;
      }
    }).sort(
      (targetA, targetB) =>
        Object.keys(IdentificationEnum).indexOf(targetB.identification.mainIdentification) -
        Object.keys(IdentificationEnum).indexOf(targetA.identification.mainIdentification),
    ),
);

export const selectStrobes = createSelector(
  selectTacticalSituation,
  (state: TacticalSituation | null): Target<StrobeLocation>[] =>
    state?.strobes.filter((s) => isValidLinearLocation(s.lastPosition?.location)) ?? [],
);

export const selectFilteredStrobes = createSelector(
  [selectStrobes, selectActualMapFilters],
  (strobes: Target<StrobeLocation>[], mapFilters: MapFilters): Target<StrobeLocation>[] =>
    getFilteredList(
      strobes,
      mapFilters,
      (strobe: Target<StrobeLocation>, key: keyof MapFilters) =>
        filterKeyToFeatureEnum(key, FilterTypeEnum.RF_DETECTION_TYPE) !== getTargetIdentification(strobe) &&
        strobe.trackIds.every((track) => track.sensorType !== filterKeyToFeatureEnum(key, FilterTypeEnum.C_UAS_SENSOR)),
    ),
);

export const selectSpatialTargets = createSelector(
  selectTargets,
  (targets: Target<TargetLocation>[]): Target<SpatialLocation>[] =>
    targets.filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (target) => target.lastPosition?.location?.type === LocationTypeEnum.SPATIAL,
    ) as Target<SpatialLocation>[],
);

export const selectDrones = createSelector(
  selectFilteredTargets,
  (targets: Target<TargetLocation>[]): Target<TargetLocation>[] => targets.filter((target) => isDrone(target)),
);

export const selectSpatialDrones = createSelector(
  selectSpatialTargets,
  (targets: Target<SpatialLocation>[]): Target<SpatialLocation>[] => targets.filter((target) => isDrone(target)),
);

export const selectGroupedTargets = createSelector(
  selectFilteredTargets,
  (targets): [Target<TargetLocation>, Target<TargetLocation>][] => {
    const targetsWithGroup = targets.filter((target) => target.targetGroup);
    return Object.values(groupBy(targetsWithGroup, 'targetGroup'))
      .filter((targets) => targets.length === 2)
      .map((targets) => [targets[0], targets[1]] as [Target<PlanarLocation>, Target<PlanarLocation>]);
  },
);

export const selectTargetIconFeatures = createSelector(
  [selectFilteredTargets],
  (targets: Target<TargetLocation>[]): Feature[] => toTargetIconFeatures(targets),
);

export const selectTargetLinkFeatures = createSelector(
  selectGroupedTargets,
  (targets: [Target<TargetLocation>, Target<TargetLocation>][]): Feature[] => toTargetLinkFeatures(targets),
);

export const selectTargetFlightPlanLinkFeatures = createSelector(
  [selectDrones, selectSituationActiveFlightPlans],
  (droneTargets, flightPlans): Feature[] => {
    const dronesWithFp = droneTargets.reduce(
      (acc, drone) => {
        const droneFlightPlans = flightPlans.filter((flightPlan) => drone.fpCodes.includes(flightPlan.code));
        if (droneFlightPlans.length === 0) {
          return acc;
        }
        return { ...acc, [drone.id]: { drone, flightPlans: droneFlightPlans } };
      },
      {} as { [key: string]: { drone: Target<TargetLocation>; flightPlans: FlightPlan[] } },
    );
    return toTargetFlightPlansLinkFeatures(dronesWithFp);
  },
);

export const selectSelectedTarget = createSelector(
  [(state, mapId: MapIdEnum) => selectTargets(state, mapId === MapIdEnum.REPLAY), selectSelectedTargetIdByMapId],
  (targets: Target<TargetLocation>[], selectedTargetId): Target<TargetLocation> | null =>
    targets.find((target) => selectedTargetId === target.id) ?? null,
);

export const selectFilteredTargetForTrajectory = createSelector(
  [
    (state, mapId: MapIdEnum) => selectFilteredTargets(state, mapId === MapIdEnum.REPLAY),
    selectActualMapFilters,
    selectSelectedTarget,
  ],
  (filteredTargets: Target<TargetLocation>[], mapFilters: MapFilters, selectedTarget): Target<TargetLocation>[] => {
    // TANT QUE LA CIBLE SELECTIONNEE NE PREND PAS EN COMPTE LES FILTRES
    const targets = selectedTarget
      ? uniqBy([...filteredTargets, selectedTarget], (target) => target.id)
      : filteredTargets;

    return getFilteredList(targets, mapFilters, (target: Target<TargetLocation>, key: keyof MapFilters) => {
      const filterType = filterKeyToFilterTypeEnum(key);
      const feature = filterType && filterKeyToFeatureEnum(key, filterType);
      if (filterType === FilterTypeEnum.TARGET_TRAJECTORY) {
        return (
          feature?.split('_').slice(1).join('_') !== getTargetIdentification(target) ||
          (target.id === selectedTarget?.id && mapFilters.displayTrajectoryPinnedTarget)
        );
      }
      return true;
    });
  },
);

export const selectTargetTrajectoryFeatures = createSelector(
  selectFilteredTargetForTrajectory,
  (targets: Target<TargetLocation>[]): Feature[] => toTargetTrajectoryFeatures(targets),
);

const selectAnyTargetSelected = (
  state: RootState,
  replayMode: boolean,
  selectedTarget: Target<AbstractLocation> | null,
) => ({
  targets: [...selectTargets(state, replayMode), ...selectStrobes(state, replayMode)],
  selectedTarget,
});

export const selectGroupedTargetWithSelectedTarget = createSelector(
  selectAnyTargetSelected,
  ({ targets, selectedTarget }) =>
    targets.find(
      (target) =>
        target.targetGroup && target.targetGroup === selectedTarget?.targetGroup && target.id !== selectedTarget.id,
    ) ?? null,
);

const selectAnyStrobeId = (state: RootState, replayMode: boolean, strobeId: string) => ({
  strobes: selectStrobes(state, replayMode),
  strobeId,
});

export const selectStrobeById = createSelector(
  selectAnyStrobeId,
  ({ strobes, strobeId }: { strobes: Target<StrobeLocation>[]; strobeId: string }) =>
    strobes.find((strobe) => strobe.id === strobeId),
);

export const selectSelectedDate = createSelector(selectSelectedFeatureDateByMapId, (date): Date | null => date);

export const selectTargetFeatures = createSelector(
  [
    (state, mapId: MapIdEnum) => selectTargetIconFeatures(state, mapId === MapIdEnum.REPLAY),
    (state, mapId: MapIdEnum) => selectTargetLinkFeatures(state, mapId === MapIdEnum.REPLAY),
    (state, mapId: MapIdEnum) => selectTargetFlightPlanLinkFeatures(state, mapId === MapIdEnum.REPLAY),
    selectTargetTrajectoryFeatures,
  ],
  (
    targetIconFeatures,
    targetLinkFeatures,
    targetFlightPlanLinkFeatures,
    targetTrajectoryFeatures,
  ): FeatureCollection => ({
    type: 'FeatureCollection',
    features: [
      ...targetIconFeatures,
      ...targetLinkFeatures,
      ...targetTrajectoryFeatures,
      ...targetFlightPlanLinkFeatures,
    ],
  }),
);

export const selectSelectedTargetCrossFeature = createSelector(
  [selectSelectedTarget, selectSelectedDate],
  (selectedTarget: Target<TargetLocation> | null, selectedDate: Date | null): Feature[] => {
    if (!selectedTarget) {
      return [];
    }
    const targetPosition = toLonLatArray(selectedTarget.lastPosition!.location!.position, false)!;
    return toTargetCrossFeature(targetPosition, selectedDate);
  },
);

export const selectSelectedTargetFeatures = createSelector(
  [selectSelectedTarget, selectSelectedTargetCrossFeature],
  (selectedTarget, selectedTargetCrossFeature): Feature[] =>
    (selectedTarget && [...toTargetIconFeatures([selectedTarget]), ...selectedTargetCrossFeature]) ?? [],
);

export const selectSelectedExpiredDroneCrossFeature = createSelector(
  [selectSelectedExpiredDroneNotifByMapId, selectSelectedDate],
  (selectSelectedExpiredDroneNotif, selectedDate): Feature[] => {
    if (!selectSelectedExpiredDroneNotif) {
      return [];
    }
    const notificationPosition = toLonLatArray(selectSelectedExpiredDroneNotif.position, false)!;
    return toTargetCrossFeature(notificationPosition, selectedDate);
  },
);

export const selectSelectedExpiredDroneTrajectoryFeatures = createSelector(
  [selectSelectedExpiredDroneNotifByMapId, selectActualMapFilters],
  (selectedExpiredDroneNotif, mapFilters): Feature[] => {
    if (
      !selectedExpiredDroneNotif ||
      (!mapFilters.displayTrajectoryDrone && !mapFilters.displayTrajectoryPinnedTarget)
    ) {
      return [];
    }
    return toExpiredDroneTrajectoryFeatures(selectedExpiredDroneNotif);
  },
);

export const selectSelectedExpiredDroneFeatures = createSelector(
  [
    selectSelectedExpiredDroneNotifByMapId,
    selectSelectedExpiredDroneCrossFeature,
    selectSelectedExpiredDroneTrajectoryFeatures,
  ],
  (selectedExpiredDroneNotif, selectedExpiredDroneCrossFeature, selectedExpiredDroneTrajectoryFeatures): Feature[] =>
    (selectedExpiredDroneNotif && [
      toExpiredDroneIconFeature(selectedExpiredDroneNotif),
      ...selectedExpiredDroneCrossFeature,
      ...selectedExpiredDroneTrajectoryFeatures,
    ]) ??
    [],
);

export const selectStrobeSectorFeatures = createSelector(
  selectFilteredStrobes,
  (strobes: Target<StrobeLocation>[]): Feature[] => toStrobeSectorFeatures(strobes),
);

export const selectStrobeAzimuthLineFeatures = createSelector(
  selectFilteredStrobes,
  (strobes: Target<StrobeLocation>[]): Feature[] => toStrobeAzimuthLineFeatures(strobes),
);

export const selectStrobeFeatures = createSelector(
  [selectStrobeSectorFeatures, selectStrobeAzimuthLineFeatures],
  (sectors: Feature[], azimuthLines: Feature[]): FeatureCollection => ({
    type: 'FeatureCollection',
    features: [...sectors, ...azimuthLines],
  }),
);

const selectTargetByReplayMode = (state: RootState, replayMode: boolean, targetId: string) => ({
  targets: selectTargets(state, replayMode),
  targetId: targetId,
});

export const selectTargetById = createSelector(
  selectTargetByReplayMode,
  ({ targets, targetId }): Target<TargetLocation> | undefined => targets.find((target) => target.id === targetId),
);

export const selectAllNoInitZones = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): NoInitZone[] => config?.noInitZones ?? [],
);

export const selectAllValidNoInitZone = createSelector(selectAllNoInitZones, (nizs: NoInitZone[]): NoInitZone[] =>
  nizs.filter((niz: NoInitZone) => isValidPolygon(niz.polygon)),
);

export const selectAllNoInitZoneFeatures = createSelector(selectAllValidNoInitZone, (nizs: NoInitZone[]): Feature[] =>
  toNIZFeatures(nizs),
);

export const selectAllNoFlyZones = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): NoFlyZone[] => config?.noFlyZones ?? [],
);

export const selectAllValidNoFlyZone = createSelector(selectAllNoFlyZones, (nfzs: NoFlyZone[]): NoFlyZone[] =>
  nfzs.filter((nfz: NoFlyZone) => nfz.active && isValidPolygon(nfz.polygon)),
);

export const selectAllNoFlyZoneFeatures = createSelector(selectAllValidNoFlyZone, (nfzs: NoFlyZone[]): Feature[] =>
  toNFZFeatures(nfzs),
);

export const selectAllNoFlyZones3d = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): NoFlyZone3d[] => config?.noFlyZones3d ?? [],
);

export const selectAllValidNoFlyZone3d = createSelector(selectAllNoFlyZones3d, (nfzs: NoFlyZone3d[]): NoFlyZone3d[] =>
  nfzs.filter((nfz: NoFlyZone3d) => nfz.active && isSolid3dPolygonValid(nfz.solidPolygon)),
);

export const selectAllNoFlyZone3dFeatures = createSelector(
  selectAllValidNoFlyZone3d,
  (nfzs: NoFlyZone3d[]): Feature[] => toNFZ3dFeatures(nfzs),
);

export const selectAllInitializationMaskingZones = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): InitializationMaskingZone[] => config?.initializationMaskingZones ?? [],
);

export const selectAllValidInitializationMaskingZone = createSelector(
  selectAllInitializationMaskingZones,
  (imzs: InitializationMaskingZone[]): InitializationMaskingZone[] =>
    imzs.filter((imz: InitializationMaskingZone) => imz.active && isValidPolygon(imz.polygon)),
);

export const selectAllInitializationMaskingZoneFeatures = createSelector(
  selectAllValidInitializationMaskingZone,
  (imzs: InitializationMaskingZone[]): Feature[] => [...toIMZFeatures(imzs), ...toIMZMarginFeatures(imzs)],
);

export const selectZonesFeatures = createSelector(
  [
    selectAllNoInitZoneFeatures,
    selectAllNoFlyZoneFeatures,
    selectAllInitializationMaskingZoneFeatures,
    selectAllNoFlyZone3dFeatures,
  ],
  (nizFeatures: Feature[], nfzFeatures: Feature[], imzFeatures: Feature[], nfz3dFeatures: Feature[]): Feature[] => [
    ...nizFeatures,
    ...nfzFeatures,
    ...imzFeatures,
    ...nfz3dFeatures,
  ],
);

export const selectZonesFilteredFeatures = createSelector(
  [selectZonesFeatures, selectActualMapFilters],
  (zones: Feature[], mapFilters: MapFilters): FeatureCollection => ({
    type: 'FeatureCollection',
    features: getFilteredList(
      zones,
      mapFilters,
      (zone: Feature, key: keyof MapFilters) => zone.properties?.filterTrigger !== key,
    ),
  }),
);

export const selectPointOfInterests = createSelector(
  selectConfigurations,
  (configuration) => configuration?.pointOfInterests ?? [],
);

export const selectFilteredPointOfInterests = createSelector(
  [selectPointOfInterests, selectActualMapFilters],
  (pois, mapFilters): PointOfInterest[] => (mapFilters.displayOtherInfoPoi ? pois : []),
);

export const selectPointOfInterestFeatures = createSelector(selectFilteredPointOfInterests, (pois) => ({
  type: 'FeatureCollection',
  features: toActivePoiFeatures(pois),
}));

export const selectRounds = createSelector(
  selectSituationState,
  (state: SituationState | null): Round[] => state?.rounds ?? [],
);

export const selectTrackFromRawSituationById = createSelector(
  (state: RootState, replayMode: boolean, trackId: string) => ({
    situation: selectSituationState(state, replayMode),
    trackId,
  }),
  ({ situation, trackId }) =>
    situation?.rawSituation?.tracks.find((track: Track<AbstractLocation>) => track.id === trackId) ?? null,
);

export const selectTrackFromRawSituationByTrackLights = createSelector(
  (state: RootState, replayMode: boolean, trackIds: TrackLight[]) => ({
    situation: selectSituationState(state, replayMode),
    trackIds: trackIds.map((t) => `${t.id}-${t.sensorCode}-${t.appCode}`),
  }),
  ({ situation, trackIds }) =>
    situation?.rawSituation?.tracks.filter((track: Track<AbstractLocation>) => trackIds.includes(track.uniqueId)) ?? [],
);
