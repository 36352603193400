export enum ProcedureModelStateEnum {
  USABLE = 'USABLE',
  DRAFT = 'DRAFT',
}

export enum ProcedureStateEnum {
  TO_PROCESS = 'TO_PROCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum ProcedureStepStateEnum {
  TO_BE_STARTED = 'TO_BE_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  IGNORED = 'IGNORED',
  CONFIRMED = 'CONFIRMED',
  DENIED = 'DENIED',
}

export enum ProcedureStepTypeEnum {
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  CONFIRMATION = 'CONFIRMATION',
  PHONE_CALL = 'PHONE_CALL',
  EVENT_CREATION = 'EVENT_CREATION',
  CLOSING = 'CLOSING',
}

export type ProcedureModelStep = {
  id: number;
  site: string;
  code: string;
  name: string;
  type: ProcedureStepTypeEnum;
  rank: number;
  mandatory: boolean;
  phoneNumber: string | null;
  instructions: string[];
};

export type FinishedStep = {
  id: number;
  code: string;
  type: ProcedureStepTypeEnum;
  mandatory: boolean;
  state: ProcedureStepStateEnum.CONFIRMED | ProcedureStepStateEnum.IGNORED | ProcedureStepStateEnum.DENIED;
  rank: number;
  phoneNumber: string | null;
  instructions: string[];
  modifiedBy: string;
  modificationTime: string;
};

export type ProcedureStep =
  | {
      id: number;
      code: string;
      type: ProcedureStepTypeEnum;
      mandatory: boolean;
      state: ProcedureStepStateEnum.TO_BE_STARTED;
      rank: number;
      phoneNumber: string | null;
      instructions: string[];
      modifiedBy: null;
      modificationTime: null;
    }
  | {
      id: number;
      code: string;
      type: ProcedureStepTypeEnum;
      mandatory: boolean;
      state: ProcedureStepStateEnum.IN_PROGRESS;
      rank: number;
      phoneNumber: string | null;
      instructions: string[];
      modifiedBy: string;
      modificationTime: string;
    }
  | FinishedStep;

export type Procedure = {
  id: number;
  name: string;
  site: string;
  state: ProcedureStateEnum;
  steps: ProcedureStep[];
};

export type ProcedureModel = {
  id: number;
  code: string;
  site: string;
  name: string;
  state: ProcedureModelStateEnum;
  steps: ProcedureModelStep[];
};
