import { toReplayEventIconFeatures } from '@components/map/layers/event/event.mapper';
import { selectActualMapFilters } from '@redux/settings/settings.selectors';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { createSelector } from '@reduxjs/toolkit';
import { sortDate } from '@utils/date.utils';
import { isCameraType } from '@utils/sensors/camera/camera.utils';
import { add, isWithinInterval } from 'date-fns';
import { FeatureCollection } from 'geojson';

import { MapFilters } from '@/types/filters.types';
import { ReplayEvent, ReplayStorageConfiguration } from '@/types/replay/replay.types';
import { CameraConfiguration } from '@/types/sensor/configuration.types';

import { RootState } from '../store';
import { replayEventsAdapter, replayManualPeriodsAdapter } from './replay.adapters';
import { ReplayState } from './replay.reducer';

function selectReplayState(state: RootState): ReplayState {
  return state.replay;
}

const { selectAll: selectAllReplayEvents } = replayEventsAdapter.getSelectors(
  (state: RootState) => selectReplayState(state).replayEvents,
);

export const ReplayEventSelectors = {
  selectAllReplayEvents,
};

export const selectSortedReplayEvent = createSelector(selectAllReplayEvents, (events: ReplayEvent[]): ReplayEvent[] => {
  return events.sort((event1, event2) => sortDate(event1.startDate, event2.startDate));
});

const { selectAll: selectAllReplayManualPeriods } = replayManualPeriodsAdapter.getSelectors(
  (state: RootState) => selectReplayState(state).replayManualPeriods,
);

export const ReplayManualPeriodSelectors = {
  selectAllReplayManualPeriods,
};

export const selectReplayStorageConfiguration = createSelector(
  selectReplayState,
  (state: ReplayState): ReplayStorageConfiguration | null => state.replayStorageConfiguration,
);

export const selectOngoingReplayEvents = createSelector(
  [selectAllReplayEvents, selectSituationTime],
  (events: ReplayEvent[], time: string | null): ReplayEvent[] =>
    events.filter(
      (event) =>
        time &&
        isWithinInterval(new Date(time), {
          start: new Date(event.startDate),
          end: add(new Date(event.startDate), { minutes: event.duration }),
        }),
    ),
);

export const selectFilteredOngoingReplayEvents = createSelector(
  [selectOngoingReplayEvents, selectActualMapFilters],
  (events: ReplayEvent[], mapFilters: MapFilters): ReplayEvent[] => (mapFilters.displayOtherInfoEvent ? events : []),
);

export const selectReplayEventFeatures = createSelector(
  selectFilteredOngoingReplayEvents,
  (events: ReplayEvent[]): FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: toReplayEventIconFeatures(events),
    };
  },
);

export const selectReplayStatus = createSelector(selectReplayState, (state) => state.status);

export const selectReplaySpeed = createSelector(selectReplayStatus, (status) => status?.speed);

export const selectReplayUuid = createSelector(selectReplayStatus, (status) => status?.uuid);

export const selectReplayStatusTime = createSelector(selectReplayStatus, (status) =>
  status?.time ? new Date(status.time) : undefined,
);

export const selectReplaySettings = createSelector(selectReplayState, (state) => state.replaySettings);

export const selectReplayStartTime = createSelector(selectReplayState, (state) => state.replayStartTime);

export const selectReplayStartPosition = createSelector(selectReplayState, (state) => state.replayStartPosition);

export const selectReplaySituation = createSelector(selectReplayState, (state) => state.situation);

export const selectReplaySensorStatus = createSelector(
  selectReplaySituation,
  (state) => state?.tacticalSituation?.monitoring.monitoringSensors.activeSensors ?? [],
);

export const selectReplayActiveCameraConfigurations = createSelector(selectReplaySensorStatus, (state) => {
  return state.filter((status) => isCameraType(status)).map((status) => status.configuration) as CameraConfiguration[];
});
