import { Box } from '@chakra-ui/react';
import NotificationToast from '@components/cockpit/toast/notification/NotificationToast';
import { useTimer } from '@hooks/useTimer';
import { ReactElement } from 'react';

const TOAST_TIMEOUT = 20;

interface ToastWithHoverProps {
  onClose: () => void;
  children: ReactElement<typeof NotificationToast>;
}

export const AutoDismissToast = ({ onClose, children }: Readonly<ToastWithHoverProps>) => {
  const { resume, pause } = useTimer({ duration: TOAST_TIMEOUT, onTimeOver: onClose });

  return (
    <Box onMouseEnter={pause} onMouseLeave={resume}>
      {children}
    </Box>
  );
};
