import { Box } from '@chakra-ui/react';
import MenuList from '@components/common/menu/MenuList';
import { MeasureOriginEnum, useCommonContextMenuItems } from '@hooks/useCommonContextMenuItems';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateSelectedDoubtCheckPerimeterCameraUniqueKey } from '@redux/global/global.reducer';
import { getSensorUniqueKey } from '@utils/sensors/sensors.utils';

import { MenuItems } from '@/types/menu.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
interface PerimeterCameraContextMenuProps {
  perimeterCamera: PerimeterCamera;
  clickPosition: { x: number; y: number } | null;
  onClose: () => void;
}

function PerimeterCameraContextMenu({
  onClose,
  perimeterCamera,
  clickPosition,
}: Readonly<PerimeterCameraContextMenuProps>) {
  const selectDoubtCheckPerimeterCamera = useWithDispatch(updateSelectedDoubtCheckPerimeterCameraUniqueKey);

  const handleActivate = () => {
    onClose();
    selectDoubtCheckPerimeterCamera(getSensorUniqueKey(perimeterCamera));
  };

  const commonMenuItems = useCommonContextMenuItems({
    closeContextMenu: onClose,
    position: perimeterCamera.coordinates,
    measureOrigin: MeasureOriginEnum.CAMERA,
  });

  const menuItems: MenuItems = {
    openCameraStream: {
      label: 'contextmenu.actions.openCameraStream',
      onAction: handleActivate,
    },
    ...commonMenuItems,
  };

  return (
    <Box position="absolute" zIndex={4} top={clickPosition?.y} left={clickPosition?.x}>
      <MenuList menuItems={menuItems} depth={0} />
    </Box>
  );
}

export default PerimeterCameraContextMenu;
