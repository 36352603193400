import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/layout';
import { Button, Input, Textarea } from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import CustomInputDateTime from '@components/common/inputs/CustomInputDateTime';
import CustomInputNumber from '@components/common/inputs/CustomInputNumber';
import CustomSelect, { Option } from '@components/common/inputs/CustomStyledSelect';
import CoordinatesBar from '@components/map/controls/CoordinatesBar';
import { useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { useCreateEventMutation } from '@services/replay/event.api';
import { createErrorMessage } from '@utils/common.utils';
import { eventColor } from '@utils/replay/event.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import DmsCoordinates from 'dms-conversion';
import { Field, Formik } from 'formik';
import { memo, useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { EventPopupData } from '@/types/map.types';
import { EventTypeEnum, ReplayEvent } from '@/types/replay/replay.types';

import { eventSchema } from '../../../validations/event.schema';
import DraggableControl from '../controls/DraggableControl';

type Props = {
  top: number;
  left: number;
  onClose: () => void;
} & EventPopupData;

function EventFormPopup({ date, coordinates, top, left, onClose }: Readonly<Props>) {
  const validationSchema = useValidation(eventSchema);
  const userSettingUnit = useAppSelector(selectCoordinatesUnit);
  const activeSite = useAppSelector(selectActiveSite);
  const [unit, setUnit] = useState<CoordinatesUnitEnum>(userSettingUnit);
  const dmsCoordinates = new DmsCoordinates(coordinates?.latitude ?? 0, coordinates?.longitude ?? 0);
  const [eventCreation, { isLoading, isError, error }] = useCreateEventMutation();
  const { formatMessage } = useIntl();

  const emptyForm: Partial<ReplayEvent> = {
    name: '',
    code: uuidv4(),
    startDate: date,
    duration: 5,
    observationType: undefined,
    position: { latitude: dmsCoordinates.latitude.dd, longitude: dmsCoordinates.longitude.dd, type: '2D' },
    comment: '',
    site: activeSite?.code,
  };

  return (
    <DraggableControl
      label={formatMessage({ id: 'live.event.creation' })}
      width={556}
      height={822}
      top={top}
      left={left}
      onClose={onClose}
    >
      <VStack backgroundColor="neutral.700" width="100%" gap={0.25}>
        <Formik
          initialValues={{ ...emptyForm }}
          onSubmit={(values) => {
            eventCreation(values as ReplayEvent)
              .unwrap()
              .then(() => {
                createToast(formatMessage({ id: 'live.event.successToast' }), ToastStatusEnum.SUCCESS);
                onClose();
              });
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, dirty, isValid, errors, touched, handleChange, setFieldValue }) => (
            <form onSubmit={handleSubmit} style={{ width: '100% ' }}>
              <Box padding={4} width="100%" backgroundColor="neutral.700">
                <VStack gap={4} flexGrow={1} alignItems="start">
                  <FormControl
                    id="observationType"
                    isRequired
                    isInvalid={touched.observationType && !!errors.observationType}
                  >
                    <Stack gap={0.5}>
                      <FormLabel variant="mapFormLabel">
                        <FormattedMessage id="live.event.type" />
                      </FormLabel>
                      <CustomSelect
                        isInvalid={touched.observationType && !!errors.observationType}
                        isClearable={false}
                        menuPlacement="bottom"
                        variant="dark"
                        width={450}
                        options={Object.keys(EventTypeEnum).map((key) => ({
                          label: formatMessage({ id: `live.event.types.${key}` }),
                          value: key as EventTypeEnum,
                        }))}
                        formatOptionLabel={(option: Option) => (
                          <HStack gap={1}>
                            <FaCircle color={eventColor[option.value as EventTypeEnum]} />
                            <Text fontSize="16px" color="neutral.white">
                              {option.label}
                            </Text>
                          </HStack>
                        )}
                        onChange={handleChange('observationType')}
                      />
                    </Stack>
                  </FormControl>
                  <FormControl id="startDate" isInvalid={touched.startDate && !!errors.startDate}>
                    <Stack gap={0.5}>
                      <FormLabel variant="mapFormLabel" pointerEvents="none">
                        <FormattedMessage id="live.event.dateTime" />
                      </FormLabel>
                      <Field
                        as={CustomInputDateTime}
                        id="startDate"
                        name="startDate"
                        type={DateTimeEnum.DATE_TIME}
                        borderRadius={0}
                        fontSize="16px"
                        isInvalid={touched.startDate && !!errors.startDate}
                        onChange={handleChange('startDate')}
                      />
                    </Stack>
                  </FormControl>
                  <FormControl id="duration" isInvalid={touched.duration && !!errors.duration}>
                    <Stack gap={0.5}>
                      <FormLabel marginRight={0} variant="mapFormLabel" pointerEvents="none">
                        <FormattedMessage id="live.event.duration" />
                      </FormLabel>
                      <HStack>
                        <Field
                          as={CustomInputNumber}
                          width="70px"
                          id="duration"
                          name="duration"
                          max={120}
                          min={1}
                          onChange={(value?: number) => setFieldValue('duration', value)}
                          isInvalid={touched.duration && !!errors.duration}
                        />
                        <Text>min (max 120)</Text>
                      </HStack>
                    </Stack>
                  </FormControl>
                  <FormControl isRequired isInvalid={touched.name && !!errors.name}>
                    <Stack gap={0.5}>
                      <FormLabel variant="mapFormLabel">
                        <FormattedMessage id="live.event.name" />
                      </FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name="name"
                        type="text"
                        height="48px"
                        maxLength="50"
                        isInvalid={touched.name && !!errors.name}
                      />
                    </Stack>
                  </FormControl>
                  <Stack gap={0.5}>
                    <FormLabel variant="mapFormLabel">
                      <FormattedMessage id="live.event.location" />
                    </FormLabel>
                    <CoordinatesBar
                      coordinatesDMS={dmsCoordinates}
                      handleUnitChange={(value) => setUnit(value as CoordinatesUnitEnum)}
                      unit={unit}
                      fixedSize
                    />
                  </Stack>
                </VStack>
              </Box>
              <Box width="100%" padding={4} backgroundColor="neutral.800">
                <FormLabel variant="mapFormLabel">
                  <FormattedMessage id="live.event.description" />
                </FormLabel>
                <Field
                  as={Textarea}
                  id="comment"
                  size="xs"
                  name="comment"
                  width="100%"
                  height="80px"
                  resize="none"
                  overflowY="auto"
                  maxLength="500"
                  backgroundColor="neutral.900"
                  variant="outline"
                />
              </Box>
              <ErrorsContainer
                errors={errors}
                touched={touched}
                apiError={isError ? createErrorMessage(error) : undefined}
              />
              <Box width="100%" padding={4} backgroundColor="neutral.700">
                <HStack justify="space-around">
                  <Button type="button" onClick={onClose} variant="formButtonSecondary">
                    <FormattedMessage id="global.cancel" />
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={!dirty || !isValid || isLoading}
                    isLoading={isLoading}
                    variant="formButtonPrimary"
                  >
                    <FormattedMessage id="live.event.create" />
                  </Button>
                </HStack>
              </Box>
            </form>
          )}
        </Formik>
      </VStack>
    </DraggableControl>
  );
}

export default memo(EventFormPopup);
