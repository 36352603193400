import { Box, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasRole, selectActiveSite, selectActiveUser } from '@redux/authent/authent.selectors';
import { updateNewMessageEntry } from '@redux/chat/chat.reducer';
import { MessagesSelectors } from '@redux/chat/chat.selectors';
import { RootState } from '@redux/store';
import { useCreateMessageMutation } from '@services/chat/messages.api';
import { compareDay, formatDate } from '@utils/date.utils';
import Scrollbars from 'rc-scrollbars';
import { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { Message } from '@/types/chat/chat.types';
import { DateTimeEnum } from '@/types/dateTime.types';

import CockpitPanelHeader from '../CockpitPanelHeader';
import Panel from '../Panel';
import { ChatFooter } from './ChatFooter';
import ChatMessage from './ChatMessage';

export default function Chat() {
  const { formatMessage } = useIntl();
  const activeUser = useAppSelector(selectActiveUser);
  const messages = useAppSelector(MessagesSelectors.selectAllMessages);
  const activeSite = useAppSelector(selectActiveSite);
  const [createMessage] = useCreateMessageMutation();
  const canSendMessages = useAppSelector((state: RootState) => hasRole(state, RoleEnum.CREATE_MSG));
  const resetNewMessageEntry = useWithDispatch(updateNewMessageEntry);

  const scrollbarRef = useRef<Scrollbars>(null);
  const [previousMessagesLength, setPreviousMessagesLength] = useState<number>(messages.length);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState<boolean>(false);

  if (previousMessagesLength !== messages.length) {
    setPreviousMessagesLength(messages.length);
    const values = scrollbarRef.current?.getValues();
    if (values && values.clientHeight + values.scrollTop === values.scrollHeight) {
      setShouldScrollToBottom(true);
    }
    resetNewMessageEntry(false);
  }

  useLayoutEffect(() => {
    if (shouldScrollToBottom) {
      scrollbarRef.current!.scrollToBottom();
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  useLayoutEffect(() => {
    scrollbarRef.current?.scrollToBottom();
    resetNewMessageEntry(false);
  }, [resetNewMessageEntry]);

  return (
    <Panel
      width="40%"
      isEmpty={messages.length === 0}
      emptyLabel={formatMessage({ id: 'components.chat.noMessage' })}
      header={<CockpitPanelHeader label={formatMessage({ id: 'components.chat.title' })} />}
      footer={
        canSendMessages ? (
          <ChatFooter
            scrollbarRef={scrollbarRef}
            onSubmit={(msg) => createMessage({ msg, site: activeSite?.code ?? '' })}
          />
        ) : undefined
      }
    >
      <VStack
        width="100%"
        gap={0}
        height="100%"
        backgroundColor="neutral.800-op70"
        paddingX={2}
        overflow="auto"
        className="cockpit-scrollbar no-margin"
      >
        {messages.map((message: Message, index: number) => {
          const chatMessage = <ChatMessage message={message} activeUserId={activeUser.id} key={message.id} />;
          if (!messages[index - 1] || !compareDay(message.time, messages[index - 1].time)) {
            return (
              <Fragment key={`frag-${message.time}`}>
                <Text
                  fontSize="12px"
                  fontWeight="medium"
                  color="neutral.200"
                  paddingBottom={1}
                  paddingTop={index === 0 ? 1 : 0}
                  userSelect="none"
                  key={message.time}
                >
                  {formatDate(message.time, DateTimeEnum.DATE)}
                </Text>
                {chatMessage}
              </Fragment>
            );
          }
          return chatMessage;
        })}
        {
          <Box
            width="100%"
            maxHeight="100%"
            height="100%"
            marginTop={0.25}
            marginBottom="-2px"
            minHeight={0}
            flexGrow={0}
            flexShrink={1}
            backgroundColor="neutral.800-op70"
          />
        }
      </VStack>
    </Panel>
  );
}
