import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { Center, Text, VStack } from '@chakra-ui/react';
import DraggableControl from '@components/map/controls/DraggableControl';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectSituationFlightPlans } from '@redux/situation/situation.selectors';
import { sortDate } from '@utils/date.utils';
import { FormattedMessage, useIntl } from 'react-intl';

import FlightPlanCard from './FlightPlanCard';

type Props = {
  top: number;
  left: number;
  onClose: () => void;
};

export default function DailyFlightPlans({ top, left, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const flightPlans = useSelectorWithReplayMode(selectSituationFlightPlans);
  const activations = flightPlans.flatMap((flightPlan) =>
    flightPlan.activations.map((activation) => ({ flightPlan, activation })),
  );

  return (
    <DraggableControl
      top={top}
      left={left}
      label={formatMessage({ id: 'dailyFlightPlans.title' })}
      height={932}
      scrollable={false}
      width={516}
      onClose={onClose}
    >
      <VStack gap={0.25} backgroundColor="neutral.900" height="100%" overflow="hidden">
        <Text padding={4} backgroundColor="neutral.700" size="lg" width="100%">
          <FormattedMessage id="dailyFlightPlans.description" values={{ count: activations.length }} />
        </Text>
        {flightPlans.length === 0 ? (
          <Center width="100%" height="100%">
            <VStack width="100%">
              <EmptyPanelDroneIcon />
              <Text fontSize="16px" color="neutral.200">
                <FormattedMessage id="dailyFlightPlans.empty" />
              </Text>
            </VStack>
          </Center>
        ) : (
          <VStack width="100%" gap={0.25} overflow="auto" className="popup-scrollbar">
            {activations
              .toSorted(({ activation: activation1 }, { activation: activation2 }) =>
                sortDate(activation2.startDate, activation1.startDate),
              )
              .map((info) => (
                <FlightPlanCard
                  isSmall
                  flightPlan={info.flightPlan}
                  activation={info.activation}
                  position={{ x: left, y: top }}
                  key={info.activation.id}
                  onClose={onClose}
                />
              ))}
          </VStack>
        )}
      </VStack>
    </DraggableControl>
  );
}
