import { ReactComponent as ChevronIcon } from '@assets/icons/24x24/ic-chevron-down-24.svg';
import { ReactComponent as DroneIcon } from '@assets/icons/32x32/ic-drone-32.svg';
import { Center, HStack, Icon, Text, useBoolean, VStack } from '@chakra-ui/react';
import { getDroneNumber } from '@utils/utm/drone.utils';
import { useIntl } from 'react-intl';

import { Drone } from '@/types/utm/drone.types';

import InfoValue from '../InfoValue';

type Props = {
  drone: Drone;
};

export default function FlightPlanDrone({ drone }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useBoolean(false);

  return (
    <VStack width="100%" gap={0}>
      <HStack width="100%" gap={0.25} height="96px">
        <HStack gap={1} flex={1} padding={4} backgroundColor="neutral.800">
          <Icon as={DroneIcon} width="32px" height="32px" color="neutral.300" />
          <Text padding={0.5} backgroundColor="neutral.black" size="md">
            {getDroneNumber(drone)}
          </Text>
        </HStack>
        <Center
          width="64px"
          height="100%"
          backgroundColor="neutral.800"
          role="group"
          cursor="pointer"
          onClick={setOpen.toggle}
        >
          <Icon
            as={ChevronIcon}
            width="24px"
            height="24px"
            color="neutral.300"
            _groupHover={{ color: 'sky.500' }}
            transform={open ? 'rotate(180deg)' : undefined}
          />
        </Center>
      </HStack>
      {open && (
        <VStack gap={3} padding={4} width="100%">
          <HStack width="100%" gap={3}>
            <InfoValue label={formatMessage({ id: 'flightPlanInfos.tab.drones.model' })} value={drone.model} />
            <InfoValue
              label={formatMessage({ id: 'flightPlanInfos.tab.drones.manufacturer' })}
              value={drone.manufacturer}
            />
          </HStack>
          <HStack width="100%" gap={3}>
            <InfoValue
              label={formatMessage({ id: 'flightPlanInfos.tab.drones.serialNumber' })}
              value={drone.serialNumber}
            />
            <InfoValue label={formatMessage({ id: 'flightPlanInfos.tab.drones.weight' })} value={null} />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}
