import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';

export const AUTHENT_URL = import.meta.env.VITE_AUTHENT_URL;
export const ADMIN = import.meta.env.VITE_ADMIN_LOGIN;
export const APP = import.meta.env.VITE_APP_LOGIN;
export const ADMIN_PROFILE = import.meta.env.VITE_ADMIN_PROFILE;
export const APP_PROFILE = import.meta.env.VITE_APP_PROFILE;

export const authentApi = createApi({
  reducerPath: 'authentApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['User'],
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
