import { ReactComponent as Backward10 } from '@assets/icons/32x32/icPlayer-10secBackward-32.svg';
import { ReactComponent as Forward10 } from '@assets/icons/32x32/icPlayer-10secForward-32.svg';
import { HStack, VStack } from '@chakra-ui/layout';
import { Box, Container, Icon, IconButton, Text, Tooltip } from '@chakra-ui/react';
import FormattedDateTime from '@components/common/FormattedDateTime';
import CustomTimeline from '@components/replay/CustomTimeline';
import { useReplayTimelineContext } from '@components/replay/ReplayTimelineContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { ReplayEventSelectors, selectReplayUuid } from '@redux/replay/replay.selectors';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAvailableRawRangesQuery } from '@services/c2/c2.api';
import { useGetNotificationsQuery } from '@services/data/notification.api';
import { useGetAvailableSituationRangesQuery, useUpdateStreamMutation } from '@services/replay/replay.api';
import { customAddSubForDate } from '@utils/date.utils';
import { minorLabels, toCustomDataItemNotifications, toCustomDataItemsRanges } from '@utils/replay/replay.utils';
import { isWithinInterval } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { TimelineOptions } from 'vis-timeline';
import { DataSet } from 'vis-timeline/standalone';

import { NotificationTypeEnum } from '@/types/data/data.types';
import {
  CustomDataItem,
  ReplayDataGroupEnum,
  ReplayDataStateEnum,
  ReplayTimeScaleToTimelineTimeAxis,
} from '@/types/replay/replay.types';

function ReplayTimeline() {
  const { activeSite } = useAppSelector(selectActiveUser);
  const { formatMessage } = useIntl();
  const { range, timeScale, currentReplayTime, backwardForwardTooltip, setBackwardForwardTooltip } =
    useReplayTimelineContext();
  const replayUuid = useAppSelector(selectReplayUuid);
  const [updateStream] = useUpdateStreamMutation();
  const start = new Date(range.from);
  const end = new Date(range.to);
  const replayEvents = useAppSelector(ReplayEventSelectors.selectAllReplayEvents).filter((event) =>
    isWithinInterval(new Date(event.startDate), { start, end }),
  );

  const groups = new DataSet(
    Object.values(ReplayDataGroupEnum).map((value) => ({
      id: value,
      content: formatMessage({ id: `replay.dataType.${value}` }),
    })),
  );

  const { data: sitacAvailableRanges = [] } = useGetAvailableSituationRangesQuery(
    activeSite ? { site: activeSite.code, from: range.from.toISOString(), to: range.to.toISOString() } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: rawAvailableRanges = [] } = useGetAvailableRawRangesQuery(
    activeSite ? { site: activeSite.code, from: range.from.toISOString(), to: range.to.toISOString() } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: notifications = [] } = useGetNotificationsQuery(
    {
      from: range.from.toISOString(),
      to: range.to.toISOString(),
      site: activeSite!.code,
      types: [NotificationTypeEnum.DRONE, NotificationTypeEnum.SEGMENT],
    },
    { skip: !activeSite },
  );

  const items: CustomDataItem[] = [
    ...toCustomDataItemsRanges(sitacAvailableRanges, ReplayDataGroupEnum.SITAC, ReplayDataStateEnum.ACCESSIBLE),
    ...toCustomDataItemsRanges(rawAvailableRanges, ReplayDataGroupEnum.RAW, ReplayDataStateEnum.ACCESSIBLE),
    ...toCustomDataItemNotifications(notifications),
  ];

  //To visualize all styles until we can retrieve all data types
  /*const range1 = {
    from: range.from.toISOString(),
    to: customAddSubForDate(range.from, 'add', timeScale, 3).toISOString(),
  };
  const range2 = {
    from: customAddSubForDate(range.from, 'add', timeScale, 3).toISOString(),
    to: customAddSubForDate(range.from, 'add', timeScale, 6).toISOString(),
  };
  const range3 = {
    from: customAddSubForDate(range.from, 'add', timeScale, 6).toISOString(),
    to: customAddSubForDate(range.from, 'add', timeScale, 8).toISOString(),
  };
  const range4 = {
    from: customAddSubForDate(range.from, 'add', timeScale, 8).toISOString(),
    to: customAddSubForDate(range.from, 'add', timeScale, 10).toISOString(),
  };
  const mockItems: CustomDataItem[] = [
    {
      group: ReplayDataGroupEnum.SITAC,
      range: range1,
      className: ReplayDataStateEnum.ACCESSIBLE,
    },
    {
      group: ReplayDataGroupEnum.SITAC,
      range: range2,
      className: ReplayDataStateEnum.ARCHIVED,
    },
    {
      group: ReplayDataGroupEnum.SITAC,
      range: range3,
      className: ReplayDataStateEnum.DELETED_MANUALLY,
    },
    {
      group: ReplayDataGroupEnum.SITAC,
      range: range4,
      className: ReplayDataStateEnum.DELETED_AUTOMATICALLY,
    },
    {
      group: ReplayDataGroupEnum.RAW,
      range: range1,
      className: ReplayDataStateEnum.DELETED_AUTOMATICALLY,
    },
    {
      group: ReplayDataGroupEnum.VIDEO,
      range: range3,
      className: ReplayDataStateEnum.ACCESSIBLE,
    },
  ];*/

  const options: TimelineOptions = {
    stack: false,
    showMajorLabels: false,
    horizontalScroll: false,
    moveable: false,
    selectable: false,
    showCurrentTime: true,
    showTooltips: true,
    start: range.from,
    end: range.to,
    format: {
      minorLabels: minorLabels,
    },
    timeAxis: ReplayTimeScaleToTimelineTimeAxis[timeScale],
  };

  function handleTimeChange(currentTime: Date) {
    if (replayUuid) {
      updateStream({ uuid: replayUuid, command: { time: currentTime } });
    }
  }

  function handleTimeDelay(action: 'add' | 'sub') {
    const beginDate = customAddSubForDate(range.from, action, timeScale, 10);
    handleTimeChange(beginDate);
    const label = `${formatMessage({ id: 'replay.timeline.backwardForwardTooltip' }, { action: action })} ${formatMessage({ id: `replay.time10Times.${timeScale}` })}`;
    if (backwardForwardTooltip.timeout) {
      clearTimeout(backwardForwardTooltip.timeout);
    }
    setBackwardForwardTooltip({
      label,
      isDisplayed: true,
      timeout: setTimeout(() => setBackwardForwardTooltip({ label, isDisplayed: false, timeout: null }), 2000),
    });
  }

  return (
    <Container maxWidth="container.uhdW" display="flex" flexDirection="column" height="100%" padding={0}>
      <HStack justifyContent="space-between" height="38px">
        <HStack backgroundColor="neutral.800" marginLeft="288px">
          <FormattedDateTime
            date={range.from}
            color="neutral.white"
            fontSize="16px"
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
            second="numeric"
          />
        </HStack>
        <HStack backgroundColor="neutral.800" marginRight="82px">
          <FormattedDateTime
            date={range.to}
            color="neutral.white"
            fontSize="16px"
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
            second="numeric"
          />
        </HStack>
      </HStack>
      <HStack gap={0} alignItems="flex-start">
        <VStack
          width="164px"
          height="97px"
          marginLeft={3}
          marginRight="34px"
          marginTop="5px"
          alignItems="flex-start"
          justifyContent="space-between"
          flexShrink={0}
        >
          <Text>
            <FormattedMessage id="replay.dataType.video" />
          </Text>
          <Text>
            <FormattedMessage id="replay.dataType.sitac" />
          </Text>
          <Text>
            <FormattedMessage id="replay.dataType.raw" />
          </Text>
        </VStack>
        <Tooltip label={`-${formatMessage({ id: `replay.time10Times.${timeScale}` })}`} placement="top" openDelay={300}>
          <IconButton
            alignSelf="flex-start"
            marginRight="5px"
            flexShrink={0}
            variant="replayTimelineBackwardForward"
            aria-label={formatMessage({ id: 'replay.timeline.actions.backward' })}
            icon={<Icon as={Backward10} width="32px" height="32px" />}
            onClick={() => handleTimeDelay('sub')}
          />
        </Tooltip>
        <Box width="100%" height="140px">
          <CustomTimeline
            className="replay-timeline"
            events={replayEvents}
            options={options}
            groups={groups}
            customItems={items}
            currentTime={currentReplayTime}
            onclick={handleTimeChange}
          />
        </Box>
        <Tooltip
          label={`+${formatMessage({ id: `replay.time10Times.${timeScale}` })}`}
          isDisabled={customAddSubForDate(range.from, 'add', timeScale, 10) > new Date()}
          placement="top"
          openDelay={300}
        >
          <IconButton
            alignSelf="flex-start"
            marginLeft="5px"
            marginRight="31px"
            flexShrink={0}
            variant="replayTimelineBackwardForward"
            aria-label={formatMessage({ id: 'replay.timeline.actions.forward' })}
            icon={<Icon as={Forward10} width="32px" height="32px" />}
            isDisabled={customAddSubForDate(range.from, 'add', timeScale, 10) > new Date()}
            onClick={() => handleTimeDelay('add')}
          />
        </Tooltip>
      </HStack>
    </Container>
  );
}

export default ReplayTimeline;
