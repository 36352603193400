import { useCallback, useEffect, useRef } from 'react';

interface TimerConfig {
  duration: number;
  interval?: number;
  onTimeOver?: () => void;
}

export const useTimer = ({
  duration,
  interval = 1000,
  onTimeOver,
}: Readonly<TimerConfig>): {
  time: number;
  resume: () => void;
  pause: () => void;
  reset: () => void;
  isRunning: boolean;
} => {
  const runningRef = useRef(true);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const timeRef = useRef(duration);

  const tick = useCallback(() => {
    if (!runningRef.current || timeRef.current <= 0) {
      return;
    }

    timeRef.current -= 1;

    if (timeRef.current === 0) {
      runningRef.current = false;
      onTimeOver && onTimeOver();
    } else {
      timerRef.current = setTimeout(tick, interval);
    }
  }, [interval, onTimeOver]);

  const resume = useCallback(() => {
    if (!runningRef.current && timeRef.current > 0) {
      runningRef.current = true;
      timerRef.current = setTimeout(tick, interval);
    }
  }, [tick, interval]);

  const pause = useCallback(() => {
    runningRef.current = false;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const reset = useCallback(() => {
    pause();
    timeRef.current = duration;
  }, [duration, pause]);

  useEffect(() => {
    timerRef.current = setTimeout(tick, interval);
  }, [interval, tick]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { time: timeRef.current, resume, pause, reset, isRunning: runningRef.current } as const;
};
