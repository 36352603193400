import { ReactComponent as FlightPlanActive } from '@assets/components/flightPlan/flightPlan-active.svg';
import { ReactComponent as AltitudeIcon } from '@assets/icons/20x20/ic-altitude-20.svg';
import { ReactComponent as PinIcon } from '@assets/icons/32x32/ic-pin-32.svg';
import { Box, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { useMapContext } from '@components/map/MapContext';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { centerMapByMapId, updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { centerOfMass } from '@turf/turf';
import { formatDate } from '@utils/date.utils';
import { formatUnit, HeightUnits } from '@utils/unit.utils';
import { toCoordinates } from '@utils/validation/coordinates.utils';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { DateTimeEnum } from '@/types/dateTime.types';
import { FeatureTypeEnum, PopupDataTypeEnum } from '@/types/map.types';
import { Activation, FlightPlan, FlightPlanActivationStateEnum } from '@/types/utm/flightPlan.types';

type Props = {
  flightPlan: FlightPlan;
  activation: Activation;
  position: { x: number; y: number };
  isSmall?: boolean;
  isArchived?: boolean;
  onClose?: () => void;
};

export default function FlightPlanCard({
  flightPlan,
  activation,
  position,
  isSmall = false,
  isArchived = false,
  onClose,
}: Readonly<Props>) {
  const { mapId } = useMapContext();
  const centerMapView = useWithDispatch(centerMapByMapId);
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);

  const isActive = activation.state === FlightPlanActivationStateEnum.ACTIVE;
  const formattedStartTime = format(new Date(activation.startDate), "HH':'mm");
  const formattedEndTime = format(new Date(activation.endDate), "HH':'mm");
  const formattedStartDate = formatDate(activation.startDate, DateTimeEnum.DATE);
  const formattedEndDate = formatDate(activation.endDate, DateTimeEnum.DATE);

  const sameDayLabel = isArchived ? 'archiveDateAndTime' : 'liveTime';
  const dateLabel = formattedStartDate === formattedEndDate ? sameDayLabel : 'multipleDay';

  function handleFlightPlanPopup() {
    onClose && onClose();
    updatePopupControl({
      mapId,
      popupControl: {
        type: PopupDataTypeEnum.INFO,
        open: true,
        position,
        data: {
          type: FeatureTypeEnum.FLIGHT_PLAN,
          featureValue: flightPlan,
          activation,
        },
      },
    });
  }

  return (
    <HStack width="100%" gap={0.25} align="stretch">
      <HStack gap={3} backgroundColor="neutral.700" padding={4} width="100%">
        {isActive ? (
          <Icon as={FlightPlanActive} width="8px" height="86px" />
        ) : (
          <Box width="4px" height="82px" backgroundColor="neutral.600" marginX={0.25} />
        )}
        <VStack gap={2} width="100%">
          <HStack justifyContent="space-between" width="100%">
            <Text padding={0.5} backgroundColor="neutral.black" size="md">
              {flightPlan.externalId ?? flightPlan.code}
            </Text>
            {!isArchived && (
              <Text
                size="md"
                padding={0.5}
                color={isActive ? 'alertZero.500' : 'neutral.300'}
                backgroundColor={isActive ? 'neutral.900' : 'neutral.700'}
              >
                <FormattedMessage id={`flightPlan.activation.state.${activation.state}`} />
              </Text>
            )}
          </HStack>
          <HStack gap={isSmall ? undefined : 5} justifyContent={isSmall ? 'space-between' : undefined} width="100%">
            <Text color="neutral.300" fontSize="16px" whiteSpace="pre-line">
              <FormattedMessage
                id={`flightPlan.${dateLabel}`}
                values={{
                  startDate: (
                    <Text as="span" fontSize="16px">
                      {formattedStartDate}
                    </Text>
                  ),
                  startTime: (
                    <Text as="span" fontSize="16px">
                      {formattedStartTime}
                    </Text>
                  ),
                  endTime: (
                    <Text as="span" fontSize="16px">
                      {formattedEndTime}
                    </Text>
                  ),
                  endDate: (
                    <Text as="span" fontSize="16px">
                      {formattedEndDate}
                    </Text>
                  ),
                }}
              />
            </Text>
            <HStack gap={1} alignItems="start">
              <Icon as={AltitudeIcon} width="24px" height="24px" color="neutral.300" />
              <Text fontSize="16px">
                {flightPlan.altitudeMax === null ? (
                  '-'
                ) : (
                  <FormattedMessage
                    id="flightPlan.maxHeight"
                    values={{ value: formatUnit(HeightUnits.METERS, flightPlan.altitudeMax) }}
                  />
                )}
              </Text>
            </HStack>
          </HStack>
        </VStack>
      </HStack>
      <Center
        role="group"
        cursor="pointer"
        width="64px"
        flexShrink={0}
        backgroundColor="neutral.700"
        onClick={() => {
          centerMapView({
            mapId: mapId,
            position:
              flightPlan.startPosition ?? toCoordinates(centerOfMass(flightPlan.polygons).geometry.coordinates)!,
            zoom: 14,
          });
          handleFlightPlanPopup();
        }}
      >
        <Icon
          color="neutral.300"
          as={PinIcon}
          width="32px"
          height="32px"
          _groupHover={{ color: 'var(--chakra-colors-sky-500)' }}
        />
      </Center>
    </HStack>
  );
}
