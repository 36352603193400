import { FlightPlan, FlightPlanCriteria } from '@/types/utm/flightPlan.types';

import { UTM_URL, utmApi } from './utm.api';

export const flightPlanApi = utmApi.injectEndpoints({
  endpoints: (builder) => ({
    getFlightPlans: builder.query<FlightPlan[], FlightPlanCriteria>({
      query: ({ sites, droneCodes, fromDate, toDate, alsoArchived }) => ({
        url: `${UTM_URL}/flight-plans`,
        params: {
          sites,
          droneCodes,
          fromDate,
          toDate,
          alsoArchived,
        },
      }),
    }),
    createFlightPlan: builder.mutation<FlightPlan, FlightPlan>({
      query: (flightplan) => ({
        url: `${UTM_URL}/flight-plans`,
        method: 'POST',
        body: flightplan,
      }),
    }),
    updateFlightPlan: builder.mutation<FlightPlan, { id: number; flightPlan: FlightPlan }>({
      query: ({ id, flightPlan }) => ({
        url: `${UTM_URL}/flight-plans/${id}`,
        method: 'PUT',
        body: flightPlan,
      }),
    }),
    reroute: builder.mutation<void, { id: number; fatoStart?: string; fatoEnd?: string }>({
      query: ({ id, fatoStart, fatoEnd }) => ({
        url: `${UTM_URL}/flight-plans/${id}`,
        method: 'PUT',
        params: {
          fatoStart: fatoStart,
          fatoEnd: fatoEnd,
        },
      }),
    }),
    deleteFlightPlan: builder.mutation<void, number>({
      query: (id) => ({
        url: `${UTM_URL}/flight-plans/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetFlightPlansQuery } = flightPlanApi;
