import { useUpdateProcedureMutation } from '@services/data/procedure.api';
import { memo } from 'react';

import { ProcedureStep, ProcedureStepStateEnum } from '@/types/config/procedure.types';

import ProcedureInstructions from './ProcedureInstructions';
import StepActionButtons from './StepActionButtons';

type Props = {
  step: ProcedureStep;
  notificationId: number;
  procedureId: number;
  canManage: boolean;
};

function EventCreationStep({ step, notificationId, procedureId, canManage }: Readonly<Props>) {
  const [updateProcedure] = useUpdateProcedureMutation();

  if (!canManage) {
    return <ProcedureInstructions instructions={step.instructions} />;
  }
  return (
    <>
      <ProcedureInstructions instructions={step.instructions} />
      <StepActionButtons
        isMandatory={step.mandatory}
        notificationId={notificationId}
        procedureId={procedureId}
        stepRank={step.rank}
        onValidate={() =>
          updateProcedure({
            notificationId,
            procedureId,
            step: step.rank,
            state: ProcedureStepStateEnum.CONFIRMED,
          }).unwrap()
        }
      />
    </>
  );
}

export default memo(EventCreationStep);
