import { Button, HStack } from '@chakra-ui/react';
import { useUpdateProcedureMutation } from '@services/data/procedure.api';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Procedure, ProcedureStepStateEnum } from '@/types/config/procedure.types';

type Props = {
  isMandatory: boolean;
  isDisabled?: boolean;
  label?: string;
  notificationId: number;
  procedureId: number;
  stepRank: number;
  onValidate: () => Promise<Procedure>;
};

export default function StepActionButtons({
  isMandatory,
  isDisabled = false,
  label = 'notification.procedure.step.validate',
  notificationId,
  procedureId,
  stepRank,
  onValidate,
}: Readonly<Props>) {
  const [updateProcedure] = useUpdateProcedureMutation();
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);
  const [isLoadingValidate, setIsLoadingValidate] = useState<boolean>(false);

  return (
    <HStack
      gap={2}
      width="100%"
      alignItems="start"
      backgroundColor="neutral.700"
      paddingY={3}
      flexShrink={0}
      justifyContent="center"
      zIndex={1}
    >
      {!isMandatory && (
        <Button
          variant="formButtonPrimary"
          height="40px"
          width="min-content"
          isLoading={isLoadingCancel}
          isDisabled={isLoadingCancel || isLoadingValidate}
          onClick={() => {
            setIsLoadingCancel(true);
            updateProcedure({ notificationId, procedureId, step: stepRank, state: ProcedureStepStateEnum.IGNORED })
              .unwrap()
              .catch(() => setIsLoadingCancel(false));
          }}
        >
          <FormattedMessage id="notification.procedure.step.skip" />
        </Button>
      )}
      <Button
        isDisabled={isDisabled || isLoadingCancel || isLoadingValidate}
        variant="formButtonPrimary"
        height="40px"
        width="min-content"
        isLoading={isLoadingValidate}
        onClick={() => {
          setIsLoadingValidate(true);
          onValidate().catch(() => setIsLoadingValidate(false));
        }}
      >
        <FormattedMessage id={label} />
      </Button>
    </HStack>
  );
}
