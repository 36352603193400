import { Text } from '@chakra-ui/layout';
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import LadSensorsTab from '@components/cockpit/panels/sytemConfig/LadSensorsTab';
import PerimeterSensorsTab from '@components/cockpit/panels/sytemConfig/PerimeterSensorsTab';
import { FormattedMessage, useIntl } from 'react-intl';

import CockpitPanelHeader from '../CockpitPanelHeader';
import Panel from '../Panel';
import { SystemParamTab } from './SystemParamTab';

export default function SystemConfiguration() {
  const { formatMessage } = useIntl();
  return (
    <Panel header={<CockpitPanelHeader label={formatMessage({ id: 'components.sidebar.SYSTEM_CONFIGURATION' })} />}>
      <Tabs height="100%" width="100%" variant="cyber">
        <TabList>
          <Tab>
            <Text size="md">
              <FormattedMessage id="components.systemConfiguration.C_UAS_SENSORS" />
            </Text>
          </Tab>
          <Tab>
            <Text size="md">
              <FormattedMessage id="components.systemConfiguration.PERIMETER_SENSORS" />
            </Text>
          </Tab>
          <Tab>
            <Text size="md">
              <FormattedMessage id="components.systemConfiguration.SYSTEM_PARAMETERS" />
            </Text>
          </Tab>
        </TabList>

        <TabPanels height="100%">
          <LadSensorsTab />
          <PerimeterSensorsTab />
          <SystemParamTab />
        </TabPanels>
      </Tabs>
    </Panel>
  );
}
