import { Abstract, Modify } from '../commons/commons.types';
import { Group, GroupLight } from './groups.types';
import { Site } from './sites.types';

export type User = {
  id: number;
  firstName: string;
  name: string;
  email: string;
  login: string;
  password: string;
  active: boolean;
  deactivationDate: string | null;
  sites: Site[];
  activeSite: Site | null;
  groups: GroupLight[];
  activeGroup: Group | null;
} & Abstract;

export type UserLight = {
  id: number;
  name: string | null;
  firstName: string | null;
  login: string | null;
  email: string | null;
};

export type NormalizedUser = Modify<
  User,
  {
    sites: string[];
    activeSite: string | null;
  }
>;

export interface AuthentSettings {
  id: number;
  passwordComplexity: boolean;
  passwordDuration: number;
  adminPasswordDuration: number;
  activationDuration: number;
  displayMode: DisplayModeEnum;
}

export interface Token {
  authorizations: number[];
  exp: number;
  id: string;
  name: string;
  sub: string;
  displayMode?: string;
}

export enum DisplayModeEnum {
  MULTI_SCREENS_WITH_COCKPIT = 'MULTI_SCREENS_WITH_COCKPIT',
  TWO_SCREENS = 'TWO_SCREENS',
  MONO_SCREEN = 'MONO_SCREEN',
  NOT_SET = 'NOT_SET',
}
