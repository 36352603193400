import { TimelineTimeAxisOption } from 'vis-timeline';

import { User } from '@/types/authent/login.types';

import { Abstract, Coordinates, DurationCriteria } from '../commons/commons.types';

export enum ObservationTypeEnum {
  DRONE = 'DRONE',
  INTRUSION = 'INTRUSION',
  VARIOUS = 'VARIOUS',
}

export enum EventTypeEnum {
  LAD = 'LAD',
  PERIMETER = 'PERIMETER',
  OTHER = 'OTHER',
}

export interface ReplayEvent extends Abstract {
  id: number;
  code: string;
  name: string;
  startDate: string;
  duration: number; // Duration in minutes
  observationType: EventTypeEnum;
  position: Coordinates | null;
  comment: string;
  site: string;
}

export interface ReplayStorageConfiguration {
  id: number;
  site: string;
  purgeSituation: number;
}

export interface Period {
  id: number;
  site: string;
  startDate: string;
  endDate: string;
}

export type ManualRecordCriteria = DurationCriteria;
export type SituationCriteria = DurationCriteria;

export interface ActiveSensorCriteria {
  site: string;
  from: number;
  to: number;
}

export enum EventStatus {
  ONGOING = 'ONGOING',
  PASSED = 'PASSED',
}

export interface ReplaySituationInfo {
  site: string;
  speed: number;
  time: Date;
  user: User;
  uuid: string;
  situationFound: boolean;
}

export interface ReplaySituationCommand {
  time?: Date;
  speed?: number;
}

export type ReplayStreamCriteria = {
  uuid: string;
  command: ReplaySituationCommand;
};

export interface RangeProps {
  from: Date;
  to: Date;
}

export interface BackwardForwardTooltip {
  isDisplayed: boolean;
  label: string;
  timeout: NodeJS.Timeout | null;
}

export enum ReplayDataGroupEnum { //enum order is important
  VIDEO = 'video',
  SITAC = 'sitac',
  RAW = 'raw',
}

export enum ReplayDataStateEnum {
  ACCESSIBLE = 'accessible',
  ARCHIVED = 'archived',
  DELETED_AUTOMATICALLY = 'deletedAutomatically',
  DELETED_MANUALLY = 'deletedManually',
}

export enum ReplayTimeScaleEnum {
  MINUTE = 'minute',
  QUARTER_HOUR = 'quarterHour',
  HALF_HOUR = 'halfHour',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
}

export interface CustomTimelineTimeAxisOption extends TimelineTimeAxisOption {
  scale: 'minute' | 'hour' | 'day' | 'week';
  step: number;
}

export const ReplayTimeScaleToTimelineTimeAxis: Record<ReplayTimeScaleEnum, CustomTimelineTimeAxisOption> = {
  [ReplayTimeScaleEnum.MINUTE]: { scale: 'minute', step: 1 },
  [ReplayTimeScaleEnum.QUARTER_HOUR]: { scale: 'minute', step: 15 },
  [ReplayTimeScaleEnum.HALF_HOUR]: { scale: 'minute', step: 30 },
  [ReplayTimeScaleEnum.HOUR]: { scale: 'hour', step: 1 },
  [ReplayTimeScaleEnum.DAY]: { scale: 'day', step: 1 },
  [ReplayTimeScaleEnum.WEEK]: { scale: 'week', step: 1 },
};

export interface CustomDataItem {
  className: string;
  type: 'range' | 'point';
  group: ReplayDataGroupEnum;
  start: string;
  end?: string;
  content?: string;
  style?: string;
}
