import { appIntl } from '@components/locale/IntlGlobalProvider';
import { useEffect, useRef, useState } from 'react';

interface CustomPerformance extends Performance {
  memory?: {
    usedJSHeapSize: number;
    jsHeapSizeLimit: number;
  };
}

const usePerformanceMonitor = (threshold = 3000, maxMemoryLimitRatio = 0.9, timeout = 5000) => {
  const { formatMessage } = appIntl();
  const [ignore, setIgnore] = useState<boolean>(false);
  const interval = useRef<NodeJS.Timeout | undefined>(undefined);

  function reloadPage() {
    clearInterval(interval.current);
    if (confirm(formatMessage({ id: 'error.reloadPage' }))) {
      setIgnore(true);
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    } else {
      setIgnore(true);
    }
  }

  function checkPerformanceThreshold(start: number) {
    const end = performance.now();
    const delta = end - start;

    if (delta > threshold) {
      console.warn(`Slowdown detected: ${delta.toFixed(2)}ms exceeds the threshold of ${threshold}ms.`);
    }
  }

  function checkMemoryValues(): boolean {
    const { memory } = performance as CustomPerformance;
    if (!memory) {
      console.warn('The performance.memory API is not supported by this browser.');
      return false;
    }

    const { usedJSHeapSize, jsHeapSizeLimit } = memory;
    const usedMB = usedJSHeapSize / 1024 / 1024;
    const limitMB = jsHeapSizeLimit / 1024 / 1024;
    const memoryLimitMB = (maxMemoryLimitRatio * jsHeapSizeLimit) / 1024 / 1024;

    if (usedMB > memoryLimitMB) {
      console.warn(`Memory usage: ${usedMB.toFixed(2)}MB / ${limitMB.toFixed(2)}MB`);
      console.warn(`High memory usage: ${usedMB.toFixed(2)}MB exceeds the limit of ${memoryLimitMB}MB.`);
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (ignore) {
      clearInterval(interval.current);
      return;
    }

    let hasToReloadPage = false;
    const monitor = () => {
      const start = performance.now();
      requestAnimationFrame(() => {
        checkPerformanceThreshold(start);
        hasToReloadPage = checkMemoryValues();
      });
      if (hasToReloadPage) {
        reloadPage();
      }
    };

    interval.current = setInterval(monitor, timeout);
    return () => clearInterval(interval.current);
  }, [threshold]);
};

export default usePerformanceMonitor;
