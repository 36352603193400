import { HStack, VStack } from '@chakra-ui/layout';
import { Button, RadioGroup, Text } from '@chakra-ui/react';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import PanelHeader from '@components/common/layout/PanelHeader';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import {
  hasRole,
  hasSensorLADorPerimeterAccessFromActiveProfile,
  hasSensorRoleFromActiveProfile,
  selectActiveUser,
} from '@redux/authent/authent.selectors';
import { updateLocalMapFilters } from '@redux/settings/settings.reducer';
import { selectLocalMapFilters } from '@redux/settings/settings.selectors';
import { usePatchMapFiltersMutation } from '@services/config/setting.api';
import {
  filterOptionsDangerLevelCategory,
  filterOptionsDangerZoneCategory,
  filterOptionsFlightplanCategory,
  filterOptionsLadSensorsCategory,
  filterOptionsMapLayerCategory,
  filterOptionsOtherInfoCategory,
  filterOptionsPerimeterSensorsCategory,
  filterOptionsRadarZoneCategory,
  filterOptionsReliabilityCategory,
  filterOptionsRfDetectionCategory,
  filterOptionsSensorInfoCategory,
  filterOptionsTargetCategory,
  filterOptionsTrajectoryCategory,
} from '@utils/map/filters/filters.constants';
import { TypeEnum } from '@utils/sensors/configuration.constants';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { FormattedMessage, useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';

import FilterCategory from './FilterCategory';

type Props = {
  onClose: () => void;
};

export default function FiltersMenu({ onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const user = useAppSelector(selectActiveUser);
  const localMapFilters = useAppSelector(selectLocalMapFilters);
  const setLocalMapFilters = useWithDispatch(updateLocalMapFilters);

  const [updateMapFilters, { isLoading }] = usePatchMapFiltersMutation();
  const canEditMapFilters = useAppSelector((state) => hasRole(state, RoleEnum.EDIT_MAP_FILTERS));
  const canAccessLADSensor = useAppSelector((state) =>
    hasSensorLADorPerimeterAccessFromActiveProfile(state, TypeEnum.LAD),
  );
  const canAccessPerimSensor = useAppSelector((state) =>
    hasSensorLADorPerimeterAccessFromActiveProfile(state, TypeEnum.PERIM),
  );
  const canAccessRFSensor = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.ACCESS, sensorFamily: SensorFamilyEnum.RF }),
  );
  const canAccessRadarSensor = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.ACCESS, sensorFamily: SensorFamilyEnum.RADAR }),
  );

  function saveMapFilters() {
    updateMapFilters({
      login: user.login,
      mapFilters: localMapFilters,
    })
      .unwrap()
      .catch(() => createToast(formatMessage({ id: 'error.mapFiltersSaveError' }), ToastStatusEnum.ERROR));
  }

  const noLocalChange = Object.keys(localMapFilters).length === 0;
  const isButtonDisabled = !canEditMapFilters || isLoading || noLocalChange;

  return (
    <VStack
      gap={0}
      alignItems="flex-start"
      position="relative"
      height="952px"
      width="1344px"
      backgroundColor="neutral.800"
      zIndex={2}
    >
      <PanelHeader label={formatMessage({ id: 'filters.title' })} onClose={onClose} />
      <MapCustomScrollbar height={888}>
        <VStack gap={0} alignItems="flex-start" width="100%" height="100%">
          <RadioGroup width="100%" paddingX={4} paddingY={3} backgroundColor="neutral.700">
            <HStack gap={2}>
              <Button
                variant="formButtonPrimary"
                height="40px"
                width="fit-content"
                paddingX={2}
                isDisabled={isButtonDisabled}
                onClick={() => setLocalMapFilters({})}
              >
                <Text color={canEditMapFilters ? undefined : 'neutral.500'}>
                  <FormattedMessage id="filters.reset" />
                </Text>
              </Button>
              <Button
                variant="formButtonPrimary"
                height="40px"
                width="fit-content"
                paddingX={2}
                isDisabled={isButtonDisabled}
                isLoading={isLoading}
                onClick={saveMapFilters}
              >
                <Text color={canEditMapFilters ? undefined : 'neutral.500'}>
                  <FormattedMessage id="filters.save" />
                </Text>
              </Button>
            </HStack>
          </RadioGroup>
          <HStack
            gap={0}
            padding={4}
            width="100%"
            flexShrink={0}
            backgroundColor="neutral.800"
            borderBottomWidth="2px"
            borderColor="neutral.900"
          >
            <FilterCategory
              filterCategory={filterOptionsTargetCategory}
              label="filters.target.title"
              color="neutral.200"
            />
            <FilterCategory filterCategory={filterOptionsTrajectoryCategory} label="filters.trajectory.title" />
            <VStack gap={4} alignSelf="start">
              {canAccessRFSensor && (
                <FilterCategory filterCategory={filterOptionsRfDetectionCategory} label="filters.rfDetection.title" />
              )}
              <FilterCategory filterCategory={filterOptionsReliabilityCategory} label="filters.reliability.title" />
            </VStack>
            <FilterCategory filterCategory={filterOptionsDangerLevelCategory} label="filters.dangerLevel.title" />
          </HStack>
          <HStack
            gap={0}
            padding={4}
            width="100%"
            flexShrink={0}
            backgroundColor="neutral.800"
            borderBottomWidth={0.25}
            borderColor="neutral.900"
          >
            {canAccessLADSensor && (
              <FilterCategory filterCategory={filterOptionsLadSensorsCategory} label="filters.ladSensors.title" />
            )}
            {canAccessPerimSensor && (
              <FilterCategory
                filterCategory={filterOptionsPerimeterSensorsCategory}
                label="filters.perimeterSensors.title"
              />
            )}
            <FilterCategory filterCategory={filterOptionsSensorInfoCategory} label="filters.sensorInfo.title" />
            {canAccessRadarSensor && (
              <FilterCategory filterCategory={filterOptionsRadarZoneCategory} label="filters.radarZone.title" />
            )}
          </HStack>
          <HStack gap={0} padding={4} width="100%" height="272px" flexShrink={0} backgroundColor="neutral.800">
            <FilterCategory filterCategory={filterOptionsDangerZoneCategory} label="filters.dangerZone.title" />
            <FilterCategory filterCategory={filterOptionsFlightplanCategory} label="filters.flightplan.title" />
            <FilterCategory filterCategory={filterOptionsOtherInfoCategory} label="filters.otherInfo.title" />
            <FilterCategory filterCategory={filterOptionsMapLayerCategory} label="filters.mapLayer.title" />
          </HStack>
        </VStack>
      </MapCustomScrollbar>
    </VStack>
  );
}
