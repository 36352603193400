import { Drone, DroneClearanceEnum } from '@/types/utm/drone.types';

import { UTM_URL, utmApi } from './utm.api';

export const droneApi = utmApi.injectEndpoints({
  endpoints: (builder) => ({
    createDrone: builder.mutation<Drone, Drone>({
      query: (drone: Drone) => ({
        url: `${UTM_URL}/drones`,
        method: 'POST',
        body: drone,
      }),
    }),
    updateDrone: builder.mutation<Drone, { id: number; drone: Drone }>({
      query: ({ id, drone }) => ({
        url: `${UTM_URL}/drones/${id}`,
        method: 'PUT',
        body: drone,
      }),
    }),
    changeDroneClearance: builder.mutation<Drone, { id: number; clearance: DroneClearanceEnum; droneCode: string }>({
      query: ({ id, droneCode, clearance }) => ({
        url: `${UTM_URL}/drones/${id}/change-clearance`,
        method: 'PUT',
        params: { clearance, droneCode },
      }),
    }),
    deleteDrone: builder.mutation<void, number>({
      query: (id) => ({
        url: `${UTM_URL}/drones/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useChangeDroneClearanceMutation } = droneApi;
