import '@components/common/layout/scrollbar.scss';

import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { ReactComponent as DroneIcon } from '@assets/icons/32x32/ic-drone-32.svg';
import { Center, HStack, Icon, Spinner, Text, VStack } from '@chakra-ui/react';
import { NotificationCard } from '@components/cockpit/panels/notification/card/NotificationCard';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { useGetNotificationsQuery } from '@services/data/notification.api';
import { formatDate } from '@utils/date.utils';
import { extendedSensorShortHand } from '@utils/map/map.constants';
import { addYears } from 'date-fns';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';

import { NotificationTypeEnum } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { HistoryPopupData } from '@/types/map.types';

import DraggableControl from '../controls/DraggableControl';

type Props = {
  top: number;
  left: number;
  data: HistoryPopupData;
  onClose: () => void;
};

function HistoryPopup({ top, left, data, onClose }: Readonly<Props>) {
  const { identifiers, displayId, sensorType, sensorName, type, openingTime } = data;
  const { formatMessage } = useIntl();
  const activeSite = useAppSelector(selectActiveSite);

  const { data: notifications, isLoading } = useGetNotificationsQuery(
    {
      from: addYears(openingTime, -1).toISOString(),
      to: openingTime.toISOString(),
      site: activeSite!.code,
      displayIds: displayId ? [displayId] : [],
      identifiers: displayId ? [] : identifiers,
      types: [type],
    },
    { skip: !activeSite },
  );

  const isDroneNotification = type === NotificationTypeEnum.DRONE;
  const notificationName =
    data.type === NotificationTypeEnum.DRONE ? (displayId ?? formatMessage({ id: 'target.unknown' })) : sensorName;

  return (
    <DraggableControl
      top={top}
      left={left}
      label={formatMessage({ id: 'historyPopup.title' }, { type: formatMessage({ id: `notification.type.${type}` }) })}
      height={825}
      width={948}
      onClose={onClose}
    >
      <VStack gap={0.25} backgroundColor="neutral.900" height="100%">
        <HStack justifyContent="space-between" backgroundColor="neutral.700" padding={4} width="100%" flexShrink={0}>
          <HStack gap={2}>
            {isDroneNotification ? (
              <Icon as={DroneIcon} width="32px" height="32px" color="neutral.white" />
            ) : (
              <Text fontSize="28px" fontWeight="medium" textTransform="uppercase" letterSpacing="-2px">
                {extendedSensorShortHand[sensorType]}
              </Text>
            )}
            <Text size="md" backgroundColor="neutral.black" paddingX={0.5} paddingY={0.25}>
              {notificationName}
            </Text>
          </HStack>
          <Text size="lg">
            <FormattedMessage
              id={isDroneNotification ? 'historyPopup.detection' : 'historyPopup.incident'}
              values={{ count: notifications?.length ?? 0 }}
            />
          </Text>
          <Text size="lg">
            <FormattedMessage
              id="historyPopup.since"
              values={{ date: formatDate(addYears(openingTime, -1), DateTimeEnum.DATE) }}
            />
          </Text>
        </HStack>
        {isLoading ? (
          <Center width="100%" height="100%">
            <Spinner size="xl" color="neutral.300" />
          </Center>
        ) : (
          <VStack width="100%" height="100%" gap={0} className="map-panel-scrollbar">
            {notifications === undefined || notifications.length === 0 ? (
              <VStack gap={3} marginY="auto">
                <EmptyPanelDroneIcon />
                <Text
                  fontSize="18px"
                  variant="space"
                  color="neutral.200"
                  maxWidth="230px"
                  textAlign="center"
                  userSelect="none"
                >
                  <FormattedMessage id="historyPopup.empty" />
                </Text>
              </VStack>
            ) : (
              notifications.map((notification, index) => (
                <NotificationCard
                  key={notification.id}
                  isArchived
                  isHistory
                  isLast={index === notifications.length - 1}
                  archivedNotification={notification}
                />
              ))
            )}
          </VStack>
        )}
      </VStack>
    </DraggableControl>
  );
}

function arePropsEqual(oldProps: Readonly<Props>, newProps: Readonly<Props>) {
  return (
    shallowEqual(oldProps.data.displayId, newProps.data.displayId) &&
    shallowEqual(oldProps.data.identifiers, newProps.data.identifiers)
  );
}

export default memo(HistoryPopup, arePropsEqual);
