import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { ReactComponent as ProcessIcon } from '@assets/icons/32x32/ic-process-32.svg';
import { Box, Center, Text, VStack } from '@chakra-ui/layout';
import DataSheetCardLink from '@components/common/layout/DataSheetCardLink';
import { useMapContext } from '@components/map/MapContext';
import FlightPlanCard from '@components/map/popup/flightPlan/FlightPlanCard';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectSituationFlightPlansByCodes, selectSituationTime } from '@redux/situation/situation.selectors';
import { sortDate } from '@utils/date.utils';
import { FormattedMessage, useIntl } from 'react-intl';

import { PopupDataTypeEnum } from '@/types/map.types';

type Props = {
  flightPlanCodes: string[];
  droneCode: string;
  position: { x: number; y: number };
};
export default function TargetFlightPlans({ flightPlanCodes, droneCode, position }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const flightPlans = useSelectorWithReplayMode(selectSituationFlightPlansByCodes, flightPlanCodes);
  const { mapId } = useMapContext();
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const situationTime = useSelectorWithReplayMode(selectSituationTime);

  function handleHistoryPopup() {
    updatePopupControl({
      mapId,
      popupControl: {
        type: PopupDataTypeEnum.FLIGHT_PLAN_HISTORY,
        data: {
          droneCode,
          openingTime: situationTime ? new Date(situationTime) : new Date(),
        },
        open: true,
        position,
      },
    });
  }

  return (
    <VStack width="100%" height="100%" overflow="hidden" gap={0}>
      {flightPlans.length === 0 ? (
        <Center width="100%" height="100%" backgroundColor="neutral.black">
          <VStack width="100%">
            <EmptyPanelDroneIcon />
            <Text fontSize="16px" color="neutral.200">
              <FormattedMessage id="targetInfos.tab.flightPlans.empty" />
            </Text>
          </VStack>
        </Center>
      ) : (
        <VStack width="100%" gap={0.25} overflow="auto" className="popup-scrollbar">
          {flightPlans
            .flatMap((flightPlan) => flightPlan.activations.map((activation) => ({ flightPlan, activation })))
            .sort(({ activation: activation1 }, { activation: activation2 }) =>
              sortDate(activation2.startDate, activation1.startDate),
            )
            .map((info) => (
              <FlightPlanCard
                flightPlan={info.flightPlan}
                activation={info.activation}
                position={position}
                key={info.flightPlan.code}
              />
            ))}
        </VStack>
      )}
      <Box padding={2} width="100%" backgroundColor="neutral.900" marginTop="auto">
        <DataSheetCardLink
          label={formatMessage({ id: 'targetInfos.tab.flightPlans.history' })}
          icon={ProcessIcon}
          onClick={handleHistoryPopup}
        />
      </Box>
    </VStack>
  );
}
