import { Coordinates, ImportChoiceItem, Mark, TrackLight } from '@/types/commons/commons.types';
import { ProcedureModel } from '@/types/config/procedure.types';
import { GlobalScreenConfiguration } from '@/types/config/screenConfiguration.types';
import { Monitoring } from '@/types/sensor/monitoring.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
import { SystemModeEnum, TrackingStatusEnum } from '@/types/sensor/status.types';

import { GroupWithUsers, Profile } from '../authent/groups.types';
import { AuthentSettings, User } from '../authent/login.types';
import { Role } from '../authent/roles.types';
import { Site } from '../authent/sites.types';
import { Message } from '../chat/chat.types';
import {
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  NoNeutralizationZone,
  Platform,
  ZoneOfInterest,
} from '../config/config.types';
import { GridConfiguration, GridLayout } from '../config/gridLayout.types';
import { Home } from '../config/home.types';
import { MergingConfiguration } from '../config/merging.types';
import { PointOfInterest } from '../config/pointOfInterest.types';
import { RoundConfiguration } from '../config/round.types';
import { NimbleConfiguration } from '../config/videoServer.types';
import { VideoStream } from '../config/videoStream.types';
import { NotificationType } from '../data/data.types';
import { Period, ReplayEvent, ReplayStorageConfiguration } from '../replay/replay.types';
import { Classification } from '../sensor/classification.types';
import {
  AutomatonSegmentFenceTypeEnum,
  EquipmentConfiguration,
  SensorConfiguration,
  SensorTypeEnum,
} from '../sensor/configuration.types';
import { Identification } from '../sensor/identification.types';
import { AbstractLocation, Plot, PlotLight, StrobeLocation, TargetLocation } from '../sensor/sensor.types';
import { SensorStorageConfiguration } from '../sensor/storage.types';
import { Drone } from '../utm/drone.types';
import { FlightPlan } from '../utm/flightPlan.types';
import { FlyZone } from '../utm/flyZone.types';
import { Pilot } from '../utm/pilot.types';
import { FlyRoute, FlySection } from '../utm/route.types';
import { HoloLog } from './logs.type';

export enum TargetStatusEnum {
  ONGOING = 'ONGOING',
  LOST = 'LOST',
}

export enum TrackStatusEnum {
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  TO_BE_PURGED = 'TO_BE_PURGED',
}

export type TargetId = number | string;

export interface Target<T extends AbstractLocation> {
  id: string;
  displayId: string | null;
  site: string;
  appCode: string;
  status: TargetStatusEnum;
  mainTrackId: string;

  trackIds: TrackLight[];
  trajectory: PlotLight<T>[];
  identification: Identification;
  classification: Classification;
  lastPosition: T extends TargetLocation ? PlotLight<T> : Plot<T> | null;

  detectionStart: string;
  detectionEnd: string;
  safetyDistance: number | null;
  criticalDistance: number | null;
  displayCollisionDiscs: boolean;

  targetGroup: string | null;
  droneCode: string | null;
  fpCodes: string[];
  marks: Mark[];
}

export interface Track<T extends AbstractLocation> {
  id: string;
  site: string;
  sensorCode: string;
  subSensorCode: string;
  sensorType: SensorTypeEnum;
  appCode: string;

  status: TrackStatusEnum;
  identification: Identification;
  classification: Classification;
  lastPosition: Plot<T> | null;
  firstDetection: string;
  lastDetection: string;
  marks: Mark[];
  uniqueId: string;
  plots: PlotLight<T>[];
}

export interface RawSituation {
  rawPlots: PlotLight<AbstractLocation>[];
  tracks: Track<AbstractLocation>[];
}

export interface TacticalSituation {
  strobes: Target<StrobeLocation>[];
  targets: Target<TargetLocation>[];
  infos: MergingInfo;
  monitoring: Monitoring;
}

export interface MergingInfo {
  mergingTime: number;
  preprocessTime: number;
  covarianceTime: number;
  targetTime: number;
  notificationTime: number;
  sensorInfos: Record<string, SensorMergingInfo>;
}

export interface SensorMergingInfo {
  receivedPlots: number;
  tracks: number;
}

export interface SituationMessage {
  version: number;
  json: string;
  fullSituation: boolean;
}

export interface SiteAndHome {
  site: Site;
  home: Home;
}

export interface GlobalConfiguration {
  items: ImportChoiceItem[];
  metadata: null;
  modelVersion: string;
  //======GLOBAL=====\\
  logs: HoloLog[];
  //======AUTHENT=====\\
  profiles: Profile[];
  users: User[];
  roles: Role[];
  sites: Site[];
  authentSettings: AuthentSettings | null;
  groups: GroupWithUsers[];
  //======CHAT======\\
  messages: Message[];
  //======CONFIG======\\
  home: Home;
  noFlyZones: NoFlyZone[];
  noFlyZones3d: NoFlyZone3d[];
  initializationMaskingZones: InitializationMaskingZone[];
  noNeutralizationZones: NoNeutralizationZone[];
  pointOfInterests: PointOfInterest[];
  zoneOfInterests: ZoneOfInterest[];
  noInitZones: NoInitZone[];
  mergingConfiguration: MergingConfiguration | null;
  videoStreams: VideoStream[];
  videoConfiguration: NimbleConfiguration | null;
  layouts: GridLayout[];
  gridConfigurations: GridConfiguration[];
  globalScreenConfigurations: GlobalScreenConfiguration[];
  roundConfigurations: RoundConfiguration[];
  platforms: Platform[];
  procedureModels: ProcedureModel[];
  //======REPLAY======\\
  replayEvents: ReplayEvent[];
  replayManualPeriods: Period[];
  replayStorageConfiguration: ReplayStorageConfiguration | null;
  replaySettings: ReplaySettings;
  //=======SENSOR=====\\
  perimeterCameras: PerimeterCamera[];
  sensors: SensorConfiguration[];
  sensorStorage: SensorStorageConfiguration | null;
  //========UTM=======\\
  drones: Drone[];
  pilots: Pilot[];
  flightPlans: FlightPlan[];
  flyRoutes: FlyRoute[];
  flySections: FlySection[];
  flyZones: FlyZone[];
  equipments: EquipmentConfiguration[];
  //========DATA==========\\
  ongoingNotifications: NotificationType[];
}

export interface AutomatonCommand {
  type: AutomatonCommandEnum;
  sensorCode: string;
}

export interface AutomatonSleepAllCabinetsCommand extends AutomatonCommand {
  type: AutomatonCommandEnum.AUTOMATON_COMMAND_SLEEP_ALL_CABINETS;
  sleep: boolean;
}

export interface AutomatonCabinetCommand extends AutomatonCommand {
  cabinetId: number;
}

export interface AutomatonDefenceCommand extends AutomatonCommand {
  type: AutomatonCommandEnum.AUTOMATON_COMMAND_DEFENCE;
  defence: boolean;
}

export interface AutomatonCabinetDefenceCommand extends AutomatonCabinetCommand {
  type: AutomatonCommandEnum.AUTOMATON_CABINET_COMMAND_DEFENCE;
  defence: boolean;
}

export interface AutomatonSegmentDefenceCommand extends AutomatonCabinetCommand {
  type: AutomatonCommandEnum.AUTOMATON_SEGMENT_COMMAND_DEFENCE;
  segmentId: number; // id de n'importe quel segment, haut ou bas, tant que le Haut/Bas est précisé dans le segmentType
  defence: boolean;
  segmentType: AutomatonSegmentFenceTypeEnum;
}

export interface AutomatonSectionDefenceCommand extends AutomatonCabinetCommand {
  type: AutomatonCommandEnum.AUTOMATON_SECTION_COMMAND_DEFENCE;
  segmentId: number; // Peu importe haut ou bas, le back prendra les 2 de la section dont est issue ce segment
  defence: boolean;
}

export interface AutomatonSleepCommand extends AutomatonCabinetCommand {
  type: AutomatonCommandEnum.AUTOMATON_COMMAND_SLEEP;
  sleep: boolean;
}

export interface AutomatonReinitializeCommand extends AutomatonCabinetCommand {
  type: AutomatonCommandEnum.AUTOMATON_COMMAND_REINITIALIZE;
  reinitialize: boolean;
}

export enum AutomatonCommandEnum {
  AUTOMATON_SECTION_COMMAND_DEFENCE = 'AutomatonSectionDefence',
  AUTOMATON_SEGMENT_COMMAND_DEFENCE = 'AutomatonSegmentDefence',
  AUTOMATON_CABINET_COMMAND_DEFENCE = 'AutomatonCabinetDefence',
  AUTOMATON_COMMAND_DEFENCE = 'AutomatonDefence',
  AUTOMATON_COMMAND_SLEEP = 'AutomatonSleep',
  AUTOMATON_COMMAND_SLEEP_ALL_CABINETS = 'AutomatonSleepAllCabinets',
  AUTOMATON_COMMAND_REINITIALIZE = 'AutomatonReinitialize',
}

export interface CameraCommand {
  type: CameraCommandEnum;
  sensorCode: string;
  cameraOperatorUuid: string | null;
}

export enum CameraCommandEnum {
  CAMERA_COMMAND_AUTO_ZOOM = 'CameraCommandAutoZoom',
  CAMERA_COMMAND_SET_ZOOM = 'CameraCommandSetZoom',
  CAMERA_COMMAND_ZOOM = 'CameraCommandZoom',
  CAMERA_COMMAND_ZOOM_SYNC = 'CameraCommandSyncZoom',
  CAMERA_COMMAND_CAMERA_ACTION = 'CameraCommandCameraAction',
  CAMERA_COMMAND_FOCUS = 'CameraCommandFocus',
  CAMERA_COMMAND_SET_FOCUS = 'CameraCommandSetFocus',
  CAMERA_COMMAND_HYBRID_FOCUS = 'CameraCommandHybridFocus',
  CAMERA_COMMAND_LOOK_AT = 'CameraCommandLookAt',
  CAMERA_COMMAND_MOVE = 'CameraCommandMove',
  CAMERA_COMMAND_SIMPLE_ROUND = 'CameraCommandSimpleRound',
  CAMERA_COMMAND_RELEASE_TOKEN = 'CameraCommandReleaseToken',
  CAMERA_COMMAND_REQUEST_TOKEN = 'CameraCommandRequestToken',
  CAMERA_COMMAND_SET_AT = 'CameraCommandSetAt',
  CAMERA_COMMAND_VERTICAL_SCAN_START = 'CameraCommandVerticalScanStart',
  CAMERA_COMMAND_VERTICAL_SCAN_STOP = 'CameraCommandVerticalScanStop',
  CAMERA_COMMAND_SYSTEM_MODE = 'CameraCommandSystemMode',
  CAMERA_COMMAND_SET_PRESET = 'CameraCommandSetPreset',
  CAMERA_COMMAND_CALL_PRESET = 'CameraCommandCallPreset',
  CAMERA_COMMAND_CLEAR_PRESET = 'CameraCommandClearPreset',
  CAMERA_COMMAND_SET_HOME = 'CameraCommandSetHome',
  CAMERA_COMMAND_CALL_HOME = 'CameraCommandCallHome',
  CAMERA_COMMAND_STOP_ALL_TRACKINGS = 'CameraCommandStopAllTrackings',
  CAMERA_COMMAND_SENSOR_TRACKING = 'CameraCommandSensorTracking',
  CAMERA_COMMAND_VIDEO_TRACKING = 'CameraCommandVideoTracking',
  CAMERA_COMMAND_CROP = 'CameraCommandCrop',
  CAMERA_COMMAND_EXPOSURE_MODE = 'CameraCommandSetExposureMode',
  CAMERA_COMMAND_ISO_MODE = 'CameraCommandSetIso',
  CAMERA_COMMAND_WHITER_BALANCE = 'CameraCommandSetWhiteBalance',
  CAMERA_COMMAND_SET_POLARITY = 'CameraCommandSetPolarity',
  CAMERA_COMMAND_REBOOT_CHARM = 'CameraCommandRebootCharm',
  CAMERA_COMMAND_DEICING = 'CameraCommandDeIcing',
  CAMERA_COMMAND_NUC = 'CameraCommandNuc',
  CAMERA_COMMAND_VIDEO_POWER = 'CameraCommandVideoPower',
}

export interface XentaCommand {
  type: XentaCommandEnum;
  sensorCode: string;
}

export enum XentaCommandEnum {
  COMMAND_ANTENNA_SURVEILLANCE = 'AntennaSurveillance',
  COMMAND_ANTENNA_STOP_AND_STARE_AZIMUTH = 'AntennaStopAndStareAzimuth',
  COMMAND_PSR_TX_ON = 'PsrTxOn',
  // Voir XentaCommand.java si besoin de rajouter les autres commandes
}

export enum XentaSurveillanceModeEnum {
  PROFILE_1 = '0',
  PROFILE_2 = '1',
  PROFILE_3 = '2',
  PROFILE_4 = '3',
}

export interface XentaRotationCommand extends XentaCommand {
  type: XentaCommandEnum.COMMAND_ANTENNA_SURVEILLANCE;
  mode: XentaSurveillanceModeEnum.PROFILE_1;
}

export const XENTA_INITIAL_AZIMUTH = 0.0; // The azimuth we want the radar to come back before stopping the rotation

export interface XentaStopRotationCommand extends XentaCommand {
  type: XentaCommandEnum.COMMAND_ANTENNA_STOP_AND_STARE_AZIMUTH;
  azimuth: number;
}

export interface XentaEmissionCommand extends XentaCommand {
  type: XentaCommandEnum.COMMAND_PSR_TX_ON;
  on: boolean;
}

export enum FocusDirectionEnum {
  IN = 'IN',
  OUT = 'OUT',
  STOP = 'STOP',
  AUTO_FOCUS = 'AUTO_FOCUS',
}

export enum ZoomDirectionEnum {
  IN = 'IN',
  OUT = 'OUT',
  STOP = 'STOP',
}

export enum CameraObjectSizeEnum {
  TARGET = 40,
  PERIMETER = 150,
}

export interface VideoBox {
  x: number;
  y: number;
  height: number;
  width: number;
}

export interface CameraVideoCommand extends CameraCommand {
  cameraId: number;
}

export interface CameraCommandAutoZoom extends CameraVideoCommand {
  distance: number;
}

export interface CameraCommandSetZoom extends CameraVideoCommand {
  fov: number;
}

export interface CameraCommandSetFocus extends CameraVideoCommand {
  focus: number;
}

export interface CameraCommandFocus extends CameraVideoCommand {
  direction: FocusDirectionEnum;
}

export interface CameraCommandZoom extends CameraVideoCommand {
  direction: ZoomDirectionEnum;
}

export interface CameraCommandZoomSync extends CameraVideoCommand {
  zoom: number;
  fov: number;
}

export interface CameraCommandVideoTracking extends CameraVideoCommand {
  box: VideoBox | null;
  mode: TrackingStatusEnum;
}

export interface CameraCommandVideoPower extends CameraVideoCommand {
  on: boolean;
}

export interface CameraCommandMove extends CameraCommand {
  panSpeed: number; //horizontal
  tiltSpeed: number; //vertical
}

export interface CameraCommandSimpleRound extends CameraCommand {
  roundOn: boolean;
  speed?: number;
}

export interface CameraCommandRequestToken extends CameraCommand {
  userName: string;
  userLogin: string;
}

export interface CameraCommandReleaseToken extends CameraCommand {
  userLogin: string;
}

export interface CameraCommandSetPreset extends CameraCommand {
  preset: number;
}

export interface CameraCommandCallPreset extends CameraCommand {
  preset: number;
}

export interface CameraCommandClearPreset extends CameraCommand {
  preset: number;
}

export type CameraCommandSetHome = CameraCommand;

export type CameraCommandCallHome = CameraCommand;

export interface CameraCommandLookAt extends CameraCommand {
  position: Coordinates;
  segmentStart: Coordinates;
  segmentEnd: Coordinates;
  altitude: number;
  objectSize: number;
}

export interface CameraCommandSetAt extends CameraCommand {
  pan: number;
  tilt: number;
  fov: { fluxId: number; fov: number }[];
}

export interface CameraCommandSensorTracking extends CameraCommand {
  trackedTargetId: string;
  targetFirstPosition: Coordinates;
}

export interface CameraCommandStopAllTrackings extends CameraCommand {
  trackedTargetId: string;
}

export interface CameraCommandSetExposureMode extends CameraVideoCommand {
  cameraId: number;
  exposureMode: ExposureModeEnum;
}

export interface CameraCommandSetIso extends CameraVideoCommand {
  cameraId: number;
  iso: IsoModeEnum;
}

export interface CameraCommandSetWhiteBalance extends CameraVideoCommand {
  cameraId: number;
  whiteBalance: WhiteBalanceModeEnum;
}

export interface CameraCommandSetPolarity extends CameraVideoCommand {
  cameraId: number;
  black: boolean;
}

export interface CameraCommandHybridFocus extends CameraVideoCommand {
  cameraId: number;
  focusLevel: number;
}

export type CameraCommandRebootCharm = CameraCommand;

export interface GroundStationCommand {
  type: GroundStationCommandEnum;
  sensorCode: string;
  targetId: string;
}

export interface TakeoffCommand extends GroundStationCommand {
  height: number;
}

export interface SetModeCommand extends GroundStationCommand {
  mode: GroundStationModeEnum;
}

export interface GoToCommand extends GroundStationCommand {
  longitude: number;
  latitude: number;
  height: number;
}

export interface CameraCommandDeIcing extends CameraVideoCommand {
  cameraId: number;
  on: boolean;
}

export interface CameraCommandSystemMode extends CameraCommand {
  systemMode: SystemModeEnum;
}

export interface CameraCommandNuc extends CameraVideoCommand {
  cameraId: number;
}

export enum GroundStationCommandEnum {
  TAKEOFF = 'Takeoff',
  SET_MODE = 'SetMode',
  GO_TO = 'GoTo',
}

export enum GroundStationModeEnum {
  LAND = 'LAND',
}

export interface HolodroneCommand {
  type: HolodroneCommandEnum;
  sensorCode: string;
}

export interface RebootHolodroneCommand extends HolodroneCommand {
  type: HolodroneCommandEnum.RebootHolodrone;
}

export interface RestartHolodroneCommand extends HolodroneCommand {
  type: HolodroneCommandEnum.RestartHolodrone;
}

export enum HolodroneCommandEnum {
  RebootHolodrone = 'RebootHolodrone',
  RestartHolodrone = 'RestartHolodrone',
}

export interface C2StorageConfiguration {
  site: string;
  replayConfiguration?: ReplayStorageConfiguration;
  sensorConfiguration?: SensorStorageConfiguration;
  videoConfiguration?: NimbleConfiguration;
}

export type ReplaySettings = {
  id: number;
  videoLatencyMargin: number;
  videoBaseSequenceDuration: number;
};

export enum ExposureModeEnum {
  EXPOSURE_MODE_UNSPECIFIED = '0',
  EXPOSURE_MODE_MANUAL_EXPOSURE_M = '1',
  EXPOSURE_MODE_PROGRAM_AUTO_P = '2',
  EXPOSURE_MODE_APERTURE_PRIORITY_A = '3',
  EXPOSURE_MODE_SHUTTER_PRIORITY_S = '4',
  EXPOSURE_MODE_INTELLIGENT_AUTO = '5',
  EXPOSURE_MODE_SUPERIOR_AUTO = '6',
  EXPOSURE_MODE_MOVIE_PROGRAM_AUTO_P = '7',
  EXPOSURE_MODE_MOVIE_APERTURE_PRIORITY_A = '8',
  EXPOSURE_MODE_MOVIE_SHUTTER_PRIORITY_S = '9',
  EXPOSURE_MODE_MOVIE_MANUAL_EXPOSURE_M = '10',
  EXPOSURE_MODE_MOVIE_INTELLIGENT_AUTO = '11',
  EXPOSURE_MODE_MOVIE_HFR_P = '12',
  EXPOSURE_MODE_MOVIE_HFR_A = '13',
  EXPOSURE_MODE_MOVIE_HFR_S = '14',
  EXPOSURE_MODE_MOVIE_HFR_M = '15',
}

export enum IsoModeEnum {
  // ISO_UNSPECIFIED = '0',
  // ISO_50 = '1',
  // ISO_64 = '2',
  // ISO_80 = '3',
  ISO_100 = '4',
  // ISO_125 = '5',
  // ISO_160 = '6',
  // ISO_200 = '7',
  // ISO_250 = '8',
  // ISO_320 = '9',
  // ISO_400 = '10',
  // ISO_500 = '11',
  // ISO_640 = '12',
  // ISO_800 = '13',
  ISO_1000 = '14',
  // ISO_1250 = '15',
  // ISO_1600 = '16',
  // ISO_2000 = '17',
  // ISO_2500 = '18',
  // ISO_3200 = '19',
  // ISO_4000 = '20',
  // ISO_5000 = '21',
  // ISO_6400 = '22',
  // ISO_8000 = '23',
  // ISO_10000 = '24',
  // ISO_12800 = '25',
  ISO_16000 = '26',
  // ISO_20000 = '27',
  // ISO_25600 = '28',
  ISO_32000 = '29',
  // ISO_40000 = '30',
  // ISO_51200 = '31',
  ISO_64000 = '32',
  // ISO_80000 = '33',
  // ISO_102400 = '34',
  ISO_128000 = '35',
  // ISO_160000 = '36',
  // ISO_204800 = '37',
  // ISO_256000 = '38',
  // ISO_320000 = '39',
  //ISO_409600 = '40',
  ISO_AUTO = '41',
  // ISO_MULTI_FLAME_NR_AUTO = '42',
  // ISO_MULTI_FLAME_NR_100 = '43',
  // ISO_MULTI_FLAME_NR_200 = '44',
  // ISO_MULTI_FLAME_NR_400 = '45',
  // ISO_MULTI_FLAME_NR_800 = '46',
  // ISO_MULTI_FLAME_NR_1600 = '47',
  // ISO_MULTI_FLAME_NR_3200 = '48',
  // ISO_MULTI_FLAME_NR_6400 = '49',
  // ISO_MULTI_FLAME_NR_12800 = '50',
  // ISO_MULTI_FLAME_NR_25600 = '51',
  // ISO_MULTI_FLAME_NR_51200 = '52',
  // ISO_MULTI_FLAME_NR_102400 = '53',
  // ISO_MULTI_FLAME_NR_204800 = '54',
  // ISO_MULTI_FLAME_NR_409600 = '55',
}

export enum WhiteBalanceModeEnum {
  WHITE_BALANCE_UNSPECIFIED = '0',
  WHITE_BALANCE_AUTO_WHITE_BALANCE = '1',
  WHITE_BALANCE_DAYLIGHT = '2',
  WHITE_BALANCE_INCANDESCENT = '3',
  WHITE_BALANCE_FLUSH = '4',
  WHITE_BALANCE_FLUOR_WARM_WHITE_m1 = '5',
  WHITE_BALANCE_FLUOR_COOL_WHITE_0 = '6',
  WHITE_BALANCE_FLUOR_DAY_WHITE_p1 = '7',
  WHITE_BALANCE_FLUOR_DAYLIGHT_p2 = '8',
  WHITE_BALANCE_CLOUDY = '9',
  WHITE_BALANCE_SHADE = '10',
  WHITE_BALANCE_COLOR_TEMP_FILTER = '11',
  WHITE_BALANCE_UNDERWATER_AUTO = '12',
}

export enum EquipmentCommandTypeEnum {
  SET_WR_ACTUATOR_STATE = 'SET_WR_ACTUATOR_STATE',
}

export interface CommonEquipmentCommand {
  equipmentCode: string;
}

export enum WebRelayActuatorStateEnum {
  ON = 'ON',
  OFF = 'OFF',
}

export interface SetWebRelayActuatorStateCommand extends CommonEquipmentCommand {
  type: EquipmentCommandTypeEnum.SET_WR_ACTUATOR_STATE;
  actuatorState: WebRelayActuatorStateEnum;
}

export type EquipmentCommand = SetWebRelayActuatorStateCommand;
