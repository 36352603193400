import { useState } from 'react';
import { useIntl } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';

import CockpitPanelHeader from '../CockpitPanelHeader';
import Panel from '../Panel';
import SensorDetail from './detail/SensorDetail';
import MonitoringList from './list/MonitoringList';

export default function MonitoringPanel() {
  const { formatMessage } = useIntl();
  const [selectedSensorCode, setSelectedSensorCode] = useState<SensorUniqueCodes | null>(null);

  return (
    <Panel header={<CockpitPanelHeader label={formatMessage({ id: 'components.sidebar.MONITORING' })} />}>
      {selectedSensorCode === null ? (
        <MonitoringList setSelectedSensorCode={setSelectedSensorCode} />
      ) : (
        <SensorDetail sensorUniqueCodes={selectedSensorCode} setSelectedSensorCode={setSelectedSensorCode} />
      )}
    </Panel>
  );
}
