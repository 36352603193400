import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { ReactComponent as HistoryIcon } from '@assets/icons/24x24/ic-history-24.svg';
import { ReactComponent as ProcedureIcon } from '@assets/icons/24x24/ic-process-24.svg';
import {
  Box,
  Center,
  HStack,
  Icon,
  Spinner,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSensorRolesFromActiveProfile } from '@redux/authent/authent.selectors';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetDetailQuery } from '@services/data/notification.api';
import { canManageNotification, getNotificationName } from '@utils/data/notification.utils';
import { FormattedMessage } from 'react-intl';

import { getNotificationIcon } from '../card/NotificationCardName';
import NotificationHistory from './alert/NotificationAlerts';
import NotificationProcedure from './procedure/NotificationProcedure';

type Props = {
  selectedNotification: number | null;
};

export default function NotificationDetail({ selectedNotification }: Readonly<Props>) {
  const userActions = useAppSelector(selectSensorRolesFromActiveProfile);

  const { data: notificationDetail, isFetching } = useGetDetailQuery(
    selectedNotification !== null
      ? {
          id: selectedNotification,
          withArchivedAlerts: false,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  if (notificationDetail === undefined || selectedNotification !== notificationDetail.notification.id) {
    const isNewNotificationFetching =
      notificationDetail !== undefined && selectedNotification !== notificationDetail.notification.id && isFetching;

    return (
      <Box
        width="580px"
        height="100%"
        flexShrink={0}
        backgroundColor={isNewNotificationFetching ? 'neutral.700' : 'neutral.800-op70'}
        borderLeft="2px solid"
        borderColor="neutral.black"
      >
        <Center width="100%" height="100%">
          {isFetching || isNewNotificationFetching ? (
            <Spinner color="neutral.200" width="100px" height="100px" />
          ) : (
            <VStack gap={2} paddingTop="58px">
              <Icon width="194px" height="194px" as={EmptyPanelDroneIcon} />
              <Text
                fontSize="18px"
                variant="space"
                color="neutral.200"
                maxWidth="230px"
                textAlign="center"
                userSelect="none"
              >
                <FormattedMessage
                  id={`components.notification.detail.${selectedNotification !== null ? 'notFound' : 'empty'}`}
                />
              </Text>
            </VStack>
          )}
        </Center>
      </Box>
    );
  }

  const { notification, alerts, procedure } = notificationDetail;

  const notificationName = getNotificationName(notification);

  const canManage = canManageNotification(userActions, notification);

  return (
    <VStack
      width="580px"
      height="100%"
      flexShrink={0}
      backgroundColor={selectedNotification ? 'neutral.900' : 'neutral.800-op70'}
      borderLeft="2px solid"
      borderColor="neutral.black"
    >
      <VStack gap={0.25} height="100%" width="100%" backgroundColor="neutral.900">
        <HStack
          gap={2}
          height="120px"
          flexShrink={0}
          width="100%"
          backgroundColor="neutral.700"
          justifyContent="center"
        >
          {getNotificationIcon(notification)}
          <Tooltip label={notificationName}>
            <Text
              paddingX={0.25}
              noOfLines={1}
              maxWidth="100%"
              backgroundColor="neutral.black"
              textTransform="uppercase"
              lineHeight="18px"
              wordBreak="break-all"
            >
              {notificationName}
            </Text>
          </Tooltip>
        </HStack>
        <Tabs
          width="100%"
          variant="neutral"
          gap={0.25}
          display="flex"
          flexDirection="column"
          flex={1}
          overflow="hidden"
        >
          <TabList>
            {procedure !== null && (
              <Tab>
                <Icon as={ProcedureIcon} width="24px" height="24px" color="inherit" />
              </Tab>
            )}
            <Tab>
              <Icon as={HistoryIcon} width="24px" height="24px" color="inherit" />
            </Tab>
          </TabList>
          <TabPanels flex={1} overflow="hidden">
            {procedure !== null && (
              <NotificationProcedure
                key={notification.currentStepRank}
                currentStepRank={notification.currentStepRank}
                notificationId={selectedNotification}
                procedure={procedure}
                isFetching={isFetching}
                canManage={canManage}
              />
            )}
            <NotificationHistory alerts={alerts} notificationType={notification.type} isFetching={isFetching} />
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  );
}
