import { EMPTY_POPUP_CONTROL, mapsAdapter, MapStateType, SelectedFeatureTypeEnum } from '@redux/maps/maps.reducer';
import { RootState } from '@redux/store';
import { createSelector, EntityId } from '@reduxjs/toolkit';

import { SEE_POI, Zone2DTypeEnum, Zone3DTypeEnum } from '@/types/config/config.types';
import { DroneNotification } from '@/types/data/data.types';
import { Dimension, PopupControlType, SensorUniqueCodes } from '@/types/map.types';

const {
  selectEntities: selectMapEntities,
  selectAll: selectAllMaps,
  selectTotal: selectMapTotal,
} = mapsAdapter.getSelectors((state: RootState) => state.maps);

const selectMapById = mapsAdapter.getSelectors((state: RootState) => state.maps).selectById as (
  state: RootState,
  id: EntityId,
) => MapStateType | undefined;

export const MapSelectors = { selectMapEntities, selectAllMaps, selectMapTotal, selectMapById };

export const selectViewByMapId = createSelector(selectMapById, (map) => map?.view);

export const selectZoomByMapId = createSelector(selectViewByMapId, (mapView) => mapView?.zoom);

export const selectPitchByMapId = createSelector(selectViewByMapId, (mapView) => mapView?.pitch);

export const selectDimensionByMapId = createSelector(
  selectPitchByMapId,
  (pitch: number | undefined): Dimension => (pitch === 0 ? Dimension['2D'] : Dimension['3D']),
);

export const selectPopupControlByMapId = createSelector(
  selectMapById,
  (state): PopupControlType => state?.popupControl ?? EMPTY_POPUP_CONTROL,
);

export const selectSelectedFeatureKeyByMapId = createSelector(selectMapById, (map) => map?.selectedFeatureKey ?? null);

export const selectSelectedTargetIdByMapId = createSelector(
  selectSelectedFeatureKeyByMapId,
  (selectedFeatureKey): string | null =>
    selectedFeatureKey && selectedFeatureKey.type === SelectedFeatureTypeEnum.TARGET
      ? selectedFeatureKey.selectedTargetId
      : null,
);

export const selectSelectedExpiredDroneNotifByMapId = createSelector(
  selectSelectedFeatureKeyByMapId,
  (selectedFeatureKey): DroneNotification | null =>
    selectedFeatureKey && selectedFeatureKey.type === SelectedFeatureTypeEnum.EXPIRED_DRONE
      ? selectedFeatureKey.selectedExpiredDroneNotif
      : null,
);

export const selectSelectedSensorUniqueCodesByMapId = createSelector(
  selectSelectedFeatureKeyByMapId,
  (selectedFeatureKey): SensorUniqueCodes | null =>
    selectedFeatureKey && selectedFeatureKey.type === SelectedFeatureTypeEnum.SENSOR
      ? selectedFeatureKey.selectedSensorUniqueCodes
      : null,
);
export const selectSelectedFeatureDateByMapId = createSelector(selectMapById, (map) => map?.selectedDate ?? null);
export const selectDisplayedPerimeterLinksCodesByMapId = createSelector(
  selectMapById,
  (map) => map?.displayedPerimeterLinksCodes ?? null,
);
export const selectMapDrawModeByMapId = createSelector(selectMapById, (map) => map?.drawInfo.isDrawnOnto);
export const selectMapDrawZoneType = createSelector(selectMapById, (map) => map?.drawInfo.zoneType);
export const selectMapDrawPolygonsPoints = createSelector(selectMapById, (map) => map?.drawInfo.polygonPoints);
export const selectMapEditPolygonsInfo = createSelector(selectMapById, (map) => map?.drawInfo.editedZone);
export const selectMapNavigationOptions = createSelector(selectMapById, (map) => map?.menuNavigation);

export const selectZoneViewMode = createSelector(
  selectMapNavigationOptions,
  (selectedOptions) =>
    selectedOptions?.at(-1)?.includes('see') &&
    [...Object.values(Zone2DTypeEnum), ...Object.values(Zone3DTypeEnum)].some(
      (value) => value === selectedOptions.at(-1)?.split('_')[1],
    ),
);

export const selectPoiViewMode = createSelector(
  selectMapNavigationOptions,
  (selectedOptions) => selectedOptions?.at(-1) === SEE_POI,
);
