import { HStack, Icon, Text } from '@chakra-ui/react';
import CustomSelect, { Option } from '@components/common/inputs/CustomStyledSelect';
import { useSetManualTargetClassificationMutation } from '@services/c2/track.api';
import { getClassificationIcon } from '@utils/target.utils';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TargetStatusEnum } from '@/types/c2/c2.types';
import { TrackLight } from '@/types/commons/commons.types';
import { Classification, ClassificationEnum } from '@/types/sensor/classification.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';

type Props = {
  classification: Classification;
  identification: IdentificationEnum;
  site: string;
  trackIds: TrackLight[];
  status: TargetStatusEnum;
  isDisabled?: boolean;
};

const AUTOMATIC = 'AUTOMATIC';

function ClassificationSelect({
  classification,
  site,
  trackIds,
  identification,
  status,
  isDisabled = false,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [updateManualClassification] = useSetManualTargetClassificationMutation();
  const manualClassificationList = [
    ClassificationEnum.FRIEND,
    ClassificationEnum.UNKNOWN,
    ClassificationEnum.WATCH,
    ClassificationEnum.SUSPECT,
    ClassificationEnum.ENEMY,
  ];

  const manualClassificationOptions = [
    {
      value: AUTOMATIC,
      label: formatMessage({
        id: `target.classification.${classification.classification}`,
      }),
    },
  ].concat(
    manualClassificationList.map((classification) => {
      return {
        value: classification.valueOf(),
        label: formatMessage({ id: `target.classification.${classification.valueOf()}` }),
      };
    }),
  );

  const formatOptionLabel = (option: Option) => (
    <HStack gap={1.5} width="100%" overflow="hidden" textOverflow="ellipsis">
      <Icon
        width="24px"
        height="24px"
        as={getClassificationIcon(
          option.value === AUTOMATIC ? classification.classification : (option.value as IdentificationEnum),
        )}
      />
      <Text fontSize="16px" color="neutral.white">
        {option.label}
      </Text>
      <Text
        fontSize="16px"
        color="neutral.200"
        marginLeft="auto"
        textOverflow="ellipsis"
        overflow="hidden"
        minWidth={0}
      >
        <FormattedMessage id={`target.${option.value === AUTOMATIC ? 'auto' : 'manual'}`} />
      </Text>
    </HStack>
  );

  return identification === IdentificationEnum.DRONE || identification === IdentificationEnum.REMOTE_CONTROL ? (
    <CustomSelect
      value={classification.manualClassification ?? AUTOMATIC}
      options={manualClassificationOptions}
      isClearable={false}
      isDisabled={isDisabled || status === TargetStatusEnum.LOST}
      width={248}
      variant="dark"
      listWidth={248}
      listOffset={false}
      formatOptionLabel={formatOptionLabel}
      onChange={(selectedOption) =>
        selectedOption &&
        updateManualClassification({
          tracks: trackIds,
          site: site,
          classificationEnum: selectedOption !== 'AUTOMATIC' ? (selectedOption as ClassificationEnum) : null,
        })
      }
    />
  ) : (
    <Text paddingTop={1} fontSize="16px" fontWeight="700" height="48px">
      <FormattedMessage
        id={`target.classification.${classification.manualClassification ?? classification.classification}`}
      />
    </Text>
  );
}

export default memo(ClassificationSelect, isEqual);
