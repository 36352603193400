import { Box } from '@chakra-ui/react';
import MenuList from '@components/common/menu/MenuList';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPoiViewMode } from '@redux/maps/maps.selectors';
import { useUpdatePointsOfInterestMutation } from '@services/config/pointOfInterest.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useIntl } from 'react-intl';

import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { MenuItems } from '@/types/menu.types';

interface PoiContextMenuProps {
  clickPosition: { x: number; y: number } | null;
  selectedPoi: PointOfInterest;
  onClose: () => void;
}

function PoiContextMenu({ selectedPoi, onClose, clickPosition }: Readonly<PoiContextMenuProps>) {
  const { formatMessage } = useIntl();
  const { mapId } = useMapContext();
  const viewMode = useAppSelector((state) => selectPoiViewMode(state, mapId));
  const [updatePoi] = useUpdatePointsOfInterestMutation();

  const handleOnClick = () => {
    updatePoi({ id: selectedPoi.id, poi: { ...selectedPoi, active: !selectedPoi.active } })
      .unwrap()
      .then(() =>
        createToast(
          formatMessage(
            { id: 'poi.toastMessage' },
            { code: selectedPoi.code, active: selectedPoi.active, success: true },
          ),
          ToastStatusEnum.SUCCESS,
        ),
      )
      .catch(() =>
        createToast(
          formatMessage(
            { id: 'poi.toastMessage' },
            { code: selectedPoi.code, active: selectedPoi.active, success: false },
          ),
          ToastStatusEnum.ERROR,
        ),
      );
    onClose();
  };

  if (!viewMode) {
    onClose();
  }

  const menuItems: MenuItems = {
    activateDeactivate: {
      label: formatMessage({ id: 'contextmenu.actions.activateDeactivate' }, { active: !selectedPoi.active }),
      shouldNotTranslate: true,
      onAction: handleOnClick,
    },
  };

  return (
    <Box position="absolute" zIndex={4} top={clickPosition?.y} left={clickPosition?.x}>
      <MenuList menuItems={menuItems} depth={0} />
    </Box>
  );
}

export default PoiContextMenu;
