import { Box, HStack, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { hasRole } from '@redux/authent/authent.selectors';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { selectNotificationsByIdentifier } from '@redux/data/data.selectors';
import { selectTargetById, selectTrackFromRawSituationByTrackLights } from '@redux/situation/situation.selectors';
import { RootState } from '@redux/store';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import {
  getCollaborativeData,
  getTargetDisplayId,
  getTargetIcon,
  getTargetIdentification,
  isDrone,
} from '@utils/target.utils';
import { capitalize } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';

import { RoleEnum } from '@/types/authent/roles.types';
import { Target } from '@/types/c2/c2.types';
import { DroneNotificationLight } from '@/types/data/data.types';
import { PopupPositionData, TargetInfoData } from '@/types/map.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import DraggableControl from '../../controls/DraggableControl';
import TargetAlerts from './TargetAlerts';
import TargetDetails from './TargetDetails';
import TargetFlightPlans from './TargetFlightPlans';

export default function TargetInfo({ targetId, top, left, onClose }: Readonly<TargetInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();

  const target: Target<TargetLocation> | undefined = useSelectorWithReplayMode(selectTargetById, targetId);
  const canAccessFlightPlans = useAppSelector((state: RootState) => hasRole(state, RoleEnum.ACCESS_FLIGHT_PLAN));

  const platforms = useSelectorWithReplayMode(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(target?.marks ?? [])),
    shallowEqual,
  );
  const tracks = useSelectorWithReplayMode(selectTrackFromRawSituationByTrackLights, target?.trackIds ?? []);
  const notification =
    useAppSelector((state) => selectNotificationsByIdentifier(state, target?.id)).find(
      (notification) => notification.expiredTime === null,
    ) ?? null;

  if (!target) {
    onClose();
    return null;
  }

  const showDroneTab = isDrone(target);
  const model = getCollaborativeData(tracks).model;
  const modelLabel = model !== undefined ? ` ${model}` : '';
  const displayedId = getTargetDisplayId(target);

  return (
    <DraggableControl
      top={top}
      left={left}
      offsetY={-200}
      key={target.id}
      width={584}
      height={isDrone(target) ? 1157 : 823}
      scrollable={false}
      label={formatMessage(
        { id: 'targetInfos.targetInfo' },
        {
          identification: capitalize(formatMessage({ id: `target.identification.${getTargetIdentification(target)}` })),
        },
      )}
      onClose={onClose}
    >
      <VStack gap={0.25} width="100%" height="100%" backgroundColor="neutral.900">
        <HStack width="100%" height="120px" gap={2} backgroundColor="neutral.700" justifyContent="center">
          <Icon as={getTargetIcon(target)} width="70px" height="70px" />
          <VStack gap={0.5} alignItems="start">
            <Text padding={0.5} backgroundColor="neutral.black" size="md">
              {target.displayId ? `${displayedId}${modelLabel}` : displayedId}
            </Text>
            <HStack maxWidth="100%" gap={0.5}>
              {platforms.map((platform) => (
                <Tooltip key={platform} label={platform} openDelay={200}>
                  <Text
                    paddingX={0.25}
                    backgroundColor="neutral.600"
                    textTransform="uppercase"
                    size="md"
                    color="neutral.200"
                  >
                    {platform.slice(0, 4)}
                  </Text>
                </Tooltip>
              ))}
            </HStack>
          </VStack>
        </HStack>
        {showDroneTab ? (
          <Tabs
            width="100%"
            height="100%"
            variant="neutral"
            gap={0.25}
            display="flex"
            flexDirection="column"
            flex={1}
            overflow="hidden"
            isLazy
            lazyBehavior="keepMounted"
          >
            <TabList>
              <Tab>
                <Text size="md">
                  <FormattedMessage id="targetInfos.tab.details.title" />
                </Text>
              </Tab>
              {canAccessFlightPlans && target.droneCode && (
                <Tab>
                  <Text size="md">
                    <FormattedMessage id="targetInfos.tab.flightPlans.title" />
                  </Text>
                </Tab>
              )}
              <Tab>
                <Text size="md">
                  <FormattedMessage id="targetInfos.tab.alerts.title" />
                </Text>
              </Tab>
            </TabList>
            <TabPanels flex={1} overflow="auto" className="popup-scrollbar">
              <TabPanel height="100%">
                <TargetDetails
                  target={target}
                  notification={notification ? (notification as DroneNotificationLight) : undefined}
                />
              </TabPanel>
              {canAccessFlightPlans && target.droneCode && (
                <TabPanel height="100%">
                  <TargetFlightPlans
                    flightPlanCodes={target.fpCodes}
                    droneCode={target.droneCode}
                    position={{ x: left, y: top }}
                  />
                </TabPanel>
              )}
              <TabPanel height="100%">
                <TargetAlerts
                  targetId={target.id}
                  displayId={target.displayId}
                  notificationId={notification?.id}
                  position={{ x: left, y: top }}
                  onClose={onClose}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Box width="100%" flex={1} overflow="auto" className="popup-scrollbar">
            <TargetDetails target={target} />
          </Box>
        )}
      </VStack>
    </DraggableControl>
  );
}
