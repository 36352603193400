import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { TabPanel, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Drone } from '@/types/utm/drone.types';

import FlightPlanDrone from './FlightPlanDrone';

type Props = {
  drones: Drone[];
};

export default function FlightPlanDrones({ drones }: Readonly<Props>) {
  return (
    <TabPanel height="100%">
      {drones.length === 0 ? (
        <VStack width="100%" paddingY="67px">
          <EmptyPanelDroneIcon />
          <Text fontSize="16px" color="neutral.200">
            <FormattedMessage id="flightPlanInfos.tab.drones.empty" />
          </Text>
        </VStack>
      ) : (
        <VStack width="100%" gap={0.25}>
          {drones.map((drone) => (
            <FlightPlanDrone drone={drone} key={drone.code} />
          ))}
        </VStack>
      )}
    </TabPanel>
  );
}
