import { userLogout } from '@redux/authent/authent.reducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapFiltersAllChecked } from '@utils/map/filters/filters.constants';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { UserSettings } from '@/types/config/settings.types';
import { MapFilters } from '@/types/filters.types';

export type SettingsState = {
  userSettings: UserSettings;
  localMapFilters: Partial<MapFilters>;
};

export const SETTINGS_INITIAL_STATE: SettingsState = {
  userSettings: {
    id: NaN,
    language: 'fr',

    mapBackground: 'satellite',
    coordinatesUnit: CoordinatesUnitEnum.DECIMAL_DEGREES,
    context: ContextEnum.LIVE,
    axisInvertedY: false,
    linearJoystickCoefficient: 0.4,
    zoomJoystickCameraSensitivity: 1,
    automatedLad: false,
    mapFilters: mapFiltersAllChecked,

    createdBy: null,
    creationTime: null,
    modifiedBy: null,
    modificationTime: null,
  },
  localMapFilters: {},
};

const settings = createSlice({
  name: 'settings',
  initialState: SETTINGS_INITIAL_STATE,
  reducers: {
    updateSettings(state, action: PayloadAction<UserSettings>) {
      state.userSettings = action.payload;
      if (
        Object.entries(state.localMapFilters).every(
          ([filter, value]) => value === action.payload.mapFilters[filter as keyof MapFilters],
        )
      ) {
        state.localMapFilters = {};
      }
      state.localMapFilters = Object.entries(state.localMapFilters).reduce((filters, [filter, value]) => {
        if (value !== state.userSettings.mapFilters[filter as keyof MapFilters]) {
          return {
            ...filters,
            [filter]: value,
          };
        }
        return filters;
      }, {} as Partial<MapFilters>);
    },
    updateLocalMapFilters(state, action: PayloadAction<Partial<MapFilters>>) {
      if (Object.keys(action.payload).length === 0) {
        state.localMapFilters = action.payload;
        return;
      }
      const localFilters = Object.entries({
        ...state.localMapFilters,
        ...action.payload,
      } as Partial<MapFilters>).reduce((filters, [filter, value]) => {
        if (value !== state.userSettings.mapFilters[filter as keyof MapFilters]) {
          return {
            ...filters,
            [filter]: value,
          };
        }
        return filters;
      }, {} as Partial<MapFilters>);
      state.localMapFilters = localFilters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => SETTINGS_INITIAL_STATE);
  },
});

export const { updateSettings, updateLocalMapFilters } = settings.actions;
export default settings.reducer;
