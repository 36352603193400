import HologardeBackground from '@assets/hologarde-background.jpg';
import { Center, Flex, Grid, GridItem } from '@chakra-ui/layout';
import { Image, Spinner, Text } from '@chakra-ui/react';
import CustomTitle from '@components/common/CustomTitle';
import MouseFocus from '@components/common/MouseFocus';
import InactivityModal from '@components/InactivityModal';
import { MapWrapper } from '@components/map/MapWrapper';
import ReplayTimelineWrapper from '@components/replay/ReplayTimelineWrapper';
import Search from '@components/replay/search/Search';
import CameraControlWrapper from '@components/video/cameraControl/CameraControlWrapper';
import JoystickControl from '@components/video/cameraControl/JoystickControl';
import PerimeterDoubtCheckGrid from '@components/video/PerimeterDoubtCheckGrid';
import PerimeterGrid from '@components/video/PerimeterGrid';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser, selectGlobalScreenConfigurationCode } from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors, LayoutSelectors } from '@redux/config/config.selectors';
import { selectJoystickControlId } from '@redux/global/global.selector';
import { selectContext } from '@redux/settings/settings.selectors';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { GridItem as GridItemType, GridItemComponentEnum } from '@/types/config/gridLayout.types';

import StreamGrid from '../video/StreamGrid';

function displayComponent(item: GridItemType) {
  switch (item.component) {
    case GridItemComponentEnum.MAP: {
      return <MapWrapper selectedMap={item.selectedMap} />;
    }
    case GridItemComponentEnum.CAMERA_CONTROL: {
      return <CameraControlWrapper defaultSelectedCameraCode={item.selectedCamera} uniqueKey={item.i} />;
    }
    case GridItemComponentEnum.DYNAMIC_CAMERA_CONTROL: {
      return <CameraControlWrapper isDynamic defaultSelectedCameraCode={item.selectedCamera} uniqueKey={item.i} />;
    }
    case GridItemComponentEnum.CAMERA_REPLAY: {
      return <CameraControlWrapper replayMode uniqueKey={item.i} />;
    }
    case GridItemComponentEnum.LAD_STREAM_GRID: {
      return (
        <StreamGrid
          values={item.selectedStreams}
          innerGridWidth={item.innerGridWidth}
          innerGridHeight={item.innerGridHeight}
          uniqueKey={item.i}
        />
      );
    }
    case GridItemComponentEnum.PERIMETER_STREAM_GRID: {
      return (
        <PerimeterGrid innerGridWidth={item.innerGridWidth} innerGridHeight={item.innerGridHeight} uniqueKey={item.i} />
      );
    }
    case GridItemComponentEnum.PERIMETER_DOUBT_CHECK: {
      return <PerimeterDoubtCheckGrid uniqueKey={item.i} />;
    }
    case GridItemComponentEnum.TIME_BAR: {
      return <ReplayTimelineWrapper key={item.i} />;
    }
    case GridItemComponentEnum.SEARCH: {
      return <Search />;
    }
    default:
      return item.component;
  }
}

export default function DisplayGrid() {
  const { code: screenConfigurationCode } = useParams();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const joystickControlId = useAppSelector(selectJoystickControlId);
  const { activeGroup } = useAppSelector(selectActiveUser);
  const inactivityLimit = activeGroup?.inactivityTime ?? null;

  const currentGlobalScreenConfigurationCode = useAppSelector(selectGlobalScreenConfigurationCode);
  const currentContext = useAppSelector(selectContext);
  const globalScreenConfigurations = useAppSelector(
    GlobalScreenConfigurationSelectors.selectAllGlobalScreenConfigurations,
  );
  const globalScreenConfiguration =
    globalScreenConfigurations.find((config) => config.code === currentGlobalScreenConfigurationCode) ?? null;
  const screenConfiguration =
    globalScreenConfiguration?.screenConfigurations.find(
      (screenConfig) => screenConfig.code === screenConfigurationCode && screenConfig.context === currentContext,
    ) ?? null;
  const gridLayout = useAppSelector((state) =>
    LayoutSelectors.selectLayoutByCode(state, screenConfiguration?.gridLayoutCode ?? ''),
  );

  const configErrorLabel =
    screenConfiguration === null ? (
      <FormattedMessage id="screenConfiguration.noConfiguration" />
    ) : (
      <FormattedMessage
        id="screenConfiguration.noLayoutContext"
        values={{ context: formatMessage({ id: `screenConfiguration.context.${currentContext}` }) }}
      />
    );

  if (gridLayout === undefined || screenConfiguration === null) {
    return (
      <Flex width="100%" height="100%" alignContent="center" justifyContent="center">
        <CustomTitle color="cyber.900">
          <Text fontWeight="medium" fontSize="18px" noOfLines={1} userSelect="none">
            <FormattedMessage id={`routes.${location.pathname}`} />
          </Text>
        </CustomTitle>
        <Image src={HologardeBackground} fit="cover" width="100%" height="100%" position="absolute" />
        <Center zIndex={1} color="neutral.white">
          {globalScreenConfigurations.length === 0 ? <Spinner size="xl" color="neutral.white" /> : configErrorLabel}
        </Center>
      </Flex>
    );
  }

  return (
    <>
      {joystickControlId !== null && <JoystickControl />}
      <MouseFocus />
      {inactivityLimit !== null && <InactivityModal inactivityLimit={inactivityLimit} />}
      <Grid
        templateRows={`repeat(${gridLayout.nbRow}, 1fr)`}
        templateColumns={`repeat(${gridLayout.nbCol}, 1fr)`}
        width="100%"
        height="100%"
        position="relative"
        backgroundColor="neutral.black"
      >
        {gridLayout.items.map((item) => {
          return (
            <GridItem
              key={item.i}
              backgroundColor="transparent"
              position="relative"
              rowSpan={item.h}
              colSpan={item.w}
              minW={item.minW ?? undefined}
              minH={item.minH ?? undefined}
              rowStart={item.y + 1}
              colStart={item.x + 1}
              width="100%"
              height="100%"
            >
              {displayComponent(item)}
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
}
