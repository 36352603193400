import {
  perimeterCameraAzimuthLayer,
  perimeterCameraSectorFillLayer,
  perimeterCameraSectorOutlineLayer,
} from '@components/map/layers/perimeterCameras/perimeterCamera.layer';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectPerimeterCameraSectorFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export function PerimeterCameraSectorLayer() {
  const perimeterCameraSectorFeatures = useSelectorWithReplayMode(selectPerimeterCameraSectorFeatures);

  if (!isFeatureCollection(perimeterCameraSectorFeatures)) return null;

  return (
    <Source id={SourceNameEnum.PERIMETER_CAMERA_SECTORS} type="geojson" data={perimeterCameraSectorFeatures} generateId>
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_SECTOR_FILLS} {...perimeterCameraSectorFillLayer} />
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_SECTOR_OUTLINES} {...perimeterCameraSectorOutlineLayer} />
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_AZIMUTHS} {...perimeterCameraAzimuthLayer} />
    </Source>
  );
}
