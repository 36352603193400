import { Box } from '@chakra-ui/layout';
import DisplayModeConfiguration from '@components/displayMode/DisplayModeConfiguration';
import { useAuth } from '@hooks/useAuth';
import usePerformanceMonitor from '@hooks/usePerformanceMonitor';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { RoutesEnum } from '@/types/routes.types';

import Cockpit from './cockpit/Cockpit';
import DisplayGrid from './cockpit/DisplayGrid';
import Login from './login/Login';
import PrivateOutlet from './login/PrivateOutlet';
import PrivateOutletOther from './login/PrivateOutletOther';
import ResetPasswordModal from './ResetPasswordModal';

export default function App() {
  const { token } = useAuth();
  const { pathname } = useLocation();
  sessionStorage.setItem('route', pathname);
  usePerformanceMonitor();

  return (
    <Box width="100vw" height="100vh" overflow="hidden" bgGradient="linear-gradient(180deg, #000000 0%, #041b2f 100%)">
      <Routes>
        <Route path={RoutesEnum.LOGIN} element={token ? <Navigate to={RoutesEnum.C2} replace /> : <Login />} />
        <Route element={<PrivateOutlet />}>
          <Route path={RoutesEnum.C2} element={<Cockpit />} />
          <Route path={RoutesEnum.DISPLAY_MODE} element={<DisplayModeConfiguration />} />
        </Route>
        <Route element={<PrivateOutletOther />}>
          <Route path={`${RoutesEnum.SCREEN}/:code`} element={<DisplayGrid />} />
        </Route>
        <Route path="*" element={<Navigate to={RoutesEnum.C2} replace />} />
      </Routes>
      <ResetPasswordModal />
    </Box>
  );
}
