import { Box, Portal } from '@chakra-ui/react';
import SensorContextMenu from '@components/map/contextMenu/sensor/SensorContextMenu';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { selectDoesScreenconfigurationContainPerimeterDoubtCheck } from '@redux/config/config.selectors';
import { selectZoneViewMode } from '@redux/maps/maps.selectors';
import { AUTOMATON_SECTION_FEATURE_TYPES, AUTOMATON_SECTION_LAYER_NAMES } from '@utils/map/map.constants';
import { getAvailableLayers } from '@utils/map/map.utils';
import { useCallback, useEffect, useRef } from 'react';
import { MapRef, useMap } from 'react-map-gl';

import { Target } from '@/types/c2/c2.types';
import { FeatureTypeEnum, LayerNameEnum, SensorUniqueCodes, zoneLayers } from '@/types/map.types';
import { AutomatonSectionNames } from '@/types/sensor/configuration.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import AutomatonSectionContextMenu from './AutomatonSectionContextMenu';
import DefaultContextMenu from './DefaultContextMenu';
import PerimeterCameraContextMenu from './PerimeterCameraContextMenu';
import TargetContextMenu from './TargetContextMenu';

function ContextMenu() {
  const { current: mapbox } = useMap();
  const { contextMenuPosition, setContextMenuPosition, menuToDisplay, setMenuToDisplay } = useMapContext();
  const isScreenConfigurationContainDoubtCheck = useAppSelector(
    selectDoesScreenconfigurationContainPerimeterDoubtCheck,
  );
  const viewMode = useSelectorWithMapId(selectZoneViewMode);
  const layers: LayerNameEnum[] = [
    LayerNameEnum.EVENTS,
    ...AUTOMATON_SECTION_LAYER_NAMES,
    LayerNameEnum.PERIMETER_SENSOR_ICONS,
    LayerNameEnum.PERIMETER_SENSOR_LABELS,
    LayerNameEnum.PERIMETER_CAMERA_LABELS,
    LayerNameEnum.PERIMETER_CAMERA_SECTOR_FILLS,
    LayerNameEnum.SENSOR_CLUSTER_LEAVES,
    LayerNameEnum.SENSOR_ICONS,
    LayerNameEnum.SENSOR_LABELS,
    LayerNameEnum.TARGETS,
  ];

  const handleDefaultContextMenu = (event: any, mapbox: MapRef) => { // eslint-disable-line
    const zoneFeatures = mapbox.queryRenderedFeatures(event.point, {
      layers: zoneLayers,
    });
    if (!viewMode || zoneFeatures.length === 0) {
      setMenuToDisplay &&
        setMenuToDisplay(
          <DefaultContextMenu
            //Add 56px to the horizontal position to take into account the menu width
            clickPosition={{ x: event.point.x + 56, y: event.point.y }}
            position={event.lngLat}
            onClose={() => setContextMenuPosition && setContextMenuPosition(null)}
          />,
        );
    }
    return true;
  };
  const handleContextMenu = useCallback(
    (event: any) => { // eslint-disable-line
      setContextMenuPosition && setContextMenuPosition(event.lngLat);
      if (mapbox) {
        const availableLayers = getAvailableLayers(mapbox.getMap(), layers) as string[];
        if (availableLayers.length === 0) {
          return;
        }
        const features = mapbox.queryRenderedFeatures(event.point, {
          layers: availableLayers,
        });

        //Add 56px to the horizontal position to take into account the menu width
        const clickPosition = { x: event.point.x + 56, y: event.point.y };

        if (features.length > 0) {
          //Stop after the first feature that open a context menu
          features.some((feature) => {
            const properties = feature.properties;
            if (properties) {
              const featureType = properties.featureType;
              const value = properties.value;
              if (AUTOMATON_SECTION_FEATURE_TYPES.includes(featureType)) {
                setMenuToDisplay &&
                  setMenuToDisplay(
                    <AutomatonSectionContextMenu
                      automatonSectionNames={JSON.parse(value) as AutomatonSectionNames}
                      clickPosition={clickPosition}
                      position={event.lngLat}
                      onClose={() => setContextMenuPosition && setContextMenuPosition(null)}
                    />,
                  );
                return true;
              } else if (featureType === FeatureTypeEnum.TARGET_ICON) {
                setMenuToDisplay &&
                  setMenuToDisplay(
                    <TargetContextMenu
                      selectedTarget={JSON.parse(value) as Target<TargetLocation>}
                      clickPosition={clickPosition}
                      onClose={() => setContextMenuPosition && setContextMenuPosition(null)}
                    />,
                  );
                return true;
              } else if ([FeatureTypeEnum.SENSOR_ICON, FeatureTypeEnum.SENSOR_LABEL].includes(featureType)) {
                setMenuToDisplay &&
                  setMenuToDisplay(
                    <SensorContextMenu
                      sensorUniqueCodes={JSON.parse(value) as SensorUniqueCodes}
                      clickPosition={clickPosition}
                      type={properties.type}
                      clusterFeature={properties.clusterFeature}
                      onClose={() => setContextMenuPosition && setContextMenuPosition(null)}
                    />,
                  );
                return true;
              } else if (
                [FeatureTypeEnum.PERIMETER_CAMERA_SECTOR, FeatureTypeEnum.PERIMETER_CAMERA_LABEL].includes(featureType)
              ) {
                if (isScreenConfigurationContainDoubtCheck) {
                  setMenuToDisplay &&
                    setMenuToDisplay(
                      <PerimeterCameraContextMenu
                        perimeterCamera={JSON.parse(value) as PerimeterCamera}
                        clickPosition={clickPosition}
                        onClose={() => setContextMenuPosition && setContextMenuPosition(null)}
                      />,
                    );
                  return true;
                } else {
                  return handleDefaultContextMenu(event, mapbox);
                }
              } else {
                setMenuToDisplay && setMenuToDisplay(null);
                setContextMenuPosition && setContextMenuPosition(null);
                return false;
              }
            }
          });
        } else {
          return handleDefaultContextMenu(event, mapbox);
        }
      }
    },
    // TODO fix lint warning if needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapbox, viewMode, isScreenConfigurationContainDoubtCheck],
  );

  useMapLayerMouseEvent('contextmenu', handleContextMenu);

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setContextMenuPosition && setContextMenuPosition(null);
        setMenuToDisplay && setMenuToDisplay(null);
      }
    }

    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setContextMenuPosition, setMenuToDisplay]);

  return (
    <Portal>
      <Box ref={ref}>{contextMenuPosition && menuToDisplay}</Box>
    </Portal>
  );
}

export default ContextMenu;
