import { ReactComponent as LevelIcon } from '@assets/icons/20x20/ic-alert-20.svg';
import { ReactComponent as DroneIcon } from '@assets/icons/20x20/ic-drone-20.svg';
import { ReactComponent as AlertIcon } from '@assets/icons/20x20/ic-eye-20.svg';
import { ReactComponent as HeightIcon } from '@assets/icons/20x20/ic-height-20.svg';
import { ReactComponent as PdvIcon } from '@assets/icons/20x20/ic-pdv-20.svg';
import { ReactComponent as SecureIcon } from '@assets/icons/20x20/ic-secure-20.svg';
import { Grid, HStack, Icon, Text } from '@chakra-ui/react';
import {
  getClearanceColor,
  getNotificationColor,
  getWorstNotificationLevel,
  isDroneNotification,
} from '@utils/data/notification.utils';
import { DistanceUnits, formatUnit } from '@utils/unit.utils';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { NotificationType } from '@/types/data/data.types';

type Props = {
  notification: NotificationType;
  isArchived: boolean;
};

function NotificationCardInfo({ notification, isArchived }: Readonly<Props>) {
  const level = getWorstNotificationLevel(notification, { isArchived });

  return (
    <Grid
      width={isArchived ? '344px' : '319px'}
      height="100%"
      paddingX={2}
      paddingY={3}
      templateColumns={`repeat(2, 1fr)`}
      templateRows={`repeat(3, 1fr)`}
      gap={1}
      flexShrink={0}
      rowGap="auto"
      columnGap={2.5}
    >
      {isDroneNotification(notification) ? (
        <>
          <HStack gap={1} wrap="nowrap">
            <Icon as={DroneIcon} width="20px" height="20px" color={getClearanceColor(notification.clearance)} />
            <Text>
              <FormattedMessage id={`notification.clearance.${notification.clearance}`} />
            </Text>
          </HStack>
          <HStack gap={1}>
            <Icon
              as={LevelIcon}
              width="20px"
              height="20px"
              color={getNotificationColor(notification, { isArchived })}
            />
            <Text>
              <FormattedMessage id={`notification.criticality.${level}`} />
            </Text>
          </HStack>
          <HStack gap={1}>
            <Icon as={SecureIcon} width="20px" height="20px" color="neutral.300" />
            <Text>
              <FormattedMessage id={`target.reliability.${notification.reliability}`} />
            </Text>
          </HStack>
          <HStack gap={1}>
            <Icon as={AlertIcon} width="20px" height="20px" color="neutral.300" />
            <Text>{`x${notification.occurrenceCount}`}</Text>
          </HStack>
          <HStack gap={1}>
            <Icon as={PdvIcon} width="20px" height="20px" color="neutral.300" />
            <Text>
              <FormattedMessage id="target.pdv" values={{ value: notification.hasFlightPlan }} />
            </Text>
          </HStack>
          <HStack gap={1}>
            <Icon as={HeightIcon} width="20px" height="20px" color="neutral.300" />
            <Text>
              {formatUnit(DistanceUnits.METERS, isArchived ? notification.maxHeight : notification.currentHeight)}
            </Text>
          </HStack>
        </>
      ) : (
        <HStack gap={1}>
          <Icon as={LevelIcon} width="20px" height="20px" color={getNotificationColor(notification, { isArchived })} />
          <Text>
            <FormattedMessage id={`notification.criticality.${level}`} />
          </Text>
        </HStack>
      )}
    </Grid>
  );
}

export default memo(NotificationCardInfo, isEqual);
