import { Center, Modal, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useAuth } from '@hooks/useAuth';
import { selectHasToLogout } from '@redux/authent/authent.selectors';
import { useLogoutMutation } from '@services/authent/login.api';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export default function ResetPasswordModal() {
  const hasToLogout = useAppSelector(selectHasToLogout);
  const [logoutMutation] = useLogoutMutation();
  const { token } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (hasToLogout) {
    logoutMutation({ token: token, emptyStoreOnLogout: true });
    setIsOpen(true);
  }

  return (
    <Modal isOpen={isOpen} isCentered onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color="neutral.white" />
        <Center backgroundColor="neutral.700" padding={5}>
          <Text textAlign="center">
            <FormattedMessage id="authent.resetHeader" />
          </Text>
        </Center>
      </ModalContent>
    </Modal>
  );
}
