import { ReactComponent as EyeIcon } from '@assets/icons/24x24/ic-eye-24.svg';
import { ReactComponent as EyeKoIcon } from '@assets/icons/24x24/ic-eye-ko-24.svg';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { HStack, Stack } from '@chakra-ui/layout';
import { Button, Checkbox, Icon, Input, InputGroup, InputRightElement, useBoolean } from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import { useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { useCreateUserMutation, useUpdateUserMutation } from '@services/authent/user.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { Field, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { newUserSchemaValidation, userSchemaValidation } from 'src/validations/user.schema';

import { User } from '@/types/authent/login.types';

const emptyUser: Partial<User> = {
  firstName: '',
  active: true,
  name: '',
  email: '',
  login: '',
  password: '',
};

type Props = {
  user?: Partial<User>;
  editMode: boolean;
  canEdit: boolean;
  onReset: () => void;
};

export default function UserForm({ user = emptyUser, editMode, canEdit, onReset }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const activeSite = useAppSelector(selectActiveSite);
  const isNewUser = user.id === undefined;
  const [createUser, { isLoading, isError }] = useCreateUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate, isError: isErrorUpdate }] = useUpdateUserMutation();
  const validationSchema = useValidation(isNewUser ? newUserSchemaValidation : userSchemaValidation);
  const [showPassword, setShowPassword] = useBoolean();

  return (
    <Formik
      initialValues={user}
      enableReinitialize
      validateOnBlur
      onSubmit={(values) => {
        if (isNewUser) {
          createUser({
            ...values,
            deactivationDate: null,
            activeGroup: null,
            groups: [],
            activeSite: activeSite,
            sites: [activeSite],
          } as User)
            .unwrap()
            .then(() => {
              onReset();
              createToast(formatMessage({ id: 'components.admin.users.message.add' }), ToastStatusEnum.SUCCESS);
            });
        } else {
          updateUser(values as User)
            .unwrap()
            .then(() => {
              onReset();
              createToast(formatMessage({ id: 'components.admin.users.message.update' }), ToastStatusEnum.SUCCESS);
            });
        }
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, errors, touched, isValid, dirty, values }) => (
        <form onSubmit={handleSubmit}>
          <Stack gap={3}>
            <Stack gap={2} width="240px">
              <FormControl
                isRequired
                isInvalid={touched.firstName && !!errors.firstName}
                isReadOnly={!editMode && !isNewUser}
              >
                <Stack gap={0}>
                  <FormLabel color="neutral.300">
                    <FormattedMessage id="authent.user.firstName" />
                  </FormLabel>
                  <Field
                    as={Input}
                    height="30px"
                    type="text"
                    name="firstName"
                    id="firstName"
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                </Stack>
              </FormControl>
              <FormControl isRequired isInvalid={touched.name && !!errors.name} isReadOnly={!editMode && !isNewUser}>
                <Stack gap={0}>
                  <FormLabel color="neutral.300">
                    <FormattedMessage id="authent.user.name" />
                  </FormLabel>
                  <Field
                    as={Input}
                    height="30px"
                    type="text"
                    name="name"
                    id="user_name"
                    isInvalid={touched.name && !!errors.name}
                  />
                </Stack>
              </FormControl>
              <FormControl isReadOnly={!isNewUser} isRequired isInvalid={touched.login && !!errors.login}>
                <Stack gap={0}>
                  <FormLabel color="neutral.300">
                    <FormattedMessage id="authent.user.login" />
                  </FormLabel>
                  <Field
                    as={Input}
                    height="30px"
                    type="text"
                    name="login"
                    id="login"
                    isInvalid={touched.login && !!errors.login}
                  />
                </Stack>
              </FormControl>
              {isNewUser && (
                <FormControl isRequired isInvalid={touched.password && !!errors.password}>
                  <Stack gap={0}>
                    <FormLabel color="neutral.300">
                      <FormattedMessage id="authent.user.password" />
                    </FormLabel>
                    <Field as={InputGroup} type="password" name="password" id="password">
                      <Input
                        id="password"
                        name="password"
                        paddingRight={4}
                        height="30px"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="off"
                        isInvalid={touched.password && !!errors.password}
                      />
                      <InputRightElement onClick={setShowPassword.toggle} cursor="pointer" padding={1}>
                        <Icon
                          as={showPassword ? EyeIcon : EyeKoIcon}
                          width="20px"
                          height="20px"
                          color="neutral.white"
                        />
                      </InputRightElement>
                    </Field>
                  </Stack>
                </FormControl>
              )}
              <FormControl isRequired isInvalid={touched.email && !!errors.email} isReadOnly={!editMode && !isNewUser}>
                <Stack gap={0}>
                  <FormLabel color="neutral.300">
                    <FormattedMessage id="authent.user.email" />
                  </FormLabel>
                  <Field
                    as={Input}
                    height="30px"
                    type="text"
                    name="email"
                    id="email"
                    isInvalid={touched.email && !!errors.email}
                  />
                </Stack>
              </FormControl>
              <FormControl isReadOnly={!editMode && !isNewUser}>
                <HStack gap={0}>
                  <FormLabel color="neutral.300">
                    <FormattedMessage id="authent.user.active" />
                  </FormLabel>
                  <Field as={Checkbox} isChecked={values.active} name="active" id="active" />
                </HStack>
              </FormControl>
            </Stack>
            <HStack gap={2} width="100%">
              <Button
                type="submit"
                isDisabled={!canEdit || !dirty || !isValid || isLoading}
                isLoading={isLoading || isLoadingUpdate}
                variant="formButtonPrimary"
              >
                {isNewUser ? <FormattedMessage id="global.add" /> : <FormattedMessage id="global.update" />}
              </Button>
              <ErrorsContainer
                errors={errors}
                touched={touched}
                width="400px"
                height="52px"
                apiError={
                  isError || isErrorUpdate
                    ? formatMessage({ id: 'components.admin.users.message.createError' })
                    : undefined
                }
              />
            </HStack>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
