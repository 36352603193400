import '@components/common/layout/scrollbar.scss';

import { Box } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { CSSProperties, memo, Ref } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListOnScrollProps } from 'react-window';

import LogBookMessage from './LogBookMessage';
type Props = {
  logs: string[];
  listRef: Ref<FixedSizeList>;
  handleScroll: (props: ListOnScrollProps) => void;
};

function LogBookList({ logs, listRef, handleScroll }: Readonly<Props>) {
  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const code = logs[index];
    return <LogBookMessage style={style} code={code} key={code} />;
  };

  const listHeight = logs.length * 102;

  return (
    <AutoSizer style={{ width: '100%', height: '100%', position: 'relative' }} disableWidth>
      {({ height }) => {
        const isOverflowing = listHeight > height;
        return (
          <>
            <FixedSizeList
              width="100%"
              ref={listRef}
              onScroll={handleScroll}
              itemKey={(index) => logs[index]}
              height={isOverflowing ? height : listHeight}
              itemCount={logs.length}
              style={{ flexShrink: 0 }}
              itemSize={102}
              className="cockpit-scrollbar"
            >
              {Row}
            </FixedSizeList>
            <Box
              position="absolute"
              backgroundColor="neutral.800-op70"
              top={listHeight}
              marginTop={0.25}
              height={height - listHeight - 2}
              width="100%"
            />
          </>
        );
      }}
    </AutoSizer>
  );
}

export default memo(LogBookList, (prev, next) => isEqual(prev.logs, next.logs));
