import { flightPlanColor } from '@utils/map/map.constants';
import { Feature } from 'geojson';

import { FeatureTypeEnum } from '@/types/map.types';
import { FlightPlan } from '@/types/utm/flightPlan.types';

export const toFlightPlanFillFeatures = (flightPlans: FlightPlan[]): Feature[] =>
  flightPlans.map((flightPlan) => {
    return {
      type: 'Feature',
      properties: {
        featureType: FeatureTypeEnum.FLIGHT_PLAN,
        value: flightPlan,
        fillColor: flightPlanColor[flightPlan.state].fill,
        outlineColor: flightPlanColor[flightPlan.state].outline,
      },
      geometry: flightPlan.polygons,
    } as Feature;
  });
