import {
  flightPlanFillLayerByStatus,
  flightPlanOutlineLayerByStatus,
} from '@components/map/layers/flightPlans/flightPlan.layer';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectFlightPlanFeatures } from '@redux/situation/situation.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

function FlightPlanLayer() {
  const flightPlanFeatures = useSelectorWithReplayMode(selectFlightPlanFeatures);

  if (!isFeatureCollection(flightPlanFeatures)) {
    return null;
  }

  return (
    <Source id={SourceNameEnum.FLIGHT_PLANS} type="geojson" data={flightPlanFeatures} generateId>
      <Layer key={LayerNameEnum.FLIGHT_PLAN_FILLS} {...flightPlanFillLayerByStatus} />
      <Layer key={LayerNameEnum.FLIGHT_PLAN_OUTLINES} {...flightPlanOutlineLayerByStatus} />
    </Source>
  );
}

export default memo(FlightPlanLayer);
