import '@components/common/layout/scrollbar.scss';

import { Box } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { Dispatch, memo, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { NotificationCard } from './card/NotificationCard';
type Props = {
  notificationIds: number[];
  selectedNotification: number | null;
  setSelectedNotification: Dispatch<number | null>;
};
const Row = ({ index, style, data }: ListChildComponentProps<Props>) => {
  const notificationId = data.notificationIds[index];
  return (
    <NotificationCard
      style={style}
      notificationId={notificationId}
      isSelected={notificationId === data.selectedNotification}
      setSelectedNotification={data.setSelectedNotification}
    />
  );
};

function LiveNotificationList({ notificationIds, selectedNotification, setSelectedNotification }: Readonly<Props>) {
  const memoizedData = useMemo(
    () => ({
      notificationIds,
      selectedNotification,
      setSelectedNotification,
    }),
    [notificationIds, selectedNotification, setSelectedNotification],
  );

  return (
    <AutoSizer style={{ width: '100%', height: '500px', position: 'relative' }} disableWidth>
      {({ height }) => {
        const totalListHeight = notificationIds.length * 130;
        const isOverflowing = totalListHeight > height;
        return (
          <>
            <FixedSizeList
              width="100%"
              itemKey={(index) => notificationIds[index]}
              height={isOverflowing ? height : totalListHeight}
              itemCount={notificationIds.length}
              style={{ flexShrink: 0 }}
              itemSize={130}
              itemData={memoizedData}
              className="cockpit-scrollbar no-margin"
            >
              {Row}
            </FixedSizeList>
            <Box
              position="absolute"
              top={totalListHeight + 2}
              height={`${height - totalListHeight - 2}px`}
              width="100%"
            />
          </>
        );
      }}
    </AutoSizer>
  );
}

export default memo(LiveNotificationList, isEqual);
