import { HStack, VStack } from '@chakra-ui/layout';
import { Button, Text } from '@chakra-ui/react';
import PerimeterSettings from '@components/cockpit/panels/settings/PerimeterSettings';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import LogoutButton from '@components/login/LogoutButton';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveProfile, selectActiveUser } from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { capitalize } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import CockpitPanelHeader from '../CockpitPanelHeader';
import Panel from '../Panel';
import CameraSettings from './CameraSettings';

export default function ProfileUserParams() {
  const { formatMessage } = useIntl();
  const { login, activeGroup, name, firstName } = useAppSelector(selectActiveUser);
  const globalScreenConfig = useAppSelector((state) =>
    GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(
      state,
      activeGroup?.globalScreenConfig ?? '',
    ),
  );

  const activeProfile = useAppSelector(selectActiveProfile);

  return (
    <Panel header={<CockpitPanelHeader label={formatMessage({ id: 'components.sidebar.SETTINGS' })} />}>
      <HStack width="100%" height="100%" gap={0}>
        <VStack
          height="100%"
          width="293px"
          backgroundColor="neutral.700"
          padding={4}
          gap={3}
          alignItems="start"
          flexShrink={0}
        >
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="authent.user.profile" />
            </Text>
            <Text size="md" color="neutral.white">
              {capitalize(activeProfile?.name)}
            </Text>
          </VStack>
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="authent.user.config" />
            </Text>
            <Text size="md" color="neutral.white">
              {capitalize(globalScreenConfig?.name)}
            </Text>
          </VStack>
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="authent.user.login" />
            </Text>
            <Text size="md" color="neutral.white">
              {login}
            </Text>
          </VStack>
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="authent.user.fullName" />
            </Text>
            <Text size="md" color="neutral.white">
              {`${firstName} ${name}`}
            </Text>
          </VStack>
          <VStack gap={2} alignItems="start" width="100%" marginTop="auto">
            <Button variant="formButtonPrimary" size="s" isDisabled>
              <FormattedMessage id="components.userSettings.changePassword" />
            </Button>
            <LogoutButton />
          </VStack>
        </VStack>
        <CockpitCustomScrollbar marginTop={0}>
          <VStack width="100%" height="100%" backgroundColor="neutral.700" gap={0.25}>
            <CameraSettings />
            <PerimeterSettings />
            <HStack width="100%" padding={2} marginTop="auto">
              <Button variant="formButtonPrimary" size="s" marginLeft="auto" width="fit-content" isDisabled>
                <FormattedMessage id="components.userSettings.resetSettings" />
              </Button>
            </HStack>
          </VStack>
        </CockpitCustomScrollbar>
      </HStack>
    </Panel>
  );
}
