import { ReactComponent as FlightPlanFillIcon } from '@assets/icons/40x40/ic-pdv-40.svg';
import { ReactComponent as FlightPlanIcon } from '@assets/icons/40x40/ic-pdv-stroke-40.svg';
import { HStack, Icon, Tab, TabList, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectSituationFlightPlanById } from '@redux/situation/situation.selectors';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FlightPlanInfoData, PopupPositionData } from '@/types/map.types';
import { FlightPlan, FlightPlanActivationStateEnum } from '@/types/utm/flightPlan.types';

import DraggableControl from '../../controls/DraggableControl';
import FlightPlanDetails from './FlightPlanDetails';
import FlightPlanDrones from './FlightPlanDrones';
import FlightPlanInstructions from './FlightPlanInstructions';
import FlightPlanPilots from './FlightPlanPilots';

export default function FlightPlanInfo({
  featureValue,
  activation,
  top,
  left,
  onClose,
}: Readonly<FlightPlanInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();
  const ongoingFlightPlan = useSelectorWithReplayMode(selectSituationFlightPlanById, featureValue.id);
  const [previousSituationFlightPlan, setPreviousSituationFlightPlan] = useState<FlightPlan | null>(ongoingFlightPlan);

  if (ongoingFlightPlan === null && previousSituationFlightPlan !== null) {
    setPreviousSituationFlightPlan(null);
    onClose();
    return null;
  } else if (previousSituationFlightPlan !== ongoingFlightPlan) {
    setPreviousSituationFlightPlan(ongoingFlightPlan);
  }

  const flightPlan = ongoingFlightPlan ?? featureValue;
  const displayedActivation = flightPlan.activations.find((a) => a.id === activation?.id) ?? activation;

  const isActive = displayedActivation?.state === FlightPlanActivationStateEnum.ACTIVE;
  const color = isActive ? 'alertZero.500' : 'neutral.300';
  const missionId = flightPlan.externalId ?? flightPlan.code;

  return (
    <DraggableControl
      top={top}
      left={left}
      offsetY={-200}
      key={flightPlan.code}
      width={612}
      height={800}
      scrollable={false}
      label={formatMessage({ id: 'flightPlanInfos.title' })}
      onClose={onClose}
    >
      <VStack gap={0.25} width="100%" height="100%" backgroundColor="neutral.900">
        <HStack width="100%" height="120px" gap={2} backgroundColor="neutral.700" justifyContent="center">
          <Icon as={isActive ? FlightPlanFillIcon : FlightPlanIcon} width="40px" height="40px" color={color} />
          <VStack gap={0.5} alignItems="start">
            <Text padding={0.5} backgroundColor="neutral.black" size="md">
              {missionId}
            </Text>
            <Text size="md" color={color}>
              <FormattedMessage
                id={
                  displayedActivation?.state
                    ? `flightPlan.activation.state.${displayedActivation.state}`
                    : `flightPlan.state.${flightPlan.state}`
                }
              />
            </Text>
          </VStack>
        </HStack>
        <Tabs
          width="100%"
          maxHeight="684px"
          variant="neutral"
          gap={0.25}
          display="flex"
          flexDirection="column"
          flex={1}
          overflow="hidden"
        >
          <TabList>
            <Tab>
              <Text size="md">
                <FormattedMessage id="flightPlanInfos.tab.details.title" />
              </Text>
            </Tab>
            <Tab>
              <Text size="md">
                <FormattedMessage id="flightPlanInfos.tab.instructions.title" />
              </Text>
            </Tab>
            <Tab>
              <Text size="md">
                <FormattedMessage id="flightPlanInfos.tab.drones.title" />
              </Text>
            </Tab>
            <Tab>
              <Text size="md">
                <FormattedMessage id="flightPlanInfos.tab.pilots.title" />
              </Text>
            </Tab>
          </TabList>

          <TabPanels flex={1} overflow="auto" className="popup-scrollbar">
            <FlightPlanDetails
              startDate={displayedActivation?.startDate}
              endDate={displayedActivation?.endDate}
              maxAltitude={flightPlan.altitudeMax}
              mission={missionId}
              provider={flightPlan.provider}
              missionStartTime={flightPlan.startTime}
              missionEndTime={flightPlan.endTime}
              information={[]}
            />
            <FlightPlanInstructions instruction={formatMessage({ id: 'flightPlanInfos.tab.instructions.empty' })} />
            <FlightPlanDrones drones={flightPlan.drones} />
            <FlightPlanPilots
              pilots={flightPlan.drones.map((drone) => drone.pilot).filter((pilot) => pilot !== null)}
            />
          </TabPanels>
        </Tabs>
      </VStack>
    </DraggableControl>
  );
}
