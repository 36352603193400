import { Box, Center, Spinner, TabPanel } from '@chakra-ui/react';
import { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { AlertType, NotificationTypeEnum } from '@/types/data/data.types';

import AlertList from './AlertList';

type Props = {
  alerts: AlertType[];
  notificationType: NotificationTypeEnum;
  isFetching: boolean;
};
function NotificationHistory({ alerts, notificationType, isFetching }: Readonly<Props>) {
  return (
    <TabPanel height="100%">
      {isFetching ? (
        <Center width="100%" height="100%" backgroundColor="neutral.900">
          <Spinner color="neutral.white" height="70px" width="70px" />
        </Center>
      ) : (
        <Box width="100%" height="100%" overflow="hidden">
          <AlertList alerts={alerts} notificationType={notificationType} />
        </Box>
      )}
    </TabPanel>
  );
}

export default memo(NotificationHistory, shallowEqual);
