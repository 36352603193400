import { Box, Button, Text, useBoolean } from '@chakra-ui/react';
import { useUpdateProcedureMutation } from '@services/data/procedure.api';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProcedureStep, ProcedureStepStateEnum } from '@/types/config/procedure.types';

import ProcedureInstructions from './ProcedureInstructions';
import StepActionButtons from './StepActionButtons';

type Props = {
  step: ProcedureStep;
  notificationId: number;
  procedureId: number;
  canManage: boolean;
};

function AcknowledgementStep({ step, notificationId, procedureId, canManage }: Readonly<Props>) {
  const [isToggle, setIsToggle] = useBoolean(false);
  const [updateProcedure] = useUpdateProcedureMutation();

  if (!canManage) {
    return <ProcedureInstructions instructions={step.instructions} />;
  }

  return (
    <>
      <Box
        paddingY={5}
        paddingX={6}
        backgroundColor="neutral.700"
        width="100%"
        flex={step.instructions.length > 0 ? 0 : 1}
      >
        <Button variant="toggleButton" isActive={isToggle} onClick={setIsToggle.toggle}>
          <Text>
            <FormattedMessage id="notification.procedure.step.type.ACKNOWLEDGEMENT.acknowledgement" />
          </Text>
        </Button>
      </Box>
      <ProcedureInstructions instructions={step.instructions} />
      <StepActionButtons
        isDisabled={!isToggle}
        isMandatory={step.mandatory}
        notificationId={notificationId}
        procedureId={procedureId}
        stepRank={step.rank}
        onValidate={() =>
          updateProcedure({
            notificationId: notificationId,
            procedureId,
            step: step.rank,
            state: ProcedureStepStateEnum.CONFIRMED,
          }).unwrap()
        }
      />
    </>
  );
}

export default memo(AcknowledgementStep);
