import { ReactComponent as DroneSureIcon } from '@assets/components/panel/notification/notification-drone-icon-sure.svg';
import { ReactComponent as DroneUnsureIcon } from '@assets/components/panel/notification/notification-drone-icon-unsure.svg';
import { HStack, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, VStack } from '@chakra-ui/react';
import DraggableControl from '@components/map/controls/DraggableControl';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { targetColor } from '@utils/map/map.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';

import { ExpiredDroneInfoData, PopupPositionData } from '@/types/map.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';

import TargetAlerts from '../TargetAlerts';
import ExpiredDroneDetails from './ExpiredDroneDetails';

export function ExpiredDroneInfo({
  notification,
  top,
  left,
  onClose,
}: Readonly<ExpiredDroneInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();

  const platforms = useSelectorWithReplayMode(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(notification.marks)),
    shallowEqual,
  );
  const color = targetColor[notification.classification];

  return (
    <DraggableControl
      top={top}
      left={left}
      offsetY={-200}
      key={notification.id}
      width={584}
      height={728}
      scrollable={false}
      label={formatMessage({ id: 'targetInfos.targetLostInfo' })}
      onClose={onClose}
    >
      <VStack gap={0.25} width="100%" height="100%" backgroundColor="neutral.900">
        <HStack width="100%" height="120px" gap={2} backgroundColor="neutral.700" justifyContent="center">
          <Icon
            as={notification.reliability === ReliabilityEnum.SURE ? DroneSureIcon : DroneUnsureIcon}
            width="70px"
            height="70px"
            sx={{
              '#background': { fill: 'neutral.black' },
              '#line': { fill: color },
            }}
          />
          <VStack gap={0.5} alignItems="start">
            <Text padding={0.5} backgroundColor="neutral.black" size="md">
              {notification.displayId ?? formatMessage({ id: 'target.unknown' })}
            </Text>
            <HStack maxWidth="100%" gap={0.5}>
              {platforms.map((platform) => (
                <Tooltip key={platform} label={platform} openDelay={200}>
                  <Text
                    paddingX={0.25}
                    backgroundColor="neutral.600"
                    textTransform="uppercase"
                    size="md"
                    color="neutral.200"
                  >
                    {platform.slice(0, 4)}
                  </Text>
                </Tooltip>
              ))}
            </HStack>
          </VStack>
        </HStack>
        <Tabs
          width="100%"
          maxHeight="730px"
          variant="neutral"
          gap={0.25}
          display="flex"
          flexDirection="column"
          flex={1}
          overflow="hidden"
          isLazy
          lazyBehavior="keepMounted"
        >
          <TabList>
            <Tab>
              <Text size="md">
                <FormattedMessage id="targetInfos.tab.details.title" />
              </Text>
            </Tab>
            <Tab>
              <Text size="md">
                <FormattedMessage id="targetInfos.tab.alerts.title" />
              </Text>
            </Tab>
          </TabList>
          <TabPanels flex={1} overflow="auto" className="popup-scrollbar">
            <TabPanel height="100%">
              <ExpiredDroneDetails notification={notification} />
            </TabPanel>
            <TabPanel height="100%">
              <TargetAlerts
                notificationId={notification.id}
                targetId={notification.identifier}
                displayId={notification.displayId}
                position={{ x: left, y: top }}
                onClose={onClose}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </DraggableControl>
  );
}
