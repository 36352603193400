import { Box, Center, HStack, Text, VStack } from '@chakra-ui/layout';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import { DistanceUnits, formatUnit } from '@utils/unit.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Coordinates, CoordinatesUnitEnum } from '@/types/commons/commons.types';

type Props = {
  mapboxPoints: Coordinates[];
  unit: CoordinatesUnitEnum;
  height: number | null;
  isLowerLevel?: boolean;
};

function ZonePointsGrid({ mapboxPoints, unit, height, isLowerLevel = false }: Readonly<Props>) {
  if (height == null) {
    // should not happen
    return (
      <Box width="100%" height="192px" backgroundColor="neutral.900" paddingY={1} flexGrow={1}>
        <FormattedMessage id="error.common.unknown_error" />
      </Box>
    );
  }

  const heightNumber = parseInt(height.toString()); //Pour éviter un crash. Formik envoie "" quand l'input est vide
  return (
    <Box width="100%" height="192px" backgroundColor="neutral.900" paddingY={1} flexGrow={1}>
      <MapCustomScrollbar margin={1} height="100%" marginTop={0} marginBottom={0} thumbColor="neutral.600">
        <VStack gap={1.5} height="100%" width="100%" paddingLeft={2}>
          {mapboxPoints.slice(0, mapboxPoints.length - 1).map((point, index) => {
            return (
              <HStack
                key={`${index + 1}`}
                gap={2}
                width="100%"
                paddingRight={3}
                paddingTop={index === 0 ? 1 : 0}
                paddingBottom={index === mapboxPoints.length - 1 ? 1 : 0}
              >
                <Center width="30px">
                  <Text size="md" border="2px solid" borderColor="neutral.white" height="24px" paddingX="5px">{`${
                    index + 1
                  }${isLowerLevel ? "'" : ''}`}</Text>
                </Center>
                <Text width="340px" noOfLines={1} size="md">
                  {formatCoordinatesToString(new DmsCoordinates(point.latitude, point.longitude), unit)}
                </Text>
                <Text size="md">
                  {formatUnit(DistanceUnits.METERS, !Number.isNaN(heightNumber) ? heightNumber : null)}
                </Text>
              </HStack>
            );
          })}
        </VStack>
      </MapCustomScrollbar>
    </Box>
  );
}

export default ZonePointsGrid;
