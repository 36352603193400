import { ReactComponent as CurrentSmallIcon } from '@assets/components/prodecureSteps/current-small.svg';
import { ReactComponent as DoneSmallIcon } from '@assets/components/prodecureSteps/done-small.svg';
import { ReactComponent as NextSmallIcon } from '@assets/components/prodecureSteps/next-small.svg';
import { Box, Center, HStack, Icon } from '@chakra-ui/react';
import { memo } from 'react';

function getIcon(rank: number, currentStepRank: number) {
  if (rank < currentStepRank) {
    return <Icon as={DoneSmallIcon} width="12px" height="12px" />;
  } else if (rank === currentStepRank) {
    return <Icon as={CurrentSmallIcon} width="14px" height="14px" />;
  } else {
    return <Icon as={NextSmallIcon} width="12px" height="12px" />;
  }
}

type Props = {
  nbSteps: number;
  currentStepRank: number;
};

function ProcedureSteps({ nbSteps, currentStepRank }: Readonly<Props>) {
  return (
    <HStack gap="9px" position="relative" cursor="default">
      <Box
        width="calc(100% - 12px)"
        position="absolute"
        height="8px"
        backgroundColor="neutral.900"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
      {Array.from({ length: Math.max(currentStepRank + 1, nbSteps) }, (_, i) => i).map((rank) => (
        <Center key={rank} zIndex={1} display="flex">
          {getIcon(rank, currentStepRank)}
        </Center>
      ))}
    </HStack>
  );
}

export default memo(ProcedureSteps);
