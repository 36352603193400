import { Button, Text, VStack } from '@chakra-ui/react';
import { useUpdateProcedureMutation } from '@services/data/procedure.api';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProcedureStep, ProcedureStepStateEnum } from '@/types/config/procedure.types';

import ProcedureInstructions from './ProcedureInstructions';
import StepActionButtons from './StepActionButtons';

type Props = {
  step: ProcedureStep;
  notificationId: number;
  procedureId: number;
  canManage: boolean;
};

function ConfirmationStep({ step, notificationId, procedureId, canManage }: Readonly<Props>) {
  const [state, setState] = useState<ProcedureStepStateEnum.CONFIRMED | ProcedureStepStateEnum.DENIED | null>(null);
  const [updateProcedure] = useUpdateProcedureMutation();

  if (!canManage) {
    return <ProcedureInstructions instructions={step.instructions} />;
  }

  return (
    <>
      <VStack
        paddingY={5}
        paddingX={6}
        gap={2}
        backgroundColor="neutral.700"
        width="100%"
        flex={step.instructions.length > 0 ? 0 : 1}
      >
        <Button
          variant="toggleButton"
          isActive={state === ProcedureStepStateEnum.CONFIRMED}
          cursor={state === ProcedureStepStateEnum.CONFIRMED ? 'default' : 'pointer'}
          onClick={() => setState(ProcedureStepStateEnum.CONFIRMED)}
        >
          <Text>
            <FormattedMessage id="notification.procedure.step.type.CONFIRMATION.confirm" />
          </Text>
        </Button>
        <Button
          variant="toggleButton"
          isActive={state === ProcedureStepStateEnum.DENIED}
          cursor={state === ProcedureStepStateEnum.DENIED ? 'default' : 'pointer'}
          onClick={() => setState(ProcedureStepStateEnum.DENIED)}
        >
          <Text>
            <FormattedMessage id="notification.procedure.step.type.CONFIRMATION.deny" />
          </Text>
        </Button>
      </VStack>
      <ProcedureInstructions instructions={step.instructions} />
      <StepActionButtons
        isDisabled={state === null}
        isMandatory={step.mandatory}
        notificationId={notificationId}
        procedureId={procedureId}
        stepRank={step.rank}
        onValidate={() => updateProcedure({ notificationId, procedureId, step: step.rank, state: state! }).unwrap()}
      />
    </>
  );
}

export default memo(ConfirmationStep);
