import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectPerimeterCameraLabelFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import { perimeterCameraIconLabelLayer } from './perimeterCamera.layer';

export function PerimeterCameraLabelLayer() {
  const perimeterCameraLabelFeatures = useSelectorWithReplayMode(selectPerimeterCameraLabelFeatures);

  if (!isFeatureCollection(perimeterCameraLabelFeatures)) return null;

  return (
    <Source id={SourceNameEnum.PERIMETER_CAMERA_LABELS} type="geojson" data={perimeterCameraLabelFeatures} generateId>
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_LABELS} {...perimeterCameraIconLabelLayer} />
    </Source>
  );
}
