import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';

export const DATA_URL = import.meta.env.VITE_DATA_URL;

export const dataApi = createApi({
  reducerPath: 'dataApi',
  baseQuery: baseQueryWithReAuth,
  keepUnusedDataFor: 0,
  tagTypes: ['Notification'],
  endpoints: () => ({}),
});
