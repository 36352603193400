import { ReactComponent as HeightIcon } from '@assets/icons/24x24/ic-height-24.svg';
import { ReactComponent as PinIcon } from '@assets/icons/24x24/ic-pin-24.svg';
import { ReactComponent as StatusEllipseIcon } from '@assets/icons/24x24/ic-status-ellipse-24.svg';
import { ReactComponent as StatusLosangeIcon } from '@assets/icons/24x24/ic-status-losange-24.svg';
import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { getAlertLevelColor, isSpaceViolationAlert } from '@utils/data/notification.utils';
import { formatDate } from '@utils/date.utils';
import { formatUnit, HeightUnits } from '@utils/unit.utils';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { AlertType, NotificationTypeEnum } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  alert: AlertType;
  notificationType: NotificationTypeEnum;
  isArchived?: boolean;
};

export default function Alert({ alert, notificationType, isArchived = false }: Readonly<Props>) {
  const color = getAlertLevelColor(alert.level);
  const situationTime = useAppSelector(selectSituationTime) ?? Date.now();

  const endTime = alert.endTime ?? situationTime;
  const formattedStarDate = formatDate(alert.startTime, DateTimeEnum.DATE);
  const formattedEndDate = formatDate(endTime, DateTimeEnum.DATE);
  const formattedStartTime = format(new Date(alert.startTime), "HH':'mm");
  const formattedEndTime = format(new Date(endTime), "HH':'mm");
  const isSameDay = formattedStarDate === formattedEndDate;

  return (
    <HStack gap={1.5} width="100%" padding={3} backgroundColor="neutral.800" alignItems="start">
      <Icon
        as={notificationType === NotificationTypeEnum.DRONE ? StatusLosangeIcon : StatusEllipseIcon}
        width="24px"
        height="24px"
        color={color}
      />
      <Flex gap={isArchived ? 4 : 0.5} width="100%" alignItems="start" flexDirection={isArchived ? 'row' : 'column'}>
        <Text size="lg" color={color}>
          <FormattedMessage id={`alert.level.${alert.level}`} />
        </Text>
        <Text color="neutral.300" fontSize="18px" whiteSpace="pre-line">
          <FormattedMessage
            id={isSameDay ? 'targetInfos.tab.alerts.sameDay' : 'targetInfos.tab.alerts.multipleDays'}
            values={{
              startDate: (
                <Text as="span" fontSize="18px" color="neutral.white">
                  {formattedStarDate}
                </Text>
              ),
              startTime: (
                <Text as="span" fontSize="18px" color="neutral.white">
                  {formattedStartTime}
                </Text>
              ),
              endDate: (
                <Text as="span" fontSize="18px" color="neutral.white">
                  {formattedEndDate}
                </Text>
              ),
              endTime: (
                <Text as="span" fontSize="18px" color="neutral.white">
                  {formattedEndTime}
                </Text>
              ),
            }}
          />
        </Text>
        <HStack width="100%" flex={1} gap={1} alignItems="start">
          {isSpaceViolationAlert(alert) && (
            <>
              <HStack flex={1} gap={1}>
                <Icon as={HeightIcon} width="24px" height="24px" color="neutral.300" />
                <VStack paddingTop={0.25} gap={0.25} alignItems="start">
                  <HStack gap={1}>
                    <Text color="neutral.300" fontSize="16px">
                      <FormattedMessage id="targetInfos.tab.alerts.min" />
                    </Text>
                    <Text color="neutral.white" size="md">
                      {formatUnit(HeightUnits.METERS, alert.heightMin)}
                    </Text>
                  </HStack>
                  <HStack gap={1}>
                    <Text color="neutral.300" fontSize="16px">
                      <FormattedMessage id="targetInfos.tab.alerts.max" />
                    </Text>
                    <Text color="neutral.white" size="md">
                      {formatUnit(HeightUnits.METERS, alert.heightMax)}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
              <HStack flex={1} gap={1}>
                <Icon as={PinIcon} width="24px" height="24px" color="neutral.300" />
                <Text size="md" flex={1}>
                  {alert.zoneName}
                </Text>
              </HStack>
            </>
          )}
        </HStack>
      </Flex>
    </HStack>
  );
}
