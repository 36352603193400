import { Divider, HStack, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasRole } from '@redux/authent/authent.selectors';
import { RootState } from '@redux/store';

import { RoleEnum } from '@/types/authent/roles.types';

import PlatformsButton from './platform/PlatformsButton';

type Props = {
  label: string;
  noPlatform?: boolean;
};

export default function CockpitPanelHeader({ label, noPlatform = false }: Readonly<Props>) {
  const canAccessPlatform = useAppSelector((state: RootState) => hasRole(state, RoleEnum.ACCESS_PLATFORM));

  return (
    <HStack
      height="56px"
      width="100%"
      paddingLeft={2}
      backgroundColor="neutral.800"
      gap={0}
      divider={
        <Divider orientation="vertical" borderWidth="2px" borderColor="neutral.black" marginInline="0 !important" />
      }
    >
      <Text fontWeight="medium" color="neutral.200" textTransform="uppercase" flex={1}>
        {label}
      </Text>
      {canAccessPlatform && !noPlatform && <PlatformsButton />}
    </HStack>
  );
}
