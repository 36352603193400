import { POLYGONS_OPACITY } from '@utils/map/map.constants';
import { SHOW_PERIMETER_CAMERA_MAX_ZOOM, SHOW_PERIMETER_CAMERA_MIN_ZOOM } from '@utils/map/mapZoom.constants';
import { SENSOR_LABEL_TEXT_SIZE } from '@utils/sensors/configuration.constants';
import { FillLayer, LineLayer, SymbolLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';
import { CAMERA_PERIM_LABEL_TEXT_OFFSET_PX } from '@/types/sensor/sensor.types';

export const perimeterCameraSectorFillLayer: FillLayer = {
  id: LayerNameEnum.PERIMETER_CAMERA_SECTOR_FILLS,
  type: 'fill',
  source: SourceNameEnum.PERIMETER_CAMERA_SECTORS,
  minzoom: SHOW_PERIMETER_CAMERA_MIN_ZOOM,
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': POLYGONS_OPACITY,
  },
};

export const perimeterCameraSectorOutlineLayer: LineLayer = {
  id: LayerNameEnum.PERIMETER_CAMERA_SECTOR_OUTLINES,
  type: 'line',
  source: SourceNameEnum.PERIMETER_CAMERA_SECTORS,
  filter: ['==', 'featureType', FeatureTypeEnum.PERIMETER_CAMERA_SECTOR],
  minzoom: SHOW_PERIMETER_CAMERA_MIN_ZOOM,
  paint: {
    'line-color': 'black',
    'line-width': 2,
  },
};

export const perimeterCameraAzimuthLayer: LineLayer = {
  id: LayerNameEnum.PERIMETER_CAMERA_AZIMUTHS,
  type: 'line',
  source: SourceNameEnum.PERIMETER_CAMERA_SECTORS,
  minzoom: SHOW_PERIMETER_CAMERA_MIN_ZOOM,
  paint: {
    'line-color': 'black',
    'line-width': 0.1,
  },
};

export const perimeterCameraIconLabelLayer: SymbolLayer = {
  id: LayerNameEnum.PERIMETER_CAMERA_LABELS,
  type: 'symbol',
  source: SourceNameEnum.PERIMETER_CAMERA_LABELS,
  filter: ['==', ['get', 'featureType'], FeatureTypeEnum.PERIMETER_CAMERA_LABEL],
  minzoom: SHOW_PERIMETER_CAMERA_MAX_ZOOM,
  layout: {
    'icon-image': 'icSymb-label',
    'icon-size': 1.5,
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'text-font': ['roboto'],
    'text-field': ['get', 'textField'],
    'text-size': SENSOR_LABEL_TEXT_SIZE,
    'text-allow-overlap': true,
    'text-offset': CAMERA_PERIM_LABEL_TEXT_OFFSET_PX,
    'symbol-z-order': 'source',
  },
  paint: {
    'text-color': 'white',
  },
};
