import { ReactComponent as ChevronIcon } from '@assets/icons/24x24/ic-chevron-down-24.svg';
import { ReactComponent as ReplayIcon } from '@assets/icons/32x32/ic-replay-32.svg';
import { Box, Center, HStack, Icon, VStack } from '@chakra-ui/react';
import { ShowOnMapButton } from '@components/cockpit/panels/notification/card/ShowOnMapButton';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasAnySensorRoleFromActiveProfile, selectActiveUser } from '@redux/authent/authent.selectors';
import { selectNotificationById } from '@redux/data/data.selectors';
import { centerMapByMapId } from '@redux/maps/maps.reducer';
import { updateReplayStartPosition, updateReplayStartTime } from '@redux/replay/replay.reducer';
import { selectReplayUuid } from '@redux/replay/replay.selectors';
import { selectContext } from '@redux/settings/settings.selectors';
import { usePatchContextMutation } from '@services/config/setting.api';
import { useLazyGetDetailQuery } from '@services/data/notification.api';
import { useUpdateStreamMutation } from '@services/replay/replay.api';
import { getAuthoritiesForNotificationManagement, getNotificationColor } from '@utils/data/notification.utils';
import { CSSProperties, Dispatch, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { AlertType, NotificationType } from '@/types/data/data.types';
import { MapIdEnum } from '@/types/map.types';

import AlertList from '../detail/alert/AlertList';
import NotificationCardDates from './NotificationCardDates';
import NotificationCardInfo from './NotificationCardInfo';
import NotificationCardName from './NotificationCardName';
import NotificationCardProcedure from './NotificationCardProcedure';

type Props =
  | {
      notificationId: number;
      isSelected: boolean;
      setSelectedNotification: Dispatch<number | null>;
      style: CSSProperties;
      isArchived?: false;
      isHistory?: never;
      archivedNotification?: never;
      isLast?: boolean;
    }
  | {
      notificationId?: never;
      isSelected?: never;
      setSelectedNotification?: never;
      style?: CSSProperties;
      isArchived: true;
      isHistory?: boolean;
      archivedNotification: NotificationType;
      isLast?: boolean;
    };

export function NotificationCard({
  notificationId,
  isSelected,
  style,
  isArchived = false,
  isHistory = false,
  isLast = false,
  archivedNotification,
  setSelectedNotification,
}: Readonly<Props>) {
  const centerMapView = useWithDispatch(centerMapByMapId);
  const replayUuid = useAppSelector(selectReplayUuid);
  const [updateStream] = useUpdateStreamMutation();
  const setReplayStartTime = useWithDispatch(updateReplayStartTime);
  const setReplayStartPosition = useWithDispatch(updateReplayStartPosition);
  const currentContext = useAppSelector(selectContext);
  const [patchContext] = usePatchContextMutation();
  const user = useAppSelector(selectActiveUser);

  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const [getDetail, { isLoading }] = useLazyGetDetailQuery();

  const [open, setOpen] = useState<boolean>(false);
  const ongoingNotification = useAppSelector((state) => selectNotificationById(state, notificationId), shallowEqual);
  const notification = isArchived ? archivedNotification : ongoingNotification;
  const canManageNotification = useAppSelector((state) =>
    hasAnySensorRoleFromActiveProfile(state, getAuthoritiesForNotificationManagement(notification)),
  );

  if (!notification) {
    return null;
  }

  const isSelectable = !!setSelectedNotification;
  const isExpired = notification.expiredTime !== null && !isArchived;

  const height = isArchived ? 136 : 130;

  return (
    <VStack
      gap={0}
      height={isHistory ? 'max-content' : `${height}px`}
      minHeight={isHistory ? undefined : `${height}px`}
      width="100%"
      style={style}
      userSelect="none"
      backgroundColor={isSelected ? 'neutral.700' : 'neutral.800'}
    >
      <HStack
        position="relative"
        gap={0}
        width="100%"
        height={`${height}px`}
        borderBottom={isLast ? 'none' : '2px solid'}
        borderColor="neutral.black"
        flexShrink={0}
        cursor={isSelectable ? 'pointer' : 'default'}
        onClick={() => isSelectable && setSelectedNotification(isSelected ? null : notificationId)}
        opacity={isExpired && !isSelected ? 0.5 : 1}
      >
        {isSelected && (
          <Box position="absolute" width="8px" height="100%" backgroundColor={getNotificationColor(notification)} />
        )}
        <NotificationCardName notification={notification} isArchived={isArchived} isSelected={isSelected} />
        <Box height="75%" width="2px" bg="neutral.900" />
        <NotificationCardInfo notification={notification} isArchived={isArchived} />
        <Box height="75%" width="2px" bg="neutral.900" />
        <NotificationCardDates
          startTime={notification.startTime}
          expiredTime={notification.expiredTime}
          type={notification.type}
          isArchived={isArchived}
        />
        {!isArchived ? (
          <>
            <NotificationCardProcedure
              notificationId={notification.id}
              nbSteps={notification.nbSteps}
              currentStepRank={notification.currentStepRank}
              currentStepType={notification.currentStepType}
              procedureState={notification.procedureState}
              canManageNotification={canManageNotification}
            />
            <ShowOnMapButton notificationLight={notification} isExpired={isExpired} />
          </>
        ) : (
          <>
            <Box height="100%" width="2px" bg="neutral.900" />
            <VStack gap={0} width="64px" height="100%">
              <Center
                cursor="pointer"
                role="group"
                height="50%"
                width="100%"
                onClick={(e) => {
                  if (currentContext !== ContextEnum.REPLAY) {
                    setReplayStartTime(notification.startTime);
                    setReplayStartPosition(notification.position);
                    patchContext({ login: user.login, context: ContextEnum.REPLAY });
                  } else {
                    if (replayUuid) {
                      updateStream({
                        uuid: replayUuid,
                        command: { time: new Date(notification.startTime), speed: 0 },
                      });
                    }
                    centerMapView({ mapId: MapIdEnum.REPLAY, position: notification.position, zoom: 14 });
                  }
                  e.stopPropagation();
                }}
              >
                <Icon
                  width="32px"
                  height="32px"
                  as={ReplayIcon}
                  color="neutral.300"
                  _groupHover={{ color: 'var(--chakra-colors-sky-500)' }}
                />
              </Center>
              <Box width="100%" height="2px" bg="neutral.900" />
              <Center
                cursor="pointer"
                role="group"
                height="50%"
                width="100%"
                onClick={(e) => {
                  setOpen(!open);
                  if (!open) {
                    getDetail({ id: notification.id, withArchivedAlerts: true })
                      .unwrap()
                      .then(({ alerts }) => setAlerts(alerts));
                  }
                  e.stopPropagation();
                }}
              >
                <Icon
                  width="24px"
                  height="24px"
                  as={ChevronIcon}
                  transform={open ? 'rotate(180deg)' : undefined}
                  color={open ? 'neutral.white' : 'neutral.300'}
                  _groupHover={{ color: 'var(--chakra-colors-sky-500)' }}
                />
              </Center>
            </VStack>
          </>
        )}
      </HStack>
      {open && (
        <AlertList alerts={alerts} notificationType={notification.type} isLoading={isLoading} isArchived={isArchived} />
      )}
    </VStack>
  );
}
