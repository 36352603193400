import { Box, VStack } from '@chakra-ui/layout';
import { Portal } from '@chakra-ui/react';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import PanelHeader from '@components/common/layout/PanelHeader';
import { memo, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

export type DraggableControlProps = {
  label: string;
  top: number;
  left: number;
  width?: number;
  height?: number;
  offsetX?: number;
  offsetY?: number;
  scrollable?: boolean;
  onClose: () => void;
  children: ReactNode;
  forceTop?: boolean;
};

//Border width * 2
const BORDER_SIZE = 4;

function DraggableControl({
  width = 487,
  offsetX = 100,
  top,
  left,
  label,
  children,
  scrollable = true,
  onClose,
  forceTop,
  ...props
}: Readonly<DraggableControlProps>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const offset = useRef<{ offsetX: number; offsetY: number }>({ offsetX: 0, offsetY: 0 });

  const move = useCallback((e: globalThis.MouseEvent): void => {
    if (ref.current) {
      const totalWidth = ref.current.clientWidth + BORDER_SIZE;
      const totalHeight = ref.current.clientHeight + BORDER_SIZE;
      if (
        e.clientX - offset.current.offsetX >= 0 &&
        e.clientX - offset.current.offsetX + totalWidth <= window.innerWidth
      ) {
        ref.current.style.left = `${e.clientX - offset.current.offsetX}px`;
      } else if (e.clientX - offset.current.offsetX < 0) {
        ref.current.style.left = '0px';
      } else if (e.clientX - offset.current.offsetX + totalWidth > window.innerWidth) {
        ref.current.style.left = `${window.innerWidth - totalWidth}px`;
      }

      if (
        e.clientY - offset.current.offsetY >= 0 &&
        e.clientY - offset.current.offsetY + totalHeight <= window.innerHeight
      ) {
        ref.current.style.top = `${e.clientY - offset.current.offsetY}px`;
      } else if (e.clientY - offset.current.offsetY < 0) {
        ref.current.style.top = '0px';
      } else if (e.clientY - offset.current.offsetY + totalHeight > window.innerHeight) {
        ref.current.style.top = `${window.innerHeight - totalHeight}px`;
      }
    }
  }, []);

  const handleDragStop = useCallback(() => {
    if (ref.current) {
      setIsDragging(false);
      document.removeEventListener('mousemove', move);
    }
  }, [move]);

  useEffect(() => {
    document.addEventListener('mouseup', handleDragStop);
    return () => document.removeEventListener('mouseup', handleDragStop);
  }, [handleDragStop]);

  function handleDragStart(e: MouseEvent) {
    if (ref.current) {
      setIsDragging(true);
      offset.current = {
        offsetX: e.clientX - ref.current.getBoundingClientRect().left,
        offsetY: e.clientY - ref.current.getBoundingClientRect().top,
      };
      document.addEventListener('mousemove', move);
    }
  }

  function getTop() {
    const totalHeight = props.height ? props.height + 68 : ref.current?.getBoundingClientRect().height;
    if (totalHeight === undefined) {
      return undefined;
    }
    const offsetY = props.offsetY ? props.offsetY : -(totalHeight - 68) / 2;
    if (top + offsetY < 0) {
      return 0;
    }
    if (top + offsetY + totalHeight > window.innerHeight) {
      return window.innerHeight - totalHeight;
    }
    return top + offsetY;
  }

  function getLeft() {
    if (left + offsetX < 0) {
      return 0;
    }
    if (left + offsetX + width > window.innerWidth) {
      if (left - width - offsetX >= 0) {
        return left - width - offsetX;
      }
      return left - width / 2;
    }
    return left + offsetX;
  }

  return (
    <Portal>
      <VStack
        gap={0}
        position="absolute"
        width={width}
        boxSizing="border-box"
        border="2px solid"
        borderColor="neutral.black"
        zIndex={3}
        top={forceTop ? top : getTop()}
        left={getLeft()}
        visibility={forceTop || getTop() !== undefined ? 'visible' : 'hidden'}
        ref={ref}
      >
        <PanelHeader
          label={label}
          onClose={onClose}
          dragManagement={{
            onMouseDown: handleDragStart,
            isDragging,
          }}
        />
        {props.height !== undefined && scrollable ? (
          <MapCustomScrollbar height={props.height}>{children}</MapCustomScrollbar>
        ) : (
          <Box width="100%" height={props.height ?? 'max-content'}>
            {children}
          </Box>
        )}
      </VStack>
    </Portal>
  );
}

export default memo(DraggableControl);
