import { Box, VStack } from '@chakra-ui/layout';
import { ButtonGroup } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { MenuItemOption, MenuItemOptionIcons, MenuItemsIcons } from '@/types/menu.types';

import ButtonBar from '../inputs/buttons/ButtonBar';
import MenuList from './MenuList';

interface MenuIconBarProps {
  menuItems: MenuItemsIcons;
  depth: number;
  selectedOptions: string[];
  handleClick: (option: string, value: MenuItemOption | MenuItemOptionIcons | undefined, depth: number) => void;
  isCompOpen?: boolean;
  compToOpen?: ReactElement;
  listButtonWidth?: string;
}

export const MenuIconBar = ({
  menuItems,
  depth,
  selectedOptions,
  handleClick,
  isCompOpen,
  compToOpen,
  listButtonWidth,
}: Readonly<MenuIconBarProps>) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Box position="relative" gap={0} paddingBottom={0.25} paddingTop={0.25}>
        <Box position="absolute" className="button-wrapper" backgroundColor="neutral.black" />
        <ButtonGroup flexDir="column" spacing={0} gap={0.25} width="100%">
          {Object.entries(menuItems).map(([key, value]) => {
            if (value) {
              const isSelectedOption = selectedOptions[depth] === key;
              const { children, onAction, label, icon, isDisabled, isActive } = value;
              return (
                <Box key={key} gap={0} width="100%" position="relative">
                  <ButtonBar
                    label={formatMessage({ id: label })}
                    tooltipPlacement="right"
                    icon={icon}
                    size="md"
                    isActive={(!children && !!onAction && isSelectedOption) || isActive}
                    isSelected={!!children && isSelectedOption}
                    onClick={() => handleClick(key, value, depth)}
                    isDisabled={isDisabled}
                  />
                  {key === selectedOptions[depth] && children && (
                    <VStack position="absolute" left="72px" top="0px" gap={0}>
                      <MenuList
                        menuItems={children}
                        depth={depth + 1}
                        selectedOptions={selectedOptions}
                        handleClick={handleClick}
                        parentLabel={menuItems[selectedOptions[depth]]?.label}
                        buttonWidth={listButtonWidth}
                      />
                    </VStack>
                  )}
                </Box>
              );
            }
          })}
        </ButtonGroup>
      </Box>
      {isCompOpen && compToOpen}
    </>
  );
};
