import { useMapContext } from '@components/map/MapContext';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { SelectedFeatureTypeEnum, updateSelectedFeatureKeyByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId, selectSelectedFeatureKeyByMapId } from '@redux/maps/maps.selectors';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useMap } from 'react-map-gl';

import { Target } from '@/types/c2/c2.types';
import { FeatureTypeEnum, LayerNameEnum, PopupDataTypeEnum, SensorUniqueCodes } from '@/types/map.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import { useAppSelector } from './redux.hooks';
import { useWithDispatch } from './useWithDispatch';

export const useSelectEvent = (layers: LayerNameEnum[]) => {
  const { current: map } = useMap();
  const { mapId } = useMapContext();
  const selectedFeatureKey = useAppSelector((state) => selectSelectedFeatureKeyByMapId(state, mapId));
  const updateSelectedFeatureKey = useWithDispatch(updateSelectedFeatureKeyByMapId);
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const isMeasureActive = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;

  const handleClick = useCallback(
    (event: any) => { // eslint-disable-line
      if (!map || event.features === undefined || isMeasureActive) {
        return;
      }
      const feature = event.features[0];
      const featureType = feature.properties?.featureType;

      if (featureType === FeatureTypeEnum.TARGET_ICON) {
        const target = JSON.parse(feature.properties?.value) as Target<TargetLocation> | undefined;

        target &&
          updateSelectedFeatureKey({
            mapId,
            selectedFeatureKey:
              selectedFeatureKey?.type === SelectedFeatureTypeEnum.TARGET &&
              selectedFeatureKey.selectedTargetId === target.id
                ? null
                : {
                    type: SelectedFeatureTypeEnum.TARGET,
                    selectedTargetId: target.id,
                  },
          });
      } else if ([FeatureTypeEnum.SENSOR_ICON, FeatureTypeEnum.SENSOR_LABEL].includes(featureType)) {
        const sensorUniqueCodes = JSON.parse(feature.properties?.value) as SensorUniqueCodes | undefined;

        sensorUniqueCodes &&
          updateSelectedFeatureKey({
            mapId,
            selectedFeatureKey:
              selectedFeatureKey?.type === SelectedFeatureTypeEnum.SENSOR &&
              _.isEqual(selectedFeatureKey.selectedSensorUniqueCodes, sensorUniqueCodes)
                ? null
                : {
                    type: SelectedFeatureTypeEnum.SENSOR,
                    selectedSensorUniqueCodes: sensorUniqueCodes,
                  },
          });
      }
    },
    [updateSelectedFeatureKey, map, mapId, selectedFeatureKey, isMeasureActive],
  );

  const handleZoomEnd = useCallback(() => {
    if (selectedFeatureKey?.type === SelectedFeatureTypeEnum.SENSOR) {
      updateSelectedFeatureKey({
        mapId,
        selectedFeatureKey: null,
      });
    }
  }, [mapId, selectedFeatureKey, updateSelectedFeatureKey]);

  useMapLayerMouseEvent('click', handleClick, layers);

  useEffect(() => {
    if (!map) return;
    map.on('zoomend', handleZoomEnd);
    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [map, handleZoomEnd]);
};
