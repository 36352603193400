import { MultiPolygon } from 'geojson';

import { Abstract, Coordinates, Modify, WithHeight } from '../commons/commons.types';
import { Drone } from './drone.types';
import { FlySection } from './route.types';

export type FlightPlanCriteria = {
  sites: string[];
  droneCodes?: string[];
  fromDate: string | null;
  toDate: string | null;
  alsoArchived?: boolean;
};

export enum FlightPlanStateEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum FlightPlanStatusEnum {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export enum FlightPlanActivationStateEnum {
  DONE = 'DONE',
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
}

export enum FlightPlanTypeEnum {
  CLASSIC = 'CLASSIC',
  PRECONFIGURED = 'PRECONFIGURED',
}

export interface FlightPlanPortion {
  section: FlySection;
}

export enum ProviderEnum {
  HOLOGARDE = 'HOLOGARDE',
  CLEARANCE = 'CLEARANCE',
  VOLOCOPTERE = 'VOLOCOPTERE',
  UNKNOWN = 'UNKNOWN',
}

export type Activation = {
  id: number;
  startDate: string;
  endDate: string;
  state: FlightPlanActivationStateEnum;
};

export type FlightPlan = {
  id: number;
  site: string;
  code: string;
  drones: Drone[];
  startPosition: Coordinates | null;
  polygons: MultiPolygon;
  startTime: string;
  endTime: string;
  type: FlightPlanTypeEnum;
  state: FlightPlanStateEnum;
  status: FlightPlanStatusEnum;
  activations: Activation[];
  altitudeMax: number | null;
  altitudeMin: number | null;
  activationId: number | null;
  externalId: string | null;
  isProtocol: boolean;
  provider: ProviderEnum;
  origin: FlightPlan | null;
  flyZoneStart: string | null;
  flyZoneEnd: string | null;
  portions: FlightPlanPortion[];
} & Abstract &
  WithHeight;

export type NormalizedFlightPlan = Modify<
  FlightPlan,
  {
    drones: string[];
  }
>;
