import { Divider, HStack, VStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { selectHome } from '@redux/config/config.selectors';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import {
  useCreateInitializationMaskingZoneMutation,
  useUpdateInitializationMaskingZoneMutation,
} from '@services/config/initializationMaskingZone.api';
import { useCreateNoFlyZoneMutation, useUpdateNoFlyZoneMutation } from '@services/config/noFlyZone.api';
import { useCreateNoInitZonesMutation, useUpdateNoInitZonesMutation } from '@services/config/noInitZone.api';
import { isNoFlyZoneType } from '@utils/map/zone.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { Formik, FormikErrors } from 'formik';
import { SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { Coordinates, CoordinatesUnitEnum } from '@/types/commons/commons.types';
import {
  InitializationMaskingZone,
  NoFlyZone,
  NoInitZone,
  Zone2DTypeEnum,
  ZoneCategoryEnum,
  ZoneFormTypeEnum,
  ZoneToEditType,
} from '@/types/config/config.types';
import { ProcedureModel } from '@/types/config/procedure.types';
import { DroneAlertLevelEnum } from '@/types/data/data.types';
import { ZoneFormDisplayedFormatEnum } from '@/types/map.types';

import { zoneSchema } from '../../../../validations/zone.schema';
import DraggableControl from '../../controls/DraggableControl';
import ZoneApiError from './ZoneApiError';
import ZoneCoordinatesForm from './ZoneCoordinatesForm';
import ZoneDetailsForm from './ZoneDetailsForm';

type Props = {
  points: Coordinates[];
  editedZone: ZoneToEditType | undefined;
  zoneType: ZoneFormTypeEnum | undefined;
  onClose: () => void;
};

export interface ZoneForm {
  zoneName: string;
  zoneType: ZoneFormTypeEnum | undefined;
  procedureModel: ProcedureModel | null;
  isZoneActive: boolean;
  displayedFormat: ZoneFormDisplayedFormatEnum;
  upperLevelHeight: number | null;
  lowerLevelHeight: number | null;
  isLowerHeightOnGround: boolean;
  marginSize: number;
}

export default function ZoneFormPopup({ points, editedZone, zoneType, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const validationSchema = useValidation(zoneSchema);
  const userSettingUnit = useAppSelector(selectCoordinatesUnit);
  const activeSite = useAppSelector(selectActiveSite);
  const home = useAppSelector(selectHome);
  const siteAltitude = home ? home.altitude : 0;
  const [unit, setUnit] = useState<CoordinatesUnitEnum>(userSettingUnit);

  const [createNIZ, { isLoading: isLoadingCreateNIZ, isError: isErrorCreateNIZ, reset: resetCreateNIZ }] =
    useCreateNoInitZonesMutation();
  const [createNFZ, { isLoading: isLoadingCreateNFZ, isError: isErrorCreateNFZ, reset: resetCreateNFZ }] =
    useCreateNoFlyZoneMutation();
  const [createIMZ, { isLoading: isLoadingCreateIMZ, isError: isErrorCreateIMZ, reset: resetCreateIMZ }] =
    useCreateInitializationMaskingZoneMutation();
  const isCreateFormLoading = isLoadingCreateNIZ || isLoadingCreateNFZ || isLoadingCreateIMZ;
  const isCreateApiError = isErrorCreateNIZ || isErrorCreateNFZ || isErrorCreateIMZ;

  const isEditing = !!editedZone;

  const [updateNIZ, { isLoading: isLoadingUpdateNIZ, isError: isErrorUpdateNIZ, reset: resetUpdateNIZ }] =
    useUpdateNoInitZonesMutation();
  const [updateNFZ, { isLoading: isLoadingUpdateNFZ, isError: isErrorUpdateNFZ, reset: resetUpdateNFZ }] =
    useUpdateNoFlyZoneMutation();
  const [updateIMZ, { isLoading: isLoadingUpdateIMZ, isError: isErrorUpdateIMZ, reset: resetUpdateIMZ }] =
    useUpdateInitializationMaskingZoneMutation();
  const isUpdateFormLoading = isLoadingUpdateNIZ || isLoadingUpdateNFZ || isLoadingUpdateIMZ;
  const isUpdateApiError = isErrorUpdateNIZ || isErrorUpdateNFZ || isErrorUpdateIMZ;

  const initialZone: ZoneForm = {
    zoneName: isEditing ? editedZone.name : '',
    zoneType: zoneType,
    procedureModel:
      isEditing && zoneType && isNoFlyZoneType(zoneType) ? (editedZone as NoFlyZone).procedureModel : null,
    displayedFormat: ZoneFormDisplayedFormatEnum.HEIGHT,
    marginSize: isEditing && 'marginSize' in editedZone ? editedZone.marginSize : 50,
    isZoneActive: isEditing ? editedZone.active : true,
    upperLevelHeight: isEditing ? (editedZone.altitudeMax ?? editedZone.heightMax ?? null) : 100,
    lowerLevelHeight: isEditing ? (editedZone.altitudeMin ?? editedZone.heightMin ?? null) : 0,
    isLowerHeightOnGround: isEditing ? editedZone.heightMin === 0 : true,
  };

  function handleHeightToAltitudeChange(
    values: ZoneForm,
    setValues: (values: SetStateAction<ZoneForm>, shouldValidate?: boolean) => Promise<void | FormikErrors<ZoneForm>>,
  ) {
    let newValues;

    if (values.displayedFormat === ZoneFormDisplayedFormatEnum.HEIGHT) {
      newValues = {
        ...values,
        upperLevelHeight: (values.upperLevelHeight ?? 0) + siteAltitude,
        lowerLevelHeight: (values.lowerLevelHeight ?? 0) + siteAltitude,
        displayedFormat: ZoneFormDisplayedFormatEnum.ALTITUDE,
      };
    } else {
      newValues = {
        ...values,
        upperLevelHeight: (values.upperLevelHeight ?? 0) - siteAltitude,
        lowerLevelHeight: (values.lowerLevelHeight ?? 0) - siteAltitude,
        displayedFormat: ZoneFormDisplayedFormatEnum.HEIGHT,
      };
    }
    setValues(newValues);
  }

  function zoneFormTypeToAlertLevelEnum(input: Zone2DTypeEnum): DroneAlertLevelEnum | null {
    switch (input) {
      case Zone2DTypeEnum.NFZ_INFO:
        return DroneAlertLevelEnum.DRONE_INFO;

      case Zone2DTypeEnum.NFZ_WARNING:
        return DroneAlertLevelEnum.DRONE_WARNING;

      case Zone2DTypeEnum.NFZ_CRITICAL:
        return DroneAlertLevelEnum.DRONE_CRITICAL;

      default:
        return null;
    }
  }

  function handleSuccess() {
    createToast(
      formatMessage({ id: editedZone ? 'zone.successUpdate' : 'zone.successCreate' }),
      ToastStatusEnum.SUCCESS,
    );
    onClose();
  }

  function submitZone({
    zoneName,
    zoneType,
    procedureModel,
    isZoneActive,
    displayedFormat,
    marginSize,
    upperLevelHeight,
    lowerLevelHeight,
  }: ZoneForm) {
    const dynamicZoneData = {
      name: zoneName,
      polygon: points,
      active: isZoneActive,
      heightMin:
        displayedFormat === ZoneFormDisplayedFormatEnum.HEIGHT
          ? lowerLevelHeight
          : Math.min((lowerLevelHeight ?? 0) - siteAltitude, (upperLevelHeight ?? 0) - siteAltitude),
      heightMax:
        displayedFormat === ZoneFormDisplayedFormatEnum.HEIGHT
          ? upperLevelHeight
          : Math.max((lowerLevelHeight ?? 0) - siteAltitude, (upperLevelHeight ?? 0) - siteAltitude),
    };

    const zoneCreateToSend = {
      ...dynamicZoneData,
      code: uuidv4(),
      site: activeSite !== null ? activeSite.code : 'local',
      minAltitudeFinite: true,
      maxAltitudeFinite: true,
    };

    if (editedZone) {
      switch (zoneType) {
        case Zone2DTypeEnum.NIZ:
          updateNIZ({
            id: editedZone.id,
            niz: {
              ...(editedZone as NoInitZone),
              ...dynamicZoneData,
            },
          })
            .unwrap()
            .then(handleSuccess);
          break;
        case Zone2DTypeEnum.NFZ_INFO:
        case Zone2DTypeEnum.NFZ_WARNING:
        case Zone2DTypeEnum.NFZ_CRITICAL:
          updateNFZ({
            id: editedZone.id,
            nfz: {
              ...(editedZone as NoFlyZone),
              ...dynamicZoneData,
              procedureModel: procedureModel!,
            },
          })
            .unwrap()
            .then(handleSuccess);
          break;
        case Zone2DTypeEnum.IMZ:
          if (marginSize >= 0) {
            updateIMZ({
              id: editedZone.id,
              imz: {
                ...(editedZone as InitializationMaskingZone),
                ...dynamicZoneData,
                marginSize: marginSize,
              },
            })
              .unwrap()
              .then(handleSuccess);
          }
          break;
        default:
          break;
      }
    } else {
      switch (zoneType) {
        case Zone2DTypeEnum.NIZ:
          createNIZ({ ...zoneCreateToSend, type: ZoneCategoryEnum.NIZ })
            .unwrap()
            .then(handleSuccess);
          break;
        case Zone2DTypeEnum.NFZ_INFO:
        case Zone2DTypeEnum.NFZ_WARNING:
        case Zone2DTypeEnum.NFZ_CRITICAL:
          createNFZ({
            ...zoneCreateToSend,
            type: ZoneCategoryEnum.NFZ,
            level: zoneFormTypeToAlertLevelEnum(zoneType)!,
            procedureModel: procedureModel!,
          })
            .unwrap()
            .then(handleSuccess);
          break;
        case Zone2DTypeEnum.IMZ:
          if (marginSize >= 0) {
            createIMZ({
              ...zoneCreateToSend,
              type: ZoneCategoryEnum.IMZ,
              marginSize: marginSize,
            })
              .unwrap()
              .then(handleSuccess);
          }
          break;
        default:
          break;
      }
    }
  }

  return (
    <DraggableControl
      label={formatMessage({ id: 'zone.creation' })}
      width={1061}
      height={737}
      top={400}
      left={400}
      onClose={onClose}
    >
      <ZoneApiError
        isVisible={isCreateApiError || isUpdateApiError}
        onClick={() => {
          if (isErrorCreateNIZ) {
            resetCreateNIZ();
          }
          if (isErrorCreateNFZ) {
            resetCreateNFZ();
          }
          if (isErrorCreateIMZ) {
            resetCreateIMZ();
          }
          if (isErrorUpdateNIZ) {
            resetUpdateNIZ();
          }
          if (isErrorUpdateNFZ) {
            resetUpdateNFZ();
          }
          if (isErrorUpdateIMZ) {
            resetUpdateIMZ();
          }
        }}
      />
      <Formik initialValues={initialZone} onSubmit={submitZone} validationSchema={validationSchema}>
        {({ handleSubmit, errors, touched, dirty, isValid, values, setFieldTouched, setValues, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <HStack height="100%" width="100%" gap={0}>
              <ZoneDetailsForm
                touched={touched}
                errors={errors}
                values={values}
                isZoneTypeDisabled={!!editedZone}
                unit={unit}
                setUnit={setUnit}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                handleHeightToAltitudeChange={() => handleHeightToAltitudeChange(values, setValues)}
              />
              <VStack gap={0} width="100%" height="100%">
                <ZoneCoordinatesForm
                  touched={touched}
                  errors={errors}
                  values={values}
                  unit={unit}
                  points={points}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
                <Divider orientation="horizontal" borderColor="neutral.600" opacity={1} />
                <HStack
                  height="72px"
                  width="100%"
                  flexShrink={0}
                  justifyContent="end"
                  backgroundColor="neutral.800"
                  gap={2}
                  padding={2}
                >
                  <Button type="button" onClick={onClose} variant="formButtonSecondary" width="120px">
                    <FormattedMessage id="global.cancel" />
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={(!editedZone && !dirty) || !isValid || isCreateFormLoading || isUpdateFormLoading}
                    isLoading={isCreateFormLoading || isUpdateFormLoading}
                    variant="formButtonPrimary"
                    width="180px"
                  >
                    <FormattedMessage id={editedZone ? 'zone.submitUpdate' : 'zone.submitCreate'} />
                  </Button>
                </HStack>
              </VStack>
            </HStack>
          </form>
        )}
      </Formik>
    </DraggableControl>
  );
}
