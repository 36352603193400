import { ReactComponent as PinIcon } from '@assets/icons/24x24/ic-pin-24.svg';
import { Center, Icon } from '@chakra-ui/react';
import { useWithDispatch } from '@hooks/useWithDispatch';
import {
  centerMapByMapId,
  SelectedFeatureTypeEnum,
  updatePopupControlByMapIdFromCockpit,
  updateSelectedFeatureKeyByMapId,
} from '@redux/maps/maps.reducer';
import { useLazyGetDetailQuery } from '@services/data/notification.api';
import { useEffect, useState } from 'react';

import { NotificationType, NotificationTypeEnum } from '@/types/data/data.types';
import { ExpiredDroneInfoData, FeatureTypeEnum, MapIdEnum, PopupDataTypeEnum, TargetInfoData } from '@/types/map.types';

type Props = {
  notificationLight: NotificationType;
  isExpired: boolean;
};

export function ShowOnMapButton({ notificationLight, isExpired }: Readonly<Props>) {
  const centerMapView = useWithDispatch(centerMapByMapId);
  const updateSelectedFeatureKey = useWithDispatch(updateSelectedFeatureKeyByMapId);
  const updatePopupControlFromCockpit = useWithDispatch(updatePopupControlByMapIdFromCockpit);
  const [getDetail, { data, isLoading }] = useLazyGetDetailQuery();
  const [openExpired, setOpenExpired] = useState<boolean>(false);

  useEffect(() => {
    if (openExpired && data) {
      const droneNotification = data.notification;
      if (droneNotification.type === NotificationTypeEnum.DRONE) {
        updateSelectedFeatureKey({
          mapId: MapIdEnum.LIVE,
          selectedFeatureKey: {
            type: SelectedFeatureTypeEnum.EXPIRED_DRONE,
            selectedExpiredDroneNotif: droneNotification,
          },
        });
        updatePopupControlFromCockpit({
          mapId: MapIdEnum.LIVE,
          popupControl: {
            type: PopupDataTypeEnum.INFO,
            open: true,
            position: { x: 50, y: 50 },
            data: {
              type: FeatureTypeEnum.EXPIRED_DRONE_ICON,
              notification: notificationLight,
            } as ExpiredDroneInfoData,
          },
        });
      }
      setOpenExpired(false);
    }
  }, [openExpired, data, updateSelectedFeatureKey, updatePopupControlFromCockpit, notificationLight]);

  const handleOpenDroneInfoInLiveMap = () => {
    if (notificationLight.type !== NotificationTypeEnum.DRONE) {
      return;
    }
    if (isExpired) {
      !data && !isLoading && getDetail({ id: notificationLight.id, withArchivedAlerts: false });
      setOpenExpired(true);
    } else {
      updateSelectedFeatureKey({
        mapId: MapIdEnum.LIVE,
        selectedFeatureKey: {
          type: SelectedFeatureTypeEnum.TARGET,
          selectedTargetId: notificationLight.identifier,
        },
      });
      updatePopupControlFromCockpit({
        mapId: MapIdEnum.LIVE,
        popupControl: {
          type: PopupDataTypeEnum.INFO,
          open: true,
          position: { x: 50, y: 50 },
          data: {
            type: FeatureTypeEnum.TARGET_ICON,
            targetId: notificationLight.identifier,
          } as TargetInfoData,
        },
      });
    }
  };

  return (
    <Center
      role="group"
      cursor="pointer"
      width="56px"
      height="100%"
      onClick={(e) => {
        centerMapView({ mapId: MapIdEnum.LIVE, position: notificationLight.position, zoom: 14 });
        notificationLight.type === NotificationTypeEnum.DRONE && handleOpenDroneInfoInLiveMap();
        e.stopPropagation();
      }}
    >
      <Icon
        color="neutral.300"
        as={PinIcon}
        width="24px"
        height="24px"
        _groupHover={{ color: 'var(--chakra-colors-sky-500)' }}
      />
    </Center>
  );
}
