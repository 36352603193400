import { getNotificationColor } from '@utils/data/notification.utils';
import { customAddSubForDate, getFormat } from '@utils/date.utils';
import { isBefore, isWithinInterval } from 'date-fns';

import { NotificationType, NotificationTypeEnum, SegmentAlertLevelEnum } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import {
  CustomDataItem,
  RangeProps,
  ReplayDataGroupEnum,
  ReplayDataStateEnum,
  ReplayTimeScaleEnum,
} from '@/types/replay/replay.types';

const NUMBER_OF_TIMELINE_GRADUATIONS = 10;

export function getNewTimelineEndAndTimeScaleFromRange(range: RangeProps): {
  newEndTime: Date;
  newTimeScale: ReplayTimeScaleEnum;
} {
  const { from, to } = range;

  for (const timeScale of Object.values(ReplayTimeScaleEnum)) {
    const timelineEnd = customAddSubForDate(from, 'add', timeScale, NUMBER_OF_TIMELINE_GRADUATIONS);
    if (isBefore(to, timelineEnd)) {
      return { newEndTime: timelineEnd, newTimeScale: timeScale };
    }
  }

  return {
    newEndTime: customAddSubForDate(from, 'add', ReplayTimeScaleEnum.WEEK, NUMBER_OF_TIMELINE_GRADUATIONS),
    newTimeScale: ReplayTimeScaleEnum.WEEK,
  };
}

export function getNewRangeFromTimeScale(timeScale: ReplayTimeScaleEnum, from: Date, currentTime: Date): RangeProps {
  const timelineEnd = customAddSubForDate(from, 'add', timeScale, NUMBER_OF_TIMELINE_GRADUATIONS);
  if (isWithinInterval(currentTime, { start: from, end: timelineEnd })) {
    return { from: from, to: timelineEnd };
  }

  const newTimelineStart = customAddSubForDate(currentTime, 'sub', timeScale, NUMBER_OF_TIMELINE_GRADUATIONS / 2);
  const newTimelineEnd = customAddSubForDate(currentTime, 'add', timeScale, NUMBER_OF_TIMELINE_GRADUATIONS / 2);
  return { from: newTimelineStart, to: newTimelineEnd };
}

export const minorLabels = {
  minute: getFormat(DateTimeEnum.TIME),
  hour: getFormat(DateTimeEnum.TIME),
  day: getFormat(DateTimeEnum.DATE_TIMELINE),
  week: getFormat(DateTimeEnum.DATE_TIMELINE),
};

export function toCustomDataItemsRanges(
  ranges: { from: string; to: string }[],
  group: ReplayDataGroupEnum,
  dataState: ReplayDataStateEnum,
): CustomDataItem[] {
  return ranges.map((range) => ({
    group: group,
    start: range.from,
    end: range.to,
    className: dataState,
    type: 'range',
  }));
}

function getNotificationIconStyle(notification: NotificationType): string {
  const iconColor = getNotificationColor(notification);
  let iconSource;
  switch (notification.type) {
    case NotificationTypeEnum.SEGMENT:
      iconSource = '/images/icSign-intrusion-24.svg';
      break;
    case NotificationTypeEnum.DRONE:
    default:
      iconSource = '/images/icSign-drone-24.svg';
      break;
  }
  return `width: 24px !important; height: 24px !important; background-color: ${iconColor}; -webkit-mask: url(${iconSource}) center / contain no-repeat; mask: url(${iconSource}) center / contain no-repeat;`;
}

export function toCustomDataItemNotifications(notifications: NotificationType[]): CustomDataItem[] {
  return notifications
    .filter(
      (notification) =>
        notification.type === NotificationTypeEnum.DRONE ||
        notification.worstAlertLevel === SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
    )
    .map((notification) => ({
      id: `notification-${notification.identifier}`,
      className: '',
      group: ReplayDataGroupEnum.SITAC,
      start: notification.startTime,
      type: 'point',
      style: getNotificationIconStyle(notification),
    }));
}

export function addDateToSourceReplayUrl(sourceReplayUrl: string, validReplayDate: string): string {
  const url = new URL(sourceReplayUrl);
  url.searchParams.delete('range');
  url.searchParams.append('range', validReplayDate);
  return decodeURIComponent(url.toString());
}

export function isValidUrl(url: string | null): boolean {
  if (!url) {
    return false;
  }
  try {
    new URL(url);
    return true;
  } catch (_) {
    console.error(`Malformed sourceReplayUrl: ${url}`);
    return false;
  }
}
