import { Text, VStack } from '@chakra-ui/layout';
import CustomSelect from '@components/common/inputs/CustomSelect';
import SideBySideSelect, { ElementInfo2 } from '@components/common/layout/SideBySideSelect';
import { useAppSelector } from '@hooks/redux.hooks';
import {
  RoleSelectors,
  selectActiveProfile,
  selectDenormalizedProfileById,
  selectDenormalizedProfilesWithoutRestricted,
} from '@redux/authent/authent.selectors';
import { RootState } from '@redux/store';
import { useUpdateProfileMutation } from '@services/authent/profile.api';
import { isBasicRole, isNotTechnical } from '@utils/authent/roles.utils';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Role } from '@/types/authent/roles.types';

type Props = {
  initiallySelectedProfileId: number | undefined | null;
};

export default function ManageProfiles({ initiallySelectedProfileId }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const profiles = useAppSelector(selectDenormalizedProfilesWithoutRestricted);
  const roles = useAppSelector(RoleSelectors.selectAllRoles);
  const activeProfile = useAppSelector(selectActiveProfile);

  const [selectedProfileId, setSelectedProfileId] = useState(initiallySelectedProfileId ?? (profiles[0]?.id || null));
  const [previousInitialProfileId, setPreviousInitialProfileId] = useState(initiallySelectedProfileId);

  const selectedProfile =
    useAppSelector((state: RootState) =>
      selectedProfileId !== null ? selectDenormalizedProfileById(state, selectedProfileId) : null,
    ) ?? null;

  const leftTitle = formatMessage({ id: 'components.admin.profiles.currentRoles' });
  const rightTitle = formatMessage({ id: 'components.admin.profiles.availableRoles' });
  const currentRolesSet = new Set(selectedProfile?.roles.map((role) => role.roleName) ?? []);
  const isDisabled = selectedProfile && activeProfile!.id === selectedProfile.id;
  const elementInfos: ElementInfo2<Role>[] = selectedProfileId
    ? roles.filter(isNotTechnical).map((role) => ({
        element: role,
        status: isBasicRole(role) || isDisabled ? 'unselectable' : 'normal',
        side: currentRolesSet.has(role.roleName) ? 'left' : 'right',
      }))
    : [];

  useEffect(() => {
    if (initiallySelectedProfileId !== previousInitialProfileId) {
      setPreviousInitialProfileId(initiallySelectedProfileId);
      setSelectedProfileId(profiles[0]?.id ?? null);
    }
  }, [initiallySelectedProfileId, previousInitialProfileId, profiles]);

  function handleRoleChange(elementInfos: ElementInfo2<Role>[]) {
    if (selectedProfile) {
      updateProfile({
        ...selectedProfile,
        roles: elementInfos.map((elementInfo) => elementInfo.element.roleName),
      });
    }
  }

  return (
    <VStack alignItems="start" gap={2}>
      <Text color="neutral.black">
        <FormattedMessage id="components.admin.profiles.manageProfiles" />
      </Text>
      <Text fontWeight="600" fontSize="17px" color="neutral.black" gap={0}>
        <FormattedMessage id="authent.user.profile" />
      </Text>
      <CustomSelect
        style={{ width: '656px', height: 10 }}
        isClearable={false}
        isDisabled={profiles.length === 0}
        placeholder={formatMessage({ id: 'components.admin.profiles.noProfileToEdit' })}
        value={selectedProfileId?.toString() ?? undefined}
        options={profiles.map((profile) => ({ value: profile.id.toString(), label: profile.name }))}
        onChange={(profileId) =>
          setSelectedProfileId(profiles.find((profile) => profile.id === parseInt(profileId))?.id ?? null)
        }
      />
      <SideBySideSelect<Role>
        leftTitle={leftTitle}
        rightTitle={rightTitle}
        elementInfos={elementInfos}
        getId={(role) => role.roleName}
        getLabel={(role) => role.roleName}
        isLoading={isLoading}
        onChange={handleRoleChange}
      />
      {isDisabled ? (
        <Text color="neutral.black">
          <FormattedMessage id="components.admin.profiles.noModifActiveProfile" />
        </Text>
      ) : (
        <></>
      )}
    </VStack>
  );
}
