import { Box } from '@chakra-ui/react';
import MenuList from '@components/common/menu/MenuList';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useCameraCommand } from '@hooks/useCameraCommand';
import { MeasureOriginEnum, useCommonContextMenuItems } from '@hooks/useCommonContextMenuItems';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasRole, hasSensorRoleFromActiveProfile } from '@redux/authent/authent.selectors';
import { updateSelectedCameraCode } from '@redux/global/global.reducer';
import { updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { MapSelectors } from '@redux/maps/maps.selectors';
import { selectHealthyCameraStatuses } from '@redux/situation/monitoring.selectors';
import { getContextMenuCameraLabel } from '@utils/sensors/camera/camera.utils';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { toCoordinates } from '@utils/validation/coordinates.utils';
import { LngLat } from 'mapbox-gl';
import { useCallback } from 'react';

import { RoleEnum } from '@/types/authent/roles.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { EventPopupData, PopupDataTypeEnum } from '@/types/map.types';
import { MenuItems } from '@/types/menu.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';
import { CameraStatus } from '@/types/sensor/status.types';

export type Props = {
  clickPosition: { x: number; y: number } | null;
  position: LngLat | undefined;
  onClose: () => void;
};

function DefaultContextMenu({ clickPosition, position, onClose }: Readonly<Props>) {
  const { mapId } = useMapContext();
  const mapState = useAppSelector((state) => MapSelectors.selectMapById(state, mapId))!;
  const accessCamCommand = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.COMMAND, sensorFamily: SensorFamilyEnum.CAMERA }),
  );
  const canResetSituation = useAppSelector((state) => hasRole(state, RoleEnum.ADMIN));
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const healthyLadCameras = useAppSelector(selectHealthyCameraStatuses);
  const updateGlobalSelectedCameraCode = useWithDispatch(updateSelectedCameraCode);

  const useCamera = useCameraCommand({ cameraStatus: null });

  const handleCreateEventClick = useCallback(() => {
    updatePopupControl({
      mapId: mapId,
      popupControl: {
        type: PopupDataTypeEnum.EVENT,
        open: true,
        position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
        data: {
          coordinates: toCoordinates(position),
          date: new Date().toISOString(),
        } as EventPopupData,
      },
    });
    onClose();
  }, [clickPosition?.x, clickPosition?.y, mapId, onClose, position, updatePopupControl]);

  function handleLookAt(cameraStatus: CameraStatus) {
    if (position) {
      useCamera.lookAt(
        {
          latitude: position.lat,
          longitude: position.lng,
          type: '2D',
        },
        null,
        null,
        null,
        null,
        true,
        cameraStatus,
      );
      updateGlobalSelectedCameraCode(getSensorUniqueCode(cameraStatus));
      onClose();
    }
  }

  function openGpsQrCodePopup() {
    if (position) {
      updatePopupControl({
        mapId,
        popupControl: {
          type: PopupDataTypeEnum.GPS_QR_CODE,
          data: {
            coordinates: { latitude: position.lat, longitude: position.lng, type: '2D' },
          },
          open: true,
          position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
        },
      });
      onClose();
    }
  }

  const handleResetSituationClick = useCallback(() => {
    updatePopupControl({
      mapId: mapId,
      popupControl: {
        type: PopupDataTypeEnum.RESET_SITUATION,
        open: true,
        position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
        data: null,
      },
    });
    onClose();
  }, [clickPosition?.x, clickPosition?.y, mapId, onClose, updatePopupControl]);

  const commonMenuItems = useCommonContextMenuItems({
    closeContextMenu: onClose,
    position: toCoordinates(position),
    measureOrigin: MeasureOriginEnum.POINT,
  });
  const menuItems: MenuItems = {
    createEvent: {
      label: 'contextmenu.actions.createEvent',
      isDisabled: mapState.popupControl.open && mapState.popupControl.type === PopupDataTypeEnum.EVENT,
      onAction: handleCreateEventClick,
    },
    pointCUAS: {
      label: 'contextmenu.actions.pointCamera',
      isHidden: !accessCamCommand,
      isDisabled: healthyLadCameras.length === 0 || !position,
      children: healthyLadCameras.reduce(
        (acc, current) => ({
          ...acc,
          [getSensorUniqueCode(current)]: {
            label: getContextMenuCameraLabel(current),
            shouldNotTranslate: true,
            onAction: () => handleLookAt(current),
          },
        }),
        {},
      ),
    },
    displayPositionQrCode: {
      label: 'contextmenu.actions.target.broadcastTarget.displayPositionQrCode',
      isDisabled: !position,
      onAction: openGpsQrCodePopup,
    },
    resetSituation: {
      label: 'contextmenu.actions.resetSituation',
      isHidden: !canResetSituation,
      onAction: handleResetSituationClick,
    },
    ...commonMenuItems,
  };

  return (
    <Box position="absolute" zIndex={4} top={clickPosition?.y} left={clickPosition?.x}>
      <MenuList menuItems={menuItems} depth={0} />
    </Box>
  );
}

export default DefaultContextMenu;
