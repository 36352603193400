import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
import { AutoDismissToast } from '@components/common/AutoDismissToast';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectSensorRolesFromActiveProfile } from '@redux/authent/authent.selectors';
import { selectAllOngoingNotifications } from '@redux/data/data.selectors';
import { deleteNotificationFromQueue } from '@redux/global/global.reducer';
import { selectNotificationQueue } from '@redux/global/global.selector';
import { canManageNotification } from '@utils/data/notification.utils';
import { defaultToastConfig, notificationToastConfig } from '@utils/toast.utils';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { ProcedureStateEnum } from '@/types/config/procedure.types';

import NotificationToast from './NotificationToast';

export function ToastNotificationContainer() {
  const notificationQueue = useAppSelector(selectNotificationQueue);
  const notifications = useAppSelector(selectAllOngoingNotifications);
  const removeNotificationFromQueue = useWithDispatch(deleteNotificationFromQueue);
  const toastIds = useRef<number[]>([]);
  const toast = useToast();
  const userActions = useAppSelector(selectSensorRolesFromActiveProfile);

  const createToast = useCallback(
    (id: string | number, element: ReactNode, options: UseToastOptions) => {
      toast({
        ...options,
        id,
        render: () => element,
      });
    },
    [toast],
  );

  const handleToastClose = useCallback(
    (id: ToastId) => {
      toast.close(id);
    },
    [toast],
  );

  useEffect(() => {
    //If queue is not empty and max number of toast is not reached
    //create a toast with the next alert in queue and remove it from the queue
    if (notificationQueue.length > 0 && toastIds.current.length < 10) {
      const notification = notificationQueue[0];
      const canManage = canManageNotification(userActions, notification);
      createToast(
        notification.id,
        <AutoDismissToast key={notification.id} onClose={() => handleToastClose(notification.id)}>
          <NotificationToast
            notification={notification}
            onClose={() => {
              toast.close(notification.id);
              toastIds.current = toastIds.current.filter((id) => id !== notification.id);
            }}
          />
        </AutoDismissToast>,
        canManage ? notificationToastConfig : defaultToastConfig,
      );
      toastIds.current = toastIds.current.concat(notification.id);
      removeNotificationFromQueue(notification);
    }
  }, [notificationQueue, createToast, removeNotificationFromQueue, toast, userActions, handleToastClose]);

  const cleanUpToasts = useCallback(() => {
    toastIds.current = toastIds.current.filter((id) => {
      const notification = notifications.find((n) => n.id === id);
      if (notification?.procedureState === ProcedureStateEnum.TO_PROCESS) {
        return true;
      }
      toast.close(id);
      return false;
    });
  }, [notifications, toast]);

  useEffect(() => {
    const interval = setInterval(cleanUpToasts, 5000);

    return () => clearInterval(interval);
  }, [cleanUpToasts, notifications, toast]);

  useEffect(() => {
    return () => {
      toastIds.current.forEach((id) => toast.close(id));
    };
  }, [toast]);

  return null;
}
