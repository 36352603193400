import { ReactComponent as AltitudeIcon } from '@assets/icons/20x20/ic-altitude-20.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/24x24/ic-arrowTriangle-24.svg';
import { Box, HStack, Icon, TabPanel, Text, VStack } from '@chakra-ui/react';
import { formatDate } from '@utils/date.utils';
import { formatUnit, HeightUnits } from '@utils/unit.utils';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import { DateTimeEnum } from '@/types/dateTime.types';
import { ProviderEnum } from '@/types/utm/flightPlan.types';

import InfoValue from '../InfoValue';

type Props = {
  startDate?: string;
  endDate?: string;
  maxAltitude: number | null;
  mission: string;
  missionStartTime: string;
  missionEndTime: string;
  information: string[];
  provider: ProviderEnum;
};

export default function FlightPlanDetails({
  startDate,
  endDate,
  maxAltitude,
  mission,
  missionStartTime,
  missionEndTime,
  information,
  provider,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const formattedStarDate = startDate ? formatDate(startDate, DateTimeEnum.DATE) : null;
  const formattedEndDate = endDate ? formatDate(endDate, DateTimeEnum.DATE) : null;
  const formattedStartTime = startDate ? format(new Date(startDate), "HH':'mm") : null;
  const formattedEndTime = endDate ? format(new Date(endDate), "HH':'mm") : null;

  const isSameDay = formattedStarDate === formattedEndDate;

  const formattedMissionStartDate = formatDate(missionStartTime, DateTimeEnum.DATE);
  const formattedMissionEndDate = formatDate(missionEndTime, DateTimeEnum.DATE);
  const formattedMissionStartTime = format(new Date(missionStartTime), "HH':'mm");
  const formattedMissionEndTime = format(new Date(missionEndTime), "HH':'mm");

  const isSameDayMission = formattedMissionStartDate === formattedMissionEndDate;

  return (
    <TabPanel height="100%">
      <VStack width="100%" height="100%" gap={0.25}>
        <HStack width="100%" padding={4} backgroundColor="neutral.700" gap={3} alignItems="start">
          {startDate && endDate && (
            <InfoValue
              label={formatMessage({ id: 'flightPlanInfos.tab.details.date' })}
              value={
                <Text color="neutral.300" fontSize="18px" whiteSpace="pre-line">
                  <FormattedMessage
                    id={isSameDay ? 'flightPlanInfos.tab.details.sameDay' : 'flightPlanInfos.tab.details.multipleDays'}
                    values={{
                      startDate: (
                        <Text as="span" key="1" fontSize="18px" color="neutral.white">
                          {formattedStarDate}
                        </Text>
                      ),
                      startTime: (
                        <Text as="span" key="2" fontSize="18px" color="neutral.white">
                          {formattedStartTime}
                        </Text>
                      ),
                      endDate: (
                        <Text as="span" key="3" fontSize="18px" color="neutral.white">
                          {formattedEndDate}
                        </Text>
                      ),
                      endTime: (
                        <Text as="span" key="4" fontSize="18px" color="neutral.white">
                          {formattedEndTime}
                        </Text>
                      ),
                    }}
                  />
                </Text>
              }
            />
          )}
          <InfoValue
            label={formatMessage({ id: 'flightPlanInfos.tab.details.maxAltitude' })}
            isEmpty={maxAltitude === null}
            value={
              <HStack gap={1} width="100%" alignItems="start">
                <Icon as={AltitudeIcon} width="24px" height="24px" color="neutral.300" />
                <Text fontSize="18px">
                  <FormattedMessage
                    id="flightPlanInfos.tab.details.maxAltitudeValue"
                    values={{ maxAltitude: formatUnit(HeightUnits.METERS, maxAltitude) }}
                  />
                </Text>
              </HStack>
            }
          />
        </HStack>
        <HStack width="100%" padding={4} backgroundColor="neutral.700" gap={3}>
          <InfoValue label={formatMessage({ id: 'flightPlanInfos.tab.details.mission' })} value={mission} />
          <InfoValue label={formatMessage({ id: 'flightPlanInfos.tab.details.missionProvider' })} value={provider} />
        </HStack>
        <Box width="100%" padding={4} backgroundColor="neutral.700">
          <InfoValue
            label={formatMessage({ id: 'flightPlanInfos.tab.details.missionDate' })}
            value={
              <Text color="neutral.300" fontSize="18px" whiteSpace="pre-line">
                <FormattedMessage
                  id={
                    isSameDayMission
                      ? 'flightPlanInfos.tab.details.sameDay'
                      : 'flightPlanInfos.tab.details.multipleDays'
                  }
                  values={{
                    startDate: (
                      <Text as="span" key="1" fontSize="18px" color="neutral.white">
                        {formattedMissionStartDate}
                      </Text>
                    ),
                    startTime: (
                      <Text as="span" key="2" fontSize="18px" color="neutral.white">
                        {formattedMissionStartTime}
                      </Text>
                    ),
                    endDate: (
                      <Text as="span" key="3" fontSize="18px" color="neutral.white">
                        {formattedMissionEndDate}
                      </Text>
                    ),
                    endTime: (
                      <Text as="span" key="4" fontSize="18px" color="neutral.white">
                        {formattedMissionEndTime}
                      </Text>
                    ),
                  }}
                />
              </Text>
            }
          />
        </Box>
        <Box width="100%" padding={4} backgroundColor="neutral.700" flex={1}>
          <InfoValue
            label={formatMessage({ id: 'flightPlanInfos.tab.details.information' })}
            value={
              information.length === 0 ? (
                <Text size="lg">
                  <FormattedMessage id="flightPlanInfos.tab.details.noInformation" />
                </Text>
              ) : (
                <VStack width="100%" gap={3} alignItems="start">
                  {information.map((information, index) => (
                    <HStack gap={1} width="100%" key={`${information}-${index}`} align="start">
                      <Icon as={ArrowIcon} width="24px" height="24px" color="neutral.300" />
                      <Text>{information}</Text>
                    </HStack>
                  ))}
                </VStack>
              )
            }
          />
        </Box>
      </VStack>
    </TabPanel>
  );
}
