import { ReactComponent as AirportIcon } from '@assets/icons/24x24/ic-airport-24.svg';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { usePlatformsPanelContext } from '@components/cockpit/Cockpit';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectAllActiveNotProcessedNotificationsCount } from '@redux/data/data.selectors';

export default function PlatformsButton() {
  const { setIsPlatformsPanelOpen } = usePlatformsPanelContext();
  const notProcessedNotificationCount = useAppSelector(selectAllActiveNotProcessedNotificationsCount);

  return (
    <HStack
      height="56px"
      paddingX={2}
      paddingY={2}
      gap={1}
      cursor="pointer"
      role="group"
      backgroundColor="neutral.700"
      onClick={() => setIsPlatformsPanelOpen(true)}
    >
      <Icon as={AirportIcon} width="24px" height="24px" color="neutral.300" _groupHover={{ color: 'sky.500' }} />
      {notProcessedNotificationCount !== 0 && (
        <Text
          paddingX={0.25}
          fontSize="12px"
          fontWeight="medium"
          backgroundColor="alertHi.500"
          height="16px"
          minWidth="20px"
          textAlign="center"
        >
          {notProcessedNotificationCount}
        </Text>
      )}
    </HStack>
  );
}
