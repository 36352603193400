import { AVAILABLE_LANGUAGES, ControlledCamera, GlobalState } from '@redux/global/global.reducer';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';

import { HoloLog } from '@/types/c2/logs.type';
import { SubCameraConfiguration } from '@/types/sensor/configuration.types';

import { logsAdapter, notificationAdapter } from './global.adapters';

function selectGlobal(state: RootState): GlobalState {
  return state.global;
}

export const selectLocaleValue = createSelector(
  selectGlobal,
  (state: GlobalState): AVAILABLE_LANGUAGES => state.locale,
);

export const selectSelectedCameraCode = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.selectedCameraCode,
);

export const selectJoystickControlId = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.joystickControlId,
);

export const selectSelectedDoubtCheckSegmentName = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.selectedDoubtCheckSegmentName,
);

export const selectSelectedDoubtCheckPerimeterCameraUniqueKey = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.selectedDoubtCheckPerimeterCameraUniqueKey,
);

export const selectJoystickControlledCam = createSelector(
  selectGlobal,
  (state: GlobalState): ControlledCamera => state.joystickControlledCam,
);

export const { selectAll: selectNotificationQueue } = notificationAdapter.getSelectors(
  (state: RootState) => selectGlobal(state).notificationQueue,
);

export const selectNewLogBookEntry = createSelector(
  selectGlobal,
  (state: GlobalState): boolean => state.newLogBookEntry,
);

export const selectSelectedCameraStreams = createSelector(
  selectGlobal,
  (state: GlobalState): SubCameraConfiguration[] => state.selectedCameraStreams,
);

export const { selectAll: selectAllLogs } = logsAdapter.getSelectors((state: RootState) => state.global.logs);

export const selectLogByCode = logsAdapter.getSelectors((state: RootState) => state.global.logs).selectById as (
  state: RootState,
  id: string,
) => HoloLog | undefined;

export const selectIsInactiveAt = createSelector(selectGlobal, (state: GlobalState): Date | null => state.isInactiveAt);

export const selectCurrentWindowId = createSelector(
  selectGlobal,
  (state: GlobalState): string => state.currentWindowId,
);
