import { HStack, Text } from '@chakra-ui/react';
import CustomTitle from '@components/common/CustomTitle';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectContext } from '@redux/settings/settings.selectors';
import { FormattedMessage } from 'react-intl';

import CockpitTime from './CockpitTime';
import NotificationSounds from './panels/notification/NotificationSounds';

export default function CockpitTitle() {
  const currentContext = useAppSelector(selectContext);

  return (
    <CustomTitle>
      <HStack gap={2}>
        <Text color="neutral.200" textTransform="uppercase">
          <FormattedMessage id={`screenConfiguration.context.${currentContext}`} />
        </Text>
        <CockpitTime />
        <NotificationSounds />
      </HStack>
    </CustomTitle>
  );
}
