import { ReactComponent as ArrowLeft } from '@assets/icons/cameras/gauge-arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/cameras/gauge-arrow-right.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { IconButton, Text } from '@chakra-ui/react';
import CustomSelect from '@components/common/inputs/CustomStyledSelect';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser, selectDenormalizedGroups, selectDenormalizedUser } from '@redux/authent/authent.selectors';
import { ADMIN } from '@services/authent/authent.api';
import { usePatchGroupsMutation } from '@services/authent/user.api';
import { isRestrictedGroup } from '@utils/authent/profiles.utils';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { User } from '@/types/authent/login.types';

type Props = {
  users: User[];
};
export default function GroupSelect({ users }: Readonly<Props>) {
  const groups = useAppSelector(selectDenormalizedGroups);
  const activeUser = useAppSelector(selectActiveUser);
  const [selectedGroupCode, setSelectedGroupCode] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number>(activeUser.id);
  const selectedUser = useAppSelector((state) => selectDenormalizedUser(state, selectedUserId));
  const otherGroups = groups.filter(
    (g) => !isRestrictedGroup(g) && !selectedUser?.groups.some((gl) => gl.code === g.code),
  );
  const [updateUserGroups] = usePatchGroupsMutation();

  const addGroupToUser = () => {
    const groupIdToAdd = otherGroups.find((group) => group.code === selectedGroupCode)?.id;
    if (selectedUser && groupIdToAdd) {
      const newUserGroupsId: number[] = selectedUser.groups.map((group) => group.id).concat(groupIdToAdd);
      updateUserGroups({
        id: selectedUser.id,
        groupsId: newUserGroupsId,
      });
    }
  };

  const withdrawGroupFromUser = () => {
    if (selectedUser) {
      updateUserGroups({
        id: selectedUser.id,
        groupsId: selectedUser.groups.filter((group) => group.code !== selectedGroupCode).map((group) => group.id),
      });
    }
  };

  return (
    <VStack gap={3} alignItems="start">
      <Text color="neutral.black">
        <FormattedMessage id="components.admin.users.subTitle" />
      </Text>
      <CustomSelect
        options={users.map((user) => ({
          value: user.id.toString(),
          label: `${user.name} - ${user.firstName}`,
        }))}
        onChange={(value) => setSelectedUserId(Number(value))}
        value={selectedUser?.id.toString()}
        isClearable={false}
        width={400}
      />
      <HStack alignItems="start">
        <VStack
          width="300px"
          border="1px solid"
          padding={0.5}
          alignItems="start"
          gap={0.25}
          height="180px"
          overflowY="auto"
        >
          {selectedUser?.groups.map((group) => {
            const isDisabled = selectedUser.activeGroup?.code === group.code || selectedUser.login === ADMIN;
            return (
              <Box
                key={group.code}
                pointerEvents="all"
                cursor={isDisabled ? 'default' : 'pointer'}
                onClick={() => {
                  !isDisabled && setSelectedGroupCode((prev) => (prev === group.code ? '' : group.code));
                }}
              >
                <Text
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  color={isDisabled ? 'neutral.300' : 'neutral.black'}
                  fontWeight={selectedGroupCode && selectedGroupCode === group.code ? '700' : 'regular'}
                  paddingLeft={2}
                  paddingRight={2}
                >
                  {`${group.name.toUpperCase()} ${group.name}`}
                </Text>
              </Box>
            );
          })}
        </VStack>
        <VStack height="200px">
          <IconButton
            isDisabled={
              !selectedUserId ||
              !selectedGroupCode ||
              selectedGroupCode === selectedUser?.activeGroup?.code ||
              !selectedUser?.groups.some((group) => group.code === selectedGroupCode)
            }
            aria-label={'Right Arrow Icon'}
            icon={<ArrowRight />}
            onClick={withdrawGroupFromUser}
          />
          <IconButton
            isDisabled={
              !selectedUserId || !selectedGroupCode || !otherGroups.some((group) => group.code === selectedGroupCode)
            }
            aria-label={'Left Arrow Icon'}
            icon={<ArrowLeft />}
            onClick={addGroupToUser}
          />
        </VStack>
        <VStack
          width="300px"
          border="1px solid"
          padding={0.5}
          alignItems="start"
          gap={0.25}
          height="180px"
          overflowY="auto"
        >
          {otherGroups.length > 0 &&
            otherGroups.map((group) => (
              <Box
                key={group.code}
                pointerEvents="all"
                cursor="pointer"
                onClick={() => {
                  setSelectedGroupCode((prev) => (prev === group.code ? '' : group.code));
                }}
              >
                <Text
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  color="neutral.black"
                  fontWeight={selectedGroupCode && selectedGroupCode === group.code ? '700' : 'regular'}
                  paddingLeft={2}
                  paddingRight={2}
                >
                  {`${group.name.toUpperCase()} ${group.name}`}
                </Text>
              </Box>
            ))}
        </VStack>
      </HStack>
    </VStack>
  );
}
