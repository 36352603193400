import {
  groupsAdapter,
  profilesAdapter,
  rolesAdapter,
  sitesAdapter,
  usersAdapter,
} from '@redux/authent/authent.adapters';
import { createSelector, EntityId } from '@reduxjs/toolkit';
import { ADMIN } from '@services/authent/authent.api';
import { isRestrictedProfile } from '@utils/authent/profiles.utils';
import { selectParameter, stringPropertyComparator } from '@utils/common.utils';
import { TypeEnum } from '@utils/sensors/configuration.constants';
import { denormalize } from 'normalizr';

import { DenormalizedProfile, Group, GroupLight, GroupWithUsers, Profile } from '@/types/authent/groups.types';
import { NormalizedUser, User } from '@/types/authent/login.types';
import { RoleEnum } from '@/types/authent/roles.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { SensorAction, SensorFamilyEnum } from '@/types/sensor/sensor.types';

import { RootState } from '../store';
import { AuthentState } from './authent.reducer';
import { fullProfileSchema, groupSchema, userSchema } from './authent.schema';

function selectAuthentState(state: RootState): AuthentState {
  return state.authent;
}

export const selectToken = createSelector(selectAuthentState, (state: AuthentState): string | null => state.token);

export const selectRenewalToken = createSelector(
  selectAuthentState,
  (state: AuthentState): string | null => state.renewalToken,
);

export const selectSessionUuid = createSelector(
  selectAuthentState,
  (state: AuthentState): string | null => state.sessionUuid,
);

export const selectHasBeenInitialized = createSelector(
  selectAuthentState,
  (state: AuthentState): boolean => state.hasBeenInitialized,
);

export const selectHasSelectedGroup = createSelector(
  selectAuthentState,
  (state: AuthentState): boolean => state.hasSelectedGroup,
);

export const selectIsChangingShift = createSelector(
  selectAuthentState,
  (state: AuthentState): boolean => state.isChangingShift,
);

export const selectHasToLogout = createSelector(
  selectAuthentState,
  (state: AuthentState): boolean => state.hasToLogout,
);

export const selectActiveUser = createSelector(selectAuthentState, (state: AuthentState): User => state.activeUser);

export const selectActiveSite = createSelector(selectActiveUser, (state: User) => state.activeSite);

export const selectUserSites = createSelector(selectActiveUser, (state: User) => state.sites);

export const selectUserGroups = createSelector(selectActiveUser, (state: User): GroupLight[] => state.groups);

export const selectActiveGroup = createSelector(selectActiveUser, (state: User): Group | null => state.activeGroup);

export const selectActiveProfile = createSelector(
  selectActiveGroup,
  (state: Group | null): Profile | null => state?.profile ?? null,
);

export const selectRolesFromActiveProfile = createSelector(selectActiveProfile, (profile: Profile | null) => {
  if (profile === null) {
    return null;
  }
  return new Set(profile.roles);
});

export const hasAnyRoles = createSelector(
  [selectRolesFromActiveProfile, selectParameter<RoleEnum[]>],
  (roleSet: Set<RoleEnum> | null, roles): boolean => {
    if (roleSet === null) {
      return false;
    }
    return roles.some((role) => roleSet.has(role));
  },
);

export const hasRoles = createSelector(
  [selectRolesFromActiveProfile, selectParameter<RoleEnum[]>],
  (roleSet: Set<RoleEnum> | null, roles: RoleEnum[]) => {
    if (roleSet === null) {
      return null;
    }
    return new Set(roles.filter((role) => roleSet.has(role)));
  },
);

export const hasRole = createSelector(
  [selectRolesFromActiveProfile, selectParameter<RoleEnum>],
  (roleSet: Set<RoleEnum> | null, role: RoleEnum) => {
    if (roleSet === null) {
      return false;
    }
    return roleSet.has(role);
  },
);

const { selectAll: selectAllRoles, selectEntities: selectRoleEntities } = rolesAdapter.getSelectors(
  (state: RootState) => state.authent.roles,
);

export const RoleSelectors = {
  selectAllRoles,
};

const { selectAll: selectAllProfiles, selectEntities: selectProfileEntities } = profilesAdapter.getSelectors(
  (state: RootState) => state.authent.profiles,
);

const { selectEntities: selectSiteEntities } = sitesAdapter.getSelectors((state: RootState) => state.authent.sites);

const { selectAll: selectAllUsers, selectEntities: selectUserEntities } = usersAdapter.getSelectors(
  (state: RootState) => state.authent.users,
);

const selectUserById = usersAdapter.getSelectors((state: RootState) => state.authent.users).selectById as (
  state: RootState,
  id: EntityId,
) => NormalizedUser | undefined;

const { selectAll: selectAllGroups, selectEntities: selectGroupEntities } = groupsAdapter.getSelectors(
  (state: RootState) => state.authent.groups,
);

export const GroupSelectors = {
  selectAllGroups,
};

export const selectGlobalScreenConfigurationCode = createSelector(
  selectActiveGroup,
  (state: Group | null): string => state?.globalScreenConfig ?? '',
);

const selectEntities = createSelector(
  [selectUserEntities, selectProfileEntities, selectRoleEntities, selectSiteEntities, selectGroupEntities],
  (users, profiles, roles, sites, groups) => ({
    users,
    profiles,
    roles,
    sites,
    groups,
  }),
);

export const selectDenormalizedUser = createSelector(
  [selectEntities, selectUserById],
  (entities, user): User | null => denormalize(user?.id, userSchema, entities) ?? null,
);

export const selectDenormalizedUsers = createSelector([selectEntities, selectAllUsers], (entities, users): User[] =>
  users.map((user) => denormalize(user.id, userSchema, entities)),
);

export const selectProfilesWithoutRestricted = createSelector(selectAllProfiles, (profiles): Profile[] =>
  profiles.filter((profile) => !isRestrictedProfile(profile)),
);

export const selectDenormalizedProfiles = createSelector(
  [selectEntities, selectAllProfiles],
  (entities, profiles): DenormalizedProfile[] =>
    profiles.map((profile) => denormalize(profile.id, fullProfileSchema, entities)),
);

export const selectSensorRolesFromActiveProfile = createSelector(
  [selectActiveProfile, selectDenormalizedProfiles],
  (profile, profiles): Set<SensorAction> => {
    if (profile === null) {
      return new Set();
    }
    const denormalizedProfile = profiles.find((p) => p.id === profile.id);
    if (!denormalizedProfile) {
      return new Set();
    }
    return new Set(
      denormalizedProfile.roles
        .filter((role) => role.action && role.sensorFamily)
        .map(
          (role) =>
            ({
              action: role.action,
              sensorFamily: role.sensorFamily,
            }) as SensorAction,
        ),
    );
  },
);

export const hasAnySensorRoleFromActiveProfile = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<SensorAction[]>],
  (userSensorActionSet: Set<SensorAction>, providedSensorActions: SensorAction[]): boolean => {
    return Array.from(userSensorActionSet).some((usa) =>
      providedSensorActions.some((psa) => usa.action === psa.action && usa.sensorFamily === psa.sensorFamily),
    );
  },
);

export const hasSensorRoleFromActiveProfile = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<SensorAction>],
  (userSensorActionSet: Set<SensorAction>, providedSensorAction: SensorAction): boolean => {
    return Array.from(userSensorActionSet).some(
      (s) => s.action === providedSensorAction.action && s.sensorFamily === providedSensorAction.sensorFamily,
    );
  },
);

export const hasSensorLADorPerimeterAccessFromActiveProfile = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<TypeEnum>],
  (sensorActionSet: Set<SensorAction>, type: TypeEnum): boolean => {
    return Array.from(sensorActionSet)
      .filter(
        (s) =>
          (type === TypeEnum.LAD && s.sensorFamily !== SensorFamilyEnum.AUTOMATON) ||
          (type !== TypeEnum.LAD && s.sensorFamily === SensorFamilyEnum.AUTOMATON),
      )
      .some((s) => s.action === ActionEnum.ACCESS);
  },
);

export const hasActionRoleOnSensors = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<ActionEnum>],
  (sensorActionSet: Set<SensorAction>, action: ActionEnum): boolean => {
    return Array.from(sensorActionSet).some((sensorAction) => sensorAction.action === action);
  },
);

export const hasAnyActionRoleOnSensors = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<ActionEnum[]>],
  (userSensorActionSet: Set<SensorAction>, providedSensorActions: ActionEnum[]): boolean => {
    return Array.from(userSensorActionSet).some((userSensorAction) =>
      providedSensorActions.some((providedSensorAction) => providedSensorAction === userSensorAction.action),
    );
  },
);

export const selectDenormalizedProfilesWithoutRestricted = createSelector(
  selectDenormalizedProfiles,
  (profiles): DenormalizedProfile[] =>
    profiles.filter((p) => !isRestrictedProfile(p)).toSorted(stringPropertyComparator('name')),
);

export const selectDenormalizedProfilesId = createSelector(
  selectDenormalizedProfiles,
  (profiles): Set<number> => new Set(profiles.map((profile) => profile.id)),
);

export const selectProfilesIdWithoutRestricted = createSelector(
  selectDenormalizedProfilesWithoutRestricted,
  (profiles): Set<number> => new Set(profiles.map((profile) => profile.id)),
);

export const selectAllProfilesWithoutRestricted = createSelector(
  [selectAllProfiles, selectProfilesIdWithoutRestricted],
  (profiles, profilesIdWithoutRestricted): Profile[] =>
    profiles.filter((profile) => profilesIdWithoutRestricted.has(profile.id)),
);

export const selectDenormalizedProfileById = createSelector(
  [selectDenormalizedProfilesWithoutRestricted, selectParameter<number>],
  (profiles: DenormalizedProfile[], id: number) => {
    return profiles.find((profile) => profile.id === id);
  },
);

export const selectDenormalizedGroups = createSelector(
  [selectEntities, selectAllGroups, selectDenormalizedProfilesId],
  (entities, groups, denormalizedProfilesId): GroupWithUsers[] =>
    groups
      .filter((group) => denormalizedProfilesId.has(Number(group.profile)))
      .map((group) => denormalize(group.code, groupSchema, entities)),
);

export const selectDenormalizedGroupsWithoutRestricted = createSelector(
  [selectEntities, selectAllGroups, selectProfilesIdWithoutRestricted],
  (entities, groups, profilesIdWithoutRestricted): GroupWithUsers[] =>
    groups
      .filter((group) => profilesIdWithoutRestricted.has(Number(group.profile)))
      .map((group) => denormalize(group.code, groupSchema, entities)),
);

export const hasAdminRole = createSelector(
  [selectUserById, selectDenormalizedGroups],
  (user, groups): boolean =>
    user?.groups
      .flatMap((group) => group.code)
      .map((groupCode) => groups.find((g) => g.code === groupCode)?.profile)
      .some((profile) => profile && profile.roles.includes(RoleEnum.ADMIN)) ?? false,
);

export const isSuperAdmin = createSelector([selectUserById], (user): boolean => user?.login === ADMIN);
