import { ReactComponent as CloseIcon } from '@assets/icons/24x24/ic-close-24.svg';
import { Box, Divider, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { usePlatformsPanelContext } from '@components/cockpit/Cockpit';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectAllActiveNotProcessedNotificationsPerTypeByPlatforms } from '@redux/data/data.selectors';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Platform from './Platform';

function Platforms() {
  const { setIsPlatformsPanelOpen } = usePlatformsPanelContext();

  const notificationsByPlatform = useAppSelector(selectAllActiveNotProcessedNotificationsPerTypeByPlatforms, isEqual);

  return (
    <Box
      position="fixed"
      width="100%"
      height="100%"
      userSelect="none"
      backgroundColor="neutral.900-op30"
      zIndex="overlay"
      onClick={() => setIsPlatformsPanelOpen(false)}
    >
      <VStack
        width="437px"
        height="100%"
        marginLeft="auto"
        backgroundColor="neutral.900"
        border="2px solid"
        borderColor="neutral.black"
        gap={0.25}
        padding={3}
        paddingTop={2.5}
        alignItems="start"
        userSelect="none"
      >
        <Icon
          position="absolute"
          as={CloseIcon}
          top={2.5}
          right={3}
          width="24px"
          height="24px"
          color="neutral.300"
          cursor="pointer"
          _hover={{ color: 'sky.500' }}
          onClick={() => setIsPlatformsPanelOpen(false)}
        />
        <Text
          lineHeight="22px"
          textTransform="uppercase"
          size="md"
          color="neutral.200"
          paddingBottom={2}
          paddingTop="26px"
        >
          <FormattedMessage id="components.platform.title" />
        </Text>
        <Divider width="100%" borderColor="neutral.700" borderWidth="1px" />
        <HStack width="100%" paddingY={1} paddingX={2}>
          <HStack gap={1} height="34px" marginLeft="auto">
            <Text width="32px" color="neutral.300" textAlign="center">
              <FormattedMessage id="components.platform.c-uas" />
            </Text>
            <Text width="32px" color="neutral.300" textAlign="center">
              <FormattedMessage id="components.platform.perim" />
            </Text>
            <Text width="34px" color="neutral.300" textAlign="center">
              <FormattedMessage id="components.platform.tech" />
            </Text>
          </HStack>
        </HStack>
        <Divider width="100%" borderColor="neutral.700" borderWidth="1px" />
        <VStack gap={0.25} width="100%" height="100%" flexShrink={1}>
          {notificationsByPlatform.map(({ platform, ...notificationsInfo }) => (
            <Platform platform={platform} key={platform.id} notificationsInfo={notificationsInfo} />
          ))}
        </VStack>
      </VStack>
    </Box>
  );
}

export default memo(Platforms);
