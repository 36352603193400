import { ReactComponent as LevelIcon } from '@assets/icons/24x24/ic-alert-24.svg';
import { ReactComponent as DroneIcon } from '@assets/icons/24x24/ic-drone-24.svg';
import { ReactComponent as AlertIcon } from '@assets/icons/24x24/ic-eye-24.svg';
import { ReactComponent as HeightIcon } from '@assets/icons/24x24/ic-height-24.svg';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { getClearanceColor, getNotificationColor, getWorstNotificationLevel } from '@utils/data/notification.utils';
import { formatUnit, HeightUnits } from '@utils/unit.utils';
import { FormattedMessage, useIntl } from 'react-intl';

import { DroneNotificationLight } from '@/types/data/data.types';

import InfoValue from '../InfoValue';

type Props = {
  notification: DroneNotificationLight;
};

export default function DroneDetails({ notification }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  return (
    <VStack gap={3} padding={4} width="100%" backgroundColor="neutral.700">
      <HStack width="100%" gap={4}>
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.clearance' })}
          value={
            <HStack gap={1}>
              <Icon as={DroneIcon} width="24px" height="24px" color={getClearanceColor(notification.clearance)} />
              <Text size="lg">
                <FormattedMessage id={`target.clearance.${notification.clearance}`} />
              </Text>
            </HStack>
          }
        />
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.worstCriticity' })}
          value={
            <HStack gap={1}>
              <Icon as={LevelIcon} width="24px" height="24px" color={getNotificationColor(notification)} />
              <Text size="lg">
                <FormattedMessage id={`notification.criticality.${getWorstNotificationLevel(notification)}`} />
              </Text>
            </HStack>
          }
        />
      </HStack>
      <HStack width="100%" gap={4}>
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.reliability' })}
          value={formatMessage({ id: `target.reliability.${notification.reliability}` })}
        />
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.detection' })}
          value={
            <HStack gap={1}>
              <Icon as={AlertIcon} width="24px" height="24px" color="neutral.300" />
              <Text size="lg">{`x${notification.occurrenceCount}`}</Text>
            </HStack>
          }
        />
      </HStack>
      <HStack width="100%" gap={4}>
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.flightPlan' })}
          value={formatMessage(
            { id: 'targetInfos.tab.details.withFlightPlan' },
            { hasFlightPlan: notification.hasFlightPlan },
          )}
        />
        <InfoValue
          label={formatMessage({ id: 'targetInfos.tab.details.maxHeight' })}
          isEmpty={notification.maxHeight == null}
          value={
            <HStack gap={1} width="100%" alignItems="start">
              <Icon as={HeightIcon} width="24px" height="24px" color="neutral.300" />
              <Text fontSize="18px">
                <FormattedMessage
                  id="targetInfos.tab.details.maxHeightValue"
                  values={{ maxHeight: formatUnit(HeightUnits.METERS, notification.maxHeight) }}
                />
              </Text>
            </HStack>
          }
        />
      </HStack>
    </VStack>
  );
}
