import { Flex } from '@chakra-ui/layout';
import MouseFocus from '@components/common/MouseFocus';
import InactivityModal from '@components/InactivityModal';
import ChangeShift from '@components/login/ChangeShift';
import JoystickControl from '@components/video/cameraControl/JoystickControl';
import { useAppSelector } from '@hooks/redux.hooks';
import { useAuth } from '@hooks/useAuth';
import {
  hasAnyActionRoleOnSensors,
  hasRoles,
  selectActiveUser,
  selectIsChangingShift,
} from '@redux/authent/authent.selectors';
import { selectJoystickControlId } from '@redux/global/global.selector';
import { RootState } from '@redux/store';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetSituationDiffQuery } from '@services/c2/sse.api';
import { createContext, useContext, useMemo, useState } from 'react';

import { RoleEnum } from '@/types/authent/roles.types';
import { ActionEnum } from '@/types/commons/commons.types';

import CockpitTitle from './CockpitTitle';
import AdminPanel from './panels/admin/AdminPanel';
import Chat from './panels/chat/Chat';
import LogBook from './panels/logbook/LogBook';
import MonitoringPanel from './panels/monitoring/MonitoringPanel';
import NotificationsPanel from './panels/notification/NotificationsPanel';
import OperatorStationConfiguration from './panels/operatorStationConfiguration/OperatorStationConfiguration';
import Platforms from './panels/platform/Platforms';
import ProfileUserParams from './panels/settings/ProfileUserParams';
import SystemConfiguration from './panels/sytemConfig/SystemConfiguration';
import CockpitSidebar from './sidebar/CockpitSidebar';

export enum CockpitPanelIntlKeysEnum {
  NOTIFICATION = 'NOTIFICATION',
  LOG_BOOK_AND_CHAT = 'LOG_BOOK_AND_CHAT',
  OPERATOR_STATION_CONFIGURATION = 'OPERATOR_STATION_CONFIGURATION',
  GRID_LAYOUT_CONFIGURATION = 'GRID_LAYOUT_CONFIGURATION',
  SYSTEM_CONFIGURATION = 'SYSTEM_CONFIGURATION',
  SETTINGS = 'SETTINGS',
  ADMIN = 'ADMIN',
  MONITORING = 'MONITORING',
}

const ROLES = [RoleEnum.ACCESS_MSG, RoleEnum.ACCESS_REPLAY];

interface PlatformsPanelContextType {
  isPlatformsPanelOpen: boolean;
  setIsPlatformsPanelOpen: (open: boolean) => void;
}

const PlatformsPanelContext = createContext<PlatformsPanelContextType | undefined>(undefined);

export const usePlatformsPanelContext = (): PlatformsPanelContextType => {
  const context = useContext(PlatformsPanelContext);
  if (!context) {
    throw new Error('usePlatformsPanelContext must be used within a PlatformsPanelContext.Provider');
  }
  return context;
};

export default function Cockpit() {
  const { token } = useAuth();
  const joystickControlId = useAppSelector(selectJoystickControlId);
  const canSeeNotifications = useAppSelector((state: RootState) =>
    hasAnyActionRoleOnSensors(state, [ActionEnum.ACCESS_TARGET, ActionEnum.MAINTAIN]),
  );
  const isChangingShift = useAppSelector(selectIsChangingShift);
  const roleSet = useAppSelector((state: RootState) => hasRoles(state, ROLES));

  const { activeSite, activeGroup } = useAppSelector(selectActiveUser);
  const inactivityLimit = activeGroup?.inactivityTime ?? null;

  const [openedMainPanel, setOpenedMainPanel] = useState<CockpitPanelIntlKeysEnum>(
    CockpitPanelIntlKeysEnum.NOTIFICATION,
  );

  const [isPlatformsPanelOpen, setIsPlatformsPanelOpen] = useState<boolean>(false);
  const contextValue = useMemo(() => ({ isPlatformsPanelOpen, setIsPlatformsPanelOpen }), [isPlatformsPanelOpen]);

  useGetSituationDiffQuery(
    activeSite && token
      ? {
          site: activeSite.code,
          platforms: activeGroup ? activeGroup.platforms : [],
          token,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <PlatformsPanelContext.Provider value={contextValue}>
      <Flex height="100%" width="100%" gap={0} backgroundColor="neutral.black" padding={0.25}>
        {joystickControlId !== null && <JoystickControl />}
        <MouseFocus />
        <CockpitTitle />
        {inactivityLimit !== null && <InactivityModal inactivityLimit={inactivityLimit} />}
        {isChangingShift && <ChangeShift />}
        {isPlatformsPanelOpen && <Platforms />}
        <CockpitSidebar state={openedMainPanel} setState={setOpenedMainPanel} />
        <Flex position="relative" flex={1} gap={0.25} wrap="nowrap">
          {openedMainPanel === CockpitPanelIntlKeysEnum.LOG_BOOK_AND_CHAT && (
            <>
              {roleSet?.has(RoleEnum.ACCESS_REPLAY) && <LogBook />}
              {roleSet?.has(RoleEnum.ACCESS_MSG) && <Chat />}
            </>
          )}
          {openedMainPanel === CockpitPanelIntlKeysEnum.NOTIFICATION && canSeeNotifications && <NotificationsPanel />}
          {openedMainPanel === CockpitPanelIntlKeysEnum.OPERATOR_STATION_CONFIGURATION && (
            <OperatorStationConfiguration />
          )}
          {openedMainPanel === CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION && <SystemConfiguration />}
          {openedMainPanel === CockpitPanelIntlKeysEnum.SETTINGS && <ProfileUserParams />}
          {openedMainPanel === CockpitPanelIntlKeysEnum.MONITORING && <MonitoringPanel />}
          {openedMainPanel === CockpitPanelIntlKeysEnum.ADMIN && <AdminPanel />}
        </Flex>
      </Flex>
    </PlatformsPanelContext.Provider>
  );
}
