import { Text } from '@chakra-ui/react';
import { useUpdateProcedureMutation } from '@services/data/procedure.api';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProcedureStep, ProcedureStepStateEnum } from '@/types/config/procedure.types';

import ProcedureInstructions from './ProcedureInstructions';
import StepActionButtons from './StepActionButtons';

type Props = {
  step: ProcedureStep;
  notificationId: number;
  procedureId: number;
  canManage: boolean;
};

function ClosingStep({ step, notificationId, procedureId, canManage }: Readonly<Props>) {
  const [updateProcedure] = useUpdateProcedureMutation();

  if (!canManage) {
    return <ProcedureInstructions instructions={step.instructions} />;
  }

  return (
    <>
      <Text
        whiteSpace="pre-line"
        textAlign="center"
        paddingY={4}
        backgroundColor="neutral.700"
        width="100%"
        flex={step.instructions.length > 0 ? 0 : 1}
      >
        <FormattedMessage id="notification.procedure.step.type.CLOSING.description" />
      </Text>
      <ProcedureInstructions instructions={step.instructions} />
      <StepActionButtons
        isMandatory={step.mandatory}
        label="notification.procedure.step.type.CLOSING.close"
        notificationId={notificationId}
        procedureId={procedureId}
        stepRank={step.rank}
        onValidate={() =>
          updateProcedure({
            notificationId,
            procedureId,
            step: step.rank,
            state: ProcedureStepStateEnum.CONFIRMED,
          }).unwrap()
        }
      />
    </>
  );
}

export default memo(ClosingStep);
