import { ReactComponent as ArrowIcon } from '@assets/icons/24x24/ic-arrow-24.svg';
import { HStack, VStack } from '@chakra-ui/layout';
import { Button, Icon } from '@chakra-ui/react';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import { TypeEnum } from '@utils/sensors/configuration.constants';
import { Dispatch } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { MonitoringFilter, MonitoringState } from '../list/MonitoringList';
import CategoryFilter from './CategoryFilter';
import StatusFilter from './StatusFilter';
import TypeFilter from './TypeFilter';

type Props = {
  state: MonitoringState;
  setState: Dispatch<Partial<MonitoringState>>;
  defaultFilters: MonitoringFilter;
};

function Filters({ state, setState, defaultFilters }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  if (!state.open) {
    return null;
  }

  return (
    <VStack
      gap={0.25}
      width="296px"
      backgroundColor="neutral.black"
      borderRight="2px solid"
      borderColor="neutral.black"
      height="100%"
      flexShrink={0}
    >
      <HStack justifyContent="space-between" paddingX={1.5} paddingY={1} backgroundColor="neutral.850" width="100%">
        <Button
          variant="formButtonPrimary"
          size="sm"
          height="40px"
          width="min-content"
          onClick={() => setState({ ...defaultFilters })}
        >
          <FormattedMessage id="components.monitoring.filter.reset" />
        </Button>
        <Icon
          as={ArrowIcon}
          width="24px"
          height="24px"
          transform="rotate(180deg)"
          color="neutral.300"
          cursor="pointer"
          _hover={{ color: 'sky.500' }}
          onClick={() => setState({ open: false })}
        />
      </HStack>
      <CockpitCustomScrollbar marginTop={1} marginBottom={1} marginRight={1} viewWidth="100%" viewHeight="100%">
        <VStack gap={0.25} height="100%" flexGrow={1}>
          <TypeFilter selectedTypes={state.selectedTypes} types={defaultFilters.selectedTypes} setState={setState} />
          {state.selectedTypes.has(TypeEnum.LAD) && (
            <CategoryFilter
              values={state.ladCategories}
              stateKey="ladCategories"
              title={formatMessage({ id: 'components.monitoring.filter.ladFilter' })}
              setState={setState}
              defaultFilters={defaultFilters.ladCategories}
            />
          )}
          {state.selectedTypes.has(TypeEnum.PERIM) && (
            <CategoryFilter
              values={state.perimCategories}
              stateKey="perimCategories"
              title={formatMessage({ id: 'components.monitoring.filter.perimFilter' })}
              setState={setState}
              defaultFilters={defaultFilters.perimCategories}
            />
          )}
          <StatusFilter statusCategories={state.statusCategories} setState={setState} />
        </VStack>
      </CockpitCustomScrollbar>
    </VStack>
  );
}

export default Filters;
