import { ReactComponent as InfoIcon } from '@assets/icons/16x16/ic-info-filled-16.svg';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { memo, ReactElement } from 'react';

type ValueType =
  | {
      value: ReactElement;
      isEmpty?: boolean;
    }
  | {
      value: string | number | undefined | null;
      isEmpty?: never;
    };

type Props = {
  showTooltip?: boolean;
  label: string;
  infoLabel?: string;
} & ValueType;

function InfoValue({ label, value, showTooltip = false, infoLabel, isEmpty = false }: Readonly<Props>) {
  const isReactElement = typeof value !== 'string' && typeof value !== 'number' && value !== undefined;

  return (
    <VStack width="100%" gap={1} alignItems="start" cursor="default">
      <HStack gap={1} justifyContent="center">
        <Text size="md" color="neutral.300">
          {label}
        </Text>
        {infoLabel && (
          <Tooltip label={infoLabel} placement="top">
            <Center>
              <Icon as={InfoIcon} color="neutral.300" />
            </Center>
          </Tooltip>
        )}
      </HStack>
      <Tooltip isDisabled={!showTooltip} label={value}>
        {isReactElement && !isEmpty ? (
          value
        ) : (
          <Text size="lg" noOfLines={2} wordBreak="break-all">
            {isEmpty || value === null || value === undefined ? '-' : value}
          </Text>
        )}
      </Tooltip>
    </VStack>
  );
}

export default memo(InfoValue);
