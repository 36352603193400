import { ReactComponent as ArrowIcon } from '@assets/icons/24x24/ic-arrowTriangle-24.svg';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type Props = {
  instructions: string[];
};

export default function ProcedureInstructions({ instructions }: Readonly<Props>) {
  if (instructions.length === 0) {
    return null;
  }
  return (
    <VStack
      gap={2}
      paddingX={6}
      paddingY={4}
      width="100%"
      flex={1}
      alignItems="start"
      backgroundColor="neutral.700"
      overflow="auto"
      className="cockpit-procedure-scrollbar"
    >
      <Text color="neutral.300" size="md">
        <FormattedMessage id="notification.procedure.step.instructions" />
      </Text>
      {instructions.map((instruction, index) => (
        <HStack gap={1} width="100%" key={`${instruction}-${index}`} align="start">
          <Icon as={ArrowIcon} width="24px" height="24px" color="neutral.300" />
          <Text>{instruction}</Text>
        </HStack>
      ))}
    </VStack>
  );
}
