import './customStyledSelect.scss';

import { ReactComponent as downArrowIcon } from '@assets/icons/24x24/ic-arrowTriangleFilled-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Box, Tooltip } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/system';
import {
  chakraComponents,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValue,
  OptionProps,
  Select,
  SingleValue,
} from 'chakra-react-select';
import classNames from 'classnames';
import { FormikErrors } from 'formik';
import { memo, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { MultiValueGenericProps, SingleValueProps } from 'react-select/dist/declarations/src';

function isMultiValue<T>(arg: MultiValue<T> | SingleValue<T>): arg is MultiValue<T> {
  return Array.isArray(arg);
}

export interface Option {
  isDisabled?: boolean;
  value: string;
  label: string;
}

type SelectType =
  | {
      isMulti?: false;
      value?: string;
      onChange: (value: string) => void;
    }
  | {
      isMulti: true;
      value: string[];
      onChange: (values: string[]) => void;
    };

interface BasicProps {
  isClearable?: boolean;
  isInvalid?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  showTooltip?: boolean;
  key?: string;
  options: Option[];
  placeholder?: string;
  menuPortalTarget?: HTMLElement | null;
  width?: number;
  listWidth?: number;
  listOffset?: boolean;
  menuPlacement?: 'bottom' | 'top' | 'auto';
  variant?: 'unit' | 'default' | 'dark';
  size?: 'xs' | 'sm' | 'md';
  noOptionsMessage?: string;
  formatOptionLabel?: (value: Option) => ReactElement;
  onBlur?: () => Promise<void | FormikErrors<any>>; // eslint-disable-line
}

export type CustomStyledSelectProps = BasicProps & SelectType;

const CustomOption = (
  { children, ...props }: OptionProps<Option, boolean, GroupBase<Option>>,
  showTooltip: boolean,
) => (
  <chakraComponents.Option {...props}>
    <Tooltip isDisabled={!showTooltip} label={props.data.label} openDelay={1000} placement="left">
      <Box width="100%" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" wordBreak="break-all">
        {children}
      </Box>
    </Tooltip>
  </chakraComponents.Option>
);

const CustomSingleValue = (
  { children, ...props }: SingleValueProps<Option, boolean, GroupBase<Option>>,
  showTooltip: boolean,
) => (
  <chakraComponents.SingleValue {...props}>
    <Tooltip isDisabled={!showTooltip} label={props.data.label} placement="left" openDelay={1000}>
      <span>{children}</span>
    </Tooltip>
  </chakraComponents.SingleValue>
);

const CustomMultiValueLabel = (
  { children, ...props }: MultiValueGenericProps<Option, boolean, GroupBase<Option>>,
  showTooltip: boolean,
) => (
  <chakraComponents.MultiValueLabel {...props}>
    <Tooltip isDisabled={!showTooltip} label={props.data.label} placement="left" openDelay={1000}>
      <span>{children}</span>
    </Tooltip>
  </chakraComponents.MultiValueLabel>
);

function CustomSelect({
  isClearable = true,
  isSearchable = false,
  isInvalid = false,
  isDisabled = false,
  menuPortalTarget = null,
  menuPlacement = 'bottom',
  showTooltip = true,
  placeholder = '',
  width = 260,
  listWidth,
  listOffset = true,
  isMulti,
  options,
  size = 'sm',
  value,
  variant = 'default',
  noOptionsMessage,
  onChange,
  onBlur,
  ...props
}: Readonly<CustomStyledSelectProps>) {
  const { formatMessage } = useIntl();

  const DropdownIndicator = (props: DropdownIndicatorProps<Option, boolean, GroupBase<Option>>) => (
    <components.DropdownIndicator
      {...props}
      className={classNames('custom-input-dropdown-icon', size, {
        selected: props.selectProps.menuIsOpen,
        reverse: menuPlacement === 'top',
        disabled: isDisabled,
      })}
    >
      <Icon className="icon" as={downArrowIcon} width="24px" height="24px" />
    </components.DropdownIndicator>
  );

  const borderControl = isInvalid ? 'alertHi.500' : 'neutral.black';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function backgroundColor(state: any) {
    switch (variant) {
      case 'unit':
        return state.selectProps.menuIsOpen ? 'cyber.500' : 'neutral.800';
      case 'dark':
        return 'neutral.900';
      case 'default':
      default:
        return 'neutral.800';
    }
  }

  function height() {
    switch (size) {
      case 'xs':
        return 40;
      case 'md':
        return 52;
      case 'sm':
      default:
        return 48;
    }
  }

  const chakraStyles = {
    //https://www.npmjs.com/package/chakra-react-select#chakrastyles
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (provided: SystemStyleObject, state: any) => ({
      ...provided,
      cursor: isSearchable ? 'text' : 'pointer',
      height: `${height()}px`,
      width,
      padding: 0,
      background: backgroundColor(state),
      outline: 0,
      border: '2px solid',
      borderColor: borderControl,
      borderRadius: 0,
      color: 'neutral.white',
      fontFamily: 'roboto',
      fontSize: '16px',
      _disabled: {
        opacity: 1,
        color: 'neutral.500',
      },
      _hover: {
        outline: 0,
      },
      _focus: {
        borderColor: borderControl,
        boxShadow: 'none',
      },
      _invalid: {
        borderColor: borderControl,
        boxShadow: 'none',
      },
    }),
    noOptionsMessage: (provided: SystemStyleObject) => ({
      ...provided,
      backgroundColor: 'neutral.800',
      color: 'neutral.white',
      cursor: 'default',
      fontFamily: 'roboto',
      fontSize: '16px',
      fontWeight: 'regular',
    }),
    option: (provided: SystemStyleObject) => ({
      ...provided,
      width: '100%',
      height: `${height()}px`,
      display: 'flex',
      padding: 0,
      paddingLeft: 1.5,
      paddingRight: 1.5,
      pointerEvents: 'auto',
      borderRadius: 0,
      border: '2px solid transparent',
      color: 'neutral.white',
      fontFamily: 'roboto',
      fontSize: '16px',
      fontWeight: 'regular',
      backgroundColor: 'neutral.800',
      boxShadow: 'none',
      justifyContent: 'start',
      lineHeight: '21px',
      _selected: {
        backgroundColor: 'cyber.500',
        borderColor: 'cyber.500',
      },
      _disabled: {
        opacity: 1,
        color: 'neutral.500',
        cursor: 'default',
      },
      _focusVisible: {
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'inset 0 0 0 2px var(--chakra-colors-sky-500)',
        backgroundColor: 'neutral.700',
        borderColor: 'neutral.700',
        _selected: {
          backgroundColor: 'cyber.500',
          borderColor: 'cyber.500',
        },
        _disabled: {
          boxShadow: 'none',
          background: 'neutral.800',
          borderColor: 'neutral.800',
          _selected: {
            backgroundColor: 'cyber.500',
            borderColor: 'cyber.500',
          },
        },
      },
    }),
    menuList: (provided: SystemStyleObject) => ({
      ...provided,
      border: '2px solid var(--chakra-colors-neutral-black)',
      borderRadius: 0,
      padding: 0,
      minWidth: width - height() + 2,
      width: listWidth ?? width - height() + 2,
      backgroundColor: 'neutral.800',
      right: `${listWidth ? listWidth - width + (listOffset ? height() - 2 : 0) : 0}px`,
      marginY: '-2px',
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: 'neutral.800',
      },
      '::-webkit-scrollbar-thumb': {
        width: '4px',
        backgroundColor: 'neutral.500',
      },
    }),
    menu: (provided: SystemStyleObject) => ({
      ...provided,
      margin: 0,
      pointerEvents: 'none',
    }),
    valueContainer: (provided: SystemStyleObject) => ({
      ...provided,
      paddingX: 'var(--chakra-space-1-5)',
      marginX: 0,
      flexWrap: 'nowrap',
    }),
    multiValue: (provided: SystemStyleObject) => ({
      ...provided,
      paddingLeft: 0.5,
      paddingRight: 0.5,
      backgroundColor: 'cyber.500',
    }),
    multiValueLabel: (provided: SystemStyleObject) => ({
      ...provided,
      color: 'neutral.white',
      lineHeight: '18px',
    }),
    multiValueRemove: (provided: SystemStyleObject) => ({
      ...provided,
      marginLeft: 0.25,
      marginRight: 0,
      color: 'neutral.white',
    }),
    clearIndicator: (provided: SystemStyleObject) => ({
      ...provided,
      _hover: {
        color: 'sky.500',
      },
    }),
  };

  return (
    <Select
      closeMenuOnSelect={!isMulti}
      variant="unstyled"
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      isInvalid={isDisabled}
      menuPlacement={menuPlacement}
      hideSelectedOptions={!isMulti}
      placeholder={placeholder}
      options={options}
      useBasicStyles
      className={classNames('custom-select', { disabled: isDisabled })}
      noOptionsMessage={() => formatMessage({ id: noOptionsMessage ?? 'components.select.noOption' })}
      chakraStyles={chakraStyles}
      styles={{ menuPortal: (provided: any) => ({ ...provided, zIndex: 100 }) }}
      menuPortalTarget={menuPortalTarget}
      isMulti={isMulti}
      menuShouldScrollIntoView={false}
      {...props}
      value={
        isMulti
          ? options.filter((option) => value.includes(option.value))
          : options.find((option) => option.value === value)
      }
      onChange={(options: SingleValue<Option> | MultiValue<Option>) => {
        if (isMultiValue(options) && isMulti) {
          onChange(options.map((option) => option.value));
        } else if (!isMultiValue(options) && !isMulti) {
          onChange(options?.value ?? '');
        }
      }}
      onBlur={onBlur}
      components={{
        DropdownIndicator,
        Option: (props: any) => CustomOption(props, showTooltip),
        MultiValueLabel: (props: any) => CustomMultiValueLabel(props, showTooltip),
        SingleValue: (props: any) => CustomSingleValue(props, showTooltip),
      }}
    />
  );
}

export default memo(CustomSelect, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps));
