import { ReactComponent as StatusIcon } from '@assets/components/panel/monitoring/status.svg';
import { ReactComponent as WorkIcon } from '@assets/icons/16x16/ic-works-16.svg';
import { HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text } from '@chakra-ui/react';
import CustomCheckbox from '@components/common/inputs/CustomCheckbox';
import { useAppSelector } from '@hooks/redux.hooks';
import { SensorSelectors } from '@redux/sensors/sensors.selectors';
import { selectSensorAndSubSensorStatuses } from '@redux/situation/monitoring.selectors';
import { getSectionStatus } from '@utils/map/automatonSection.utils';
import {
  SensorStatusCategoryEnum,
  statusCategoryColor,
  statusCategoryFromSensorStatus,
} from '@utils/sensors/configuration.constants';
import { getSubSensorAndSensorConfiguration } from '@utils/sensors/sensors.utils';
import { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';

import { SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { AutomatonSegmentStatus, SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import { MonitoringState } from '../list/MonitoringList';

type Props = {
  statusCategories: Set<SensorStatusCategoryEnum>;
  setState: Dispatch<Partial<MonitoringState>>;
};

function StatusFilter({ statusCategories, setState }: Readonly<Props>) {
  const allStatus = statusCategories.size === Object.values(SensorStatusCategoryEnum).length;
  const noStatus = statusCategories.size === 0;
  const statuses = useAppSelector(selectSensorAndSubSensorStatuses);
  const sensorConfigurations = useAppSelector(SensorSelectors.selectAllSensors).flatMap((sensor) =>
    getSubSensorAndSensorConfiguration(sensor),
  );

  const statusCount = Object.values(SensorStatusCategoryEnum).reduce(
    (acc, key) => {
      acc[key] = 0;
      return acc;
    },
    {} as Record<SensorStatusCategoryEnum, number>,
  );

  sensorConfigurations.forEach((config) => {
    if (!config.configuration.active) {
      statusCount[SensorStatusCategoryEnum.INACTIVE] += 1;
    } else if (config.configuration.maintenance) {
      statusCount[SensorStatusCategoryEnum.MAINTENANCE] += 1;
    } else {
      const statusInfo = statuses.find((status) => status.uniqueCode === config.sensorUniqueCodes.sensorUniqueCode);
      if (statusInfo) {
        const status =
          config.configuration.type === SubSensorTypeEnum.AUTOMATON_SEGMENT
            ? getSectionStatus(
                Object.values(statusInfo.status as AutomatonSegmentStatus)
                  .filter(Boolean)
                  .map((segment) => segment.status),
              )
            : (statusInfo.status as SubSensorStatus | SensorStatus).status;
        statusCount[statusCategoryFromSensorStatus[status]] += 1;
      } else {
        statusCount[SensorStatusCategoryEnum.UNREACHABLE] += 1;
      }
    }
  });

  return (
    <VStack
      gap={2}
      padding={2}
      alignItems="start"
      width="100%"
      flexGrow={1}
      backgroundColor="neutral.850"
      paddingRight={3}
    >
      <Text fontWeight="medium" color="neutral.300">
        <FormattedMessage id="components.monitoring.filter.statusFilter" />
      </Text>
      <HStack gap={1} width="100%">
        <Text
          paddingX={1}
          paddingY="1px"
          border="2px solid"
          borderColor={allStatus ? 'neutral.600' : 'cyber.500'}
          color={allStatus ? 'neutral.500' : 'neutral.white'}
          cursor={allStatus ? 'default' : 'pointer'}
          noOfLines={1}
          onClick={() =>
            !allStatus &&
            setState({
              statusCategories: new Set(Object.values(SensorStatusCategoryEnum)),
            })
          }
        >
          <FormattedMessage id="global.all" />
        </Text>
        <Text
          paddingX={1}
          paddingY="1px"
          border="2px solid"
          borderColor={noStatus ? 'neutral.600' : 'cyber.500'}
          color={noStatus ? 'neutral.500' : 'neutral.white'}
          cursor={noStatus ? 'default' : 'pointer'}
          noOfLines={1}
          onClick={() =>
            !noStatus &&
            setState({
              statusCategories: new Set(),
            })
          }
        >
          <FormattedMessage id="global.none" />
        </Text>
      </HStack>
      {Object.values(SensorStatusCategoryEnum).map((status) => (
        <HStack width="100%" key={status}>
          <CustomCheckbox
            isChecked={statusCategories.has(status)}
            value={status}
            onChange={() => {
              const newSet = new Set(statusCategories);
              newSet.has(status) ? newSet.delete(status) : newSet.add(status);
              setState({
                statusCategories: newSet,
              });
            }}
          >
            <HStack marginLeft={1} gap={1}>
              <Icon
                as={status === SensorStatusCategoryEnum.MAINTENANCE ? WorkIcon : StatusIcon}
                width="16px"
                height="16px"
                color={statusCategoryColor[status]}
              />
              <Text>
                <FormattedMessage id={`components.monitoring.filter.status.${status}`} />
              </Text>
            </HStack>
          </CustomCheckbox>
          <Text marginLeft="auto" fontWeight="medium" color="sky.500">
            {statusCount[status]}
          </Text>
        </HStack>
      ))}
    </VStack>
  );
}

export default StatusFilter;
