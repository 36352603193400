import { ReactComponent as EyeIcon } from '@assets/icons/24x24/ic-eye-24.svg';
import { ReactComponent as EyeKoIcon } from '@assets/icons/24x24/ic-eye-ko-24.svg';
import { ReactComponent as LockIcon } from '@assets/icons/24x24/ic-padlock-24.svg';
import { ReactComponent as UserIcon } from '@assets/icons/24x24/ic-user-24.svg';
import { useBoolean } from '@chakra-ui/hooks';
import { Flex, HStack, VStack } from '@chakra-ui/layout';
import {
  Button,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import { useAuth } from '@hooks/useAuth';
import useValidation from '@hooks/useValidation';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateIsChangingShift, userLogout } from '@redux/authent/authent.reducer';
import { LoginRequest, useLoginMutation, useLogoutMutation } from '@services/authent/login.api';
import { createErrorMessage } from '@utils/common.utils';
import { Field, Formik } from 'formik';
import { ChangeEvent, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { RoutesEnum } from '@/types/routes.types';

import { loginSchema } from '../../validations/login.schema';

export default function ChangeShift() {
  const validationSchema = useValidation(loginSchema);
  const { formatMessage } = useIntl();
  const { token } = useAuth();

  const navigate = useNavigate();
  const [login, { isLoading, isError, error }] = useLoginMutation();
  const [logoutMutation] = useLogoutMutation();
  const logout = useWithDispatch(userLogout);
  const [show, setShow] = useBoolean();
  const [formState, setFormState] = useState<LoginRequest>({
    login: '',
    password: '',
  });
  const updateIsChangingShiftWithDispatch = useWithDispatch(updateIsChangingShift);

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev: LoginRequest) => ({ ...prev, [name]: value }));

  const handleLogin = useCallback(async () => {
    login(formState)
      .unwrap()
      .then((data) => {
        logoutMutation({ token, emptyStoreOnLogout: false }).finally(() => {
          logout({ token: data.token, renewalToken: data.renew });
          navigate(RoutesEnum.LOGIN);
        });
      });
  }, [formState, login, logout, logoutMutation, navigate, token]);

  useHotkeys('enter', handleLogin, {
    enableOnFormTags: ['input'],
  });

  return (
    <Flex
      position="absolute"
      width="100%"
      height="100%"
      userSelect="none"
      backgroundColor="neutral.900-op30"
      zIndex={9999}
    >
      <VStack
        position="fixed"
        width="536px"
        height="100%"
        right={0}
        gap={5}
        padding={8}
        alignItems="start"
        justifyContent="center"
        opacity={1}
        backgroundColor="neutral.800"
      >
        <Text fontSize="24px" variant="space">
          <FormattedMessage id="authent.shiftTitle" />
        </Text>
        <Formik initialValues={formState} onSubmit={handleLogin} validationSchema={validationSchema}>
          {({ handleSubmit, errors, touched, isSubmitting, submitForm }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <VStack gap={5}>
                <VStack gap={3} width="100%">
                  <VStack gap={1} width="100%" alignItems="start">
                    <Text fontSize="16px">
                      <FormattedMessage id="authent.user.login" />
                    </Text>
                    <FormControl isRequired isInvalid={touched.login && !!errors.login}>
                      <Field as={InputGroup} id="login" type="text" name="login">
                        <InputLeftElement pointerEvents="none" color="neutral.black">
                          <UserIcon width="24px" height="24px" />
                        </InputLeftElement>
                        <Input
                          id="login"
                          type="text"
                          name="login"
                          paddingLeft={7}
                          onChange={handleChange}
                          placeholder={formatMessage({ id: 'authent.user.login' })}
                          variant="login"
                        />
                      </Field>
                    </FormControl>
                  </VStack>
                  <VStack gap={1} width="100%" alignItems="start">
                    <Text fontSize="16px">
                      <FormattedMessage id="authent.user.password" />
                    </Text>
                    <FormControl isRequired isInvalid={touched.password && !!errors.password}>
                      <Field as={InputGroup} id="password" name="password">
                        <InputLeftElement pointerEvents="none" color="neutral.500">
                          <LockIcon width="24px" height="24px" />
                        </InputLeftElement>
                        <Input
                          id="password"
                          name="password"
                          paddingLeft={7}
                          paddingRight={7}
                          type={show ? 'text' : 'password'}
                          placeholder={formatMessage({ id: 'authent.user.password' })}
                          onChange={handleChange}
                          autoComplete="off"
                          variant="login"
                        />
                        <InputRightElement onClick={setShow.toggle} cursor="pointer">
                          <Icon as={show ? EyeIcon : EyeKoIcon} width="24px" height="24px" color="neutral.300" />
                        </InputRightElement>
                      </Field>
                    </FormControl>
                  </VStack>
                  <ErrorsContainer
                    touched={touched}
                    errors={errors}
                    apiError={isError ? createErrorMessage(error) : undefined}
                  />
                </VStack>
                <HStack gap={2} width="100%">
                  <Button
                    variant="formButtonSecondary"
                    size="m"
                    onClick={() => updateIsChangingShiftWithDispatch(false)}
                    isDisabled={isLoading || isSubmitting}
                  >
                    <FormattedMessage id="global.cancel" />
                  </Button>
                  <Button
                    variant="formButtonPrimary"
                    size="m"
                    onClick={submitForm}
                    isLoading={isLoading || isSubmitting}
                  >
                    <FormattedMessage id="authent.takeShift" />
                  </Button>
                </HStack>
                <Text width="100%" textAlign="left" color="neutral.200" whiteSpace="pre-line">
                  <FormattedMessage
                    id="authent.help"
                    values={{ number: formatMessage({ id: 'global.adminNumber' }) }}
                  />
                </Text>
              </VStack>
            </form>
          )}
        </Formik>
      </VStack>
    </Flex>
  );
}
