import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { ReactComponent as HistoryIcon } from '@assets/icons/32x32/ic-history-32.svg';
import { Box, Center, Spinner, Text, VStack } from '@chakra-ui/react';
import Alert from '@components/cockpit/panels/notification/detail/alert/Alert';
import DataSheetCardLink from '@components/common/layout/DataSheetCardLink';
import { useMapContext } from '@components/map/MapContext';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { useGetDetailQuery } from '@services/data/notification.api';
import { FormattedMessage, useIntl } from 'react-intl';

import { NotificationTypeEnum } from '@/types/data/data.types';
import { PopupDataTypeEnum } from '@/types/map.types';

type Props = {
  notificationId?: number;
  displayId: string | null;
  targetId: string;
  position: { x: number; y: number };
  onClose: () => void;
};
export default function TargetAlerts({ notificationId, targetId, displayId, position }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const { mapId } = useMapContext();
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const { data: notificationDetail = undefined, isFetching } = useGetDetailQuery(
    { id: notificationId!, withArchivedAlerts: false },
    { skip: notificationId === undefined },
  );
  const situationTime = useSelectorWithReplayMode(selectSituationTime);

  if (isFetching) {
    return (
      <Center width="100%" height="100%">
        <Spinner height="72px" width="72px" color="neutral.white" />
      </Center>
    );
  }

  function handleHistoryPopup() {
    updatePopupControl({
      mapId,
      popupControl: {
        type: PopupDataTypeEnum.HISTORY,
        data: {
          identifiers: [targetId],
          type: NotificationTypeEnum.DRONE,
          displayId,
          openingTime: situationTime ? new Date(situationTime) : new Date(),
        },
        open: true,
        position,
      },
    });
  }

  return (
    <VStack width="100%" height="100%" overflow="hidden" gap={0}>
      {notificationDetail === undefined || notificationDetail.alerts.length === 0 ? (
        <Center width="100%" height="100%" backgroundColor="neutral.black">
          <VStack width="100%">
            <EmptyPanelDroneIcon />
            <Text fontSize="16px" color="neutral.200">
              <FormattedMessage id="targetInfos.tab.alerts.empty" />
            </Text>
          </VStack>
        </Center>
      ) : (
        <VStack width="100%" gap={0.25} overflow="auto" className="popup-scrollbar">
          {notificationDetail.alerts.map((alert) => (
            <Alert key={alert.id} alert={alert} notificationType={notificationDetail.notification.type} />
          ))}
        </VStack>
      )}
      {notificationDetail !== undefined && displayId !== null && (
        <Box padding={2} width="100%" backgroundColor="neutral.900" marginTop="auto">
          <DataSheetCardLink
            label={formatMessage({ id: 'targetInfos.tab.alerts.history' })}
            icon={HistoryIcon}
            onClick={handleHistoryPopup}
          />
        </Box>
      )}
    </VStack>
  );
}
