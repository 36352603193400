import { NotificationInfo } from '@components/cockpit/panels/platform/Platform';
import { selectActiveGroupsPlatforms } from '@redux/config/config.selectors';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getPlatformsCodesFromMarks, selectParameter } from '@utils/common.utils';
import {
  isCriticalNotification,
  isLadNotification,
  isLadTechnicalNotification,
  isPerimeterNotification,
  isPerimeterTechnicalNotification,
  isSegmentNotification,
  sortNotificationByDanger,
} from '@utils/data/notification.utils';

import { Platform } from '@/types/config/config.types';
import { ProcedureStateEnum } from '@/types/config/procedure.types';
import { NotificationFamilyEnum, NotificationType } from '@/types/data/data.types';

import { notificationAdapter } from './data.adapters';
import { DataState } from './data.reducer';

function selectDataState(state: RootState): DataState {
  return state.data;
}

export const { selectAll: selectAllOngoingNotifications, selectTotal: selectOngoingNotificationsCount } =
  notificationAdapter.getSelectors((state: RootState) => selectDataState(state).ongoingNotifications);

export const selectNotificationById = notificationAdapter.getSelectors(
  (state: RootState) => state.data.ongoingNotifications,
).selectById as (state: RootState, id: number | undefined) => NotificationType | undefined;

export const selectNotificationsByIdentifier = createSelector(
  [selectAllOngoingNotifications, selectParameter<string | undefined>],
  (notifications, identifier) => notifications.filter((notification) => notification.identifier === identifier),
);

export const selectAllActiveNotProcessedNotifications = createSelector(selectAllOngoingNotifications, (notifications) =>
  notifications.filter((notification) => notification.procedureState === ProcedureStateEnum.TO_PROCESS),
);

export const selectAllActiveNotProcessedNotificationsPerTypeByPlatforms = createSelector(
  [selectAllActiveNotProcessedNotifications, selectActiveGroupsPlatforms],
  (
    notifications: NotificationType[],
    platforms: Platform[],
  ): {
    platform: Platform;
    LAD: NotificationInfo;
    PERIM: NotificationInfo;
    TECH: NotificationInfo;
  }[] => {
    const platformWithNotifications = platforms.map((platform) => ({
      platform,
      notifications: notifications
        .filter((notification) => getPlatformsCodesFromMarks(notification.marks).includes(platform.code))
        .sort((notification1, notification2) => sortNotificationByDanger(notification1, notification2)),
    }));

    return platformWithNotifications.map(({ platform, notifications }) => {
      const ladNotifications = notifications.filter(isLadNotification);
      const perimNotification = notifications.filter((notification) => isPerimeterNotification(notification));
      const techNotification = notifications.filter(
        (notification) => isLadTechnicalNotification(notification) || isPerimeterTechnicalNotification(notification),
      );

      return {
        platform,
        LAD: {
          count: ladNotifications.length,
          maxLevel: ladNotifications.length === 0 ? null : ladNotifications[0].currentWorstAlertLevel,
        },
        PERIM: {
          count: perimNotification.length,
          maxLevel: perimNotification.length === 0 ? null : perimNotification[0].currentWorstAlertLevel,
        },
        TECH: {
          count: techNotification.length,
          maxLevel: techNotification.length === 0 ? null : techNotification[0].currentWorstAlertLevel,
        },
      };
    });
  },
);

export const selectAllActiveNotProcessedNotificationsCount = createSelector(
  selectAllActiveNotProcessedNotifications,
  (notifications) => notifications.length,
);

export const selectHasCriticalNotProcessedNotification = createSelector(
  selectAllActiveNotProcessedNotifications,
  (notifications) => notifications.some((notification) => isCriticalNotification(notification)),
);

export const selectHighestNotProcessedNotificationFamily = createSelector(
  selectAllActiveNotProcessedNotifications,
  (notifications: NotificationType[]): NotificationFamilyEnum | null => {
    if (notifications.length === 0) {
      return null;
    }
    if (notifications.some(isLadNotification)) {
      return NotificationFamilyEnum.LAD;
    }
    if (notifications.some((notification) => isPerimeterNotification(notification))) {
      return NotificationFamilyEnum.PERIMETER;
    }
    return NotificationFamilyEnum.TECHNICAL;
  },
);

export const selectOngoingNotificationsBySegmentName = createSelector(
  [selectAllOngoingNotifications, selectParameter<string>],
  (notifications, segmentName) =>
    notifications
      .filter(isSegmentNotification)
      .filter((notification) => isPerimeterNotification(notification))
      .filter((notification) => notification.segmentName === segmentName),
);
