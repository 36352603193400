import { Drone, DroneClearanceEnum, NormalizedDrone } from '@/types/utm/drone.types';

export const UNCATEGORIZED_CLEARANCE = 'Uncategorized';

export function getDroneClearanceLabelFromClearanceEnum(clearance: DroneClearanceEnum | null): string {
  switch (clearance) {
    case DroneClearanceEnum.FULL_CLEARANCE:
      return DroneClearanceEnum.FULL_CLEARANCE;
    case DroneClearanceEnum.NO_CLEARANCE:
      return DroneClearanceEnum.NO_CLEARANCE;
    default:
      return UNCATEGORIZED_CLEARANCE;
  }
}

export function getDroneClearanceLabel(drone?: NormalizedDrone): string {
  return getDroneClearanceLabelFromClearanceEnum(drone?.clearance ?? null);
}

export function getDroneNumber(drone: Drone) {
  return (
    drone.holodroneNumber ??
    drone.aeroscopeNumber ??
    drone.groundStationNumber ??
    drone.adsbNumber ??
    drone.serialNumber
  );
}
