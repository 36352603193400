import { ReactComponent as CurrentMediumIcon } from '@assets/components/prodecureSteps/current-medium.svg';
import { ReactComponent as CurrentMediumSelectedIcon } from '@assets/components/prodecureSteps/current-selected-medium.svg';
import { ReactComponent as CurrentSmallIcon } from '@assets/components/prodecureSteps/current-small.svg';
import { ReactComponent as DoneMediumIcon } from '@assets/components/prodecureSteps/done-medium.svg';
import { ReactComponent as DoneSmallIcon } from '@assets/components/prodecureSteps/done-small.svg';
import { ReactComponent as NextMediumIcon } from '@assets/components/prodecureSteps/next-medium.svg';
import { ReactComponent as NextSmallIcon } from '@assets/components/prodecureSteps/next-small.svg';
import { ReactComponent as SelectedBackground } from '@assets/components/prodecureSteps/selected-background.svg';
import { ReactComponent as SkipMediumIcon } from '@assets/components/prodecureSteps/skip-medium.svg';
import { Box, Center, HStack, Icon } from '@chakra-ui/react';
import { Dispatch, memo } from 'react';

import { Procedure, ProcedureStep, ProcedureStepStateEnum } from '@/types/config/procedure.types';

function getIcon(
  step: ProcedureStep,
  selectedStepRank: number | null,
  size: 'sm' | 'md',
  setSelectedStepRank?: Dispatch<number>,
) {
  if (size === 'sm') {
    switch (step.state) {
      case ProcedureStepStateEnum.TO_BE_STARTED:
        return <Icon as={NextSmallIcon} width="12px" height="12px" />;
      case ProcedureStepStateEnum.IN_PROGRESS: {
        return <Icon as={CurrentSmallIcon} width="14px" height="14px" />;
      }
      case ProcedureStepStateEnum.CONFIRMED:
      case ProcedureStepStateEnum.DENIED:
      case ProcedureStepStateEnum.IGNORED: {
        return <Icon as={DoneSmallIcon} width="12px" height="12px" />;
      }
    }
  }

  const isSelected = step.rank === selectedStepRank;

  switch (step.state) {
    case ProcedureStepStateEnum.TO_BE_STARTED:
      return <Icon as={NextMediumIcon} width="12px" height="12px" />;
    case ProcedureStepStateEnum.IN_PROGRESS: {
      return (
        <Center position="relative" width="24px" height="24px">
          {isSelected && <Icon position="absolute" as={SelectedBackground} width="36px" height="36px" />}
          <Icon
            as={isSelected ? CurrentMediumSelectedIcon : CurrentMediumIcon}
            width="24px"
            height="24px"
            _hover={
              isSelected
                ? undefined
                : {
                    '.outer-circle': { stroke: 'sky.500' },
                    '.inter-circle': { fill: 'sky.500' },
                    cursor: 'pointer',
                  }
            }
            onClick={() => setSelectedStepRank && setSelectedStepRank(step.rank)}
            zIndex={1}
          />
        </Center>
      );
    }
    case ProcedureStepStateEnum.CONFIRMED:
    case ProcedureStepStateEnum.DENIED:
    case ProcedureStepStateEnum.IGNORED: {
      const isConfirmed = step.state !== ProcedureStepStateEnum.IGNORED;
      return (
        <Center position="relative" width="24px" height="24px">
          {isSelected && <Icon position="absolute" as={SelectedBackground} width="36px" height="36px" />}
          <Icon
            as={isConfirmed ? DoneMediumIcon : SkipMediumIcon}
            display="block"
            color="cyber.500"
            width="24px"
            height="24px"
            _hover={isSelected ? undefined : { color: 'sky.500 ', cursor: 'pointer' }}
            zIndex={1}
            onClick={() => setSelectedStepRank && setSelectedStepRank(step.rank)}
          />
        </Center>
      );
    }
  }
}

type Props = {
  procedure: Procedure;
  selectedStepRank?: number | null;
  size?: 'sm' | 'md';
  setSelectedStepRank?: Dispatch<number>;
};

function ProcedureSteps({ procedure, size = 'sm', selectedStepRank = null, setSelectedStepRank }: Readonly<Props>) {
  return (
    <HStack gap={size === 'sm' ? '9px' : 2} position="relative" cursor="default">
      <Box
        width="calc(100% - 12px)"
        position="absolute"
        height="8px"
        backgroundColor="neutral.900"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
      {procedure.steps.map((step) => (
        <Center key={step.rank} zIndex={1} display="flex">
          {getIcon(step, selectedStepRank, size, setSelectedStepRank)}
        </Center>
      ))}
    </HStack>
  );
}

export default memo(ProcedureSteps);
