import 'vis-timeline/dist/vis-timeline-graph2d.min.css';

import { Box, Text } from '@chakra-ui/layout';
import { Portal } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { DateType, TimelineOptions } from 'vis-timeline';
import { Timeline } from 'vis-timeline/standalone';
import { DataGroupCollectionType, DataItemCollectionType, TimelineEventPropertiesResult } from 'vis-timeline/types';

import { CustomDataItem, ReplayEvent } from '@/types/replay/replay.types';

import TimelineEvent from './TimelineEvent';

interface CustomTimelineProps {
  className?: string;
  events: ReplayEvent[];
  customItems?: CustomDataItem[];
  groups?: DataGroupCollectionType;
  options?: TimelineOptions;
  currentTime: DateType;
  onclick: (currentTime: Date) => void;
}

export default function CustomTimeline({
  className,
  events,
  customItems = [],
  groups = [],
  options = {},
  currentTime,
  onclick,
}: Readonly<CustomTimelineProps>) {
  const { formatMessage } = useIntl();

  const timelineRef = useRef<HTMLDivElement>(null);
  const [timeline, setTimeline] = useState<Timeline | null>(null);
  const onClickRef = useRef<((properties: TimelineEventPropertiesResult) => void) | undefined>(undefined);
  const [tooltip, setTooltip] = useState<{ positionX: number; positionY: number; className: string } | null>(null);
  const onItemOver = useRef<((properties: TimelineEventPropertiesResult) => void) | undefined>(undefined);
  const timelineStart = new Date(options.start ?? 0).getTime();
  const timelineEnd = new Date(options.end ?? 0).getTime();

  const items: DataItemCollectionType = customItems.map((item, index) => ({
    id: index,
    group: item.group,
    content: item.content ?? '<div></div>',
    start: item.start,
    end: item.end,
    className: item.className,
    style: item.style ?? '',
    type: item.type,
  }));

  useEffect(() => {
    if (!timelineRef.current) {
      return;
    }
    const newTimeline = new Timeline(timelineRef.current, items, groups, options);
    options.start && options.end && newTimeline.setWindow(new Date(options.start), new Date(options.end));
    newTimeline.addCustomTime(new Date(currentTime), 1);

    onClickRef.current = (properties: TimelineEventPropertiesResult): void => {
      onclick(properties.time);
    };
    newTimeline.on('mouseUp', onClickRef.current);

    newTimeline.on('itemout', () => {
      setTooltip(null);
    });

    setTimeline(newTimeline);

    const handleMouseOut = (event: MouseEvent) => {
      if (
        timelineRef.current &&
        event.currentTarget instanceof Node &&
        !timelineRef.current.contains(event.currentTarget)
      ) {
        setTooltip(null);
      }
    };
    document.addEventListener('mouseout', handleMouseOut);

    return () => {
      newTimeline.destroy();
      document.removeEventListener('mouseout', handleMouseOut);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (timelineRef.current && timeline) {
      options.start && options.end && timeline.setWindow(new Date(options.start), new Date(options.end));
      timeline.setCustomTime(new Date(currentTime), 1);
      options.timeAxis && timeline.setOptions({ timeAxis: options.timeAxis });
      timeline.setItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, options.end, options.start, options.timeAxis, items]);

  useEffect(() => {
    if (timelineRef.current && timeline) {
      timeline.off('mouseUp', onClickRef.current);
      onClickRef.current = (properties: TimelineEventPropertiesResult): void => {
        const event = timeline.getEventProperties(properties.event);
        onclick(event.time);
      };
      timeline.on('mouseUp', onClickRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onclick]);

  useEffect(() => {
    if (timelineRef.current && timeline) {
      timeline.off('itemover', onItemOver.current);
      onItemOver.current = (properties: TimelineEventPropertiesResult): void => {
        const event = timeline.getEventProperties(properties.event);
        const className = items.find((item) => item.id === event.item)?.className;

        if (className) {
          setTooltip({
            positionX: event.pageX,
            positionY: event.pageY,
            className,
          });
        }
      };
      timeline.on('itemover', onItemOver.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <Box ref={timelineRef} className={className} width="100%" style={{ containerType: 'size' }}>
      {events.map((event) => (
        <TimelineEvent event={event} key={event.id} timelineStart={timelineStart} timelineEnd={timelineEnd} />
      ))}
      {tooltip && (
        <Portal>
          <Text
            position="fixed"
            top={tooltip.positionY}
            left={tooltip.positionX + 20}
            padding={1}
            backgroundColor="neutral.900"
            zIndex={2}
            pointerEvents="none"
          >
            {formatMessage({ id: `replay.timeline.dataState.${tooltip.className}` })}
          </Text>
        </Portal>
      )}
    </Box>
  );
}
