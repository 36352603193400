import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectAutomatonCabinetSectionIconFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import {
  automatonSectionIconLabelLayer,
  automatonSectionIconLayer,
  perimeterPortalLayer,
} from './automatonSection.layer';

export function AutomatonSectionIconLayer() {
  const segmentIconFeatures = useSelectorWithReplayMode(selectAutomatonCabinetSectionIconFeatures);

  if (!isFeatureCollection(segmentIconFeatures)) return null;

  return (
    <Source id={SourceNameEnum.AUTOMATON_SECTION_ICONS} type="geojson" data={segmentIconFeatures} generateId>
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_LABELS} {...automatonSectionIconLabelLayer} />
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_ICONS} {...automatonSectionIconLayer} />
      <Layer key={LayerNameEnum.PERIMETER_PORTALS} {...perimeterPortalLayer} />
    </Source>
  );
}
