import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Button, TabPanel, Text, useBoolean } from '@chakra-ui/react';
import GroupSelect from '@components/cockpit/panels/admin/GroupSelect';
import { useAppSelector } from '@hooks/redux.hooks';
import {
  hasAdminRole,
  hasRoles,
  isSuperAdmin,
  selectActiveUser,
  selectDenormalizedUsers,
} from '@redux/authent/authent.selectors';
import { RootState } from '@redux/store';
import { APP } from '@services/authent/authent.api';
import { useDeleteUserMutation, usePatchReinitializePasswordMutation } from '@services/authent/user.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';

import UserForm from './UserForm';

const ROLES = [
  RoleEnum.CREATE_USER,
  RoleEnum.EDIT_USER,
  RoleEnum.DELETE_USER,
  RoleEnum.ADMIN,
  RoleEnum.ACCESS_GROUP,
  RoleEnum.ACCESS_PROFILE,
];

function Users() {
  const { formatMessage } = useIntl();
  const users = useAppSelector(selectDenormalizedUsers).filter((user) => user.login !== APP);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
  const [editMode, setEditMode] = useBoolean(false);
  const [reinitializePassword] = usePatchReinitializePasswordMutation();
  const [deleteUser] = useDeleteUserMutation();
  const activeUser = useAppSelector(selectActiveUser);
  const isActiveUser = selectedUserId === activeUser.id;
  const roleSet = useAppSelector((state: RootState) => hasRoles(state, ROLES));
  const selectedUserIsSuperAdmin = useAppSelector((state) => isSuperAdmin(state, selectedUserId ?? ''));
  const selectedUserIsAdmin = useAppSelector((state) => hasAdminRole(state, selectedUserId ?? ''));

  function canManage() {
    if (!isActiveUser && selectedUserId && !selectedUserIsSuperAdmin) {
      if (roleSet?.has(RoleEnum.ADMIN)) {
        return true;
      } else if (roleSet?.has(RoleEnum.ACCESS_GROUP) && roleSet.has(RoleEnum.ACCESS_PROFILE)) {
        return !selectedUserIsAdmin;
      }
    }
    return false;
  }

  function handleReinitialize() {
    if (selectedUserId) {
      reinitializePassword({ id: selectedUserId })
        .unwrap()
        .then(() => {
          setSelectedUserId(undefined);
          createToast(formatMessage({ id: 'components.admin.users.message.resetPassword' }), ToastStatusEnum.SUCCESS);
        });
    }
  }

  return (
    <TabPanel height="100%" backgroundColor="white" padding={3}>
      <HStack alignItems="start" gap={3}>
        <HStack>
          <VStack gap={3} alignItems="start">
            <Text color="neutral.black">
              <FormattedMessage id="components.admin.users.subTitle" />
            </Text>
            <UserForm
              user={users.find((user) => selectedUserId === user.id)}
              editMode={editMode}
              canEdit={canManage()}
              key={`${editMode}`}
              onReset={setEditMode.off}
            />
            <HStack>
              <VStack
                width="300px"
                border="1px solid"
                padding={0.5}
                alignItems="start"
                gap={0.25}
                height="180px"
                overflowY="auto"
              >
                {users.map((user) => (
                  <Box
                    key={user.id}
                    pointerEvents="all"
                    cursor="pointer"
                    onClick={() => {
                      setEditMode.off();
                      setSelectedUserId((prev) => (prev === user.id ? undefined : user.id));
                    }}
                  >
                    <Text
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      color="neutral.black"
                      fontWeight={selectedUserId === user.id ? '700' : 'regular'}
                      paddingLeft={2}
                      paddingRight={2}
                    >
                      {`${user.name.toUpperCase()} ${user.firstName}`}
                    </Text>
                  </Box>
                ))}
              </VStack>
              <VStack height="200px">
                <Button
                  isDisabled={!canManage() || selectedUserId === undefined || !roleSet?.has(RoleEnum.EDIT_USER)}
                  width="100%"
                  onClick={setEditMode.toggle}
                >
                  {editMode ? <FormattedMessage id="global.cancel" /> : <FormattedMessage id="global.update" />}
                </Button>
                <Button
                  width="100%"
                  isDisabled={!canManage() || !selectedUserId || !roleSet?.has(RoleEnum.EDIT_USER)}
                  onClick={handleReinitialize}
                >
                  <FormattedMessage id="components.admin.users.resetPassword" />
                </Button>
                <Button
                  width="100%"
                  isDisabled={!canManage() || !selectedUserId || !roleSet?.has(RoleEnum.DELETE_USER)}
                  onClick={() => {
                    if (selectedUserId) {
                      deleteUser(selectedUserId)
                        .unwrap()
                        .then(() => {
                          setSelectedUserId(undefined);
                          createToast(
                            formatMessage({ id: 'components.admin.users.message.delete' }),
                            ToastStatusEnum.SUCCESS,
                          );
                        });
                    }
                  }}
                >
                  <FormattedMessage id="global.delete" />
                </Button>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
        <GroupSelect users={users} />
      </HStack>
    </TabPanel>
  );
}

export default memo(Users);
