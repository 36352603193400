import { selectActiveUser } from '@redux/authent/authent.selectors';
import { updateNotification, updateNotifications } from '@redux/data/data.reducer';
import { selectAllOngoingNotifications } from '@redux/data/data.selectors';
import { addNotificationToQueue, updateSelectedDoubtCheckSegmentName } from '@redux/global/global.reducer';
import { selectAutomatedLad, selectContext } from '@redux/settings/settings.selectors';
import { RootState } from '@redux/store';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { settingApi } from '@services/config/setting.api';
import { dataApi } from '@services/data/data.api';
import { compareObjects } from '@utils/common.utils';
import { isSegmentNotification } from '@utils/data/notification.utils';

import { ContextEnum } from '@/types/config/screenConfiguration.types';
import {
  NotificationDetails,
  NotificationDetailsParameters,
  NotificationType,
  SegmentAlertLevelEnum,
} from '@/types/data/data.types';
import { RoutesEnum } from '@/types/routes.types';

export const dataMiddleware = createListenerMiddleware();

function extractNotificationsWithNewAlert(originalState: RootState, nextNotifications: NotificationType[]) {
  const currentNotifications = selectAllOngoingNotifications(originalState);
  return nextNotifications.filter((nextNotification) => {
    const oldAlertcount = currentNotifications.find(
      (currentNotification) => currentNotification.id === nextNotification.id,
    )?.alertCount;
    return !oldAlertcount || nextNotification.alertCount - oldAlertcount > 0;
  });
}

function hasNotificationProcedureChanged(originalState: RootState, nextNotification: NotificationType) {
  const oldVersion: NotificationType | undefined = (
    Object.values(originalState.dataApi.queries).find(
      (obj) =>
        obj?.endpointName === 'getDetail' &&
        obj.status === 'fulfilled' &&
        (obj.originalArgs as NotificationDetailsParameters).id === nextNotification.id,
    )?.data as NotificationDetails | undefined
  )?.notification;
  return (
    oldVersion !== undefined &&
    !compareObjects(oldVersion, nextNotification, [
      'nbSteps',
      'currentStepRank',
      'currentStepType',
      'procedureState',
      'alertCount',
    ])
  );
}

function shouldRun() {
  // This prevents the multiple execution of this middleware
  return sessionStorage.getItem('route') === RoutesEnum.C2;
}

dataMiddleware.startListening({
  actionCreator: updateNotification,
  effect: async ({ payload: nextNotification }, listenerApi) => {
    if (!shouldRun()) {
      return;
    }
    const originalState = listenerApi.getOriginalState() as RootState;
    if (hasNotificationProcedureChanged(originalState, nextNotification)) {
      listenerApi.dispatch(dataApi.util.invalidateTags([{ type: 'Notification', id: nextNotification.id }]));
    }
  },
});

dataMiddleware.startListening({
  actionCreator: updateNotifications,
  effect: async ({ payload: modelDataList }, listenerApi) => {
    if (!shouldRun()) {
      return;
    }
    const originalState = listenerApi.getOriginalState() as RootState;
    const newState = listenerApi.getState() as RootState;
    const nextNotifications = modelDataList.dataList;
    const notificationWithUpdatedProcedure = nextNotifications.filter((nextNotification) =>
      hasNotificationProcedureChanged(originalState, nextNotification),
    );

    if (notificationWithUpdatedProcedure.length > 0) {
      listenerApi.dispatch(
        dataApi.util.invalidateTags(
          notificationWithUpdatedProcedure.map((notification) => ({ type: 'Notification', id: notification.id })),
        ),
      );
    }

    const notificationsWithNewAlert = extractNotificationsWithNewAlert(originalState, nextNotifications);

    notificationsWithNewAlert.forEach((newNotification) => {
      listenerApi.dispatch(addNotificationToQueue(newNotification));
    });

    const isLADAutomated = selectAutomatedLad(newState);
    const isLive = selectContext(newState) === ContextEnum.LIVE;
    if (isLADAutomated && isLive) {
      const activeUser = selectActiveUser(newState);
      const perimeterNotificationWithNewAlert = notificationsWithNewAlert
        .filter(isSegmentNotification)
        .filter((newNotification) => newNotification.lastAlertLevel === SegmentAlertLevelEnum.PERIMETER_EFFRACTION);
      if (perimeterNotificationWithNewAlert.length > 0) {
        listenerApi.dispatch(
          settingApi.endpoints.patchContext.initiate({
            login: activeUser.login,
            context: ContextEnum.DOUBT_PERIM,
          }),
        );
        listenerApi.dispatch(updateSelectedDoubtCheckSegmentName(perimeterNotificationWithNewAlert[0].segmentName));
      }
    }
  },
});
