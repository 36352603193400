import '@components/common/layout/scrollbar.scss';

import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { Center, Spinner, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { AlertType, NotificationTypeEnum } from '@/types/data/data.types';

import Alert from './Alert';

type Props = {
  alerts: AlertType[];
  notificationType: NotificationTypeEnum;
  isLoading?: boolean;
  isArchived?: boolean;
};

export default function AlertList({
  alerts,
  notificationType,
  isLoading = false,
  isArchived = false,
}: Readonly<Props>) {
  if (isLoading) {
    return (
      <Center backgroundColor="neutral.900" paddingY={3} width="100%" zIndex={3}>
        <Spinner color="neutral.white" size="md" />
      </Center>
    );
  }

  return (
    <VStack
      backgroundColor="neutral.900"
      width="100%"
      height="100%"
      minHeight={isArchived ? 'max-content' : undefined}
      alignItems="start"
      zIndex={3}
      gap={0.25}
      overflowY="auto"
      userSelect="none"
      className="cockpit-scrollbar"
      marginTop="-2px"
    >
      {alerts.length === 0 ? (
        <Center width="100%" height="100%" padding={3} backgroundColor="neutral.black">
          <VStack width="100%">
            {!isArchived && <EmptyPanelDroneIcon />}
            <Text fontSize="16px" color="neutral.200">
              <FormattedMessage id="components.notification.noAlert" />
            </Text>
          </VStack>
        </Center>
      ) : (
        alerts
          .concat(alerts)
          .concat(alerts)
          .concat(alerts)
          .map((alert) => (
            <Alert key={alert.id} alert={alert} notificationType={notificationType} isArchived={isArchived} />
          ))
      )}
    </VStack>
  );
}
