import 'video.js/dist/video-js.css';

import { Box } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectReplaySettings, selectReplaySpeed } from '@redux/replay/replay.selectors';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { EPSILON } from '@utils/common.utils';
import { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

import { PlayerProps } from './Player';

const LATENCY_MARGIN = 8_000;
const BASE_SEQUENCE_DURATION = 60_000;
const LIVE_DELAY = 2_000;

export function ReplayPlayer(props: Readonly<PlayerProps>) {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);

  const replaySettings = useAppSelector(selectReplaySettings);
  const latencyMargin = replaySettings?.videoLatencyMargin ?? LATENCY_MARGIN;
  const baseSequenceDuration = replaySettings?.videoBaseSequenceDuration ?? BASE_SEQUENCE_DURATION;

  const replayTimeString = useAppSelector((state) => selectSituationTime(state, true));
  const replayTime = (replayTimeString ? new Date(replayTimeString) : new Date()).getTime();
  const replaySpeed = useAppSelector(selectReplaySpeed);
  const isPlaying = !!replaySpeed && Math.abs(replaySpeed) > EPSILON;

  const [playTime, setPlayTime] = useState(replayTime);
  const [sequenceDuration, setSequenceDuration] = useState(baseSequenceDuration);

  function setSrc(sourceUrl: string, playTime: number, sequenceDuration: number) {
    try {
      const src = `${sourceUrl.replace('ws', 'http')}/playlist_dvr_range-${playTime
        .toString()
        .slice(0, -3)}-${Math.round(sequenceDuration / 1000)}.m3u8`;

      if (src !== playerRef.current?.currentSrc()) {
        playerRef.current?.currentTime(0);
        playerRef.current?.src({
          src,
          type: 'application/x-mpegURL',
        });
      }
    } catch (_) {
      console.error('[Player] player could not update sourceUrl');
    }
  }

  function isLaggingBehind(currentTime: number) {
    return replayTime && currentTime && playTime + currentTime + latencyMargin < replayTime;
  }

  function isPlayingAhead(currentTime: number) {
    return replayTime && currentTime && playTime + currentTime - latencyMargin > replayTime;
  }

  function hasReachedEnd(currentTime: number) {
    return replayTime && (replayTime > playTime + sequenceDuration || currentTime > sequenceDuration - latencyMargin);
  }

  function isPlayerAheadOrBehindReplayTime(currentTime: number) {
    return (
      replayTime &&
      (playTime + currentTime + latencyMargin < replayTime || playTime + currentTime - latencyMargin > replayTime)
    );
  }

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(
        videoElement,
        {
          inactivityTimeout: 0,
          muted: false,
          fluid: true,
          autoplay: false,
          controls: false,
          preload: 'auto',
          playbackRates: [1],
        },
        () => {
          setSrc(props.src, playTime, sequenceDuration);
        },
      );
      isPlaying && playerRef.current.play()?.catch(console.log);
    } else {
      setSrc(props.src, playTime, sequenceDuration);
      isPlaying ? playerRef.current?.play()?.catch(console.log) : playerRef.current?.pause();
    }
  }, [props.src, videoRef, playTime, sequenceDuration, isPlaying]);

  useEffect(() => {
    const currentTime = (playerRef.current?.currentTime() ?? new Date().getTime()) * 1000;
    // Update start time
    if (
      // Need to play after end of replay file
      hasReachedEnd(currentTime) ||
      // Player too much ahead or behind replayTime
      isPlayerAheadOrBehindReplayTime(currentTime)
    ) {
      playerRef.current?.pause();
      setPlayTime(replayTime);
      setSequenceDuration(
        Date.now() - playTime < baseSequenceDuration
          ? Math.round(Date.now() - playTime) - LIVE_DELAY
          : baseSequenceDuration,
      );
    } else if (isLaggingBehind(currentTime)) {
      // Player is lagging behind the target replaytime
      playerRef.current?.pause();
      playerRef.current?.currentTime(replayTime - playTime);
    } else if (isPlayingAhead(currentTime)) {
      // Player is a bit ahead of the target replaytime
      playerRef.current?.pause();
      playerRef.current?.currentTime(Math.max(0, replayTime - playTime));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replayTime, playTime, playerRef.current?.currentTime]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <Box data-vjs-player>
      <div id={props.id} ref={videoRef} />
    </Box>
  );
}
