import { TargetStatusEnum } from '@/types/c2/c2.types';
import { MarkEnum } from '@/types/commons/commons.types';
import {
  Procedure,
  ProcedureStateEnum,
  ProcedureStep,
  ProcedureStepStateEnum,
  ProcedureStepTypeEnum,
} from '@/types/config/procedure.types';
import {
  AlertContextEnum,
  AlertStatusEnum,
  AlertTypeEnum,
  DroneAlertLevelEnum,
  DroneNotification,
  DroneNotificationLight,
  NotificationStatusEnum,
  NotificationTypeEnum,
  SegmentAlertLevelEnum,
  SegmentNotification,
  SensorAlertLevelEnum,
  SensorFailureAlert,
  SensorNotification,
  SpaceViolationAlert,
  VerticalTrespassEnum,
} from '@/types/data/data.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { SensorTypeEnum } from '@/types/sensor/configuration.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';
import { DroneClearanceEnum } from '@/types/utm/drone.types';

import { PLATFORM } from './config.constants';

export const DRONE_NOTIFICATION_WARNING: DroneNotificationLight = {
  id: 0,
  site: 'local',
  identifier: 'drone xyz',
  displayId: null,
  type: NotificationTypeEnum.DRONE,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:10:13.721Z',
  expiredTime: null,
  occurrenceCount: 12,
  alertCount: 3,
  currentAlertCount: 2,
  worstAlertLevel: DroneAlertLevelEnum.DRONE_WARNING,
  currentWorstAlertLevel: DroneAlertLevelEnum.DRONE_WARNING,
  lastAlertLevel: DroneAlertLevelEnum.DRONE_WARNING,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  currentHeight: 50,
  maxHeight: 150,
  targetStatus: TargetStatusEnum.ONGOING,
  classification: ClassificationEnum.SUSPECT,
  hasFlightPlan: true,
  trackIds: [
    {
      id: '0',
      sensorCode: 'code',
      sensorType: SensorTypeEnum.ADSB,
      appCode: 'sensor_local',
    },
  ],
  reliability: ReliabilityEnum.SURE,
  clearance: DroneClearanceEnum.FULL_CLEARANCE,
  lastDetectionTime: '2025-01-20T19:08:13.721Z',
  procedureState: ProcedureStateEnum.TO_PROCESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const DRONE_NOTIFICATION_CRITICAL: DroneNotificationLight = {
  id: 1,
  site: 'local',
  identifier: 'drone xyz',
  displayId: null,
  occurrenceCount: 1,
  type: NotificationTypeEnum.DRONE,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:09:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 2,
  worstAlertLevel: DroneAlertLevelEnum.DRONE_CRITICAL,
  currentWorstAlertLevel: DroneAlertLevelEnum.DRONE_CRITICAL,
  lastAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  currentHeight: 50,
  maxHeight: 150,
  targetStatus: TargetStatusEnum.ONGOING,
  classification: ClassificationEnum.ENEMY,
  hasFlightPlan: true,
  trackIds: [
    {
      id: '0',
      sensorCode: 'code',
      sensorType: SensorTypeEnum.ADSB,
      appCode: 'sensor_local',
    },
  ],
  reliability: ReliabilityEnum.SURE,
  clearance: DroneClearanceEnum.NO_CLEARANCE,
  lastDetectionTime: '2025-01-21T13:08:13.721Z',
  procedureState: ProcedureStateEnum.TO_PROCESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const DRONE_NOTIFICATION_INFO: DroneNotificationLight = {
  id: 2,
  site: 'local',
  identifier: 'drone xyz',
  displayId: null,
  type: NotificationTypeEnum.DRONE,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:08:13.721Z',
  expiredTime: null,
  occurrenceCount: 2,
  alertCount: 3,
  currentAlertCount: 2,
  worstAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  currentWorstAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  lastAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  currentHeight: 50,
  maxHeight: 150,
  targetStatus: TargetStatusEnum.ONGOING,
  classification: ClassificationEnum.WATCH,
  hasFlightPlan: true,
  trackIds: [
    {
      id: '0',
      sensorCode: 'code',
      sensorType: SensorTypeEnum.ADSB,
      appCode: 'sensor_local',
    },
  ],
  reliability: ReliabilityEnum.SURE,
  clearance: DroneClearanceEnum.FULL_CLEARANCE_WITHIN_FLIGHTPLAN,
  lastDetectionTime: '2025-02-20T13:08:13.721Z',
  procedureState: ProcedureStateEnum.TO_PROCESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const FULL_DRONE_NOTIFICATION_CRITICAL: DroneNotification = {
  ...DRONE_NOTIFICATION_CRITICAL,
  trajectory: [
    {
      latitude: 48,
      longitude: 2,
      type: '2D',
    },
    {
      latitude: 48,
      longitude: 3,
      type: '2D',
    },
  ],
  detectedDrones: {
    id: 1,
    site: 'local',
    sensorType: SensorTypeEnum.ADSB,
    collaborativeId: 'collaborative-id',
    occurrences: 2,
    lastOccurrenceTime: '',
    worstClassification: ClassificationEnum.SUSPECT,
  },
};

export const SEGMENT_FAILURE_NOTIFICATION: SegmentNotification = {
  id: 4,
  site: 'local',
  identifier: 'failure x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:07:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  currentWorstAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  lastAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
    {
      id: 1,
      type: MarkEnum.PLATFORM,
      context: 'CDG1',
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  segmentStatus: SensorStatusEnum.DEGRADED,
  procedureState: ProcedureStateEnum.IN_PROGRESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const PERIMETER_EFFRACTION_NOTIFICATION: SegmentNotification = {
  id: 3,
  site: 'local',
  identifier: 'effraction x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:07:33.721Z',
  expiredTime: '2025-01-21T13:06:13.721Z',
  alertCount: 3,
  currentAlertCount: 1,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  currentWorstAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  lastAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
    {
      id: 1,
      type: MarkEnum.PLATFORM,
      context: 'CDG1',
    },
  ],
  status: NotificationStatusEnum.EXPIRED,
  segmentStatus: SensorStatusEnum.EFFRACTION,
  procedureState: ProcedureStateEnum.TO_PROCESS,
  currentStepRank: null,
  currentStepType: null,
  nbSteps: 3,
};

export const OLD_PERIMETER_EFFRACTION_NOTIFICATION: SegmentNotification = {
  id: 5,
  site: 'local',
  identifier: 'effraction x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:06:13.721Z',
  expiredTime: '2025-01-21T13:07:13.721Z',
  alertCount: 3,
  currentAlertCount: 1,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  currentWorstAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  lastAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.EXPIRED,
  segmentStatus: SensorStatusEnum.DEGRADED,
  procedureState: ProcedureStateEnum.IN_PROGRESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const SENSOR_DISCONNECTED_NOTIFICATION: SensorNotification = {
  id: 6,
  site: 'local',
  identifier: 'disconnected x',
  sensorName: 'Aeroscope',
  type: NotificationTypeEnum.SENSOR,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T12:05:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  worstAlertLevel: SensorAlertLevelEnum.SENSOR_DISCONNECTED,
  currentWorstAlertLevel: SensorAlertLevelEnum.SENSOR_DISCONNECTED,
  lastAlertLevel: SensorAlertLevelEnum.SENSOR_DISCONNECTED,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  sensorType: SensorTypeEnum.AEROSCOPE,
  sensorStatus: SensorStatusEnum.DISCONNECTED,
  procedureState: ProcedureStateEnum.IN_PROGRESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const SENSOR_FAILURE_NOTIFICATION: SensorNotification = {
  id: 7,
  site: 'local',
  identifier: 'failure x',
  sensorName: 'Aeroscope',
  type: NotificationTypeEnum.SENSOR,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T12:04:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  worstAlertLevel: SensorAlertLevelEnum.SENSOR_FAILURE,
  currentWorstAlertLevel: SensorAlertLevelEnum.SENSOR_FAILURE,
  lastAlertLevel: SensorAlertLevelEnum.SENSOR_FAILURE,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  sensorType: SensorTypeEnum.AEROSCOPE,
  sensorStatus: SensorStatusEnum.DEGRADED,
  procedureState: ProcedureStateEnum.IN_PROGRESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const SEGMENT_DISCONNECTED_NOTIFICATION: SegmentNotification = {
  id: 8,
  site: 'local',
  identifier: 'disconnected x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T12:03:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.SEGMENT_DISCONNECTED,
  currentWorstAlertLevel: SegmentAlertLevelEnum.SEGMENT_DISCONNECTED,
  lastAlertLevel: SegmentAlertLevelEnum.SEGMENT_DISCONNECTED,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
    {
      id: 1,
      type: MarkEnum.PLATFORM,
      context: 'CDG1',
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  segmentStatus: SensorStatusEnum.DISCONNECTED,
  procedureState: ProcedureStateEnum.IN_PROGRESS,
  currentStepRank: 0,
  currentStepType: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  nbSteps: 3,
};

export const SPACE_VIOLATION_ALERT: SpaceViolationAlert = {
  id: 0,
  site: 'local',
  identifier: 'alert',
  startTime: new Date().toISOString(),
  type: AlertTypeEnum.SPACE_VIOLATION,
  context: AlertContextEnum.OUTSIDE_FLIGHT_PLAN,
  level: DroneAlertLevelEnum.DRONE_CRITICAL,
  endTime: null,
  ackTime: null,
  ackUser: null,
  confirmationTime: null,
  confirmationUser: null,
  archivedTime: null,
  archivingUser: null,
  parentId: 1,
  zoneId: 1,
  zoneName: 'Zone critique',
  heightMin: 0,
  heightMax: 100,
  marks: [],
  verticalTrespass: VerticalTrespassEnum.ABOVE_MAX_HEIGHT,
  status: AlertStatusEnum.ACKNOWLEDGED,
};

export const SENSOR_FAILURE_ALERT: SensorFailureAlert = {
  id: 1,
  site: 'local',
  identifier: 'alert',
  startTime: new Date().toISOString(),
  type: AlertTypeEnum.SENSOR_FAILURE,
  context: AlertContextEnum.SENSOR_FAILURE,
  level: SensorAlertLevelEnum.SENSOR_FAILURE,
  endTime: null,
  ackTime: null,
  ackUser: null,
  confirmationTime: null,
  confirmationUser: null,
  archivedTime: null,
  archivingUser: null,
  parentId: 1,
  marks: [],
  status: AlertStatusEnum.ACKNOWLEDGED,
};

export const PROCEDURE_ACK_STEP: ProcedureStep = {
  id: 0,
  code: 'step1',
  type: ProcedureStepTypeEnum.ACKNOWLEDGEMENT,
  state: ProcedureStepStateEnum.CONFIRMED,
  mandatory: true,
  rank: 0,
  phoneNumber: null,
  instructions: [],
  modifiedBy: 'killian',
  modificationTime: new Date().toISOString(),
};

export const PROCEDURE: Procedure = {
  id: 0,
  name: 'code',
  site: 'local',
  state: ProcedureStateEnum.IN_PROGRESS,
  steps: [
    PROCEDURE_ACK_STEP,
    {
      id: 1,
      code: 'step2',
      type: ProcedureStepTypeEnum.CONFIRMATION,
      state: ProcedureStepStateEnum.DENIED,
      mandatory: true,
      rank: 1,
      phoneNumber: null,
      instructions: [],
      modifiedBy: 'killian',
      modificationTime: new Date().toISOString(),
    },
    {
      id: 2,
      code: 'step3',
      type: ProcedureStepTypeEnum.PHONE_CALL,
      state: ProcedureStepStateEnum.IN_PROGRESS,
      mandatory: false,
      rank: 2,
      phoneNumber: '012345678',
      instructions: [
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
      ],
      modifiedBy: 'killian',
      modificationTime: new Date().toISOString(),
    },
    {
      id: 3,
      code: 'step4',
      type: ProcedureStepTypeEnum.CLOSING,
      state: ProcedureStepStateEnum.TO_BE_STARTED,
      mandatory: true,
      rank: 3,
      phoneNumber: null,
      instructions: [],
      modifiedBy: null,
      modificationTime: null,
    },
    {
      id: 4,
      code: 'step5',
      type: ProcedureStepTypeEnum.CLOSING,
      state: ProcedureStepStateEnum.TO_BE_STARTED,
      mandatory: true,
      rank: 4,
      phoneNumber: null,
      instructions: [
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
        "Ceci est une consigne de test pour vérifier que l'affichage est correct",
      ],
      modifiedBy: null,
      modificationTime: null,
    },
  ],
};
