import { Box, VStack } from '@chakra-ui/react';
import { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { ProcedureStep, ProcedureStepStateEnum, ProcedureStepTypeEnum } from '@/types/config/procedure.types';

import AcknowledgementStep from './step/AcknowledgementStep';
import ClosingStep from './step/ClosingStep';
import ConfirmationStep from './step/ConfirmationStep';
import EventCreationStep from './step/EventCreationStep';
import FinishedStep from './step/FinishedStep';
import PhoneCallStep from './step/PhoneCallStep';

function getProcedureComponent(param: {
  step: ProcedureStep;
  notificationId: number;
  procedureId: number;
  canManage: boolean;
}) {
  switch (param.step.type) {
    case ProcedureStepTypeEnum.ACKNOWLEDGEMENT:
      return <AcknowledgementStep {...param} key={param.step.id} />;
    case ProcedureStepTypeEnum.CONFIRMATION:
      return <ConfirmationStep {...param} key={param.step.id} />;
    case ProcedureStepTypeEnum.PHONE_CALL:
      return <PhoneCallStep {...param} key={param.step.id} />;
    case ProcedureStepTypeEnum.CLOSING:
      return <ClosingStep {...param} key={param.step.id} />;
    case ProcedureStepTypeEnum.EVENT_CREATION:
      return <EventCreationStep {...param} key={param.step.id} />;
  }
}

type Props = {
  step: ProcedureStep;
  notificationId: number;
  procedureId: number;
  canManage: boolean;
};

function ProcedureStepDetail({ step, notificationId, procedureId, canManage }: Readonly<Props>) {
  if (
    step.state === ProcedureStepStateEnum.CONFIRMED ||
    step.state === ProcedureStepStateEnum.IGNORED ||
    step.state === ProcedureStepStateEnum.DENIED
  ) {
    return (
      <>
        <FinishedStep step={step} />
        <Box flex={1} backgroundColor="neutral.700" width="100%" height="100%" />
      </>
    );
  }

  return (
    <VStack gap={0.25} height="100%" width="100%" overflow="hidden">
      {getProcedureComponent({ step, notificationId, procedureId, canManage })}
    </VStack>
  );
}

export default memo(ProcedureStepDetail, shallowEqual);
