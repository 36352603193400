import { TabPanel, Text } from '@chakra-ui/react';

type Props = {
  instruction: string;
};

export default function FlightPlanInstructions({ instruction }: Readonly<Props>) {
  return (
    <TabPanel height="100%">
      <Text
        fontSize="18px"
        height="100%"
        width="100%"
        whiteSpace="pre-line"
        color="neutral.200"
        backgroundColor="neutral.700"
        padding={4}
      >
        {instruction}
      </Text>
    </TabPanel>
  );
}
