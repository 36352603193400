import { ReactComponent as NotificationDefault } from '@assets/components/panel/platform/notification-default.svg';
import { ReactComponent as NotificationStatusIcon } from '@assets/components/panel/platform/notification-status.svg';
import { Center, HStack } from '@chakra-ui/layout';
import { Icon, Text } from '@chakra-ui/react';
import CustomCheckbox from '@components/common/inputs/CustomCheckbox';
import { getAlertLevelColor } from '@utils/data/notification.utils';

import type { Platform } from '@/types/config/config.types';
import { AlertLevelEnum } from '@/types/data/data.types';

export type NotificationInfo = {
  count: number;
  maxLevel: AlertLevelEnum | null;
};

type Props = {
  platform: Platform;
  notificationsInfo: {
    LAD: NotificationInfo;
    PERIM: NotificationInfo;
    TECH: NotificationInfo;
  };
};

function getIcon(notificationInfo: NotificationInfo) {
  const { count, maxLevel } = notificationInfo;
  if (count === 0 || maxLevel == null) {
    return <Icon as={NotificationDefault} width="10px" height="10px" />;
  } else {
    return (
      <>
        <Icon
          as={NotificationStatusIcon}
          width="28px"
          height="28px"
          fill={getAlertLevelColor(maxLevel, 800)}
          color={getAlertLevelColor(maxLevel, 500)}
        />
        <Text position="absolute">{count}</Text>
      </>
    );
  }
}

export default function Platform({ platform, notificationsInfo }: Readonly<Props>) {
  return (
    <HStack gap={2} paddingRight={2} paddingY={0.5} width="100%" height="48px" position="relative">
      <CustomCheckbox isChecked={true} />
      <Text fontWeight="medium" noOfLines={2} flex={1}>
        {platform.name}
      </Text>
      <HStack paddingX={0.5} gap={2} marginLeft="auto">
        <Center width="24px">{getIcon(notificationsInfo.LAD)}</Center>
        <Center width="24px">{getIcon(notificationsInfo.PERIM)}</Center>
        <Center width="24px">{getIcon(notificationsInfo.TECH)}</Center>
      </HStack>
    </HStack>
  );
}
